import React from 'react'
import classNames from 'classnames'

const PlayIcon = ({
  wrapperClasses = '',
  iconClasses = '',
  isGoldIcon = false,
  onClick,
  useSVGIcon = false,
  isPlaying = false,
}) => {
  return (
    <div className={classNames(wrapperClasses)}>
      {useSVGIcon ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 48 48'
          fill='none'
          className={classNames(
            'me-3 align-self-center cursor-pointer',
            iconClasses,
          )}
          onClick={onClick}>
          <circle cx='24' cy='24' r='24' fill='#BF9000' />
          {isPlaying ? (
            <>
              <rect x='18' y='16' width='3' height='16' rx='0.5' fill='#fff' />
              <rect x='27' y='16' width='3' height='16' rx='0.5' fill='#fff' />
            </>
          ) : (
            <path
              d='M33.625 23.7835C33.7917 23.8797 33.7917 24.1203 33.625 24.2165L19.375 32.4437C19.2083 32.54 19 32.4197 19 32.2272L19 15.7728C19 15.5803 19.2083 15.46 19.375 15.5563L33.625 23.7835Z'
              fill='white'
            />
          )}
        </svg>
      ) : (
        <span
          className={classNames(
            'icon cursor-pointer',
            {
              'icon-play-media-gold': isGoldIcon,
              'icon-play-media': !isGoldIcon,
            },
            iconClasses,
          )}
          onClick={onClick}>
          <span className='path1'></span>
          <span className='path2'></span>
        </span>
      )}
    </div>
  )
}

export default PlayIcon
