import { Fancybox } from '@fancyapps/ui'
// import '@fancyapps/ui/dist/fancybox/fancybox.css'

const FancyboxPopup = (url) => {
  Fancybox.show([
    {
      src: url,
      type: 'iframe',
      iframe: {
        preload: false,
      },
    },
  ])
}

export default FancyboxPopup
