import React from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import { isLGScreen } from '../../utils/utility'

const MediaPageTitle = ({
  title,
  description,
  icon,
  tooltip,
  extraDescription = '',
}) => {
  return (
    <>
      <div className='d-flex pt-3 pb-2 space-x-2 align-items-center position-relative d-lg-none bg-white justify-content-center'>
        {icon}
        <h3
          className={classnames(
            'font-open-sans font-22 align-items-center d-flex fw-bold leading-relaxed letter-spacing-minus-04 text-black',
            {
              'mb-0': !isLGScreen,
            },
          )}>
          {title}
          {tooltip}
        </h3>
      </div>
      {description && (
        <div className='d-flex d-lg-none justify-content-center text-gray-800 pt-1 font-open-sans font-14 fw-semibold leading-6 letter-spacing-minus-02'>
          <Container className='text-center'>
            {description}
            {extraDescription}
          </Container>
        </div>
      )}
      <Container className='pt-4 d-none d-lg-block'>
        <Row>
          <Col className='text-center d-flex flex-row flex-lg-column justify-content-center align-items-center space-x-3 space-lg-x-0'>
            {icon}
            <h2 className='title font-open-sans w-100 d-flex align-items-center justify-content-center fw-bold leading-9 letter-spacing-minus-04 text-black position-relative font-28'>
              {tooltip}
              {title}
            </h2>
            {description && (
              <span className='text-gray-800 font-open-sans font-14 font-semibold leading-6 letter-spacing-minus-02'>
                {description}
                {extraDescription}
              </span>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

MediaPageTitle.defaultProps = {
  tooltip: null,
}

export default MediaPageTitle
