import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AssessmentScoreCard from './AssessmentScoreCard'
import { conditionMatched } from '../layout'

const OverviewSurveyResults = ({
  survey,
  onLearnMoreClick,
  wrapperClasses = '',
  titlePosition = 'bottom',
  titleClasses = '',
}) => {
  if (survey?.inTransition) return null
  return (
    <Row xs={1} sm={1} md={1} lg={3} xl={3} className={wrapperClasses}>
      <Col className='position-relative text-center'>
        <AssessmentScoreCard
          title='Self Awareness'
          percentage={survey?.results?.self_awareness?.percent}
          category={survey?.categories.find(
            (categories) => categories?.key === 'self_awareness',
          )}
          onLearnMoreClick={onLearnMoreClick}
          outerBackgroudColor='rgba(10, 34, 60, 0.25)'
          innerBackgroudColor='#0A223C'
          width={conditionMatched ? '10rem' : '15.79rem'}
          height='14.745rem'
          linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
          titlePosition={titlePosition}
          titleClasses={titleClasses}
        />
      </Col>
      <Col className='text-center'>
        <AssessmentScoreCard
          title='Team Cohesion'
          percentage={
            survey?.inTransition
              ? null
              : survey?.results?.team_cohesion?.percent
          }
          category={survey?.categories.find(
            (categories) => categories?.key === 'team_cohesion',
          )}
          onLearnMoreClick={onLearnMoreClick}
          outerBackgroudColor='rgba(206, 129, 71, 0.25)'
          innerBackgroudColor='#CE8147'
          width={conditionMatched ? '10rem' : '15.79rem'}
          height='14.745rem'
          linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
          titlePosition={titlePosition}
          titleClasses={titleClasses}
        />
      </Col>
      <Col className='text-center'>
        <AssessmentScoreCard
          title='Vision & Impact'
          percentage={
            survey?.inTransition
              ? null
              : survey?.results?.vision__impact?.percent
          }
          category={survey?.categories.find(
            (categories) => categories?.key === 'vision__impact',
          )}
          onLearnMoreClick={onLearnMoreClick}
          outerBackgroudColor='rgba(239, 192, 48, 0.25)'
          innerBackgroudColor='#EFC030'
          width={conditionMatched ? '10rem' : '15.79rem'}
          height='14.745rem'
          linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
          titlePosition={titlePosition}
          titleClasses={titleClasses}
        />
      </Col>
    </Row>
  )
}

export default OverviewSurveyResults
