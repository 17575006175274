import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation, NavLink } from 'react-router-dom'
import { NavTabs, useErrorService, ABSpinner } from '@abroad/components'
import { Helmet } from 'react-helmet'
import { FreePlanNoSurvey, MediaList } from '../components'
import API from '../utils/API'
import mediaLibrary from '../constants/mediaLibrary'
import {
  PageHeader,
  UpgradePlanModal,
  UpgradePlanModalProvider,
} from '../components/common'
const { mediaCategories } = mediaLibrary

const findMediaCategory = (id) => {
  const category = mediaCategories.find((category) => category.id === id)
  return category
}

const MediaLibrary = () => {
  const { id } = useParams()
  const [mediaLibraryId, setMediaLibraryId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [categoryArray, setCategoryArray] = useState(null)
  const [category, setCategory] = useState(null)
  const [surveyNotTaken, setSurveyNotTaken] = useState(false)
  let history = useHistory()
  const location = useLocation()
  const Error = useErrorService()

  useEffect(() => {
    setCategory(findMediaCategory(mediaLibraryId))
    const getMediaLibraryCategoryData = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.mediaLibrary.getMediaByCategory(
          mediaLibraryId,
        )
        if (data) {
          setCategoryArray(data)
        }
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
          // setPlanUpgradeRequire(true)
        } else if (e?.code === 'not_taken_survey') {
          setIsLoading(false)
          setSurveyNotTaken(true)
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (mediaLibraryId) {
      getMediaLibraryCategoryData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaLibraryId])

  useEffect(() => {
    if (id) {
      setMediaLibraryId(id)
    } else {
      history.replace(`/media-library/${mediaCategories[0].id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${category?.name} | Abroad`}</title>
        <meta name='title' content={`${category?.name} | Abroad`}></meta>
        <meta property='og:title' content={`${category?.name} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${category?.name} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${category?.to}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${category?.to}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${category?.to}`}></meta>
      </Helmet>
    )
  }

  return (
    <UpgradePlanModalProvider>
      {renderHelmet()}
      <UpgradePlanModal />
      <PageHeader title='Media Library' />
      <NavTabs
        pages={mediaCategories.slice(0, 4).map((e) => ({
          path: `/media-library/${e.id}`,
          name: e.name,
        }))}
        pathname={location?.pathname}
        hash={location?.hash}
        NavLink={NavLink}
      />
      <main>
        <section className='mt-40px'>
          {surveyNotTaken && <FreePlanNoSurvey />}
          {isLoading && !surveyNotTaken ? (
            <ABSpinner />
          ) : (
            categoryArray &&
            categoryArray.map((category) => (
              <MediaList
                category={category}
                key={category.title}
                rowMarginBottom='mb-52px'
              />
            ))
          )}
        </section>
      </main>
    </UpgradePlanModalProvider>
  )
}

export default MediaLibrary
