import React from 'react'
import classNames from 'classnames'

const InfoIcon = ({ className, fillColor = 'black', ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      className={classNames('cursor-pointer', className)}
      {...rest}
    >
      <path
        d='M11.3357 14.0886C11.1312 14.1077 10.9259 14.0615 10.7493 13.9568C10.6202 13.8243 10.5584 13.6403 10.5811 13.4568C10.5859 13.3039 10.6041 13.1518 10.6357 13.0022C10.6662 12.8306 10.7056 12.6607 10.7538 12.4931L11.2902 10.6477C11.3455 10.4655 11.3821 10.2782 11.3993 10.0886C11.3993 9.88402 11.4266 9.74308 11.4266 9.66129C11.438 9.29672 11.2823 8.94687 11.0038 8.71129C10.6613 8.44832 10.2347 8.31906 9.80383 8.34765C9.49504 8.3523 9.18863 8.40285 8.89473 8.49765C8.57352 8.59765 8.23563 8.71734 7.88109 8.85675L7.72656 9.45675C7.83109 9.42039 7.9584 9.37949 8.10383 9.33402C8.24258 9.29293 8.38641 9.27148 8.53109 9.27039C8.73406 9.24839 8.93836 9.29824 9.10836 9.41129C9.22383 9.54906 9.27801 9.72804 9.25836 9.90675C9.25785 10.0596 9.24109 10.212 9.20836 10.3613C9.17652 10.5204 9.13563 10.6886 9.08563 10.8658L8.54473 12.7203C8.50113 12.8927 8.46625 13.0672 8.4402 13.2431C8.41895 13.3937 8.40832 13.5455 8.40836 13.6976C8.40613 14.0647 8.57398 14.4121 8.86289 14.6385C9.2107 14.9056 9.64336 15.038 10.0811 15.0112C10.3893 15.0176 10.6964 14.973 10.9902 14.8794C11.2477 14.7915 11.5917 14.6658 12.022 14.5021L12.1675 13.9294C12.0509 13.9778 11.9309 14.0173 11.8084 14.0476C11.6534 14.083 11.4944 14.0967 11.3357 14.0886Z'
        fill={fillColor}
      />
      <path
        d='M11.904 5.34913C11.6566 5.12194 11.3307 4.99971 10.9949 5.00823C10.6593 5.00065 10.3337 5.12276 10.0858 5.34913C9.63143 5.74092 9.58069 6.4269 9.97253 6.88132C10.0074 6.92182 10.0453 6.95967 10.0858 6.9946C10.6035 7.4576 11.3864 7.4576 11.904 6.9946C12.3583 6.59893 12.406 5.90987 12.0103 5.45549C11.9774 5.41764 11.9419 5.3821 11.904 5.34913Z'
        fill={fillColor}
      />
      <path
        d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10 19.0909C4.97922 19.0909 0.909102 15.0208 0.909102 10C0.909102 4.97922 4.97922 0.909102 10 0.909102C15.0208 0.909102 19.0909 4.97922 19.0909 10C19.0909 15.0208 15.0208 19.0909 10 19.0909Z'
        fill={fillColor}
      />
    </svg>
  )
}

export default InfoIcon
