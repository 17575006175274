import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
  ABSpinner,
  useErrorService,
  leaderAssessments,
  leaderAssessmentsUrl,
} from '@abroad/components'
import { UserRequestSection } from '../leaderSimple360'
import { PageHeader } from '../common'
import API from '../../utils/API'
import { getAssessmentCurrentData } from '../../utils/date'
import { CONSTANTS, leader360Type } from '../../utils/constants'

const RequestedLA = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [requestData, setRequestData] = useState([])
  const [canAddMembers, setCanAddMembers] = useState(true)
  const [canViewResult, setCanViewResult] = useState(false)
  const [leaderDisable, setLeaderDisable] = useState(true)
  const history = useHistory()
  const Error = useErrorService()
  const { id } = useParams()

  useEffect(() => {
    const getLeaderRequest = async () => {
      try {
        const { data } = await API.survey.getLeaderRequests(id)
        if (data) {
          setRequestData(data)
          setIsLoading(false)
          setCanAddMembers(data?.canAddMembers)
          setCanViewResult(data?.canViewResult)
          setLeaderDisable(data.length === 0)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (id !== 'new') {
      getLeaderRequest()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${leaderAssessments} Request | Abroad`}</title>
        <meta
          name='title'
          content={`${leaderAssessments} Request | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${leaderAssessments} Request | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${leaderAssessments} Request | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}`}></meta>
      </Helmet>
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      {renderHelmet()}
      <main>
        <section>
          <PageHeader
            title={leaderAssessments}
            version='small'
            backArrowAction={() => {
              if (history.length > 1) {
                history.goBack()
              } else {
                history.push('/survey/leader-assessments')
              }
            }}
            showBackArrow={true}
          />
          <h3 className='s1 mb-32px'>
            {leaderAssessments} Request -{' '}
            {getAssessmentCurrentData(requestData?.createdAt, 'MMMM Do, YYYY')}
          </h3>
          <UserRequestSection
            requestData={requestData?.receivers || []}
            canViewResult={canViewResult}
            canAddMembers={canAddMembers}
            title={CONSTANTS.LEADER_360_METADATA.leader.headerText}
            description={CONSTANTS.LEADER_360_METADATA.leader.description}
            emailBody={CONSTANTS.LEADER_360_METADATA.leader.emailMessage}
            type={leader360Type.LEADER}
            leaderDisable={leaderDisable}
          />
        </section>
      </main>
    </>
  )
}

export default RequestedLA
