import React from 'react'

const ReturnLeftIcon = ({ ...rest }) => {
  return (
    <svg
      width='12'
      height='19'
      viewBox='0 0 12 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M10.1275 1.60742L2 9.63973'
        stroke='black'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M10.1275 17.672L2 9.63965'
        stroke='black'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ReturnLeftIcon
