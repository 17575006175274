import React, { useState } from 'react'
import classNames from 'classnames'
import { Col, Nav, Row, Tab } from 'react-bootstrap'

const UpcomingQuestsInclusionsInvestments = ({ upcomingpublic, questId }) => {
  const [selectedList, setSelectedList] = useState(1)
  const handleButtonClick = (listNumber) => {
    setSelectedList(listNumber)
  }
  return (
    <>
      <div className='mt-52px'>
        <Row>
          {(upcomingpublic.experientialInclusions &&
            upcomingpublic.experientialInclusions.length > 0) ||
          (upcomingpublic.digitalInclusions &&
            upcomingpublic.digitalInclusions.length > 0) ? (
            <Col xl={6} lg={6} md={6} sm={6} Col className='pe-xl-5'>
              <h1 className='s3 mb-0'>What’s Included </h1>
              <Tab.Container
                activeKey={selectedList.toString()}
                onSelect={(k) => handleButtonClick(Number(k))}>
                <div className='pe-0 header-tabs'>
                  <div className='row mx-0 mb-2'>
                    <Nav className='rounded px-0'>
                      <div className='col-6 border-first'>
                        <Nav.Item>
                          <Nav.Link
                            eventKey='1'
                            className={`border-second w-100  ${
                              selectedList === 1 ? 'active' : ''
                            }`}>
                            EXPERIENTIAL
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className='col-6 border-first'>
                        <Nav.Item>
                          <Nav.Link
                            eventKey='2'
                            className={`border-second w-100 ${
                              selectedList === 2 ? 'active' : ''
                            }`}>
                            digital
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </Nav>
                  </div>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey='1'>
                    {upcomingpublic.experientialInclusions &&
                      upcomingpublic.experientialInclusions.map((name) => (
                        <div
                          className={classNames('s6 mt-2', {
                            show: selectedList === 1,
                            hide: selectedList !== 1,
                          })}>
                          <ul>
                            <li>{name.inclusion}</li>
                          </ul>
                        </div>
                      ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey='2'>
                    {upcomingpublic.digitalInclusions &&
                      upcomingpublic.digitalInclusions.map((name) => (
                        <div
                          className={classNames('s6 mt-2', {
                            show: selectedList === 2,
                            hide: selectedList !== 2,
                          })}>
                          <ul>
                            <li>{name.inclusion}</li>
                          </ul>
                        </div>
                      ))}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          ) : null}
          {upcomingpublic &&
            upcomingpublic.investments &&
            upcomingpublic.investments.standardRate &&
            upcomingpublic.investments.singleSupplement && (
              <Col xl={6} lg={6} md={6} sm={6} Col className='pe-xl-5 mt-5'>
                <div className='bg-abroad-blue rounded py-16px pl-16px pr-29px'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='upgrade-inquiry'>
                      <h2 className='inquiry-title text-white mb-0'>
                        Investment
                      </h2>
                    </div>
                    <span className='s-captions text-saffron-300'>
                      <a
                        className='text-decoration-none'
                        target='blank'
                        href='https://www.abroad.io/terms-and-conditions'>
                        Cancellation Policy
                      </a>
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between mt-44px'>
                    <span className='s-captions text-white'>Standard Rate</span>
                    <div className='upgrade-inquiry'>
                      <h2 className='inquiry-title text-white'>
                        ${upcomingpublic.investments.standardRate}
                      </h2>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <span className='s-captions text-white'>
                      Single Supplement
                    </span>
                    <div className='upgrade-inquiry'>
                      <h2 className='inquiry-title text-white'>
                        ${upcomingpublic.investments.singleSupplement}
                      </h2>
                    </div>
                  </div>
                </div>
              </Col>
            )}
        </Row>
      </div>
    </>
  )
}

export default UpcomingQuestsInclusionsInvestments
