import React, { useState } from 'react'

const ProgressBar = ({ curTime, duration, setClickedTime, bufferSize }) => {
  const [hover, setHover] = useState(false)
  const curPercentage = (curTime / duration) * 100

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX
    let bar = document.querySelector('#media-control-progress-bar')
    if (!bar) {
      bar = document.querySelector('#media-control-progress-bar-2')
    }
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  const handleTimeDrag = (e) => {
    setClickedTime(calcClickedTime(e))

    const updateTimeOnMove = (eMove) => {
      setClickedTime(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  const toggleHover = () => {
    setHover(!hover)
  }

  return (
    <div className='bar mb-2'>
      <div
        className='bar-progress m-0'
        id='media-control-progress-bar'
        style={{
          background: `linear-gradient(to right, rgba(255,255,255,0.30) ${bufferSize}%, rgba(255,255,255,0.5) 0), linear-gradient(to right, ${
            hover ? '#EFC030' : 'white'
          } ${curPercentage}%, rgba(255,255,255,0.5) 0)`,
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onMouseDown={handleTimeDrag}>
        <span
          className='bar-progress-knob'
          style={{ left: `calc(${curPercentage}% - 7px)` }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
