import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request'],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production']
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default ErrorBoundary
export { bugsnagClient }
