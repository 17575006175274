import React, { useState, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import {
  useErrorService,
  FeatherLoader,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import { ProgramModule } from '../../components'
import ContinueProgramEvent from '../../constants/events'

const ContinueYourProgram = () => {
  const Error = useErrorService()
  const { user } = useUserService()
  const [module, setModule] = useState(null)
  const [isLoadingBreakthrough, setIsLoadingBreakthrough] = useState(true)

  useEffect(() => {
    const getCurrentStatus = async () => {
      try {
        const { data } = await API.programs.getCurrentStatus()
        if (data) {
          setModule(data)
        }
        setIsLoadingBreakthrough(false)
      } catch (e) {
        Error.showError(e)
        setIsLoadingBreakthrough(false)
      }
    }
    getCurrentStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gtmOpenContinueProgramAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ContinueProgramEvent.event.openContinueProgram,
        eventProps: {
          category: ContinueProgramEvent.category.openContinueProgramCategory,
          action: 'User open continue program from home page',
          label: 'Open continue program from home page',
          value: `User open continue program from home page, program name is ${module?.title}`,
          userId: user?.id,
        },
      },
    })
  }

  if (isLoadingBreakthrough) {
    return (
      <div className='text-center my-2'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <>
      <div className='s3 mb-3 mt-32px'>Continue Your Program</div>
      <ProgramModule
        isLoading={isLoadingBreakthrough}
        program={module}
        programId={module?.parentId}
        onClick={gtmOpenContinueProgramAction}
      />
    </>
  )
}

export default ContinueYourProgram
