import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link, useHistory, matchPath, useLocation } from 'react-router-dom'
import Logout from './Logout'
import ProfileButton from './ProfileButton'
import { CustomDropdownToggle } from '../../common'

const ProfileDropdown = ({ onLogout }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const history = useHistory()
  const pages = [
    '/profile',
    '/profile/my-information',
    '/ask-question',
    '/profile/plans-payments',
    '/profile/update-password',
    '/upgrade-inquiry',
    '/profile/plans-payments/coaching-plan/:coachingPlanId',
  ]
  const profileArray = [
    '/profile',
    '/profile/my-information',
    '/profile/plans-payments',
    '/profile/update-password',
  ]
  const location = useLocation()
  const isMatched = matchPath(location.pathname, {
    path: pages,
    exact: true,
    strict: false,
  })

  return (
    <Dropdown
      show={showDropdown}
      className='z3 d-flex justify-content-center align-items-center'
      onToggle={() => setShowDropdown(!showDropdown)}>
      <Dropdown.Toggle as={CustomDropdownToggle} id='dropdown-profile'>
        <ProfileButton isActive={showDropdown || isMatched} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        onClick={() => setShowDropdown(!showDropdown)}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
          ],
        }}
        align='right'
        className='profile-dropdown header-drop-down text-start'>
        <Dropdown.Item
          as={Link}
          to='/profile'
          eventKey='2'
          active={profileArray.includes(history.location.pathname)}
          className='text-start z1200'>
          Your Profile
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to='/ask-question'
          eventKey='3'
          active={history.location.pathname === '/ask-question'}
          className='text-start z1200'>
          Ask a Question
        </Dropdown.Item>
        <Logout className='text-start pb-0' onLogout={onLogout} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
