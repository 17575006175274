import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Formik } from 'formik'
import { string, object } from 'yup'
import {
  Button,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import SignupPasswordField from './SignupPasswordField'
import { InputField } from '../form'
import API from '../../utils/API'
import ResetPasswordEvent from '../../constants/events'

const ResetPasswordFormInitialValues = {
  password: '',
  confirmPassword: '',
}

const ResetPasswordForm = () => {
  let { state } = useLocation()
  const Error = useErrorService()
  const Notification = useNotificationService()
  let history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = object().shape({
    password: string()
      .matches(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-=_+\\[\\]{};\':",./<>?\\\\|]).{8,}$',
        '',
      )
      .required('Required'),
    confirmPassword: string()
      .test(
        'password-match',
        'Please Make Sure The Passwords Match',
        function (value) {
          const { password } = this.parent
          return password === value
        },
      )
      .required('\0'),
  })

  useEffect(() => {
    if (!state?.token || !state?.email) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history])

  const gtmUserResetPasswordAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ResetPasswordEvent.event.resetPassword,
        eventProps: {
          category: ResetPasswordEvent.category.resetPasswordCategory,
          action: 'User reset password',
          label: 'Reset password',
          value: `User reset password successfully in ResetPassword page`,
        },
      },
    })
  }

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      const { data } = await API.onboarding.resetPassword({
        password: values.password,
        token: state?.token,
        email: state?.email,
      })
      if (data) {
        setIsLoading(false)
        gtmUserResetPasswordAction()
        Notification.showNotification(
          'Your password has been changed successfully!',
          'success',
        )
        history.replace('/')
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Formik
        initialValues={ResetPasswordFormInitialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ handleSubmit }) => (
          <Row className='g-0'>
            <Col className='d-flex flex-column'>
              <Form onSubmit={handleSubmit}>
                <SignupPasswordField
                  label='NEW PASSWORD'
                  formGroupProps={{
                    className: 'd-flex flex-column position-relative',
                  }}
                  size='lg'
                />
                <InputField
                  formGroupProps={{
                    className: 'mt-40px d-flex flex-column position-relative',
                  }}
                  showErrorMessage
                  name='confirmPassword'
                  type='password'
                  label='CONFIRM NEW PASSWORD'
                  size='lg'
                />
                <Button
                  variant='saffron'
                  className='btn w-100 s9 onbording-action-btn'
                  disabled={isLoading}
                  type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <>Submit</>
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  )
}

export default ResetPasswordForm
