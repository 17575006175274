import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '@abroad/components'
import { useHistory, useLocation } from 'react-router-dom'
import { surveyType } from '../../utils/constants'
const BookCircleModal = ({ user }) => {
  const [showModal, setShowModal] = React.useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (
      ![
        '/schedule-session',
        '/faqs',
        '/schedule-session/individual',
        '/fetching-coaching-loading',
        '/schedule-detail',
      ].includes(location.pathname)
    ) {
      if (
        (user?.wisdomTaken || user?.surveyTaken) &&
        user?.companyId?.isCircleSchedulingEnabled
      ) {
        if (!user?.isInGroup || !user?.isGrowthPlanCreated) {
          setShowModal(true)
        } else {
          setShowModal(false)
        }
      }
    } else {
      setShowModal(false)
    }
  }, [user, location.pathname])

  const handleClose = () => {
    setShowModal(false)
    history.push('/faqs', {
      surveyType: user?.wisdomTaken ? surveyType.WISDOM : surveyType.AWARENESS,
    })
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {}}
      centered
      backdrop='static'
      className='payment-modal'>
      <Modal.Header className='payment-modal-header d-flex justify-content-center'>
        <h3 className='payment-modal-title mb-0'>BOOK YOUR CIRCLE</h3>
      </Modal.Header>
      <Modal.Body className='payment-modal-body pb-0 mb-75px'>
        <h3 className='confirmation-title'>
          {!user?.isInGroup && !user?.isGrowthPlanCreated
            ? 'You have not yet booked your coaching circle or individual growth plan session with your coach. Please do so now…'
            : !user?.isInGroup && user?.isGrowthPlanCreated
            ? 'Please select your coach & circle times.'
            : 'Please book your 1:1 Growth Plan Session.'}
        </h3>
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
        <Button
          variant='saffron'
          size='md'
          onClick={handleClose}
          className='text-uppercase border-radius-normal text-white hover:text-white ms-0'>
          Book
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BookCircleModal
