import React from 'react'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router-dom'
import { Button, plans, useUserService } from '@abroad/components'
import ButtonEvent from '../../constants/events'

const CoachingPlanBtn = ({
  buttonClasses = '',
  buttonText = 'Inquire about Coaching',
}) => {
  const { user } = useUserService()
  const history = useHistory()
  const gtmUpgradeButtonClickedAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ButtonEvent.event.upgradeButton,
        eventProps: {
          category: ButtonEvent.category.upgradeButtonCategory,
          action: 'User click on upgrade button',
          label: 'Click on upgrade button',
          value: `User Click on ${buttonText} button`,
          userId: user?.id,
        },
      },
    })
  }
  return (
    <Button
      variant='saffron'
      className={classNames('btn s10a text-uppercase', buttonClasses)}
      onClick={() => {
        gtmUpgradeButtonClickedAction()
        history.push(`/upgrade-inquiry?plan=${plans.coaching.code}`)
      }}>
      {buttonText}
    </Button>
  )
}

export default CoachingPlanBtn
