import React from 'react'
import classnames from 'classnames'

const DropdownCaretUpIcon = ({ className = '', ...rest }) => {
  return (
    <svg
      className={classnames('cursor-pointer', className)}
      width='12'
      height='12'
      viewBox='0 0 10 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M4.13397 0.5C4.51887 -0.166667 5.48113 -0.166666 5.86603 0.5L9.33013 6.5C9.71503 7.16667 9.2339 8 8.4641 8L1.5359 8C0.766098 8 0.284973 7.16667 0.669873 6.5L4.13397 0.5Z'
        fill='black'
      />
    </svg>
  )
}

export default DropdownCaretUpIcon
