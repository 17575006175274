import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Imgix from 'react-imgix'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'react-bootstrap'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import 'react-photo-view/dist/react-photo-view.css'
import { PageHeader } from '../common'
import { useResolutionService } from '../../hooks'

const Gallery = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const { questId } = useParams()
  const { galleryImageFileNames } = location.state || {}
  const { isMDScreen } = useResolutionService()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 900)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Helmet>
        <title>{'Gallery | Abroad'}</title>
        <meta name='title' content={'Gallery | Abroad'}></meta>
        <meta property='og:title' content={'Gallery | Abroad'}></meta>
        <meta property='twitter:title' content={'Gallery | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <div>
        <PageHeader
          showBackArrow={true}
          version='small'
          title={`Bhutan`}
          backArrowAction={() =>
            history.push(`/quests/${questId}/public`)
          }
        />
        <div className='s1 mt-lg-0 mt-2'>Gallery</div>

        <div>
          <PhotoProvider>
            <Row>
              {galleryImageFileNames.map((galleryImageFileNames, index) => (
                <PhotoView
                  key={index}
                  src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${galleryImageFileNames}`}>
                  {isLoading ? (
                    <Col xl={4} lg={6} md={6} sm={12} Col>
                      <ShimmerThumbnail
                        className='shimmer-thumbnail-mediaplayer border-radius-10 mt-24px lazyload imgix-object-cover'
                        rounded
                        width={'100%'}
                        height={224}
                      />
                    </Col>
                  ) : (
                    <Col xl={4} lg={6} md={6} sm={12} Col>
                      <Imgix
                        className='border-radius-10 mt-24px lazyload imgix-object-cover cursor-pointer'
                        imgixParams={{
                          fit: 'fill',
                          crop: 'center',
                          ar: isMDScreen ? '2:1' : '5:3',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${galleryImageFileNames}`}
                        width={'100%'}
                        height={224}
                        alt='image'
                        attributeConfig={{
                          src: 'data-src',
                          srcSet: 'data-srcset',
                          sizes: 'data-sizes',
                        }}
                        htmlAttributes={{
                          src: `$${
                            process.env.REACT_APP_IMG_SOURCE
                          }/quest/${questId}/${galleryImageFileNames}?ar=${
                            isMDScreen ? '2:1' : '5:3'
                          }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                        }}
                      />
                    </Col>
                  )}
                </PhotoView>
              ))}
            </Row>
          </PhotoProvider>
        </div>
      </div>
    </>
  )
}

export default Gallery
