import React from 'react'
import { Button as RBButton, Spinner } from 'react-bootstrap'
import classnames from 'classnames'

const Button = React.forwardRef(
  ({ children, isLoading, className, ...rest }, ref) => {
    return (
      <React.Fragment>
        <RBButton
          className={classnames('font-montserrat border-radius-5', className)}
          ref={ref}
          {...rest}
        >
          {isLoading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='Loading'
              aria-hidden='true'
              className='btn-spinner'
            />
          )}
          {!isLoading && children}
        </RBButton>
      </React.Fragment>
    )
  }
)

export default Button
