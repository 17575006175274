import React from 'react'
import { useTable, useSortBy, useGlobalFilter, useRowSelect } from 'react-table'
import PropTypes from 'prop-types'

const TableInstance = ({
  children,
  columns,
  data,
  manualSortBy,
  disableSortRemove,
  disableMultiRemove,
  tableHooks,
  autoResetSelectedRows,
  ...rest
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualGlobalFilter: true,
      disableGlobalFilter: true,
      manualSortBy,
      // disableSortBy: true,
      disableSortRemove,
      disableMultiRemove,
      autoResetSelectedRows,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case 'toggleAllRowsSelected':
            return {
              ...newState,
              selectedRowIds: {},
            }

          default:
            return newState
        }
      },
      ...rest,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    ...tableHooks,
  )

  return <>{children({ ...tableInstance, columns, data })}</>
}

TableInstance.defaultProps = {
  manualSortBy: true,
  disableSortRemove: true,
  disableMultiRemove: true,
  // autoResetSelectedRows: Boolean
  // Defaults to true
  // When true, the selectedRowIds state will automatically reset if any of the following conditions are met:
  // data is changed
  // To disable, set to false

  // when stop table state from automatically resetting when data changes => set autoResetSelectedRows to false
  autoResetSelectedRows: true,
  tableHooks: [],
}

TableInstance.propTypes = {
  tableHooks: PropTypes.array,
}

export default TableInstance
