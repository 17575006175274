import React from 'react'
import classNames from 'classnames'

const NotificationIcon = ({ isActive, isShowGoldCircle, onClick }) => {
  return (
    <div
      className={classNames('notification-icon-wrapper d-flex', {
        active: isActive,
      })}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='20'
        viewBox='0 0 18 22'
        fill={isActive ? '#bf9000' : 'none'}
        className='cursor-pointer align-self-center notification-icon text-black'
        onClick={onClick}>
        <path
          d='M6.29125 17.4706C5.63632 17.4706 3.59584 17.4706 2.08407 17.4706C1.27859 17.4706 0.755692 16.5502 1.11591 15.768L2.04135 13.7584C2.34226 13.1049 2.49891 12.3852 2.49891 11.6546C2.49891 10.7491 2.49891 9.404 2.49891 8.05882C2.49891 5.70588 3.58244 1 9.00007 1C14.4177 1 15.5012 5.70588 15.5012 8.05882C15.5012 9.404 15.5012 10.7491 15.5012 11.6546C15.5012 12.3852 15.6579 13.1049 15.9588 13.7584L16.8842 15.768C17.2444 16.5502 16.7205 17.4706 15.9151 17.4706H11.7089M6.29125 17.4706C6.29125 19.8235 7.37478 21 9.00007 21C10.6254 21 11.7089 19.8235 11.7089 17.4706M6.29125 17.4706C7.98383 17.4706 11.7089 17.4706 11.7089 17.4706'
          stroke={isActive ? '#bf9000' : 'black'}
          strokeLinejoin='round'
        />
      </svg>
      {isShowGoldCircle && (
        <div className='position-absolute notification-circle top-0 right-0'></div>
      )}
    </div>
  )
}

export default NotificationIcon
