import React from 'react'
import classNames from 'classnames'

const AngleRightIconForAdmin = ({
  isDropDownOpen = false,
  className,
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='18'
      viewBox='0 0 10 18'
      fill='none'
      className={classNames(
        {
          'angle-left-icon': !isDropDownOpen,
          'angle-down-icon': isDropDownOpen
        },
        className
      )}
      {...rest}
    >
      <line
        x1='0.353553'
        y1='0.646447'
        x2='9.35355'
        y2='9.64645'
        stroke='white'
      />
      <line
        x1='0.646447'
        y1='17.6464'
        x2='8.64645'
        y2='9.64645'
        stroke='white'
      />
    </svg>
  )
}

export default AngleRightIconForAdmin
