import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from 'react'
import classNames from 'classnames'
import { ModelContext, useResolutionService } from '@abroad/components'
import AssessmentScoreCard from './AssessmentScoreCard'
import InfoIconOverlay from './InfoIconOverlay'
import { conditionMatched } from '../layout'
import { InfoIcon } from '../assetsComponents'
import AssessmentCategoryAudio from '../common/AssessmentCategoryAudio'
const EfficacySubCategoryTable = ({
  managerEfficacyKeyTitle,
  categories,
  clickOnLearnMoreBtn,
  getValueByIdFromLeaderSurvey,
  getCategoryName,
  getSubCategoryValueByKey,
  getCategoryDescription,
  managerEfficacyBGColor,
  pauseOthers,
  categoryKeyName,
  mediaUrl,
  setMediaUrl,
  loadedAudioId,
  setLoadedAudioId,
}) => {
  const tableRef = useRef(null)
  const [height, setHeight] = useState(0)
  const [rowNumber, setRowNumber] = useState(null)
  const { isLGScreen } = useResolutionService()
  const category = categories?.find((e) => e._id === '62963961a279e16eeb3aa867')
  const { onShow, setModalData } = useContext(ModelContext)
  useLayoutEffect(() => {
    setHeight(tableRef.current.clientHeight)
  }, [])

  useEffect(() => {
    function handleWindowResize() {
      setHeight(tableRef.current.clientHeight)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div className='mb-52px'>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='assessment-chart text-center align-self-lg-center'>
          <AssessmentScoreCard
            title={managerEfficacyKeyTitle}
            linkClasses='s11 mb-0 text-white cursor-pointer'
            category={category}
            onLearnMoreClick={() => {
              clickOnLearnMoreBtn(
                categories?.find((e) => e._id === '62963961a279e16eeb3aa867'),
              )
            }}
            percentage={getValueByIdFromLeaderSurvey(
              '62963961a279e16eeb3aa867',
              'percent',
            )}
            backgroudColor={managerEfficacyBGColor}
            variant='table-attached-card'
            outerBackgroudColor='#E0E7DE'
            innerBackgroudColor='#84A07C'
            width='15.79rem'
            height={
              isLGScreen || conditionMatched ? `${height}px` : '22.437rem'
            }
          />
        </div>
        <div className='assessment-score mt-lg-0 mt-4' ref={tableRef}>
          <div className='d-flex justify-content-between mb-18px pt-0 px-2'>
            <div className='d-flex'>
              {/* <PlayIcon
                onClick={() => {
                  //#TODO-assessment play audio
                }}
                useSVGIcon={true}
                wrapperClasses='align-self-center'
              /> */}
              <AssessmentCategoryAudio
                key={categoryKeyName}
                categoryKeyName={categoryKeyName}
                mediaUrl={mediaUrl}
                setMediaUrl={setMediaUrl}
                loadedAudioId={loadedAudioId}
                setLoadedAudioId={setLoadedAudioId}
                pauseOthers={pauseOthers}
              />
              <h3 className='s2 mb-0'>{managerEfficacyKeyTitle}</h3>
            </div>
            <InfoIcon
              className='info-icon align-self-center cursor-pointer font-20 text-decoration-none'
              onClick={() => {
                onShow()
                setModalData(category)
                clickOnLearnMoreBtn(
                  categories?.find((e) => e._id === '62963961a279e16eeb3aa867'),
                )
              }}
            />
          </div>
          {getValueByIdFromLeaderSurvey(
            '62963961a279e16eeb3aa867',
            'order',
          ).map((subcategoryId, index) => (
            <div className='survey-subcat-link py-18px' key={index}>
              <div
                className={classNames('courses-title-row', {
                  'first-row': index === 0,
                  'border-bottom-transparent': index === rowNumber - 1,
                })}
                onMouseEnter={() => {
                  setRowNumber(index)
                }}
                onMouseLeave={() => {
                  setRowNumber(null)
                }}>
                <div className='courses-title'>
                  <span className='s6 text-black hover-text-bold'>
                    {getCategoryName(subcategoryId)}
                  </span>
                </div>
                <div className='courses-score'>
                  <span className='text-manager-efficacy s6 hover-text-bold'>
                    {Number.isInteger(
                      getSubCategoryValueByKey(
                        getValueByIdFromLeaderSurvey(
                          '62963961a279e16eeb3aa867',
                          'subcategories',
                        ),
                        subcategoryId,
                        'percent',
                      ),
                    )
                      ? `${getSubCategoryValueByKey(
                          getValueByIdFromLeaderSurvey(
                            '62963961a279e16eeb3aa867',
                            'subcategories',
                          ),
                          subcategoryId,
                          'percent',
                        )}%`
                      : 'N/A'}
                  </span>
                  <InfoIconOverlay
                    contentText={getCategoryDescription(subcategoryId)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EfficacySubCategoryTable
