import React from 'react'
import classnames from 'classnames'
import { Container, Spinner as RBSpinner } from 'react-bootstrap'

const ABSpinner = ({ colorClass }) => {
  return (
    <Container className='mt-4 px-lg-5'>
      <div className='h-40 d-flex justify-content-center align-items-center'>
        <RBSpinner
          animation='border'
          className={classnames(colorClass)}
          role='status'
        />
      </div>
    </Container>
  )
}

ABSpinner.defaultProps = {
  colorClass: 'text-saffron-700'
}

export default ABSpinner
