import React from 'react'
import { plans } from '@abroad/components'
import RecommendedAudio from './RecommendedAudio'
import { UpgradeNow } from '../common'
import { ProgramsList } from '../breakthrough'
import RecentNotesAndLogInsight from './RecentNotesAndLogInsight'
import RecentlyPlayedAudio from './RecentlyPlayedAudio'
import HomeMiniSurveyResult from './HomeMiniSurveyResult'
import QuestsHome from '../quests/QuestsHome'
import RecentFavorites from './RecentFavorites'

const GrowthPlanHome = () => {
  return (
    <div>
      <QuestsHome />
      <div className='mb-52px mt-32px'>
        <RecommendedAudio />
      </div>
      <HomeMiniSurveyResult />
      <RecentNotesAndLogInsight />
      <div className='mb-52px'>
        <RecentlyPlayedAudio />
      </div>
      <div className='mb-52px'>
        <RecentFavorites />
      </div>
      <div>
        <div className='s1 mb-3'>Unlock doors to unparalleled growth</div>
        <div className='s5 mb-52px'>
          Our signature programs, curated by industry-leading experts, offer
          bespoke modules that empower, enlighten, and evolve your leadership
          skills. Get a taste of what's in store with a glimpse into the
          introductory modules.
        </div>
        <ProgramsList />
      </div>
      <UpgradeNow
        wrapperClasses='mt-52px mb-4 border-radius-full'
        title='Ready to Unlock the Full Experience?'
        subTitle={`Dive into each program's expansive journey, filled with tailored modules designed to redefine your leadership prowess. Upgrade to the Coaching Plan and embark on an odyssey of purposeful growth.`}
        buttonClasses='leading-4'
        plan={plans.coaching.code}
        buttonText='Inquire about Coaching'
      />
    </div>
  )
}

export default GrowthPlanHome
