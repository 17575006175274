import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../../utils/API'
import ArchiveIntentionEvent from '../../constants/events'

const archived = true

const ArchiveIntentionAction = ({ intentionId }) => {
  const Error = useErrorService()
  const { user } = useUserService()
  const [isLoading, setIsLoading] = useState(false)
  const placement = 'left'
  const containerRef = useRef(null)
  const history = useHistory()

  const archiveIntention = async () => {
    try {
      setIsLoading(true)
      const { data } = await API.intention.toggleArchive(intentionId, archived)
      if (data) {
        setIsLoading(false)
        history.replace('/coaching/intentions')
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const gtmArchiveIntentionAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ArchiveIntentionEvent.event.archiveIntention,
        eventProps: {
          category: ArchiveIntentionEvent.category.archiveIntentionCategory,
          action: 'User archive intention',
          label: 'archive intention',
          value: `User archive intention in growthPlan page`,
          userId: user?._id,
        },
      },
    })
  }

  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        trigger='click'
        rootClose={isLoading ? false : true}
        container={containerRef.current}
        overlay={
          <Tooltip id={`tooltip-${placement}`} className='archive-intention'>
            <span
              className={classNames('archive-link', {
                'cursor-pointer': !isLoading,
                'pe-none': isLoading,
              })}
              onClick={() => {
                gtmArchiveIntentionAction()
                archiveIntention()
              }}>
              Archive Intention
            </span>
            {isLoading && (
              <Spinner
                animation='border'
                size='sm'
                className='font-saffron-700 ms-3'
                role='status'
              />
            )}
          </Tooltip>
        }>
        <span className='icon icon-extra-settings text-black font-20 ms-3 my-3 cursor-pointer icon-growth-settings'></span>
      </OverlayTrigger>
    </>
  )
}

export default ArchiveIntentionAction
