import React from 'react'
import { Image } from 'react-bootstrap'
const SignupHeaderIcon = (props) => {
  return (
    <Image
      src={`${process.env.PUBLIC_URL}/assets/images/signupHeaderIcon.svg`}
      alt='header logo'
      width='145'
      height='87'
      {...props}
    />
  )
}

export default SignupHeaderIcon
