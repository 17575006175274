import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const LeftArrowIcon = React.forwardRef(
  (
    { className, style, bgColor, bgOpacity, removeSwiperClass, ...rest },
    ref
  ) => {
    return (
      <svg
        ref={ref}
        className={classnames(
          {
            'swiper-button-prev': !removeSwiperClass
          },
          className
        )}
        width='16'
        height='23'
        viewBox='0 0 16 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={style}
        {...rest}
      >
        <path
          d='M14 1.58594L2 11.2922'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M14 20.9992L2 11.293'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </svg>
    )
  }
)

LeftArrowIcon.propTypes = {
  removeSwiperClass: PropTypes.bool
}

LeftArrowIcon.defaultProps = {
  removeSwiperClass: false
}

export default LeftArrowIcon
