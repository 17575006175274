import React, { useState } from 'react'
import loadable from '@loadable/component'
const SanitizeHtml = loadable.lib(() =>
  import(/* webpackChunkName: "sanitizeHtml" */ 'sanitize-html'),
)
const descriptionLength = 450

const CoachDetail = ({ detail }) => {
  const [showFullContent, setShowFullContent] = useState(false)
  return (
    <div className='d-flex'>
      <p className='s6 mb-0'>
        {showFullContent ? (
          <SanitizeHtml fallback={'Loading...'}>
            {({ default: sanitizeHtml }) =>
              sanitizeHtml(detail?.bio, {
                allowedTags: [],
                allowedAttributes: {},
              })
            }
          </SanitizeHtml>
        ) : (
          <>
            <SanitizeHtml fallback={'Loading...'}>
              {({ default: sanitizeHtml }) =>
                sanitizeHtml(detail?.bio, {
                  allowedTags: [],
                  allowedAttributes: {},
                })?.slice(0, descriptionLength)
              }
            </SanitizeHtml>
            <span>...</span>
          </>
        )}
        {detail?.bio?.length > descriptionLength ? (
          <span
            className='cursor-pointer text-saffron-700 s11 ms-3'
            onClick={() => setShowFullContent(!showFullContent)}>
            {showFullContent ? 'Read less' : 'Read more'}
          </span>
        ) : null}
      </p>
    </div>
  )
}

export default CoachDetail
