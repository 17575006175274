import React from 'react'
import { Col, Row } from 'react-bootstrap'
import RequestedUserListingModal from './RequestedUserListingModal'
import ReportTooltip from './ReportTooltip'

export const reportType = {
  RESPONSES_COMPLETE: 'responsesComplete',
  PROFESSIONAL_CONNECTION: 'professionalConnection',
  PERSONAL_CONNECTION: 'personalConnection',
}

const DirectReports = ({
  totalRequestsCompletedCount,
  totalRequestsCount,
  workingRelationshipPercentage,
  personalRelationshipPercentage,
  requestedUsers,
}) => {
  const renderResponseConnection = (value, label, type) => {
    return (
      <Col
        lg={4}
        md={4}
        sm={12}
        className='mb-2 d-flex flex-column text-center'>
        <div className='d-flex justify-content-center align-items-center mb-lg-3 mb-1'>
          <p className='text-saffron-300 s2 report-score text-saffron-300 mb-0'>
            {value}
          </p>
          {type === reportType.RESPONSES_COMPLETE ? (
            <RequestedUserListingModal
              className='ms-3'
              requestedUsers={requestedUsers}
              index={value}
            />
          ) : (
            <ReportTooltip type={type} />
          )}
        </div>
        <span className='s10a align-self-center text-white'>{label}</span>
      </Col>
    )
  }

  return (
    <div className='direct-reports mb-52px'>
      <Row className='d-flex justify-content-between'>
        {/* renderResponseConnection(value, label, type) */}
        {renderResponseConnection(
          `${totalRequestsCompletedCount}/${totalRequestsCount}`,
          'RESPONSES COMPLETE',
          reportType.RESPONSES_COMPLETE,
        )}
        {renderResponseConnection(
          `${workingRelationshipPercentage}%`,
          'PROFESSIONAL CONNECTION',
          reportType.PROFESSIONAL_CONNECTION,
        )}
        {renderResponseConnection(
          `${personalRelationshipPercentage}%`,
          'PERSONAL CONNECTION',
          reportType.PERSONAL_CONNECTION,
        )}
      </Row>
    </div>
  )
}

export default DirectReports
