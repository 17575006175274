import React from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

// todo - remove code
const ModuleInfo = ({
  type,
  title,
  description,
  author,
  handleClosePage,
  wrapperClasses = '',
  programName = '',
}) => {
  let { mediaCategory } = useParams()

  return (
    <div
      className={classNames(
        'media-info w-100 d-flex mt-2 flex-column',
        { 'media-info-container mt-5': mediaCategory },
        wrapperClasses,
      )}>
      {/* <span className='icon icon-breakthrough text-white breakthrough-icon'></span> */}
      <div className='d-flex align-items-center'>
        <div
          className='d-flex flex-column px-0 justify-content-center align-items-center pointer-cursor back-btn'
          onClick={handleClosePage}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='43'
            viewBox='0 0 24 43'
            fill='none'>
            <path
              d='M18.5748 1.35352L1.4248 17.9997'
              stroke={!mediaCategory ? 'black' : 'white'}
              strokeWidth='2.5'
              strokeLinecap='round'
            />
            <path
              d='M18.5748 34.6462L1.4248 18'
              stroke={!mediaCategory ? 'black' : 'white'}
              strokeWidth='2.5'
              strokeLinecap='round'
            />
          </svg>

          <svg
            width='24'
            height='43'
            viewBox='0 0 24 43'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
              fill={!mediaCategory ? 'black' : 'white'}
            />
          </svg>
        </div>
        <div className='d-flex flex-column align-self-start ps-3'>
          <div
            className={classNames('s8 text-uppercase type', {
              'text-dark-900': !mediaCategory,
              'd-none': !type,
            })}>
            {(!mediaCategory || type) && `${programName} - ${type}`}
          </div>
          <div className='d-flex'>
            <div
              className={classNames('s2 title', {
                'text-dark-900': !mediaCategory,
              })}>
              {title}
            </div>
          </div>
        </div>
      </div>
      {mediaCategory && (
        <div className='description-section'>
          <p className='s5 description'>{description}</p>
          {author && (
            <div className='s8 auther'>
              {mediaCategory === 'program' ? 'TAUGHT BY' : 'EXPERT'}&nbsp;
              <b>
                <span className='s9'>{author}</span>
              </b>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ModuleInfo
