import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUserService } from '@abroad/components'

const PrivateRoute = ({ children, component: Component, render, ...rest }) => {
  const { user } = useUserService()
  return (
    <Route
      {...rest}
      render={({ location, history, pathname, ...renderProps }) => {
        const query = new URLSearchParams(location?.search)
        const nextUrl = query.get('next')
        if (render) {
          render({ ...location, ...renderProps })
          return
        }
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: `/${location?.search ? location?.search : ''}`,
                state: {
                  from: `${location.pathname}${location.search}`,
                },
              }}
            />
          )
        } else if (user && user?.emailVerified) {
          const isTwoFactorVerified = user?.isTwoFactorVerified
          if (user?.isAdmin || user?.isCoach) {
            if (!user?.twoFactorMode && !isTwoFactorVerified) {
              if (
                location.pathname === '/2fa-setup' ||
                location.pathname === '/2fa-email'
              ) {
                return (
                  children || (
                    <Component
                      {...{ location, history, pathname, ...renderProps }}
                    />
                  )
                )
              }
              return (
                <Redirect
                  to={{
                    pathname: `/2fa-setup${nextUrl ? `?next=${nextUrl}` : ''}`,
                  }}
                />
              )
            } else if (user?.twoFactorMode === 'APP' && !isTwoFactorVerified) {
              if (
                location.pathname === '/2fa-login' ||
                location.pathname === '/2fa-email'
              ) {
                return (
                  children || (
                    <Component
                      {...{ location, history, pathname, ...renderProps }}
                    />
                  )
                )
              }
              return (
                <Redirect
                  to={{
                    pathname: `/2fa-login${nextUrl ? `?next=${nextUrl}` : ''}`,
                  }}
                />
              )
            } else if (
              user?.twoFactorMode === 'EMAIL' &&
              !isTwoFactorVerified
            ) {
              if (
                location.pathname === '/2fa-email' ||
                location.pathname === '/2fa-setup'
              ) {
                return (
                  children || (
                    <Component
                      {...{ location, history, pathname, ...renderProps }}
                    />
                  )
                )
              }
              return (
                <Redirect
                  to={{
                    pathname: `/2fa-email${nextUrl ? `?next=${nextUrl}` : ''}`,
                  }}
                />
              )
            } else if (isTwoFactorVerified) {
              return (
                children || (
                  <Component
                    {...{ location, history, pathname, ...renderProps }}
                  />
                )
              )
            }
          }
          return (
            children || (
              <Component {...{ location, history, pathname, ...renderProps }} />
            )
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: '/verify-email-sent',
                state: {
                  email: user?.email,
                  firstName: user?.firstName,
                },
              }}
            />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
