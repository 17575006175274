import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import {
  FeatherLoader,
  useUserService,
  useErrorService,
  OverviewWisdomSurveyResults,
  surveyType,
} from '@abroad/components'
import { OverviewSurveyResults } from '../assessmentResult'
import LearnMoreBtnEvent from '../../constants/events'
import API from '../../utils/API'

export default function HomeMiniSurveyResult() {
  const history = useHistory()
  const [recentSurveyMiniResult, setRecentSurveyMiniResult] = useState()
  const [recentSurveyLoader, setRecentSurveyLoader] = useState(true)
  const { user } = useUserService()
  const Error = useErrorService()

  useEffect(() => {
    const getRecentSurveyMiniResult = async () => {
      try {
        setRecentSurveyLoader(true)
        const { data } = await API.user.getRecentSurveyMiniResult()
        if (data) {
          setRecentSurveyMiniResult(data)
        }
        setRecentSurveyLoader(false)
      } catch (e) {
        if (e?.code === 'not_taken_survey') {
          setRecentSurveyLoader(false)
          return
        }
        Error.showError(e)
      }
    }
    getRecentSurveyMiniResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gtmLearnMoreBtnClickedAction = (categoryId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: LearnMoreBtnEvent.event.learnMore,
        eventProps: {
          category: LearnMoreBtnEvent.category.learnMoreCategory,
          action: 'User click on learnMore button',
          label: 'click on learnMore button',
          value: categoryId,
          userId: user._id,
        },
      },
    })
  }

  if (recentSurveyLoader) {
    return (
      <div className='mb-52px text-center m-3'>
        <FeatherLoader />
      </div>
    )
  }

  if (!recentSurveyMiniResult) {
    return null
  }

  if (recentSurveyMiniResult?.inTransition) return null

  return (
    <>
      <div className='mt-52px mb-4 d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center'>
        <div className='s3'>Overview of Assessment Results</div>
        <div
          className='s11 font-saffron-700 cursor-pointer mt-lg-0 mt-2'
          onClick={() =>
            history.push(
              `/${
                recentSurveyMiniResult?.surveyType === surveyType.WISDOM
                  ? 'wisdom'
                  : 'survey'
              }/${recentSurveyMiniResult?.id}`,
            )
          }>
          See Full Results
        </div>
      </div>
      {recentSurveyMiniResult?.surveyType === surveyType.WISDOM ? (
        <OverviewWisdomSurveyResults
          survey={recentSurveyMiniResult}
          onLearnMoreClick={gtmLearnMoreBtnClickedAction}
        />
      ) : (
        <OverviewSurveyResults
          wrapperClasses='mb-52px'
          survey={recentSurveyMiniResult}
          onLearnMoreClick={gtmLearnMoreBtnClickedAction}
          titleClasses='s4'
        />
      )}
    </>
  )
}
