import React, { useState, useEffect, useRef } from 'react'
import Imgix from 'react-imgix'
import classNames from 'classnames'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/attrchange/ls.attrchange'
// import { ZoomInIcon, ZoomOutIcon } from '../assetsComponents'
import { isLGScreen } from '../../utils/utility'

const ProgramBannerImage = ({ bannerImage, subTitle }) => {
  const [isShowFullImage, setIsShowFullImage] = useState(false)
  const nodeRef = useRef()

  useEffect(() => {
    const prefixes = ['', 'moz', 'webkit', 'ms']

    prefixes.forEach(function (prefix) {
      document.addEventListener(prefix + 'fullscreenchange', function () {
        let fullscreenElement =
          document.fullscreenElement /* Standard syntax */ ||
          document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
          document.mozFullScreenElement /* Firefox syntax */ ||
          document.msFullscreenElement /* IE/Edge syntax */
        if (!fullscreenElement) {
          setIsShowFullImage(false)
        }
      })
    })
    return () => {
      prefixes.forEach(function (prefix) {
        document.removeEventListener(prefix + 'fullscreenchange', (e) => {})
      })
    }
  }, [])

  // eslint-disable-next-line no-unused-vars
  const fullScreenImage = (e) => {
    if (typeof document.webkitCurrentFullScreenElement !== 'undefined') {
      if (!isShowFullImage) {
        const img = document.getElementById('p-banner-image')
        if (img.requestFullscreen) img.requestFullscreen()
        else if (img.webkitRequestFullscreen) img.webkitRequestFullscreen()
        else if (img.mozRequestFullScreen) img.mozRequestFullScreen()
        // Careful to the capital S
        else if (img.msRequestFullscreen) img.msRequestFullscreen()
        else if (img.webkitEnterFullscreen) img.webkitEnterFullscreen()
      }
      setIsShowFullImage(!isShowFullImage)
    }
  }

  return (
    <React.Fragment>
      <div
        // onClick={fullScreenImage}
        className={classNames(
          // 'banner-image-my',
          {
            'px-3': !isLGScreen,
          },
        )}>
        <div className='position-relative'>
          <div
            // onClick={(e) => {
            //   e.stopPropagation()
            //   setIsShowFullImage(!isShowFullImage)
            //   if (document.exitFullscreen) {
            //     document.exitFullscreen()
            //   } else if (document.webkitExitFullscreen) {
            //     /* Safari */
            //     document.webkitExitFullscreen()
            //   } else if (document.msExitFullscreen) {
            //     /* IE11 */
            //     document.msExitFullscreen()
            //   }
            // }}
            id='p-banner-image'
            className={classNames({ 'd-none': !isShowFullImage })}>
            <Imgix
              className={classNames('lazyload img-cover border-radius-5')}
              imgixParams={{
                ar: '21:9',
                fit: 'crop',
                crop: 'edges',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}`}
              width={'100%'}
              height={'100%'}
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}?ar=21:9&crop=edges&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                ref: nodeRef,
              }}
              alt='module-image'
            />
            {/* <div
              className={classNames(
                'position-fixed resize-image zoomout-section',
                { 'd-none': !isShowFullImage },
              )}>
              <ZoomOutIcon className='cursor-zoom-out' />
            </div> */}
          </div>
          <Imgix
            disableQualityByDPR={true}
            className={classNames(
              'lazyload w-100 img-cover border-radius-5 banner-image',
            )}
            imgixParams={{
              fit: 'crop',
              crop: 'edges',
              ar: '21:9',
            }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/${bannerImage}`}
            sizes='100%'
            alt='image'
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
            htmlAttributes={{
              src: `${
                process.env.REACT_APP_IMG_SOURCE
              }/${bannerImage}?ar=${'21:9'}&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format&q=0`, // low quality image here
            }}
          />
          {/* {isLGScreen && (
            <div className='position-absolute resize-image'>
              <ZoomInIcon className='cursor-zoom-in' />
            </div>
          )} */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgramBannerImage
