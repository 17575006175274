import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

const numberRegex = RegExp('(?=.*?[0-9])')
const uppercaseRegex = RegExp('(?=.*?[A-Z])')
const lowercaseRegex = RegExp('(?=.*?[a-z])')
const specialCharacterRegex =
  // eslint-disable-next-line no-useless-escape
  /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/
const characterRegex = RegExp('.{8,}')

const PasswordInValidError = ({ setIsValidPassword }) => {
  const {
    values: { password },
    touched: { password: touchedPasswordField },
  } = useFormikContext()

  useEffect(() => {
    const isValidPassword =
      characterRegex.test(password) &&
      numberRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      lowercaseRegex.test(password) &&
      uppercaseRegex.test(password)
    setIsValidPassword(isValidPassword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password])

  if (!touchedPasswordField) {
    return null
  }

  return (
    <div className='s6'>
      <span
        className={`${
          characterRegex.test(password) ? 'text-green-100' : 'text-error'
        }`}>
        At least 8 characters
      </span>
      <br />
      <span
        className={`${
          numberRegex.test(password) ? 'text-green-100' : 'text-error'
        }`}>
        At least 1 number
      </span>
      <br />
      <span
        className={`${
          specialCharacterRegex.test(password)
            ? 'text-green-100'
            : 'text-red-100'
        }`}>
        At least 1 special character
      </span>
      <br />
      <span
        className={`${
          lowercaseRegex.test(password) && uppercaseRegex.test(password)
            ? 'text-green-100'
            : 'text-red-100'
        }`}>
        Both uppercase and lowercase letters
      </span>
    </div>
  )
}

export default PasswordInValidError
