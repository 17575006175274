import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import TagManager from 'react-gtm-module'
import API from '../../utils/API'
import takeAssessmentEvent from '../../constants/events'

const TakeNewAssessment = ({
  variant,
  className,
  title,
  wrapperProps,
  getTypeFormLink = false,
  getEmailBySurveyRequestId = () => {},
  getEmailByGroupRequestId = () => {},
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [link, setLink] = useState('')
  const Notification = useNotificationService()
  const { user } = useUserService()

  const getLink = async () => {
    return API.survey.getLink()
  }

  useEffect(() => {
    const getUrl = async () => {
      try {
        let isEmailMatch
        isEmailMatch = await getEmailBySurveyRequestId()
        const { data } = await getLink(isEmailMatch)
        setLink(data.link)
        setIsLoading(false)
        if (data?.code === 'upgrade_plan') {
          setError(data?.message)
        } else {
          setError(null)
        }
      } catch (e) {
        if (e?.code === 'survey_limit_reached') {
          setError(e.message)
        }
        setIsLoading(false)
      }
    }
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const takeNewAssessment = () => {
    Notification.showNotification(error)
  }

  const gtmAssessmentBtnClickedAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: takeAssessmentEvent.event.TakeAssessment,
        eventProps: {
          category: takeAssessmentEvent.category.UserTakeAssessmentCategory,
          action: 'User take assessment',
          label: 'Take assessment',
          value: `User take new survey assessment`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <a
      {...(link && {
        href: link,
        target: '_self',
        rel: 'noopener noreferrer',
      })}
      {...(error
        ? { onClick: takeNewAssessment }
        : { onClick: gtmAssessmentBtnClickedAction })}
      {...wrapperProps}>
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        variant={variant}
        className={className}
        {...rest}>
        {title ? title : <>Take new assessment</>}
      </Button>
    </a>
  )
}

TakeNewAssessment.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
}

TakeNewAssessment.defaultProps = {
  variant: 'black',
}

export default TakeNewAssessment
