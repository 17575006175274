import React from 'react'

const JournalIcon = ({ width, height, ...rest }) => {
  return (
    <span
      className='icon icon-journal text-black hover:text-saffron-700'
      style={{ fontSize: width }}
    />
  )
}

JournalIcon.defaultProps = {
  width: 26,
  height: 26
}

export default JournalIcon
