import React, { useState, useEffect } from 'react'
import { MediaControlBarRoot, MediaControlBar } from '../media'
import { LayoutContext } from '../../utils/contexts'

const MediaControlWrapper = ({ children }) => {
  const isLGScreen = window.innerWidth >= 992 ? true : false
  const [showSidebar, setShowSidebar] = useState(isLGScreen)
  const [showPlayBar, setShowPlayBar] = useState(false)
  const [isMediaLoading, setIsMediaLoading] = useState(false)
  const [minimizePlayBar, setMinimizePlayBar] = useState(false)
  const [mediaObject, setMediaObject] = useState(null)
  const [audio, setAudio] = useState(null)
  const [volumeLevel, setVolumeLevel] = useState(0.3)
  const [duration, setDuration] = useState()
  const [curTime, setCurTime] = useState()
  const [tempTime, setTempTime] = useState(null)
  const [contextJourneyId, setContextJourneyId] = useState(null)
  const [contextJourneyAudioId, setContextJourneyAudioId] = useState(null)
  const [isJourneyModuleAudio, setIsJourneyModuleAudio] = useState(null)
  const [journeyAudioType, setJourneyAudioType] = useState(null)
  const [journeyGroupId, setJourneyGroupId] = useState(null)

  useEffect(() => {
    if (!isLGScreen) {
      setShowSidebar(false)
    }
  }, [isLGScreen])

  return (
    <LayoutContext.Provider
      value={{
        setShowSidebar: (isSidebarVisible) => setShowSidebar(isSidebarVisible),
        showSidebar: showSidebar,
        showPlayBar: showPlayBar,
        setShowPlayBar: (isPlayBarVisible) => setShowPlayBar(isPlayBarVisible),
        minimizePlayBar: minimizePlayBar,
        setMinimizePlayBar: (isMinimize) => setMinimizePlayBar(isMinimize),
        mediaObject: mediaObject,
        setMediaObject: (obj) => setMediaObject(obj),
        audio: audio,
        setAudio: (el) => setAudio(el),
        volumeLevel: volumeLevel,
        setVolumeLevel: (level) => setVolumeLevel(level),
        duration: duration,
        setDuration: (data) => setDuration(data),
        curTime: curTime,
        setCurTime: (data) => setCurTime(data),
        tempTime: tempTime,
        setTempTime: (data) => setTempTime(data),
        contextJourneyId: contextJourneyId,
        setContextJourneyId: (data) => setContextJourneyId(data),
        contextJourneyAudioId: contextJourneyAudioId,
        setContextJourneyAudioId: (data) => setContextJourneyAudioId(data),
        isJourneyModuleAudio: isJourneyModuleAudio,
        setIsJourneyModuleAudio: (data) => setIsJourneyModuleAudio(data),
        journeyAudioType: journeyAudioType,
        setJourneyAudioType: (data) => setJourneyAudioType(data),
        journeyGroupId: journeyGroupId,
        setJourneyGroupId: (data) => setJourneyGroupId(data),
        isMediaLoading: isMediaLoading,
        setIsMediaLoading: (data) => setIsMediaLoading(data),
      }}>
      <div>
        {children}
        <MediaControlBarRoot>
          <MediaControlBar />
        </MediaControlBarRoot>
      </div>
    </LayoutContext.Provider>
  )
}

export default MediaControlWrapper
