import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import InfoIconOverlay from './InfoIconOverlay'
import { conditionMatched } from './OverviewSurveyResults'

const SortedSubCategories = ({ results, categories }) => {
  const [sortedSubCategories, setSortedSubCategories] = useState([])
  const SelfUnderstandingId = '6639261151fc141698164cb6'
  const wisdomAndConnectionId = '6639261151fc141698164cb7'

  useEffect(() => {
    if (results) {
      let subCategories = results.self_understanding.order.map((cat) => ({
        ...results.self_understanding.subcategories[cat],
        key: cat,
        parentId: SelfUnderstandingId
      }))
      subCategories = [
        ...subCategories,
        ...results?.wisdom__connection?.order.map((cat) => ({
          ...results.wisdom__connection.subcategories[cat],
          key: cat,
          parentId: wisdomAndConnectionId
        }))
      ]
      subCategories = subCategories.sort((a, b) => b.percent - a.percent)
      setSortedSubCategories(subCategories)
    }
  }, [results])

  const renderSubCategoriesBox = (category, index) => {
    return (
      <div className='d-flex category-details'>
        <Col className='col-9'>
          <h3 className='mb-0 s7 hover-text-bold ms-2'>{category?.title}</h3>
        </Col>
        <Col className='col-3 d-flex justify-content-end'>
          <p
            className={classNames('mb-0 align-self-center hover-text-bold s7', {
              'text-blue-600': category?.parentId === SelfUnderstandingId,
              'text-green-500': category?.parentId === wisdomAndConnectionId
            })}
          >{`${category.percent}%`}</p>
          <InfoIconOverlay
            contentText={
              categories?.find((e) => e?.key === category?.key)?.description
            }
          />
        </Col>
      </div>
    )
  }

  return (
    <Row className='d-flex flex-lg-row flex-column mb-52px'>
      <Col
        lg={12}
        md={12}
        sm={12}
        className='mb-lg-0 mb-3 d-flex flex-lg-row flex-column'
      >
        <div className='mr-lg-12px w-100 mb-lg-0 mb-4'>
          <h3
            className={classNames('text-black mb-3', {
              s3: conditionMatched,
              s2: !conditionMatched
            })}
          >
            Celebrations
          </h3>
          <div className='box-content'>
            {sortedSubCategories.slice(0, 3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='ml-lg-12px w-100'>
          <h3
            className={classNames('text-black mb-3', {
              s3: conditionMatched,
              s2: !conditionMatched
            })}
          >
            Key Areas for Growth
          </h3>
          <div className='box-content'>
            {sortedSubCategories.slice(-3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default SortedSubCategories
