import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Container, Row, Col, Button as RBButton } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { Helmet } from 'react-helmet'
import sysend from 'sysend'
import {
  Button,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import {
  OnboardTitle,
  OnboardSubTitle,
  ReturnBtn,
  AbroadGoldenIcon,
} from '../components/onboarding'
import API from '../utils/API'
import AuthService from '../utils/AuthService'
import { InviteDetailsContext } from '../utils/contexts'
import { getResendEmailParams } from '../utils/utility'
import { conditionMatched } from '../components/layout'
import { sowRefId as sowRefIdConst } from '../utils/constants'

const VerifyEmailSent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hideBtn, setHideBtn] = useState(false)
  const [time, setTime] = useState(10)
  let { state, search } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const { user, setUser } = useUserService()
  const { inviteLinkDetails, setInviteLinkDetails } =
    useContext(InviteDetailsContext)
  const query = new URLSearchParams(search)
  const queryCompanyCode = query.get('c') || query.get('companyCode')
  const queryEcosystemCode = query.get('e') || query.get('networkCode')
  const queryPromo = query.get('promocode') || query.get('promo')
  let timeInterval = null

  const [otp, setOtp] = useState('')
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [hasErrored, setHasErrored] = useState(false)
  const Notification = useNotificationService()
  const handleChange = (otp) => setOtp(otp)

  useEffect(() => {
    const getVerificationStatus = async () => {
      try {
        const { data } = await API.onboarding.verificationStatus()
        if (data.emailVerified) {
          history.replace('/home')
          setUser({ ...user, emailVerified: true })
        }
      } catch (e) {
        // error
      }
    }
    getVerificationStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state?.inviteLinkDetails) {
      setInviteLinkDetails(state?.inviteLinkDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, history])

  useEffect(() => {
    return () => {
      clearInterval(timeInterval)
    }
  }, [timeInterval])

  const resendEmail = async () => {
    const searchParams = getResendEmailParams(
      queryCompanyCode,
      queryEcosystemCode,
      queryPromo,
    )
    try {
      setIsLoading(true)
      await API.onboarding.verifyEmailResend(`?${searchParams.toString()}`)
      setHideBtn(true)
      setIsLoading(false)
      timeInterval = setInterval(() => {
        setTime((prev) => {
          if (prev === 0) {
            setHideBtn(false)
            clearInterval(timeInterval)
            return 10
          }
          return prev - 1
        })
      }, 1000)
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const logout = async (e) => {
    e.preventDefault()
    if (AuthService.isLogedIn() && AuthService.getUserData()?.emailVerified) {
      history.push('/home', {})
    } else {
      try {
        await API.onboarding.logout()
        setUser(null)
        AuthService.removeData()
        sysend.broadcast('client_logout', { message: 'client_logout' })
        history.push('/', {})
      } catch (e) {
        Error.showError(e)
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (otp.length !== 6) {
      setHasErrored(true)
      Notification.showNotification('Enter correct OTP!', 'danger')
      return
    }
    setIsBtnLoading(true)
    try {
      await API.onboarding.verifyEmail({
        email: state?.email,
        otp,
      })
      const data = true
      if (data) {
        setUser({ ...user, emailVerified: true })
        setIsBtnLoading(false)
        const sowRefId = localStorage.getItem(sowRefIdConst)
        if (sowRefId) {
          history.replace(
            `/profile/plans-payments/coaching-plan/${sowRefId}?${sowRefIdConst}=${sowRefId}`,
          )
          return
        }
        history.replace('/home')
      } else {
        setHasErrored(true)
        Notification.showNotification('Incorrect OTP!', 'danger')
        setIsBtnLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsBtnLoading(false)
    }
  }

  useEffect(() => {
    if (otp) {
      setHasErrored(false)
    }
  }, [otp])

  return (
    <>
      <Helmet>
        <title>{'Verify Email Sent | Abroad'}</title>
        <meta name='title' content={'Verify Email Sent | Abroad'}></meta>
        <meta property='og:title' content={'Verify Email Sent | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Verify Email Sent | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/verify-email-sent`}></link>
      </Helmet>
      <main>
        <section>
          <Container fluid className='px-0'>
            <Row className='vh-100 row g-0'>
              <Col className='onboard bg-abroad-blue'>
                <div className='h-100 d-flex flex-column align-items-center'>
                  <AbroadGoldenIcon />
                  <section
                    className={classNames('onboard-wrapper otp-screen', {
                      'h-lg-65': !conditionMatched,
                    })}>
                    <OnboardTitle className='mb-2'>
                      Email Verification
                    </OnboardTitle>
                    <OnboardSubTitle className='mb-40px opacity-75'>
                      In order to verify your email, please enter the 6-digit
                      PIN that was sent to your email.
                    </OnboardSubTitle>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className='w-100 align-items-center justify-content-center'>
                        <OtpInput
                          hasErrored={hasErrored}
                          errorStyle='two-fa-otp-error'
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          isInputNum={true}
                          containerStyle='two-fa-otp-input justify-content-between'
                          inputStyle={{ color: 'white' }}
                        />
                      </div>
                      <div className='d-flex flex-column onbording-action-btn'>
                        <Button
                          {...(inviteLinkDetails?.buttonColorHex
                            ? { variant: 'none' }
                            : { variant: 'saffron' })}
                          {...(inviteLinkDetails?.buttonColorHex && {
                            style: {
                              backgroundColor:
                                inviteLinkDetails?.buttonColorHex,
                              color: inviteLinkDetails?.fontColorHex,
                              borderColor: inviteLinkDetails?.buttonColorHex,
                            },
                          })}
                          className={classNames('btn w-100 s9 mb-4', {
                            'btn-custom': inviteLinkDetails?.buttonColorHex,
                          })}
                          isLoading={isBtnLoading}
                          disabled={isBtnLoading}
                          type='submit'>
                          Continue To Abroad
                        </Button>
                        <RBButton
                          disabled={isLoading || hideBtn}
                          onClick={resendEmail}
                          variant='outline-dark'
                          className='btn w-100 s9'>
                          {isLoading ? 'Resending...' : 'Resend Email'}
                        </RBButton>
                      </div>
                    </form>
                    <div className='d-flex s5 mt-2 text-white justify-content-center'>
                      {hideBtn && `Resend email in ${time} second`}&nbsp;
                    </div>
                  </section>
                  <ReturnBtn onClick={logout} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default VerifyEmailSent
