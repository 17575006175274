import React from 'react'
import classnames from 'classnames'

const OnboardSubTitle = ({ className, commonClass = 's6', children }) => {
  return (
    <div className={classnames('text-white', commonClass, className)}>
      {children}
    </div>
  )
}

export default OnboardSubTitle
