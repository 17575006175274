import React, { useRef } from 'react'
import classNames from 'classnames'
import { Form, Row, Col } from 'react-bootstrap'
import moment from 'moment'

const ChooseCircleDate = ({
  dateAndTime,
  coachId,
  checkedCircleDetail,
  setCheckedCircleDetail,
  growthPlanLink,
}) => {
  return (
    <div>
      <div className='d-flex mb-3'>
        <h3 className='s5 fw-semibold mr-10px mb-0'>
          Choose Circle Session Days & Times
        </h3>
      </div>
      {dateAndTime?.map((data, index) => (
        <DateSection
          key={index}
          data={data}
          index={data}
          coachId={coachId}
          checkedCircleDetail={checkedCircleDetail}
          setCheckedCircleDetail={setCheckedCircleDetail}
          growthPlanLink={growthPlanLink}
          dateAndTime={dateAndTime}
        />
      ))}
    </div>
  )
}

const DateSection = ({
  index,
  data,
  coachId,
  checkedCircleDetail,
  setCheckedCircleDetail,
  growthPlanLink,
  dateAndTime,
}) => {
  const inputRef = useRef(null)
  return (
    <div
      className={classNames('date-time-box p-3 cursor-pointer', {
        'mb-3': index + 1 !== data?.length,
      })}
      onClick={() => {
        inputRef.current.click()
      }}>
      <div className='d-flex justify-content-between'>
        <span className='s6'>
          Time: {data?.from} - {data?.to} (CT)
        </span>
        <Form.Check id='custom-select-circle' custom={true} type='checkbox'>
          <Form.Check.Input
            ref={inputRef}
            type='checkbox'
            className={classNames(
              'z3 circle-checkbox cursor-pointer choose-circle-input',
              {
                'checked-bg-color':
                  data?.slotId === checkedCircleDetail?.slotId &&
                  coachId === checkedCircleDetail?.coachId,
              },
            )}
            checked={
              data?.slotId === checkedCircleDetail?.slotId &&
              coachId === checkedCircleDetail?.coachId
            }
            onClick={(event) => {
              event.stopPropagation()
            }}
            onChange={(event) => {
              setCheckedCircleDetail(
                event.target.checked
                  ? {
                      slotId: data?.slotId,
                      coachId,
                      growthPlanLink: growthPlanLink,
                      dateAndTime: dateAndTime,
                    }
                  : null,
              )
            }}
          />
        </Form.Check>
      </div>
      <Row className='mt-10px'>
        {data?.dates &&
          data.dates.map((d, index) => (
            <Col
              xs={6}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={index}
              className='mb-3'>
              <div
                className={classNames('s8 date-time-box date-box text-center', {
                  'selected-box':
                    data?.slotId === checkedCircleDetail?.slotId &&
                    coachId === checkedCircleDetail?.coachId,
                })}
                key={index}>
                <div className='date-color'>
                  {moment(d).format('MM/DD/YYYY')}
                </div>
                <div className='day-color'>{moment(d).format('dddd')}</div>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  )
}

export default ChooseCircleDate
