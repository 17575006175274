import { useEffect, useState, useContext } from 'react'
import { useErrorService } from '@abroad/components'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'

const useStartMediaPlayer = (props = { disabledControls: true }) => {
  const [mediaParams, setMediaParams] = useState()

  const Error = useErrorService()
  const {
    setIsMediaLoading,
    mediaObject,
    setMediaObject,
    setShowPlayBar,
    setContextJourneyId,
  } = useContext(LayoutContext)

  useEffect(() => {
    const getMedia = async (
      mediaId,
      mediaSubCategoryId,
      isProgramMedia = false,
    ) => {
      try {
        setIsMediaLoading(true)
        setShowPlayBar(true)
        let mediaData
        if (isProgramMedia) {
          mediaData = await API.programs.getMediaById(
            mediaSubCategoryId,
            mediaId,
            null, //journeyId
            null, // sequenceId
          )
          mediaData = mediaData?.data
        } else {
          mediaData = await API.mediaLibrary.getMediaById(
            mediaSubCategoryId,
            mediaId,
            null, //journeyId
            null, // sequenceId
          )
          mediaData = mediaData?.data
        }
        if (mediaData) {
          const mediaObjectInstance = {
            ...mediaData,
            disabledControls: props.disabledControls,
          }
          setMediaObject(mediaObjectInstance)
        }
        setIsMediaLoading(false)
      } catch (e) {
        if (e.code === 'upgrade_plan' && !mediaObject) {
          setShowPlayBar(false)
        }
        setIsMediaLoading(false)
        Error.showError(e)
      }
    }
    const getModuleFirstMedia = async (programId) => {
      try {
        setIsMediaLoading(true)
        setShowPlayBar(true)
        const { data } = await API.programs.getModuleFirstMedia(programId)
        if (data) {
          setContextJourneyId(null)
          setMediaObject(data)
          setIsMediaLoading(false)
        }
      } catch (e) {
        if (e.code === 'upgrade_plan' && !mediaObject) {
          setShowPlayBar(false)
        }
        setIsMediaLoading(false)
        Error.showError(e)
      }
    }
    if (mediaParams) {
      const { mediaId, mediaSubCategoryId, isProgramMedia, isFullModule } =
        mediaParams
      if (isFullModule) {
        getModuleFirstMedia(mediaId)
      } else {
        getMedia(mediaId, mediaSubCategoryId, isProgramMedia)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaParams])
  return { setMediaParams }
}

export default useStartMediaPlayer
