import React, { useState, useCallback, useEffect, useContext } from 'react'
import classnames from 'classnames'
import { Toast } from 'react-bootstrap'
import { NotificationContext } from './NotificationProvider'
import { NotificationContainerContext } from './NotificationContainerProvider'
import CloseIcon from '../assetsComponents/common/CloseIcon'
const Notification = () => {
  const [alerts, setAlerts] = useState([])
  const { clearMessage } = useContext(NotificationContext)
  const { notification } = useContext(NotificationContainerContext)

  const generate = useCallback(() => {
    if (notification?.message) {
      setAlerts((alerts) => {
        if (
          alerts.find((alerts) => alerts?.message === notification?.message)
        ) {
          return alerts
        }
        return [
          ...alerts,
          {
            id: new Date().getTime(),
            type: notification?.type || 'danger',
            message: notification?.message
          }
        ]
      })
    }
  }, [notification])

  useEffect(() => {
    clearMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts])

  useEffect(() => {
    generate()
  }, [notification, generate])

  const onDismissed = useCallback((alert) => {
    setAlerts((alerts) => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }, [])

  if (alerts.length === 0) {
    return null
  }

  return (
    <div aria-live='polite' aria-atomic='true'>
      <div className='notification-container'>
        {alerts.map((alert) => {
          return (
            <Toast
              show
              key={alert.id}
              delay={alert.type === 'danger' ? 5000 : 3000}
              autohide
              onClose={() => onDismissed(alert)}
              className={classnames(
                'border-radius-5 bg-opacity-75 overflow-hidden',
                {
                  'bg-red-800': alert.type === 'danger',
                  'bg-green-100': alert.type === 'success'
                }
              )}
            >
              <Toast.Body className='text-white justify-content-between d-flex align-items-center'>
                {alert.message}
                <CloseIcon
                  className='text-white font-12 cursor-pointer ml-2 ms-2'
                  onClick={() => onDismissed(alert)}
                />
              </Toast.Body>
            </Toast>
          )
        })}
      </div>
    </div>
  )
}

export default Notification
