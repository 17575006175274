import React from 'react'

const PaymentSuccessIcon = ({ className, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 60 60'
      className={className}
      {...rest}
    >
      <g transform='translate(-1061 -406)'>
        <circle
          className='payment-success-circle'
          cx='30'
          cy='30'
          r='30'
          opacity='0.3'
          fill='#509f92'
          transform='translate(1061 406)'
        />
        <g transform='translate(1077 424.583)'>
          <path
            className='payment-success-line'
            fill='#509f92'
            d='M53.168,33.277,34.215,52.035a2.033,2.033,0,0,1-1.484.565,2.126,2.126,0,0,1-1.484-.565l-6.965-6.893a1.93,1.93,0,0,1,0-2.712,1.865,1.865,0,0,1,1.37-.565,1.78,1.78,0,0,1,1.37.565l5.709,5.65,17.7-17.515A1.865,1.865,0,0,1,51.8,30a1.78,1.78,0,0,1,1.37.565A1.93,1.93,0,0,1,53.168,33.277Z'
            transform='translate(-23.725 -29.882)'
          />
        </g>
      </g>
    </svg>
  )
}

export default PaymentSuccessIcon
