import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorService, FeatherLoader } from '@abroad/components'
import API from '../../utils/API'
import MediaLibSwiperUI from './MediaLibSwiperUI'

const RecommendedAudio = () => {
  const Error = useErrorService()
  const recommendedSwiperRef = useRef(null)
  const [recommendedData, setRecommendedData] = useState([])
  const [isRecommendedLoading, setIsRecommendedLoading] = useState(false)
  const history = useHistory()
  const [recommendPage, setRecommendPage] = useState(1)
  const limit = 6

  useEffect(() => {
    const getRecommendedData = async () => {
      try {
        if (recommendPage === 1) {
          // setIsLoading(true)
          setIsRecommendedLoading(true)
        }
        const { data } = await API.mediaLibrary.getRecommendedMedias({
          page: recommendPage,
          limit,
        })
        if (Array.isArray(data)) {
          setRecommendedData([...recommendedData, ...data])
        } else {
          throw data
        }
        setIsRecommendedLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsRecommendedLoading(false)
          Error.showError(e)
        }
      }
    }
    getRecommendedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendPage])

  useEffect(() => {
    if (!isRecommendedLoading && recommendedData.length > 0) {
      setTimeout(() => {
        if (recommendedSwiperRef && recommendedSwiperRef.current) {
          recommendedSwiperRef.current.update()
        }
      })
    }
  }, [isRecommendedLoading, recommendedData])

  if (isRecommendedLoading) {
    return (
      <div className='text-center my-2'>
        <FeatherLoader />
      </div>
    )
  }

  if (recommendedData.length === 0) {
    return null
  }

  return (
    <MediaLibSwiperUI
      isLoading={isRecommendedLoading}
      header='Recommended for You'
      rightHeader='Go to Media Library'
      limit={limit}
      setPage={setRecommendPage}
      data={recommendedData}
      rightHeaderRedirectFn={() => history.push('/media-library')}
    />
  )
}

export default RecommendedAudio
