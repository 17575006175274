import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ClearCompanyValue = () => {
  const {
    values: { transition },
    setFieldValue,
  } = useFormikContext()
  useEffect(() => {
    if (transition) {
      setFieldValue('company', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition])

  return null
}

export default ClearCompanyValue
