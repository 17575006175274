import React from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '@abroad/components'

const Feedback = () => {
  return (
    <section>
      <Container className='text-center py-5'>
        <p className='text-gray-201 font-normal'>
          Have feedback or suggestions for the ABROAD assessment or report?
        </p>
        <p className='text-gray-201 font-normal'>
          Impact stories inspire our team, so please click the button below to
          send us feedback.
        </p>
        <a href='mailto:service@abroad.io' subject='hello'>
          <Button
            variant='black'
            size='sm'
            className='d-none d-lg-inline-block align-self-center'>
            SEND FEEDBACK
          </Button>
          <Button
            variant='black'
            size='lg'
            className='d-inline-block d-lg-none'>
            SEND FEEDBACK
          </Button>
        </a>
      </Container>
    </section>
  )
}

export default Feedback
