// import { FFmpeg } from '@ffmpeg/ffmpeg';

const audioService = {
  audioBlobs: [] /*of type Blob[]*/,
  /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
  mediaRecorder: null /*of type MediaRecorder*/,
  /** Stores the reference to the stream currently capturing the audio*/
  streamBeingCaptured: null,
  start: function () {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      //Feature is not supported in browser
      //return a custom error
      return Promise.reject(
        new Error(
          'mediaDevices API or getUserMedia method is not supported in this browser.',
        ),
      )
    } else {
      return navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioService.streamBeingCaptured = stream

          audioService.mediaRecorder = new MediaRecorder(stream)
          audioService.audioBlobs = []

          audioService.mediaRecorder.addEventListener(
            'dataavailable',
            (event) => {
              //store audio Blob object
              audioService.audioBlobs.push(event.data)
            },
          )
          audioService.mediaRecorder.start()
        })
    }
  },

  stop: function () {
    return new Promise((resolve) => {
      let mimeType = audioService.mediaRecorder.mimeType
      audioService.mediaRecorder.addEventListener('stop', async () => {
        let audioBlob = new Blob(audioService.audioBlobs, { type: mimeType })
        // const ffmpeg = createFFmpeg({ log: false })
        // await ffmpeg.load()

        // const inputName = `input.${mimeType}`
        // const outputName = 'output.mp3'

        // ffmpeg.FS(
        //   'writeFile',
        //   inputName,
        //   await fetch(audioBlob).then((res) => res.arrayBuffer()),
        // )

        // await ffmpeg.run('-i', inputName, outputName)

        // const outputData = ffmpeg.FS('readFile', outputName)
        // const outputBlob = new Blob([outputData.buffer], { type: 'audio/mp3' })
        resolve(audioBlob)
      })

      audioService.mediaRecorder.stop()

      audioService.stopStream()

      audioService.resetRecordingProperties()
    })
  },

  stopStream: function () {
    audioService.streamBeingCaptured
      .getTracks()
      .forEach((track) => track.stop())
  },

  resetRecordingProperties: function () {
    audioService.mediaRecorder = null
    audioService.streamBeingCaptured = null
  },
}

export default audioService
