import React from 'react'

const AbroadIconForSM = ({ className, ...rest }) => {
  return (
    <span
      className={`icon icon-feather text-dark-900 font-24 ${className}`}
      {...rest}
    />
  )
}

export default AbroadIconForSM
