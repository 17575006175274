import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const ReturnBtn = ({ onClick, to, position, linkClasses }) => {
  return (
    <Link
      {...(to && { to: to })}
      {...(onClick && {
        onClick: (e) => {
          e.preventDefault()
          onClick(e)
        },
      })}
      className={classNames('s7 return-link my-3', linkClasses)}>
      <div className='d-flex'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='9'
          height='16'
          viewBox='0 0 9 16'
          fill='none'
          className='align-self-center'>
          <path
            d='M8 1L1 7.79438'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M8 14.5893L1 7.79492'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
          />
        </svg>
        <span className='align-items-baseline return-text'>Return</span>
      </div>
    </Link>
  )
}

export default ReturnBtn
