import React from 'react'
import Imgix from 'react-imgix'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useResolutionService } from '../../hooks'

const UpcomingQuestsFaculty = ({ upcomingpublic, questId }) => {
  const { isMDScreen } = useResolutionService()
  return (
    <>
      {upcomingpublic?.faculties?.length > 0 && (
        <div>
          <h1 className='mt-52px s3 mb-0'>Faculty</h1>
          <Swiper
            slidesPerView={3}
            navigation
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              425: {
                slidesPerView: 1.1,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 2.1,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 2.5,
                spaceBetween: 60,
              },
              2560: {
                slidesPerView: 2.5,
                spaceBetween: 80,
              },
            }}
            className='overflow-xy custom-swiper'>
            {upcomingpublic.faculties &&
              upcomingpublic.faculties.map((item) => (
                <SwiperSlide key={item.id}>
                  <div style={{ width: '100%' }}>
                    <Imgix
                      className='border-radius-10 w-100 mt-4 lazyload imgix-object-cover'
                      imgixParams={{
                        fit: 'crop',
                        crop: 'faces',
                        ar: isMDScreen ? '2:1' : '5:3',
                      }}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${item.imageFileName}`}
                      alt='image'
                      attributeConfig={{
                        src: 'data-src',
                        srcSet: 'data-srcset',
                        sizes: 'data-sizes',
                      }}
                      htmlAttributes={{
                        src: `${
                          process.env.REACT_APP_IMG_SOURCE
                        }/quest/${questId}/${item.imageFileName}?ar=${
                          isMDScreen ? '2:1' : '5:3'
                        }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                      }}
                    />
                    <h4 className='s10a mt-3 font-saffron-700'>
                      {item.expertise}
                    </h4>
                    <h2 className='s2'>{item.name}</h2>
                    <pre
                      className='s6 pre text-break'
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  )
}
export default UpcomingQuestsFaculty
