import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import {
  Button,
  useNotificationService,
  FeatherLoader,
  useUserService,
  useErrorService,
} from '@abroad/components'
import API from '../utils/API'
import { openLinkInTab } from '../utils/openLinkInTab'
import TextArea from '../components/custom360/TextArea'
import BackIcon from '../components/custom360/BackIcon'

const SurveyRequest = () => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [isRequestLoading, setIsRequestLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [surveyData, setSurveyData] = useState()
  const [isSubmitting, setSubmitting] = useState(false)
  const history = useHistory()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { user } = useUserService()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const type = query.get('type')
  const requestId = query.get('request')
  const token = query.get('t')
  const custom_Survey_answer_storage_key = `customSurveyAnswers_of_${token}_${requestId}`

  const getSurveyRequest = async () => {
    try {
      const { data } = await API.survey.getSurveyRequestForm(
        type,
        requestId,
        token,
      )
      if (data) {
        setSurveyData(data)
        setIsRequestLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setIsRequestLoading(false)
      history.push('/')
    }
  }

  useEffect(() => {
    type && requestId && token && getSurveyRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, requestId, token])

  const storedAnswers =
    JSON.parse(localStorage.getItem(custom_Survey_answer_storage_key)) || {}

  useEffect(() => {
    if (surveyData?.data?.questions) {
      const questionsWithAnswers = surveyData.data.questions.map((q) => ({
        ...q,
        ans: storedAnswers[q._id] || '',
      }))
      setQuestions(questionsWithAnswers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData])

  // if (isRequestLoading) {
  //   return (
  //     <Container fluid className='onboard bg-abroad-blue '>
  //       <div className='d-flex justify-content-center align-items-center'>
  //         <FeatherLoader width='60' />
  //       </div>
  //     </Container>
  //   )
  // }

  const handleTextAreaChange = (event) => {
    const textarea = event.target
    textarea.style.height = '2.5rem'
    textarea.style.height = textarea.scrollHeight + 'px'
    handleChange(textarea.value)
  }

  const handleChange = (ans) => {
    const updatedQuestions = [...questions]
    updatedQuestions[activeIndex].ans = ans
    setQuestions(updatedQuestions)
    const storedAnswers =
      JSON.parse(localStorage.getItem(custom_Survey_answer_storage_key)) || {}
    storedAnswers[questions[activeIndex]._id] = ans
    localStorage.setItem(
      custom_Survey_answer_storage_key,
      JSON.stringify(storedAnswers),
    )
  }

  const handleNext = () => {
    if (activeIndex === null) {
      if (questions.length > 0) {
        setActiveIndex(0)
      } else {
        Notification.showNotification(
          'Something went wrong! Please try again later.',
          'danger',
        )
      }
    } else {
      setActiveIndex((prev) => prev + 1)
    }
  }

  const handlePrevious = () => {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1)
    } else {
      setActiveIndex(null)
    }
  }

  const onSubmit = async (data) => {
    try {
      setSubmitting(true)
      const ans = questions.map((q) => ({ text: q.ans, questionId: q._id }))
      const { status } = await API.custom360.custom360Answers(requestId, {
        answers: ans,
        token: token,
      })
      if (status) {
        localStorage.removeItem(custom_Survey_answer_storage_key)
        Notification.showNotification(
          'Your feedback has been submitted!',
          'success',
        )
        user
          ? history.replace('/home')
          : (window.location.href = 'https://rc.abroad.io/account')
      }
    } catch (e) {
      setSubmitting(false)
      Error.showError(e)
    }
  }

  const custom360Info = () => {
    return (
      <>
        <div className='row justify-content-center'>
          <div className='col-6 col-xl-6 col-lg-7 col-md-9 col-sm-10'>
            <div className='s1 font-white mb-3 tracking-normal'>
              {surveyData?.header}
            </div>
            {surveyData?.body?.map((paragraph, index) => (
              <p className='s13 font-white mb-3' key={index}>
                {paragraph}
              </p>
            ))}
            <Button
              variant='saffron'
              onClick={handleNext}
              className='border-radius-normal px-36px py-14px s9 mt-4'>
              BEGIN
            </Button>
          </div>
        </div>
      </>
    )
  }
  const renderWritten = (length) => {
    return (
      <>
        <div>
          <TextArea
            value={
              questions[activeIndex]?.ans ? questions[activeIndex]?.ans : ''
            }
            onChange={handleTextAreaChange}
          />
          <div className='custom-border mb-5'></div>
        </div>
      </>
    )
  }

  const renderNumerical = (length) => {
    return (
      <>
        <div className='mt-40px'>
          <div className='grid-box'>
            {['1', '2', '3', '4', '5', '6'].map((value, index) => (
              <div key={value}>
                <Button
                  className={`rounded s14 p-0 pe-auto ${
                    questions[activeIndex]?.ans === value ? 'selected' : ''
                  }`}
                  style={{ width: '100%', height: '52px' }}
                  variant={
                    questions[activeIndex]?.ans === value
                      ? 'saffron'
                      : 'outline-dark'
                  }
                  onClick={() => handleChange(value)}>
                  {value}
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-24px'>
          <p className='s9 font-white'>STRONGLY DISAGREE</p>
          <p className='s9 font-white'>STRONGLY AGREE </p>
        </div>
      </>
    )
  }

  const renderQuestion = () => {
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <h6 className='s10a text-saffron-300 mb-3'>
              {activeIndex + 1}/{questions.length}
            </h6>
            <div className='s1 font-white mb-40px text-break tracking-normal'>
              {questions &&
                questions[activeIndex] &&
                questions[activeIndex].text?.replaceAll(
                  '@firstname',
                  surveyData.data.client?.fname,
                )}
            </div>
            {questions[activeIndex]?.type === 'written'
              ? renderWritten()
              : renderNumerical()}
            <div className='mt-80px'>
              <Button
                variant='outline-dark'
                onClick={handlePrevious}
                style={{
                  opacity: isSubmitting ? 0.5 : 1,
                  cursor: isSubmitting ? 'not-allowed' : 'pointer',
                }}
                className='border-radius-normal px-36px py-14px s9 rounded mr-24px mb-lg-0 mb-sm-2 mb-2'>
                PREVIOUS
              </Button>
              <Button
                variant='saffron'
                disabled={!questions[activeIndex]?.ans || isSubmitting}
                isLoading={isSubmitting}
                onClick={
                  activeIndex === questions.length - 1 ? onSubmit : handleNext
                }
                className='border-radius-normal px-36px py-14px s9 mb-lg-0 mb-sm-2 mb-2'>
                {activeIndex === questions.length - 1
                  ? 'SUMBIT AND RETURN TO PLATFORM'
                  : 'NEXT'}
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  }
  const handleTypeFormLink = () => {
    openLinkInTab(surveyData.data.url, '_self')
  }
  return (
    <>
      <Container fluid className='px-0'>
        <Row className='vh-100 row g-0'>
          {isRequestLoading ? (
            <Col className='onboard bg-abroad-blue d-flex'>
              <div className='d-flex mx-auto align-items-center'>
                <FeatherLoader width='60' />
              </div>
            </Col>
          ) : (
            <Col className='onboard bg-abroad-blue d-flex'>
              <>
                <BackIcon />
                {surveyData?.data?.questions?.length > 0 ? (
                  activeIndex === null ? (
                    <Container className='d-flex align-items-center'>
                      {custom360Info()}
                    </Container>
                  ) : (
                    <Container className='d-flex align-items-center justify-content-center'>
                      {renderQuestion()}
                    </Container>
                  )
                ) : (
                  <Container className='d-flex align-items-center'>
                    <div className='row justify-content-center'>
                      <div className='col-6 col-xl-6 col-lg-7 col-md-9 col-sm-10'>
                        <div className='s1 font-white mb-3 tracking-normal'>
                          {surveyData?.header}
                        </div>
                        {surveyData?.body?.map((paragraph, index) => (
                          <p className='s13 font-white mb-3' key={index}>
                            {paragraph}
                          </p>
                        ))}
                        <Button
                          variant='saffron'
                          onClick={handleTypeFormLink}
                          className='border-radius-normal px-36px py-14px s9 mt-5 mt-md-4 mt-sm-3'>
                          BEGINs
                        </Button>
                      </div>
                    </div>
                  </Container>
                )}
              </>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default SurveyRequest
