import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  useErrorService,
  useUserService,
  programs as programConst,
} from '@abroad/components'
import API from '../utils/API'
import TagManager from 'react-gtm-module'
import { ProgramModule } from '../components'
import {
  PageHeader,
  UpgradePlanModal,
  UpgradePlanModalProvider,
} from '../components/common'

const Program = ({
  history,
  match: {
    params: { programId },
  },
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [programs, setPrograms] = useState(Array.from(Array(5)))
  const Error = useErrorService()
  const { user } = useUserService()
  const userId = user?._id
  const { name } = programConst[programId]

  useEffect(() => {
    const getPrograms = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.programs.getPrograms(programId)
        setPrograms(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
        history.replace('/home')
      }
    }
    getPrograms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId])

  const toggleIsFavorite = async (e, programObj) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      const { data } = await API.programs.toggleFavorite(
        {
          isFavorite: !programObj.isFavorite,
        },
        programObj._id,
      )
      if (data) {
        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: `${name}_like_dislike`,
            eventProps: {
              category: `${name} Module`,
              action: 'User like/dislike Module',
              label: `${name} module like/dislike`,
              value: `isFavorite - ${!programObj.isFavorite} of ${
                programObj?.subTitle
              } from ${name} Page`,
              userId,
            },
          },
        })
        setPrograms((prev) =>
          prev.map((program) => {
            if (program._id === programObj._id) {
              return { ...program, isFavorite: !programObj.isFavorite }
            }
            return program
          }),
        )
      }
    } catch (e) {
      Error.showError(e)
    }
  }
  return (
    <>
      <Helmet>
        <title>{`${name} | Abroad`}</title>
        <meta name='title' content={`${name} | Abroad`}></meta>
        <meta property='og:title' content={`${name} | Abroad`}></meta>
        <meta property='twitter:title' content={`${name} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${name}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${name}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${name}`}></meta>
      </Helmet>
      <main>
        <section>
          <PageHeader
            title='Programs'
            version='small'
            backArrowAction={() => history.push('/programs')}
            showBackArrow={true}
          />
          <div className='s1 mb-32px mt-lg-0 mt-2'>{name}</div>
          <UpgradePlanModalProvider>
            <UpgradePlanModal />
            <div className='space-y-programs'>
              {programs.map((program, index) => (
                <ProgramModule
                  isLoading={isLoading}
                  program={program}
                  toggleIsFavorite={toggleIsFavorite}
                  key={program?._id || index}
                />
              ))}
            </div>
          </UpgradePlanModalProvider>
        </section>
      </main>
    </>
  )
}

export default Program
