import React from 'react'

export const NotificationContext = React.createContext({
  showNotification: () => {},
  clearMessage: () => {}
})

const NotificationProvider = React.memo(({ children, setNotification }) => {
  const showNotification = (newMessage, newType) => {
    setNotification({ message: newMessage, type: newType })
  }

  const clearMessage = () => {
    setNotification({ message: null, type: null })
  }

  return (
    <NotificationContext.Provider value={{ showNotification, clearMessage }}>
      {children}
    </NotificationContext.Provider>
  )
})

export default NotificationProvider
