import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AssessmentScoreCard from './AssessmentScoreCard'

const tableMaxWidth = 1080
const tableMinWidth = 1024

export const conditionMatched =
  window.innerWidth <= tableMaxWidth && window.innerWidth >= tableMinWidth

const OverviewSurveyResults = ({
  survey,
  onLearnMoreClick,
  wrapperClasses = '',
  titlePosition = 'bottom',
  titleClasses = ''
}) => {
  return (
    <Row className='d-flex justify-content-center align-items-center mb-52px'>
      <Col
        lg={12}
        md={12}
        sm={12}
        className='text-center d-lg-flex justify-content-lg-center'
      >
        <AssessmentScoreCard
          title='Self Understanding'
          percentage={survey?.results?.self_understanding?.percent}
          category={
            survey?.categories?.self_understanding ||
            survey?.categories?.find(
              (e) => e._id === '6639261151fc141698164cb6'
            )
          }
          onLearnMoreClick={onLearnMoreClick}
          outerBackgroudColor='rgba(18,60,105, 0.25)'
          innerBackgroudColor='#123C69'
          width={conditionMatched ? '10rem' : '15.79rem'}
          height='14.745rem'
          linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
          titlePosition={titlePosition}
          titleClasses={titleClasses}
          wrapperClasses='mr-lg-36px'
        />
        <AssessmentScoreCard
          title='Wisdom & Connection'
          percentage={survey?.results?.wisdom__connection?.percent}
          category={
            survey?.categories?.wisdom__connection ||
            survey?.categories?.find(
              (e) => e._id === '6639261151fc141698164cb7'
            )
          }
          onLearnMoreClick={onLearnMoreClick}
          outerBackgroudColor='rgba(40,82,56, 0.25)'
          innerBackgroudColor='#285238'
          width={conditionMatched ? '10rem' : '15.79rem'}
          height='14.745rem'
          linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
          titlePosition={titlePosition}
          titleClasses={titleClasses}
          wrapperClasses='mr-lg-36px'
        />
      </Col>
    </Row>
  )
}

export default OverviewSurveyResults
