import React, { useEffect, useState } from 'react'
import { ShimmerText, ShimmerTitle } from 'react-shimmer-effects'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  Button,
  AddCardModal,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import CardSelectionCheckbox from './CardSelectionCheckbox'
import DeleteCard from './DeleteCard'
import API from '../../utils/API'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const CardsListing = ({
  selectedCardDetails,
  setSelectedCardDetails = () => {},
  isDiscardOnsubmit = true,
  enabledUncheckedAction = false,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCardId, setSelectedCardId] = useState(
    selectedCardDetails ? selectedCardDetails?.id : null,
  )
  const [isToggleCardListing, setIsToggleCardListing] = useState(false)
  const [isCardChanging, setIsCardChanging] = useState(false)
  const { coachingPlanId, cardId } = useParams()
  const [cards, setCards] = useState([])
  const Error = useErrorService()
  const Notification = useNotificationService()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const isPaid = query.get('isPaid')
  const { user } = useUserService()
  const stripe = useStripe()

  useEffect(() => {
    if (isDiscardOnsubmit) {
      setSelectedCardDetails({ id: selectedCardId })
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCardId])

  const changeSubscriptionCardHandler = async () => {
    setIsCardChanging(true)
    try {
      const { data } = await API.payment.changeSubscriptionCard({
        subscriptionId: coachingPlanId,
        paymentMethodId: selectedCardId,
      })
      if (data?.actionRequired && isPaid) {
        let options = { payment_method: selectedCardId }
        // if (data?.status === 'requires_payment_method') {
        //   options = {
        //     ,
        //   }
        // }
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          data?.clientSecret,
          options,
        )
        if (paymentIntent?.status === 'succeeded') {
          await API.payment.confirmPayment({
            userId: user?._id,
            sowId: coachingPlanId,
            paymentIntentId: paymentIntent?.id,
          })
          setIsCardChanging(false)
          history.replace('/profile/plans-payments')
        } else {
          if (error?.code === 'card_declined') {
            Notification.showNotification(error?.message, 'danger')
          } else {
            Notification.showNotification(
              'Your payment is not completed. Please try again.',
              'danger',
            )
          }
          setIsCardChanging(false)
        }
      } else {
        setIsCardChanging(false)
        // redirect to plan & payment page
        history.replace('/profile/plans-payments')
      }
    } catch (e) {
      setIsCardChanging(false)
      setSelectedCardId(cardId)
      Error.showError(
        e?.code === 'payment_fail'
          ? {
              code: e?.code,
              message: 'Your payment is not completed. Please try again.',
            }
          : e,
      )
    }
  }

  useEffect(() => {
    const isCardMatch = cards?.find((card) => card?.id === selectedCardId)
    if (cards?.length === 0 || !isCardMatch) {
      setSelectedCardDetails(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards])

  useEffect(() => {
    const getUserCardListing = async () => {
      setIsLoading(true)
      try {
        const { data: response } = await API.payment.getUserCardListing()
        if (response.data && response.data?.length > 0) {
          if (!selectedCardDetails) {
            setSelectedCardDetails(response.data[0])
            setSelectedCardId(response.data[0]?.id)
          }
          setCards(response.data)
          setIsLoading(false)
        } else {
          setSelectedCardDetails(null)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserCardListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggleCardListing])

  const renderTitle = () => {
    return (
      <div className='d-flex mt-52px mb-2'>
        <h3 className='s3 mb-0'>Saved Cards</h3>
        {/* {isCardChanging && (
          <span className='ms-2 s11 text-saffron-700 align-self-center'>
            Updating...
          </span>
        )} */}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='saved-cards-shimmer'>
        {renderTitle()}
        {[1, 2, 3].map((number, index) => (
          <ShimmerTitle
            line={1}
            variant='primary'
            className='input-shimmer mb-4'
            key={index}
          />
        ))}
        <ShimmerText line={1} />
      </div>
    )
  }

  return (
    <>
      <Row className='card-listing-wrapper mb-4'>
        {renderTitle()}
        <div className='card-listing'>
          {cards.map(({ card, id }, index) => (
            <Col key={index} className='col-12'>
              <div className='d-flex justify-content-between mb-3'>
                <div className='d-flex'>
                  <CardSelectionCheckbox
                    selectedId={selectedCardId}
                    setSelectedId={setSelectedCardId}
                    checkboxId={id}
                    disabled={isCardChanging}
                    enabledUncheckedAction={enabledUncheckedAction}
                  />
                  <p className='mb-0 align-self-center s6'>{`**** **** **** ${card?.last4}`}</p>
                </div>
                <DeleteCard
                  onSuccess={(cardId) => {
                    setCards((prev) => prev.filter((e) => e?.id !== cardId))
                  }}
                  cardId={id}
                  isLoading={isCardChanging}
                />
              </div>
            </Col>
          ))}
        </div>
        {!isDiscardOnsubmit && (
          <div>
            <Button
              variant='saffron'
              size='sm'
              disabled={!selectedCardId || isCardChanging}
              isLoading={isCardChanging}
              onClick={changeSubscriptionCardHandler}
              className='text-uppercase border-radius-normal text-white hover:text-white my-3'>
              {isPaid ? 'Save and Charge' : 'Save'}
            </Button>
          </div>
        )}
        {/* Add New Card */}
        <AddCardModal
          API={API}
          onSuccess={(newCardId) => {
            setSelectedCardDetails({ id: newCardId })
            setSelectedCardId(newCardId)
            setIsToggleCardListing(!isToggleCardListing)
          }}
          isDisabled={isCardChanging}
        />
      </Row>
    </>
  )
}

const CardListingWithStripeWrap = ({ ...props }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
          },
        ],
      }}>
      <CardsListing {...props} />
    </Elements>
  )
}

export default CardListingWithStripeWrap
