import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ShimmerBadge } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'

const FavoriteFilterTabs = ({
  isShow,
  globalFilter,
  setGlobalFilter,
  setSelectedId,
  isLoading,
  isEmpty,
}) => {
  const [isFetchingCategories, setIsFetchingCategories] = useState(true)
  const [isToggleTags, setIsToggleTags] = useState(true)
  const [tabs, setTabs] = useState([])
  const Error = useErrorService()

  useEffect(() => {
    const getFavoriteCategories = async () => {
      try {
        setIsFetchingCategories(true)
        const { data } = await API.user.getFavoriteCategories()
        if (data) {
          setTabs(data)
          setIsFetchingCategories(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsFetchingCategories(false)
      }
    }
    getFavoriteCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggleTags])

  useEffect(() => {
    if (isEmpty) {
      setGlobalFilter({ categoryId: null })
      setSelectedId(null)
      setIsToggleTags(!isToggleTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty])

  return (
    <>
      {(isLoading || isFetchingCategories) && (
        <div className='d-flex fevorite'>
          <ShimmerBadge width={120} />
          <ShimmerBadge width={120} />
          <ShimmerBadge width={120} />
          <ShimmerBadge width={120} />
        </div>
      )}
      {isShow && !isLoading && !isFetchingCategories && (
        <div className='d-flex flex-basis-100'>
          {globalFilter?.categoryId && (
            <span
              onClick={() => {
                setSelectedId(null)
                setGlobalFilter({ categoryId: null })
              }}
              className={classNames(
                'icon icon-cross font-20 text-black align-self-center me-3',
                {
                  'pe-none': isLoading,
                  'cursor-pointer': !isLoading,
                },
              )}></span>
          )}
          <div className='d-flex outline-tabs my-lg-0 my-1'>
            {tabs.map((tab, index) => (
              <div
                class='position-relative tab-item'
                key={index}
                onClick={() => {
                  if (globalFilter?.categoryId !== tab?._id) {
                    setSelectedId(tab?._id)
                    setGlobalFilter({ categoryId: tab?._id })
                  }
                }}>
                <p
                  class={classNames('custom-tab cursor-pointer mb-0', {
                    active: globalFilter?.categoryId === tab?._id,
                  })}>
                  {tab?.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default FavoriteFilterTabs
