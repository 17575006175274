import React, { useState } from 'react'
import classNames from 'classnames'
import { messages } from '../../utils/constants'

const InfoCard = ({ notes = '', className }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  if (!notes) {
    return (
      <div className='mb-4 d-flex align-items-center justify-content-center'>
        <span className='s5'>{messages.noJourneysFound}</span>
      </div>
    )
  }
  return (
    <div
      className='intro-notes cursor-pointer'
      onClick={() => setIsExpanded(!isExpanded)}>
      <pre
        className={classNames(
          's5 text-gray-900 abroad-fs-exclude mb-0',
          { notes: !isExpanded },
          className,
        )}
        style={{ whiteSpace: 'break-spaces' }}>
        {notes}
      </pre>
    </div>
  )
}

export default InfoCard
