import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { Spinner } from 'react-bootstrap'
import DropdownCaretDownIcon from '../assetsComponents/common/DropdownCaretDownIcon'
import DropdownCaretUpIcon from '../assetsComponents/common/DropdownCaretUpIcon'
const LeaderSelect = ({
  name,
  options,
  defaultValue = null,
  setFirstDefault = false,
  onChange = () => {},
  placeholder = '',
  disabled = false,
  customDropdown = false,
  onOptionClick = () => {},
  keyProp = null,
  isLoading = false,
  loader = null,
  isRenderOnClientSide = false,
  ...rest
}) => {
  const [dropdownValue, setDropdownValue] = useState(defaultValue)

  useEffect(() => {
    if (options?.length > 0 && setFirstDefault) {
      setDropdownValue(options[0])
    } else {
      setDropdownValue(defaultValue)
    }
  }, [defaultValue, options, setFirstDefault, keyProp])

  const customStyles = {
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderBottom: '1px solid #000',
      borderLeft: '1px solid #000',
      borderRight: '1px solid #000',
      boxShadow: 'none',
      borderRadius: 'none',
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '95%'
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      boxShadow: 'none',
      zIndex: 25
    }),
    option: (provided, state) => {
      return {
        ...provided,
        // display: state.isSelected ? 'none' : 'block',
        color: state.isSelected ? '#BF9000' : 'black',
        backgroundColor: 'transparent',
        padding: '0.5rem 1rem',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer'
        },
        '&:active': {
          backgroundColor: 'transparent'
        },
        fontFamily: 'Open Sans',
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        fontStyle: 'normal',
        fontWeight: state.isSelected ? 700 : 600,
        letterSpacing: '-0.02em',
        boxSizing: 'border-box',
        maxWidth: '50%'
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 'auto',
        borderColor: '#000',
        boxShadow: 0,
        padding: 0,
        borderTopLeftRadius: isRenderOnClientSide ? '5px' : '3px',
        borderTopRightRadius: isRenderOnClientSide ? '5px' : '3px',
        borderBottomColor: state.menuIsOpen ? 'transparent !important' : '#000',
        borderBottomLeftRadius: state.menuIsOpen
          ? '0px'
          : isRenderOnClientSide
          ? '5px'
          : '3px',
        borderBottomRightRadius: state.menuIsOpen
          ? '0px'
          : isRenderOnClientSide
          ? '5px'
          : '3px',
        borderBottom: state.menuIsOpen ? 0 : '1px solid #000',
        '&:hover': {
          borderColor: '#000'
        }
      }
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        opacity: 0
      }
    },
    groupHeading: (base) => ({
      ...base,
      flex: '1 1',
      color: 'white',
      margin: 0
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: 'none'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis'
    })
  }
  if (customDropdown) {
    customStyles.singleValue = (provided, state) => {
      return {
        ...provided,
        opacity: 1,
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer'
        }
      }
    }
  }

  const MenuList = (props) => {
    return (
      <React.Fragment>
        <components.MenuList {...props}>
          <div className='font-open-sans font-semibold font-12 leading-5 letter-spacing-minus-02 px-3 custom-mb-0-625'>
            Select which awareness assessment you would like to compare with.
          </div>
          <div className='leader-select-options-wrp'>{props.children}</div>
        </components.MenuList>
      </React.Fragment>
    )
  }

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <span onClick={(e) => onOptionClick(props.data)}>{children}</span>
      </components.SingleValue>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <>
            {isRenderOnClientSide ? (
              <span className='icon icon-left-arrow font-20 text-black' />
            ) : (
              <>
                {props.selectProps.menuIsOpen ? (
                  <DropdownCaretUpIcon />
                ) : (
                  <DropdownCaretDownIcon />
                )}
              </>
            )}
          </>
        </components.DropdownIndicator>
      )
    )
  }

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </components.ValueContainer>
    )
  }

  let customComponents = {}
  if (customDropdown) {
    customComponents = {
      SingleValue
    }
  }

  return (
    <div className='d-flex compare-date'>
      <Select
        name={name}
        options={options}
        classNamePrefix='ab-select'
        styles={customStyles}
        isSearchable={false}
        components={{
          DropdownIndicator,
          ...customComponents,
          MenuList,
          ValueContainer: CustomValueContainer
        }}
        // menuPortalTarget={document.body}
        onChange={(e) => {
          setDropdownValue(e)
          if (dropdownValue?.value !== e?.value) onChange(e)
        }}
        placeholder='Select Awareness Assessment'
        isDisabled={disabled}
        openMenuOnClick='true'
        controlShouldRenderValue='true'
        value={dropdownValue}
        // closeMenuOnSelect={false}
        {...rest}
      />
      {isLoading && (
        <div className='d-flex justify-content-center align-items-center ml-1 ms-1'>
          <Spinner
            animation='border'
            className='text-saffron-700 report-loader'
          />
        </div>
      )}
    </div>
  )
}

export default LeaderSelect
