import React from 'react'
import classNames from 'classnames'

const AngleRightIconForClient = ({
  isDropDownOpen = false,
  className,
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      className={classNames(
        {
          'angle-left-icon': !isDropDownOpen,
          'angle-down-icon': isDropDownOpen
        },
        className
      )}
      {...rest}
    >
      <path
        d='M3.60078 9.95312L7.80078 5.87649'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M3.60078 1.80618L7.80078 5.88281'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default AngleRightIconForClient
