import React from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js'

const AddCreditCard = ({ baseStyle, labelClasses }) => {
  const styleObj = {
    // hidePostalCode: true, // only use in 'card' element
    style: {
      base: baseStyle,
      invalid: {
        borderColor: '#B22222',
        color: '#000'
      }
      // complete:{}, // if field is valid, this style will be applied, for now no usage
    }
  }

  return (
    <React.Fragment>
      <div className='d-flex w-100'>
        <label className={classNames('w-100', labelClasses)}>
          <p className='card-labels'>CARD NUMBER*</p>
          <CardNumberElement
            className='field form-control-md'
            options={{ ...styleObj, placeholder: '0000 0000 0000 0000' }}
          />
          {/* other way pass style={{}} as props */}
          {/* other way pass class='form-control' */}
        </label>
      </div>
      <Row className='d-flex justify-content-between'>
        <Col className='col-6'>
          <div className='d-flex w-100'>
            <label className={classNames('w-100', labelClasses)}>
              <p className='card-labels'>EXPIRATION DATE*</p>
              <CardExpiryElement
                className='field form-control-md'
                options={styleObj}
              />
            </label>
          </div>
        </Col>
        <Col className='col-5'>
          <div className='d-flex w-100'>
            <label className={classNames('w-100', labelClasses)}>
              <p className='card-labels'>CVV*</p>
              <CardCvcElement
                className='field form-control-md'
                options={{ ...styleObj, placeholder: '000' }}
              />
            </label>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AddCreditCard
