import React from 'react'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import HighchartsReact from 'highcharts-react-official'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const GaugeChart = ({
  className,
  gaugeOption,
  backgroundColor,
  color,
  percentage,
  labelProps,
  size
}) => {
  return (
    <HighchartsReact
      containerProps={{
        className: className
      }}
      highcharts={Highcharts}
      options={Highcharts.merge(
        {
          chart: {
            type: 'solidgauge',
            backgroundColor: 'transparent'
          },
          caption: {
            text: null
          },
          title: null,
          pane: {
            center: ['50%', '50%'],
            size: size,
            startAngle: 0,
            endAngle: 360,
            background: {
              backgroundColor: backgroundColor,
              borderWidth: 0,
              innerRadius: '90%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },
          tooltip: {
            enabled: false
          },
          // the value axis
          yAxis: {
            zIndex: 4,
            stops: [
              [0, color], // White
              [1, color] // White
            ],
            background: {
              backgroundColor: backgroundColor,
              innerRadius: '90%',
              outerRadius: '100%',
              shape: 'arc'
            },
            tickInterval: 0,
            lineWidth: 0,
            minorTickWidth: 0,
            tickWidth: 0,
            title: {
              text: ''
            },
            labels: {
              enabled: false
            },
            min: 0,
            max: 100
          },
          plotOptions: {
            solidgauge: {
              innerRadius: '90%',
              dataLabels: {
                y: 0,
                borderWidth: 0,
                useHTML: true
              }
            }
          },
          exporting: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: '',
              data: [percentage || 0],
              dataLabels: {
                format: `<span class="${labelProps?.scoreClassName}">${
                  Number.isInteger(percentage) ? percentage : 'N/A'
                }</span>
              <span class="${labelProps?.percentageClassName}">${
                  Number.isInteger(percentage) ? '%' : ''
                }</span>`,
                align: 'center',
                verticalAlign: 'middle'
              },
              tooltip: {
                enabled: false
              }
            }
          ]
        },
        gaugeOption
      )}
    />
  )
}

GaugeChart.defaultProps = {
  color: '#000000',
  className: 'gaugeSmaller',
  backgroundColor: 'transparent',
  size: '100%'
}

export default GaugeChart
