import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { isLGScreen } from '../utils/utility'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{'Not Found | Abroad'}</title>
        <meta name='title' content={'Not Found | Abroad'}></meta>
        <meta property='og:title' content={'Not Found | Abroad'}></meta>
        <meta property='twitter:title' content={'Not Found | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/404`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/404`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/404`}></meta>
      </Helmet>
      <div className='position-relative h-100 w-100 vh-100 vw-100 not-found'>
        {isLGScreen ? (
          <Imgix
            className='lazyload w-100 h-lg-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found.png`}
          />
        ) : (
          <Imgix
            className='lazyload vh-100 h-md-100 vw-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found-sm.png`}
          />
        )}

        <Container
          fluid
          className='position-absolute inset-0 h-100 p-lg-5 p-md-5 p-2 text-lg-start text-md-start text-center'>
          <Row className='w-100 h-100 m-0'>
            <Col>
              <p className='pb-lg-5 tracking-md-wide'>404 - PAGE NOT FOUND</p>
              <h1 className='pb-3 fw-bolder'>Oh No.</h1>
              <div className='text-lg-start text-md-start text-center w-lg-35 w-sm-100'>
                <p className='font-lg-24 font-md-24 font-sm-14 fw-medium text-break'>
                  The page you requested could not be found. Please check your
                  link and you will be soaring again soon.
                </p>
              </div>
              <span className='font-lg-24 font-md-24 tracking-lg-wide home-link mb-4'>
                <Link to='/home' className='home-link report-link'>
                  CLICK HERE TO RETURN TO HOME.
                </Link>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NotFound
