import TagManager from 'react-gtm-module'
import FavoriteEvent from '../../constants/events'

export const gtmPlayFavoriteAudioAction = (
  mediaId,
  mediaSubCategoryId,
  isProgramMedia,
  userId,
) => {
  //GTM code
  TagManager.dataLayer({
    dataLayer: {
      event: FavoriteEvent.event.playFavoritedAudio,
      eventProps: {
        category: FavoriteEvent.category.playFavoritedAudioCategory,
        action: 'User play favorited audio',
        label: 'Play favorited audio',
        value: `User play ${
          isProgramMedia ? 'module' : 'media library'
        } favorited audio (mediaId - ${mediaId}, subCategoryId - ${mediaSubCategoryId})`,
        userId,
      },
    },
  })
}
