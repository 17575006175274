import React, { useRef, useEffect, useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { PlayIcon, plans } from '@abroad/components'
import { ABSwiperSlider } from '../slider'
import { useStartMediaPlayer } from '../media'
import classNames from 'classnames'
import { UpgradePlanModalContext } from '../../utils/contexts'
import { useResolutionService } from '../../hooks'

const MediaLibSwiperUI = ({
  isLoading,
  header,
  rightHeader,
  rightHeaderRedirectFn = () => {},
  limit,
  setPage,
  data,
}) => {
  const swiperRef = useRef(null)
  const [recentIndex, setRecentIndex] = useState(0)
  const { isMDScreen, isLGScreen } = useResolutionService()
  const { onShow, setModalData } = useContext(UpgradePlanModalContext)

  const { setMediaParams } = useStartMediaPlayer()

  useEffect(() => {
    if (!isLoading && data.length > 0) {
      setTimeout(() => {
        if (swiperRef && swiperRef.current) {
          swiperRef.current.update()
        }
      })
    }
  }, [isLoading, data])

  const openUpgradePlanModal = () => {
    onShow()
    setModalData({
      header: `Unlock the Full \nAbroad Media Library`,
      subHeader: `You're exploring the tip of an insightful iceberg! Our media library is packed with bite-sized audios designed to enhance your understanding and cultivate growth. Enjoy unrestricted access to the entire library, diving into all 108 transformative audios by upgrading to a Growth Plan`,
      btnText: 'Inquire about Growth Plan',
      plan: plans.growth.code,
    })
  }

  return (
    <Row className='g-0'>
      <Col className='g-0'>
        <div className='d-flex flex-lg-row flex-column justify-content-lg-between align-items-lg-center mb-3'>
          <div className='s3'>{header}</div>
          <div
            className='s11 font-saffron-700 cursor-pointer mt-lg-0 mt-2'
            onClick={() => rightHeaderRedirectFn()}>
            {rightHeader}
          </div>
        </div>
        {!isLoading && (
          <ABSwiperSlider
            onSlideNextTransitionEnd={(e) => {
              const index = e.activeIndex
              if (
                index > recentIndex &&
                index > 0 &&
                (index + 3) % limit === 0
              ) {
                setRecentIndex(() => index)
                setPage((prev) => prev + 1)
              }
            }}
            arrowProps={{
              hide:
                (isLGScreen && data.length < 3) ||
                (isMDScreen && data.length < 2),
              bgColor: '#646464',
              bgOpacity: 1,
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            containerClassName='card-swiper'>
            {data.map((media) => {
              const hasChildMediaId = !!media?.mediaId?._id
              const hasInnerSubcatId = !!media?.subCategoryId?._id
              let isUpgradeRequired,
                subCategoryId,
                mediaId,
                title,
                duration,
                imageFilename
              if (hasChildMediaId) {
                mediaId = media?.mediaId?._id
                isUpgradeRequired = media?.mediaId?.isUpgradeRequired
                subCategoryId = media?.mediaId?.subCategoryId
                title = media?.mediaId?.title
                duration = media?.mediaId?.duration
                imageFilename = media?.mediaId?.imageFilename
              } else {
                mediaId = media?._id
                isUpgradeRequired = media?.isUpgradeRequired
                subCategoryId = hasInnerSubcatId
                  ? media?.subCategoryId?._id
                  : media?.subCategoryId
                title = media?.title
                duration = media?.duration
                imageFilename = media?.isProgram
                  ? `${media?.subCategoryId}.jpg`
                  : media?.imageFilename
              }
              return (
                <div key={mediaId}>
                  <div
                    className='cursor-pointer'
                    onClick={(e) => {
                      if (isUpgradeRequired) {
                        openUpgradePlanModal()
                        return
                      }
                      if (!isUpgradeRequired) {
                        setMediaParams({
                          mediaId,
                          mediaSubCategoryId: subCategoryId,
                          isProgramMedia: media?.isProgram,
                        })
                      }
                    }}>
                    <div className='position-relative border-radius-10 overflow-hidden breakthrough-media-card'>
                      <Imgix
                        className='border-radius-10 img-cover'
                        imgixParams={{
                          fit: 'crop',
                          crop: 'edges',
                          w: 400,
                          h: 400,
                          ar: '1:1',
                        }}
                        src={`${process.env.REACT_APP_IMG_SOURCE}/${imageFilename}`}
                        width='100%'
                        alt='image'
                      />
                      <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
                        <PlayIcon className='play-icon' />
                      </div>
                    </div>
                  </div>
                  <div className='d-block mt-15px mb-3px'>
                    <div
                      className={classNames('s7 text-truncate', {
                        'cursor-pointer': media.isUpgradeRequired,
                      })}
                      onClick={() => {
                        if (media.isUpgradeRequired) {
                          openUpgradePlanModal()
                        }
                      }}>
                      {media.isUpgradeRequired && (
                        <span className='icon icon-padlock me-1 font-16 font-saffron-700' />
                      )}
                      {title}
                    </div>
                  </div>
                  <span className='s11 font-saffron-700'>
                    {Math.round(duration / 60)} minutes
                  </span>
                </div>
              )
            })}
          </ABSwiperSlider>
        )}
      </Col>
    </Row>
  )
}

export default MediaLibSwiperUI
