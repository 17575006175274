import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const CheckPromoCodeStatus = ({ render }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    if (values.promoCode && values.promoCode.trim()) {
      render(true)
    } else {
      render(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.promoCode])
  return null
}

export default CheckPromoCodeStatus
