import React, { useContext } from 'react'
import classNames from 'classnames'
import {
  Col,
  Accordion,
  useAccordionToggle,
  AccordionContext,
  Container,
  Row
} from 'react-bootstrap'
import DropdownCaretUpIcon from '../assetsComponents/common/DropdownCaretUpIcon'

function ContextAwareToggle({ title, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  const scrollToBottom = () => {
    const contentId = document.getElementById('content')
    setTimeout(() => {
      contentId.scrollTo(0, contentId.scrollHeight)
    }, 250)
  }

  return (
    <span
      onClick={() => {
        decoratedOnClick()
        scrollToBottom()
      }}
      className='font-open-sans font-bold font-24 leading-9 cursor-pointer mb-0 letter-spacing-minus-04'
    >
      {title}
      <DropdownCaretUpIcon
        className={classNames('polygon-icon', {
          'rotate-180': isCurrentEventKey
        })}
      />
    </span>
  )
}

const BottomAccordion = ({ title, rightBtn, children }) => {
  return (
    <Accordion>
      <Container className='bottom-accordion' fluid>
        <Row className='d-flex justify-content-between flex-column-reverse flex-md-row'>
          <Col className='px-0 mt-2 mt-lg-0'>
            <ContextAwareToggle eventKey={title} title={title} />
          </Col>
          <Col className='px-0 text-md-right'>{rightBtn}</Col>
        </Row>
      </Container>
      <Accordion.Collapse eventKey={title}>
        <React.Fragment>{children}</React.Fragment>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default BottomAccordion
