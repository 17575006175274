import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Table as RBTable } from 'react-bootstrap'
import TableHeader from './TableHeader'
import TableHeaderCell from './TableHeaderCell'
import TableBody from './TableBody'
/**
 * @title Table
 * @description Table for table data
 *
 * @param {Function} renderContent                  // React Renderer prop to display table body data
 */
const Table = React.forwardRef(
  (
    {
      renderContent,
      tableInstance,
      isLoading,
      emptyTableMessage,
      fetchData,
      selectedRow,
      totalNumberOfColumns = null,
      trClasses = '',
      bookmarkHover = false,
      otherOption = null,
    },
    ref,
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      columns,
      data,
      selectedFlatRows,
      state: { globalFilter, sortBy, selectedRowIds },
      toggleAllRowsSelected,
    } = tableInstance
    const observer = useRef(null)
    const loader = useRef(null)
    const [page, setPage] = useState(1)
    const limit = 25

    const handleObserver = useCallback(
      (entities) => {
        const target = entities[0]
        if (target.isIntersecting) {
          fetchData({ ...globalFilter, sortBy, page: page + 1, limit })
          setPage((page) => page + 1)
        }
      },
      [fetchData, globalFilter, sortBy, page, limit],
    )

    useEffect(() => {
      // initialize IntersectionObserver
      // and attaching to Load More div
      if (ref.current && !isLoading) {
        let options = {
          root: null,
          rootMargin: '20px',
          threshold: 1.0,
        }
        observer.current = new IntersectionObserver(handleObserver, options)
        if (loader.current && observer.current && data?.length >= limit) {
          // eslint-disable-next-line no-unused-expressions
          observer.current?.observe(loader.current)
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        observer.current?.disconnect()
      }
      return () => {
        // eslint-disable-next-line no-unused-expressions
        observer.current?.disconnect()
      }
    }, [ref, data, isLoading, handleObserver])

    useEffect(() => {
      setPage(1)
      fetchData({ ...globalFilter, sortBy, page: 1, limit })
    }, [fetchData, globalFilter, sortBy, limit])

    useEffect(() => {
      if (data.length === 0 && isLoading) {
        toggleAllRowsSelected(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading])

    useEffect(() => {
      selectedRow(selectedFlatRows.map((d) => d.original))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowIds])

    return (
      <RBTable hover responsive className='custom-table' {...getTableProps()}>
        <TableHeader
          headerGroups={headerGroups}
          renderCell={(columns) => {
            return columns.map((column, index) => {
              return (
                <TableHeaderCell
                  key={index}
                  column={column}
                  index={index}
                  totalColumns={columns}
                  isSortColumn={index !== 0 && index !== columns.length - 1}
                />
              )
            })
          }}
        />
        {renderContent ? (
          renderContent(getTableBodyProps, rows, prepareRow)
        ) : (
          <TableBody
            columns={columns}
            isLoading={isLoading}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            prepareRow={prepareRow}
            emptyTableMessage={emptyTableMessage}
            totalNumberOfColumns={totalNumberOfColumns}
            trClasses={trClasses}
            otherOption={otherOption}
            bookmarkHover={bookmarkHover}
          />
        )}
        <tfoot>
          <tr ref={loader}></tr>
        </tfoot>
      </RBTable>
    )
  },
)

Table.defaultProps = {
  emptyTableMessage: '',
  isLoading: false,
  selectedRow: () => {},
}

export default React.memo(Table)
