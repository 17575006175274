import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Imgix from 'react-imgix'
import TagManager from 'react-gtm-module'

import {
  useErrorService,
  FeatherLoader,
  useUserService,
} from '@abroad/components'

import ModuleMiniDetails from './ModuleMiniDetails'
// import ModuleActions from './ModuleActions'
import API from '../../utils/API'
import { useResolutionService } from '../../hooks'
import ProgramEvent from '../../constants/events'

const ProgramsList = () => {
  const [programs, setPrograms] = useState([])
  const history = useHistory()
  const { isMDScreen } = useResolutionService()
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { user } = useUserService()

  useEffect(() => {
    const getPrograms = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.programs.getMainPrograms()
        setPrograms(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getPrograms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gtmOpenProgramAction = (programTitle) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ProgramEvent.event.openProgram,
        eventProps: {
          category: ProgramEvent.category.openProgramCategory,
          action: 'User open program from programs page',
          label: 'Open program from programs page',
          value: `User open program from programs page, program name is ${programTitle}`,
          userId: user?.id,
        },
      },
    })
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <div className='container-fluid g-0'>
      {programs.map((program) => {
        return (
          <>
            <div
              className='row g-0 program-wrp cursor-pointer p-32px custom-gray-bg-100 mt-32px'
              onClick={() => {
                gtmOpenProgramAction(program?.title)
                history.push(`/program/${program._id}`)
              }}>
              <div className='col col-lg-4 col-12'>
                <div className='position-relative overflow-hidden border-radius-5'>
                  <Imgix
                    className='border-radius-5 img-cover w-100 program-img-zoom lazyload'
                    imgixParams={{
                      fit: 'crop',
                      crop: 'edges',
                      ar: isMDScreen ? '3:1' : '4:5',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/${program?.imageFilename}`}
                    alt='image'
                    attributeConfig={{
                      src: 'data-src',
                      srcSet: 'data-srcset',
                      sizes: 'data-sizes',
                    }}
                    htmlAttributes={{
                      src: `${process.env.REACT_APP_IMG_SOURCE}/${
                        program?.imageFilename
                      }?ar=${
                        isMDScreen ? '3:1' : '4:5'
                      }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                    }}
                  />
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
                </div>
              </div>
              <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
                {/* <ModuleActions
                  // todo ----
                  // toggleIsFavorite={toggleIsFavorite}
                  isFavorite={false}
                  showPlayIcon={false}
                /> */}
                <ModuleMiniDetails
                  className='mb-lg-12px'
                  details={{
                    label1: `${program?.expert}`,
                    label2: `${program?.subcategoryCount} Modules`,
                    label3: `${program?.mediaCount} Audios`,
                  }}
                />
                <div className='s2 my-12px'>
                  <span className='fst-normal'>{program.title}</span>
                  {': '}
                  <span className='fst-italic'>{program.subTitle}</span>
                </div>
                <div className='s7'>{program.description}</div>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default ProgramsList
