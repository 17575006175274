import React from 'react'
import classNames from 'classnames'
import { Button } from '@abroad/components'

const TakeNewAssessmentBtn = ({
  text = 'TAKE NEW ASSESSMENT',
  isBtnDisabled = false,
  btnClasses = '',
  onClick = () => {},
}) => {
  return (
    <Button
      disabled={isBtnDisabled}
      className={classNames('btn s9', btnClasses)}
      variant='abroad-blue'
      onClick={onClick}>
      {text}
    </Button>
  )
}

export default TakeNewAssessmentBtn
