import React, { useContext } from 'react'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
  useUserService,
  WisdomAssessmentResult as WisdomAssessmentResultSection,
  surveyType,
  PrintableArea,
} from '@abroad/components'
import { useResolutionService } from '../hooks'
import assessmentEvent from '../constants/events'
import { ProgramContext } from '../utils/contexts'
import API from '../utils/API'
import { HeaderWrp } from '../components/layout'

const WisdomAssessmentResult = () => {
  const { showEnrollBtn } = useContext(ProgramContext)
  const { isLGScreen } = useResolutionService()
  const { user } = useUserService()
  const { id } = useParams()
  const userId = user?.id || user?._id

  const gtmLearnMoreBtnClickedAction = (categoryId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.learnMore,
        eventProps: {
          category: assessmentEvent.category.learnMoreCategory,
          action: 'User click on learnMore button',
          label: 'click on learnMore button',
          value: categoryId,
          userId,
        },
      },
    })
  }

  const gtmAssessmentBtnClickedAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.TakeAssessment,
        eventProps: {
          category: assessmentEvent.category.UserTakeAssessmentCategory,
          action: 'User take assessment (Wisdom Assessment)',
          label: 'Take assessment (Wisdom Assessment)',
          value: `User take new survey assessment (Wisdom Assessment)`,
          userId: user?.id,
        },
      },
    })
  }

  const gtmCompareAssessmentAction = () => {
    // GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.compareAssessment,
        eventProps: {
          category: assessmentEvent.category.compareAssessmentCategory,
          action: 'User compare two assessment',
          label: 'compare two assessment',
          value: `User compare two assessment in survey page (Personal Assessment)`,
          userId: user?.id,
        },
      },
    })
  }

  const gtmUpgradeButtonClickedAction = (buttonText) => {
    // GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.upgradeButton,
        eventProps: {
          category: assessmentEvent.category.upgradeButtonCategory,
          action: 'User click on upgrade button',
          label: 'Click on upgrade button',
          value: `User Click on ${buttonText} button`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>Wisdom Awareness Result | Abroad</title>
        <meta name='title' content='Wisdom Awareness Result | Abroad' />
        <meta property='og:title' content='Wisdom Awareness Result | Abroad' />
        <meta
          property='twitter:title'
          content='Wisdom Awareness Result | Abroad'
        />
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}`}
        />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}`}
        />
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/wisdom/${id}`}
        />
      </Helmet>
      <WisdomAssessmentResultSection
        showEnrollBtn={showEnrollBtn}
        isLGScreen={isLGScreen}
        API={API}
        gtmLearnMoreBtnClickedAction={gtmLearnMoreBtnClickedAction}
        gtmAssessmentBtnClickedAction={gtmAssessmentBtnClickedAction}
        gtmCompareAssessmentAction={gtmCompareAssessmentAction}
        gtmUpgradeButtonClickedAction={gtmUpgradeButtonClickedAction}
        renderHeader={() => {
          return <HeaderWrp />
        }}
        apiFunction={() => {
          return API.survey.getSurveyById(id, surveyType.WISDOM) // (id, surveyType)
        }}
      />
      <PrintableArea printSelector='main' />
    </>
  )
}

export default WisdomAssessmentResult
