import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Card, Form, Spinner } from 'react-bootstrap'
import { useErrorService, useNotificationService } from '@abroad/components'
import axios from 'axios'
import { secondsToMin } from '../../utils/utility'
import audioService from '../../utils/audioService'
import API from '../../utils/API'

const RecordAudio = ({ intentionId, updateList }) => {
  const [timer, setTimer] = useState(0)
  const [title, setTitle] = useState('')
  const [isRecording, setIsRecording] = useState(true)
  const [isUploading, setIsUploading] = useState(false)
  const timeintervalRef = useRef()
  const ref = useRef()
  const [audioData, setAudioData] = useState()
  const Error = useErrorService()
  const notification = useNotificationService()
  useEffect(() => {
    timeintervalRef.current = setInterval(() => {
      setTimer((prev) => prev + 1)
    }, 1000)
    return () => {
      clearInterval(timeintervalRef.current)
    }
  }, [])

  const finishRecording = () => {
    try {
      clearInterval(timeintervalRef.current)
      const audioAsblob = audioService.stop()
      setAudioData(audioAsblob)
      setIsRecording(false)
    } catch (e) {
      switch (e.name) {
        case 'InvalidStateError':
          notification.showNotification('An Invalid State Error has occured.')
          break
        default:
          notification.showNotification('An error occured')
      }
    }
  }

  const handleChange = useCallback((e) => {
    setTitle(e.target.value)
  }, [])

  const handleBlur = useCallback(async () => {
    if (title?.trim() !== '') {
      try {
        setIsUploading(true)
        const { data } = await API.intention.getAudioSignedUrl(intentionId)
        let audioUrl = await axios.put(data.url, audioData, {
          headers: {
            'Content-Type': 'audio/mp3',
          },
        })
        if (audioUrl) {
          const { data: insightData } = await API.intention.updateAudioInsight(
            intentionId,
            data.tempInsightId,
            {
              mediaFilename: data.filename,
              title: title,
              duration: timer,
            },
          )
          updateList(insightData)
        }
      } catch (e) {
        setIsUploading(false)
        Error.showError(e)
      }
    } else {
      Error.showError('Please Enter a title for this Insight')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, audioData])

  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      ref.current.blur()
    }
  }, [])

  if (isRecording) {
    return (
      <Card className={'bg-abroad-blue border-0 border-radius-5'}>
        <Card.Body className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center space-x-3'>
            <span className='icon icon-microphone font-saffron-700 s7' />
            <span className='font-white s7'>{secondsToMin(timer)}</span>
          </div>
          <span
            className='font-saffron-700 s10a cursor-pointer'
            onClick={finishRecording}>
            FINISH RECORDING
          </span>
        </Card.Body>
      </Card>
    )
  } else {
    return (
      <Card className={'bg-abroad-blue border-0 border-radius-5'}>
        <Card.Body className='d-flex align-items-center justify-content-between'>
          <div className='d-flex flex-grow-1 flex-shrink-0 align-items-center space-x-3 pe-2'>
            <Form.Group className='w-100'>
              <Form.Control
                ref={ref}
                className='border-0 audio-title bg-transparent font-white s7 w-100 py-1 px-1'
                as='input'
                value={title}
                autoFocus={true}
                placeholder='Please enter a title for this insight'
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={onKeyPress}
              />
            </Form.Group>
          </div>
          <div className='d-flex align-items-center space-x-3'>
            <span className='font-white s7'>{secondsToMin(timer)}</span>
            {isUploading ? (
              <Spinner
                animation='border'
                size='sm'
                className={'font-saffron-700'}
                role='status'
              />
            ) : (
              <span className='icon icon-play font-saffron-700 s7' />
            )}
          </div>
        </Card.Body>
      </Card>
    )
  }
}

export default RecordAudio
