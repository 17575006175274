import React, { useState } from 'react'
import { ModelContext } from '../../contexts'

const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState(null)
  const onHide = () => {
    setShow(false)
  }
  const onShow = () => {
    setShow(true)
  }

  const setModalData = (data) => {
    setData(data)
  }

  return (
    <ModelContext.Provider
      value={{
        show: show,
        data: data,
        onShow: onShow,
        onHide: onHide,
        setModalData: setModalData
      }}
    >
      {children}
    </ModelContext.Provider>
  )
}

export default ModalProvider
