import React, { useState } from 'react'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import { useErrorService, useUserService } from '@abroad/components'
import RestoreIntentionEvent from '../../constants/events'
import API from '../../utils/API'

const RestoreIntentionLink = ({
  onSuccess,
  setIsRestoring,
  isDeleting,
  intentionId,
  intention,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const Error = useErrorService()
  const { user } = useUserService()
  const gtmRestoreIntentionAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: RestoreIntentionEvent.event.restoreIntention,
        eventProps: {
          category: RestoreIntentionEvent.category.restoreIntentionCategory,
          action: 'User restore intention',
          label: 'Restore intention',
          value: `User restore intention in growthPlan page`,
          userId: user?._id,
        },
      },
    })
  }

  const archiveIntention = async () => {
    try {
      const archived = false
      setIsLoading(true)
      setIsRestoring(true)
      const { data } = await API.intention.toggleArchive(intentionId, archived)
      if (data) {
        gtmRestoreIntentionAction()
        setIsLoading(false)
        setIsRestoring(false)
        onSuccess(intentionId, intention)
      }
    } catch (e) {
      setIsLoading(false)
      setIsRestoring(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <span
        className={classNames('restore-link text-saffron-700 s10a', {
          'cursor-pointer': !isLoading,
          'pe-none': isLoading || isDeleting,
        })}
        onClick={archiveIntention}>
        {isLoading ? 'restoring...' : 'restore'}
      </span>
    </>
  )
}

export default RestoreIntentionLink
