import React from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment'
import classNames from 'classnames'
import PolygonChart from '../PolygonChart'
import SurveyComparisonTabelRow from '../SurveyComparisonTabelRow'
import ABSpinner from '../../Spinner'
import Feedback from '../../common/Feedback'
import { GoBackIcon } from '../../assetsComponents'

const WisdomComparisonReport = ({
  isLoading,
  preDate,
  postDate,
  comparisonData,
  header,
  onGoBack = () => {},
  isRequiredToFormatDate = true,
  showGoBackButton = true,
  isRenderFromOP = false
}) => {
  const spitedPreDate = preDate.toString().split(' - ')
  const spitedPostDate = postDate.toString().split(' - ')
  const formatedPreDate = isRequiredToFormatDate
    ? `${moment(spitedPreDate[0]).format('MM-DD-YYYY')} - ${moment(
        spitedPreDate[1]
      ).format('MM-DD-YYYY')}`
    : `${moment(preDate).format('MM-DD-YYYY')}`
  const formatedPostDate = isRequiredToFormatDate
    ? `${moment(spitedPostDate[0]).format('MM-DD-YYYY')} - ${moment(
        spitedPostDate[1]
      ).format('MM-DD-YYYY')}`
    : `${moment(postDate).format('MM-DD-YYYY')}`

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <div className='abroad-fs-exclude'>
      <section
        className={classNames({
          'px-0 custom-offset-lg-1 custom-col-lg-12': isRenderFromOP
        })}
      >
        <Container className='pt-4 px-0' fluid>
          <Row className='page-header'>
            {isRenderFromOP ? (
              <Col className='text-center'>
                <span className='icon icon-assessments font-40' />
                <div className='d-flex'>
                  {showGoBackButton && (
                    <div className='back-btn'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='36'
                        viewBox='0 0 20 36'
                        fill='none'
                      >
                        <path
                          d='M18.5748 1.35352L1.4248 17.9997'
                          stroke='black'
                          strokeWidth='2.5'
                          strokeLinecap='round'
                        />
                        <path
                          d='M18.5748 34.6462L1.4248 18'
                          stroke='black'
                          strokeWidth='2.5'
                          strokeLinecap='round'
                        />
                      </svg>

                      <svg
                        onClick={onGoBack}
                        className='cursor-pointer'
                        width='20'
                        height='36'
                        viewBox='0 0 22 38'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
                          fill='black'
                        />
                      </svg>
                    </div>
                  )}
                  <div className='d-flex w-100 justify-content-center'>
                    <h2 className='font-montserrat font-semibold text-dark-600'>
                      {header()}
                    </h2>
                  </div>
                </div>
              </Col>
            ) : (
              <Col>
                <div className='d-flex flex-column'>
                  <div className='d-flex justify-content-between'>
                    {showGoBackButton && (
                      <div className='d-flex align-self-center'>
                        <GoBackIcon
                          showSmallIcon='true'
                          onClick={onGoBack}
                          className='me-2'
                        />
                        <span className='s-captions text-capitalize'>
                          Wisdom Assessment
                        </span>
                      </div>
                    )}
                    {header()}
                  </div>
                </div>
                <h3 className='s1 mt-minus-04 mb-32px'>Compare Results</h3>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <section>
        <Container className='pb-4' fluid>
          <Row className='mb-3'>
            <Col className='text-center'>
              <div className='d-flex flex-column text-gray-800 align-items-center font-open-sans'>
                <p
                  className={classNames('w-100 font-normal', {
                    'px-0 custom-col-lg-12': isRenderFromOP,
                    'w-100 font-normal': !isRenderFromOP
                  })}
                >
                  The following charts and quantitative data help you see how
                  your Self Understanding, and Wisdom & Connection scores have
                  changed between the two assessment periods you selected.
                </p>
                <p
                  className={classNames('w-100 font-normal', {
                    'px-0 custom-col-lg-12': isRenderFromOP,
                    'w-100 font-normal': !isRenderFromOP
                  })}
                >
                  We invite you not to be overly concerned with whether your
                  scores have increased or decreased. Sometimes life and
                  business present challenges that require us to change our
                  working habits for a period of time. If an area decreases,
                  that is good awareness around opportunities to course correct.
                  Similarly, if an area increases, we invite you to celebrate,
                  but also not attach to it always being that way.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Self Understanding */}
      <section className={isRenderFromOP && 'offset-lg-1 col-lg-10 px-lg-0'}>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-blue-100'>
              Self Understanding
            </span>
          </Container>
        </div>
        <Row noGutters>
          <Col className='col-12 bg-gray-101 col-lg-3'>
            {/* survey length < 3 */}
            <Container
              fluid='lg'
              className=' d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'
            >
              <div className='assessment-date space-y-2'>
                <div className='bg-blue-100 result-compare-circle' />
                <div className='text-center results-date'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPreDate}
                  <br />
                </div>
              </div>
              <div className='assessment-date space-y-2'>
                <div className='bg-blue-200 result-compare-circle' />
                <div className='text-center results-date'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPostDate}
                  <br />
                </div>
              </div>
            </Container>
          </Col>
          <Col className='col-12 col-lg-9 py-4'>
            <div className='d-flex flex-column align-items-center'>
              <PolygonChart
                categories={[
                  'Focus & Flow',
                  'Fear & Attachment',
                  'Burnout Resistance',
                  'Emotional Intelligence',
                  'Self Compassion'
                ]}
                colors={['#69B1d8', '#0076BD']}
                surveyOne={[
                  parseInt(
                    comparisonData[0]?.results.self_understanding.subcategories
                      .time_management__focus?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_understanding.subcategories
                      .fear__attachment?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_understanding.subcategories
                      .burnout_resistance?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_understanding.subcategories
                      .emotional_intelligence?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.self_understanding.subcategories
                      .self_compassion?.percent
                  )
                ]}
                surveyTwo={[
                  parseInt(
                    comparisonData[1]?.results.self_understanding.subcategories
                      .time_management__focus?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_understanding.subcategories
                      .fear__attachment?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_understanding.subcategories
                      .burnout_resistance?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_understanding.subcategories
                      .emotional_intelligence?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.self_understanding.subcategories
                      .self_compassion?.percent
                  )
                ]}
              />
              <Container fluid>
                <Table
                  responsive
                  hover
                  size='sm'
                  className='comparison-table m-auto'
                >
                  <thead>
                    <tr>
                      <th>Subcategory</th>
                      <th className='text-center text-blue-100'>
                        Score #1
                        <br />
                        {formatedPreDate}
                      </th>
                      <th className='text-center text-blue-200'>
                        Score #2
                        <br />
                        {formatedPostDate}
                      </th>
                      <th className='text-center'>
                        Change <br />
                        (+/-)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <SurveyComparisonTabelRow
                      title='Focus & Flow'
                      survey0={
                        comparisonData[0]?.results.self_understanding
                          .subcategories.time_management__focus?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_understanding
                          .subcategories.time_management__focus?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Fear & Attachment'
                      survey0={
                        comparisonData[0]?.results.self_understanding
                          .subcategories.fear__attachment?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_understanding
                          .subcategories.fear__attachment?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Burnout Resistance'
                      survey0={
                        comparisonData[0]?.results.self_understanding
                          .subcategories.burnout_resistance?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_understanding
                          .subcategories.burnout_resistance?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Emotional Intelligence'
                      survey0={
                        comparisonData[0]?.results.self_understanding
                          .subcategories.emotional_intelligence?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_understanding
                          .subcategories.emotional_intelligence?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Self Compassion'
                      survey0={
                        comparisonData[0]?.results.self_understanding
                          .subcategories.self_compassion?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.self_understanding
                          .subcategories.self_compassion?.percent
                      }
                    />
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
      {/* Wisdom & Connection */}
      <section className={isRenderFromOP && 'offset-lg-1 col-lg-10 px-lg-0'}>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-green-100'>
              Wisdom & Connection
            </span>
          </Container>
        </div>
        <Row noGutters>
          <Col className='col-12 bg-gray-101 col-lg-3'>
            {/* survey length < 3 */}
            <Container
              fluid='lg'
              className=' d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'
            >
              <div className='assessment-date space-y-2'>
                <div className='bg-green-100 result-compare-circle' />
                <div className='text-center results-date'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPreDate}
                  <br />
                </div>
              </div>
              <div className='assessment-date space-y-2'>
                <div className='bg-green-200 result-compare-circle' />
                <div className='text-center results-date'>
                  RESULTS DATED&#58;&nbsp;
                  {formatedPostDate}
                  <br />
                </div>
              </div>
            </Container>
          </Col>
          <Col className='col-12 col-lg-9 py-4'>
            <div className='d-flex flex-column align-items-center'>
              <PolygonChart
                categories={[
                  'Purpose & Meaning',
                  'Ethics & Decision-Making',
                  'Belonging',
                  'Community',
                  'Compassion',
                  'Nature',
                  'Balance & Inner Peace'
                ]}
                colors={['#509f92', '#37763B']}
                surveyOne={[
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .purpose__meaning?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .decision_making__ethics?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .belonging?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .community?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .compassion?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .nature?.percent
                  ),
                  parseInt(
                    comparisonData[0]?.results.wisdom__connection.subcategories
                      .balance__inner_peace?.percent
                  )
                ]}
                surveyTwo={[
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .purpose__meaning?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .decision_making__ethics?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .belonging?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .community?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .compassion?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .nature?.percent
                  ),
                  parseInt(
                    comparisonData[1]?.results.wisdom__connection.subcategories
                      .balance__inner_peace?.percent
                  )
                ]}
              />
              <Container fluid>
                <Table
                  responsive
                  hover
                  size='sm'
                  className='comparison-table m-auto'
                >
                  <thead>
                    <tr>
                      <th>Subcategory</th>
                      <th className='text-center text-green-100'>
                        Score #1
                        <br />
                        {formatedPreDate}
                      </th>
                      <th className='text-center text-green-200'>
                        Score #2
                        <br />
                        {formatedPostDate}
                      </th>
                      <th className='text-center'>
                        Change <br />
                        (+/-)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <SurveyComparisonTabelRow
                      title='Purpose & Meaning'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.purpose__meaning?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.purpose__meaning?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Ethics & Decision-Making'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.decision_making__ethics?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.decision_making__ethics?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Belonging'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.belonging?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.belonging?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Community'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.community?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.community?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Compassion'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.compassion?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.compassion?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Nature'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.nature?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.nature?.percent
                      }
                    />
                    <SurveyComparisonTabelRow
                      title='Balance & Inner Peace'
                      survey0={
                        comparisonData[0]?.results.wisdom__connection
                          .subcategories.balance__inner_peace?.percent
                      }
                      survey1={
                        comparisonData[1]?.results.wisdom__connection
                          .subcategories.balance__inner_peace?.percent
                      }
                    />
                  </tbody>
                </Table>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
      <Feedback />
    </div>
  )
}

export default WisdomComparisonReport
