import React, { useRef } from 'react'
import SwiperCore, { Navigation, EffectFade } from 'swiper'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'
import { LeftArrowIcon, RightArrowIcon } from '../assetsComponents/slider'

SwiperCore.use([Navigation, EffectFade])

const ABSwiperSlider = React.forwardRef(
  (
    {
      children,
      breakpoints,
      containerClassName,
      effect,
      arrowProps: { hide, ...resetArrowProps },
      ...rest
    },
    ref
  ) => {
    const prevRef = useRef(null)
    const nextRef = useRef(null)
    return (
      <div className={containerClassName}>
        <Swiper
          ref={ref}
          breakpoints={breakpoints}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            // swiper.navigation.init()
            // swiper.navigation.update()
          }}
          navigation={{
            nextEl: nextRef.current ? nextRef.current : undefined,
            prevEl: prevRef.current ? prevRef.current : undefined
          }}
          style={{ position: 'unset' }}
          {...rest}
        >
          {!hide && (
            <React.Fragment>
              <LeftArrowIcon ref={prevRef} {...resetArrowProps} />
              <RightArrowIcon ref={nextRef} {...resetArrowProps} />
            </React.Fragment>
          )}
          {children}
        </Swiper>
      </div>
    )
  }
)

ABSwiperSlider.propTypes = {
  containerClassName: PropTypes.string
}

ABSwiperSlider.defaultProps = {
  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    576: {
      slidesPerView: 'auto',
      spaceBetween: 25
    },
    992: {
      slidesPerView: 'auto',
      spaceBetween: 25
    }
  },
  arrowProps: {
    hide: false
  }
}

export default ABSwiperSlider
export { SwiperSlide }
