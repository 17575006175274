import React from 'react'
import { Modal } from 'react-bootstrap'
import {
  Button,
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import CKEditor from '../editor'
import TagManager from 'react-gtm-module'
import API from '../../utils/API'
import JournalSaveDataEvent from '../../constants/events'

const JournalModal = React.memo(
  ({
    show,
    onHide,
    journalData = '',
    mediaId,
    onJournalDataChange,
    closingQuestion = '',
    isMediaLibraryAudio = false,
  }) => {
    const Error = useErrorService()
    const Notification = useNotificationService()
    const { user } = useUserService()
    const userId = user?.id
    const saveJournalData = async (reqData) => {
      if (reqData?.journal) {
        let mId = mediaId
        try {
          const { data } = await API.mediaLibrary.saveJournal(mId, reqData)
          if (data) {
            onJournalDataChange(reqData.journal, mId)
            //GTM code
            TagManager.dataLayer({
              dataLayer: {
                event: JournalSaveDataEvent.event.journalDataSave,
                eventProps: {
                  category:
                    JournalSaveDataEvent.category.journalDataSaveCategory,
                  action: 'User save journal data',
                  label: 'User save journal',
                  value: `User save journal data by mediaId - ${mediaId} from Media Page`,
                  userId,
                },
              },
            })
          }
          Notification.showNotification(
            'Your journal entry has been saved.',
            'success',
          )
        } catch (e) {
          Error.showError(e)
        }
      }
    }

    return (
      <Modal
        show={show}
        size='lg'
        onHide={onHide}
        aria-labelledby='Journal'
        centered>
        <Modal.Header className='border-0 pb-0 px-4'>
          <span className='text-uppercase font-montserrat text-saffron-700 font-14'>
            {isMediaLibraryAudio ? <>Reflection</> : <>Journal</>}
          </span>
        </Modal.Header>
        <Modal.Body className='pb-3 pt-2 px-4 abroad-fs-exclude'>
          {closingQuestion && isMediaLibraryAudio && (
            <h5 className='mb-3 font-16 font-montserrat font-medium font-lg-20'>
              <span
                dangerouslySetInnerHTML={{
                  __html: closingQuestion,
                }}
              />
            </h5>
          )}
          <CKEditor
            data={journalData || ''}
            onBlur={(event, editor) => {
              saveJournalData({ journal: editor.getData() })
            }}
          />
          <div className='text-right mt-3'>
            <Button
              variant='none'
              size='sm'
              onClick={onHide}
              className='px-3 secondary-popup-button'>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  },
)

export default JournalModal
