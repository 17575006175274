import moment from 'moment'

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  return moment(date).format(format)
}

export const formateMonthDate = (date) => {
  return moment.utc(date).format('MMMM Do')
}

export const formateAmPm = (time) => {
  return moment(time, 'HH:mm')
    .format('h:mm a')
    .replace('am', 'a.m.')
    .replace('pm', 'p.m.')
}

export const insightDateFormat = (date) => {
  return moment(date).format('MMM Do YYYY')
}

export const getAssessmentCurrentData = (date, dateFormat) => {
  if (date) {
    return moment(date).format(dateFormat ? dateFormat : 'MMM Do, YYYY')
  } else {
    return moment().format(dateFormat ? dateFormat : 'MMM Do, YYYY')
  }
}

export const sortedByDate = (data, key) => {
  return data?.sort((a, b) => (moment(a?.[key]) > moment(b?.[key]) ? -1 : 1))
}

export const formatDuration = (duration) => {
  return moment.duration(duration, 'seconds').format('mm:ss', { trim: false })
}
