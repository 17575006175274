import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { ABSpinner, useErrorService, useUserService } from '@abroad/components'
import { FreePlanNoSurvey, PageHeader } from '../components'
import API from '../utils/API'
import { surveyType } from '../utils/constants'
import { sortedByDate } from '../utils/date'

const type = 'personal'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Wisdom Assessment | Abroad'}</title>
      <meta name='title' content={'Wisdom Assessment | Abroad'}></meta>
      <meta property='og:title' content={'Wisdom Assessment | Abroad'}></meta>
      <meta
        property='twitter:title'
        content={'Wisdom Assessment | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/wisdom`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/wisdom`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/wisdom`}></meta>
    </Helmet>
  )
}

const renderPageHeader = () => <PageHeader title='Wisdom Assessment' />

const BeginWisdomAssessment = () => {
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { user } = useUserService()
  const history = useHistory()

  useEffect(() => {
    const getWisdomSurveys = async () => {
      try {
        const { data } = await API.survey.getSurveys(type, surveyType.WISDOM) // (surveyType, filterBy)
        if (data && data?.surveys.length > 0) {
          const sortedSurveys = sortedByDate(data?.surveys, 'createdAt') // (data, key)
          history.push(`/wisdom/${sortedSurveys[0]?._id}`)
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (user?.wisdomTaken) {
      getWisdomSurveys()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      {renderHelmet()}
      <main>
        {renderPageHeader()}
        <FreePlanNoSurvey
          type={surveyType.WISDOM}
          isRenderOnAwarenessPage={true}
          wisdomPage={true}
        />
      </main>
    </>
  )
}

export default BeginWisdomAssessment
