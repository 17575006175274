import React from 'react'
import classNames from 'classnames'

const GoBackIcon = ({ onClick, className }) => {
  return (
    <div className='go-back-icon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        onClick={onClick}
        className={classNames('cursor-pointer', className)}>
        <path
          d='M11 1.41406L4 8.20845'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
        />
        <path
          d='M11 14.9975L4 8.20312'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>

      <svg
        width='16'
        height='16'
        viewBox='0 0 24 43'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={onClick}
        className={classNames('cursor-pointer', className)}>
        <path
          d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
          fill='black'
        />
      </svg>
    </div>
  )
}

export default GoBackIcon
