import React from 'react'

export const AuthContext = React.createContext({
  auth: false,
  setAuth: () => {},
  user: null,
  setUser: () => {},
  journeyMetdata: null
})

export const IntentionContext = React.createContext()

export const SelfReflectionContext = React.createContext()

export const ModelContext = React.createContext({
  show: false,
  onHide: () => {},
  onShow: () => {},
  setModalData: () => {},
  data: null
})

export const TeamContext = React.createContext()
