import React from 'react'
import classnames from 'classnames'

const ForwardIcon = ({
  isDisabled,
  wrapperClassName,
  className,
  fontSize = '22px',
  ...rest
}) => {
  return (
    <span
      className={classnames(
        'd-flex align-items-start hover:text-saffron-700 forward-icon-wrapper',
        {
          'cursor-pointer': !isDisabled,
          'pointer-event-none': isDisabled,
        },
        wrapperClassName,
      )}
      style={{
        fontSize,
        ...(isDisabled && {
          color: 'rgba(255,255,255, 0.50)',
        }),
      }}
      {...rest}>
      <span className='s-captions'>15</span>
      <span
        className={classnames(
          'icon icon-forward hover:text-saffron-700',
          {
            'cursor-pointer': !isDisabled,
            'pointer-event-none': isDisabled,
          },
          className,
        )}
        style={{
          fontSize,
          ...(isDisabled && {
            color: 'rgba(255,255,255, 0.50)',
          }),
        }}
      />
    </span>
  )
}

export default ForwardIcon
