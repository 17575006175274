import React, { useEffect, useState } from 'react'
import Button from '../../Button'
import { useNotificationService } from '../../../services'
import { surveyType } from '../../../helper'

const TakeNewAssessment = ({
  variant,
  className,
  title,
  wrapperProps,
  getTypeFormLink = false,
  API,
  type = surveyType.WISDOM,
  gtmAssessmentBtnClickedAction,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [link, setLink] = useState('')
  const Notification = useNotificationService()

  useEffect(() => {
    const getUrl = async () => {
      try {
        const { data } = await API.survey.getLink(type)
        setLink(data.link)
        setIsLoading(false)
        if (data?.code === 'upgrade_plan') {
          setError(data?.message)
        } else {
          setError(null)
        }
      } catch (e) {
        if (e?.code === 'survey_limit_reached') {
          setError(e.message)
        }
        setIsLoading(false)
      }
    }
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const takeNewAssessment = () => {
    Notification.showNotification(error)
  }

  return (
    <a
      {...(link && {
        href: link,
        target: '_self',
        rel: 'noopener noreferrer'
      })}
      {...(error
        ? { onClick: takeNewAssessment }
        : { onClick: gtmAssessmentBtnClickedAction })}
      {...wrapperProps}
    >
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        variant={variant}
        className={className}
        {...rest}
      >
        {title || <>Take new assessment</>}
      </Button>
    </a>
  )
}

export default TakeNewAssessment
