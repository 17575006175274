import { useContext } from 'react'
import { NotificationContext } from '../Components/Notification/NotificationProvider'
import { bugsnagClient } from '../utils/BugsnagClient'

const useErrorService = () => {
  const Notification = useContext(NotificationContext)

  const showError = (error) => {
    if (error?.constructor?.name === 'Error') {
      if (error.message === 'Request aborted') {
        return
      }
      if (error.message === 'Network Error') {
        Notification &&
          Notification.showNotification('Network Error, Please try again later')
      } else {
        bugsnagClient.leaveBreadcrumb('Error State', error, 'error')
        bugsnagClient.notify('ErrorService')
        Notification &&
          Notification.showNotification(
            'Something went wrong, please try again later.'
          )
      }
    } else {
      switch (error?.code) {
        case 'payment_fail':
        case 'token_expired':
        case 'not_found':
        case 'signup_error':
        case 'email_exists':
        case 'internal_server_error':
        case 'user_not_found':
        case 'unauthorize':
        case 'upgrade_plan':
        case 'forbidden':
        case 'previous_media_not_completed':
        case 'media_not_completed':
        case 'previous_module_not_completed':
        case 'validation_error':
        case 'code_already_exists':
        case 'need_at_least_one_survey':
        case 'resource_not_found':
        case 'company_cycle_not_started_yet':
        case 'not_in_current_billing_cycle':
        case 'session_limit_exceeded':
        case 'cycle_start_date_cannot_change':
        case 'payment_must_be_within_cycle':
        case 'cycle_start_date_not_set':
        case 'only_six_months_date_range_allowed':
        case 'participant_level_required_to_assign_team':
        case 'different_team_participant_level':
        case 'conflict':
        case 'session_duration_not_set':
        case 'max_media_limit_exceeded':
        case 'session_date_must_be_within_cycle':
        case 'need_at_least_three_survey':
        case 'sow_team_custom_rate_needed':
        case 'not_taken_survey':
        case 'transition_cannot_do_leader':
        case 'user_not_eligible':
        case 'user_plan_not_expire':
        case 'leader_req_cant_update':
        case 'coaching_plan_limit_exceeded':
        case 'cant_update_plan_as_user_in_group':
        case 'register_with_org_provided_email':
        case 'org_not_invite_only':
        case 'network_not_invite_only':
        case 'cant_set_no_plan_in_org':
        case 'cant_set_no_plan_in_network':
        case 'csv_max_limit':
        case 'video_already_exists':
        case 'register_with_invite_link':
          if (Notification) Notification.showNotification(error.message)
          break
        case 'document_not_found':
        case 'participant_level_must_be_set':
          if (Notification) Notification.showNotification(error.message)
          break
        case 'user_not_part_of_team':
          if (Notification) Notification.showNotification(error.message)
          break
        case 'session_notes_character_limit_exceeded':
          if (Notification) Notification.showNotification(error.message)
          break
        case 'two_factor_not_verified':
        case 'payment_required':
        case 'plan_expired':
        case 'account_not_verify':
          Notification && Notification.showNotification(error.message)
          break
        default:
          bugsnagClient.leaveBreadcrumb('Error State', error, 'error')
          bugsnagClient.notify('ErrorService default')
          if (Notification)
            Notification.showNotification('Something went wrong!')
          break
      }
    }
  }

  return { showError }
}

export default useErrorService
