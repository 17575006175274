import { useState, useEffect } from 'react'

const useResolutionService = () => {
  const [isLGScreen, setIsLGScreen] = useState(window.innerWidth >= 992)
  const [isSMScreen, setIsSMScreen] = useState(
    window.innerWidth >= 576 && window.innerWidth < 768,
  )
  const [isMDScreen, setIsMDScreen] = useState(
    window.innerWidth >= 768 && window.innerWidth < 992,
  )
  const [isXSScreen, setIsXSScreen] = useState(window.innerWidth < 576)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const windowSize = () => {
      if (window.innerWidth >= 992) {
        setIsLGScreen(true)
        setIsSMScreen(false)
        setIsMDScreen(false)
        setIsXSScreen(false)
      } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
        setIsLGScreen(false)
        setIsSMScreen(true)
        setIsMDScreen(false)
        setIsXSScreen(false)
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        setIsLGScreen(false)
        setIsSMScreen(false)
        setIsMDScreen(true)
        setIsXSScreen(false)
      } else {
        setIsXSScreen(true)
        setIsLGScreen(false)
        setIsSMScreen(false)
        setIsMDScreen(false)
      }
    }
    window.addEventListener('resize', windowSize)
    return () => window.removeEventListener('resize', windowSize)
  })

  return { isLGScreen, isSMScreen, isMDScreen, isXSScreen }
}

export default useResolutionService
