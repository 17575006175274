import React, { useState } from 'react'
import classNames from 'classnames'
import { Col } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import { useErrorService, useUserService } from '@abroad/components'
import API from '../../utils/API'
import SessionEvent from '../../constants/events'

const AttachKeyResources = ({ pdfData, sessionId }) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const Error = useErrorService()
  const { user } = useUserService()
  const userId = user?.id

  const downloadKeySkillHandler = (title) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.sessionKeySkills,
        eventProps: {
          category: SessionEvent.category.sessionKeySkillsCategory,
          action: 'User download recommended key skills',
          label: 'Download recommended key skills',
          value: `User download recommended key skills, title is ${title}`,
          userId,
        },
      },
    })
  }

  const handleDownloadPDF = async () => {
    try {
      setIsDownloadLoading(true)
      const { data } = await API.programs.getURLForSkillPDF(
        pdfData?.subCategoryId,
        pdfData?._id,
        sessionId,
      )
      downloadKeySkillHandler(`${pdfData?.title} (PDF)`)
      if (data) {
        window.open(data.url.url)
        setIsDownloadLoading(false)
      }
    } catch (e) {
      setIsDownloadLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Col
      key={pdfData?.programId}
      className='col-12 col-lg-7 mx-auto mx-lg-0 border-radius-10 pt-1 cursor-pointer d-flex align-items-center space-x-3 my-1 ps-0'>
      {/* download icon */}
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        className={classNames('d-flex align-self-center flex-grow-1', {
          'download-icon-rotate': isDownloadLoading,
        })}
        onClick={handleDownloadPDF}
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20 39.5C30.7696 39.5 39.5 30.7696 39.5 20C39.5 9.23045 30.7696 0.5 20 0.5C9.23045 0.5 0.5 9.23045 0.5 20C0.5 30.7696 9.23045 39.5 20 39.5Z'
          stroke='black'
        />
        <line
          x1='11.5'
          y1='28.5'
          x2='28.5'
          y2='28.5'
          stroke='black'
          strokeLinecap='round'
        />
        <path
          d='M20.8462 25.6565C20.4539 26.2793 19.5461 26.2793 19.1538 25.6566L15.7693 20.2829C15.3499 19.617 15.8285 18.75 16.6155 18.75L23.3845 18.75C24.1715 18.75 24.6501 19.617 24.2307 20.2829L20.8462 25.6565Z'
          fill='black'
        />
        <line
          x1='20'
          y1='20.4287'
          x2='20'
          y2='11.0001'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
      <div className='d-flex flex-column justify-content-start flex-shrink-10'>
        <p className='font-open-sans font-bold font-14 mb-0 truncate leading-6 text-left font-montserrat'>
          {pdfData?.title} (PDF)
        </p>
        <span className='font-montserrat font-semibold font-10 text-left text-uppercase tracking-md-wide'>
          {pdfData?.description}
        </span>
      </div>
    </Col>
  )
}

export default AttachKeyResources
