import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import API from '../../utils/API'
import { isSMScreen } from '../../utils/utility'

const NextPrevModuleSection = ({ wrapperClasses, isPrevModuleDisabled }) => {
  const { moduleId, programId } = useParams()
  const history = useHistory()
  const [adjoining, setAdjoining] = useState({
    nextModule: null,
    previousModule: null,
  })

  const PreviousIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        className='previous-module'>
        <path
          d='M15.9996 3.70313L7.59961 11.8564'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
        />
        <path
          d='M15.9996 19.997L7.59961 11.8438'
          stroke='black'
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    )
  }
  const NextIcon = () => {
    return (
      <>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          class='pointer-cursor ml-3 next-module align-self-center'>
          <path
            d='M8.00039 20.2969L16.4004 12.1436'
            stroke='black'
            stroke-width='2'
            stroke-linecap='round'
          />
          <path
            d='M8.00039 4.00299L16.4004 12.1562'
            stroke='black'
            stroke-width='2'
            stroke-linecap='round'
          />
        </svg>
      </>
    )
  }
  useEffect(() => {
    const getAdjoining = async () => {
      try {
        const { data } = await API.programs.getAdjoining(moduleId)
        setAdjoining(data)
      } catch (e) {
        if (e?.code === 'resource_not_found') {
          setAdjoining({
            nextModule: null,
            previousModule: null,
          })
        }
      }
    }
    getAdjoining()
  }, [moduleId])

  return (
    <div
      className={classNames(
        'd-flex module-container justify-content-center w-100',
        {
          'ps-2': isSMScreen,
          //  'px-3': !isLGScreen
        },
        wrapperClasses,
      )}>
      <div className='w-100 h-100 module-list media-content'>
        <Row>
          <Col className='col-6'>
            <div
              className={classNames('d-flex', {
                'pointer-event-none text-gray-501': !adjoining?.previousModule,
                'pointer-cursor': adjoining?.previousModule,
              })}
              onClick={() => {
                if (!adjoining?.previousModule) return
                history.push(
                  `/module/${programId}/${adjoining.previousModule?._id}`,
                )
              }}>
              <span className='back-btn cursor-pointer d-flex align-items-center'>
                {/* {renderNextPrevIcon('me-3 align-self-center')} */}
                <PreviousIcon />
                <span className='s6 ms-2'>Previous Module</span>
              </span>
            </div>
          </Col>
          <Col className='col-6'>
            <div
              className={classNames(
                'd-flex justify-content-end pointer-cursor',
                {
                  'pointer-event-none text-gray-501':
                    !adjoining?.nextModule || adjoining?.nextModule?.isDisabled,
                  'pointer-cursor':
                    adjoining?.nextModule && !adjoining?.nextModule?.isDisabled,
                },
              )}
              onClick={() => {
                if (!adjoining?.nextModule) return
                history.push(
                  `/module/${programId}/${adjoining.nextModule?._id}`,
                )
              }}>
              <span className='back-btn cursor-pointer d-flex align-items-center'>
                <span className='text-end s6 me-2'>Next Module</span>
                {/* {renderNextPrevIcon('ms-3 next-module align-self-center')} */}
                <NextIcon />
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default NextPrevModuleSection
