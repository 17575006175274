import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import { InputField } from '../form'

const EmailField = ({ isCustomValidating = false, isSubmitting = false }) => {
  let { state } = useLocation()
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext()

  useEffect(() => {
    if (state?.email) {
      setFieldValue('email', state.email)
      setFieldTouched('email', true, false)
    }
  }, [state, setFieldValue, setFieldTouched, validateField])
  return (
    <InputField
      name='email'
      type='email'
      label='EMAIL'
      size='lg'
      isSubmitting={isSubmitting}
      isCustomValidating={isCustomValidating}
    />
  )
}

export default EmailField
