import React from 'react'

const VolumeMuteIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      className='icon-mute cursor-pointer'
      {...props}
    >
      <path
        d='M11.2401 6.25988L7.54674 9.95318C7.34497 10.155 7.24408 10.2558 7.12634 10.328C7.02196 10.392 6.90816 10.4391 6.78911 10.4677C6.65484 10.4999 6.51217 10.4999 6.22681 10.4999H4.20001C3.54662 10.4999 3.21992 10.4999 2.97036 10.6271C2.75084 10.7389 2.57236 10.9174 2.4605 11.1369C2.33334 11.3865 2.33334 11.7132 2.33334 12.3665V15.6332C2.33334 16.2867 2.33334 16.6133 2.4605 16.8629C2.57236 17.0824 2.75084 17.2609 2.97036 17.3727C3.21992 17.4999 3.54662 17.4999 4.20001 17.4999H6.22681C6.51217 17.4999 6.65484 17.4999 6.78911 17.5322C6.90816 17.5608 7.02196 17.6079 7.12634 17.6718C7.24408 17.7439 7.34497 17.8449 7.54674 18.0467L11.24 21.7399C11.7398 22.2397 11.9897 22.4896 12.2043 22.5065C12.3904 22.5211 12.5724 22.4457 12.6936 22.3037C12.8333 22.1402 12.8333 21.7868 12.8333 21.08V6.91985C12.8333 6.21306 12.8333 5.85966 12.6936 5.69603C12.5724 5.55404 12.3904 5.47869 12.2043 5.49334C11.9897 5.51022 11.7398 5.76011 11.2401 6.25988Z'
        fill='#BF9000'
      />
      <path
        d='M17.4994 9.49935L26.4994 18.4994M26.4994 9.49935L17.4994 18.4994M11.2401 6.25988L7.54674 9.95318C7.34497 10.155 7.24408 10.2558 7.12634 10.328C7.02196 10.392 6.90816 10.4391 6.78911 10.4677C6.65484 10.4999 6.51217 10.4999 6.22681 10.4999H4.20001C3.54662 10.4999 3.21992 10.4999 2.97036 10.6271C2.75084 10.7389 2.57236 10.9174 2.4605 11.1369C2.33334 11.3865 2.33334 11.7132 2.33334 12.3665V15.6332C2.33334 16.2867 2.33334 16.6133 2.4605 16.8629C2.57236 17.0824 2.75084 17.2609 2.97036 17.3727C3.21992 17.4999 3.54662 17.4999 4.20001 17.4999H6.22681C6.51217 17.4999 6.65484 17.4999 6.78911 17.5322C6.90816 17.5608 7.02196 17.6079 7.12634 17.6718C7.24408 17.7439 7.34497 17.8449 7.54674 18.0467L11.24 21.7399C11.7398 22.2397 11.9897 22.4896 12.2043 22.5065C12.3904 22.5211 12.5724 22.4457 12.6936 22.3037C12.8333 22.1402 12.8333 21.7868 12.8333 21.08V6.91985C12.8333 6.21306 12.8333 5.85966 12.6936 5.69603C12.5724 5.55404 12.3904 5.47869 12.2043 5.49334C11.9897 5.51022 11.7398 5.76011 11.2401 6.25988Z'
        stroke='#BF9000'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default VolumeMuteIcon
