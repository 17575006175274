import React from 'react'
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useLocation,
  NavLink,
  useHistory,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'
import {
  FeatherLoader,
  Button,
  NavTabs,
  useErrorService,
  useUserService,
} from '@abroad/components'
import { isLGScreen } from '../utils/utility'
import { PageHeader } from '../components/common'
import loadable from '@loadable/component'
import API from '../utils/API'
import AuthService from '../utils/AuthService'
import { UpdatePassword } from '../components'

const urlArray = [
  '/profile',
  '/profile/my-information',
  '/profile/plans-payments',
]

const ProfileForm = loadable(
  () => import('../components/profile/ProfileForm'),
  {
    fallback: <FeatherLoader width={60} />,
  },
)

const UserPlansAndPayments = loadable(() => import('./UserPlansAndPayments'), {
  fallback: <FeatherLoader width={60} />,
})

const Profile = () => {
  let location = useLocation()
  const { url } = useRouteMatch()
  const match = urlArray.includes(url)
  const { user, setUser } = useUserService()
  const Error = useErrorService()
  const history = useHistory()

  const pages = [
    {
      path: '/profile/my-information',
      name: 'My Info',
      defaultUrl: '/profile',
    },
    {
      path: '/profile/plans-payments',
      name: 'Plans and Payment',
    },
  ]

  const onLogout = async () => {
    try {
      await API.onboarding.logout()
      setUser(null)
      AuthService.removeData()
      history.push('/')
    } catch (e) {
      Error.showError(e)
    }
  }

  return (
    <>
      <Helmet>
        <title>{'Profile | Abroad'}</title>
        <meta name='title' content={'Profile | Abroad'}></meta>
        <meta property='og:title' content={'Profile | Abroad'}></meta>
        <meta property='twitter:title' content={'Profile | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/profile`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile`}></meta>
      </Helmet>
      <main>
        <section className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
          <PageHeader
            title='Your Profile'
            showBackArrow={true}
            backArrowAction={() => {
              if (history.length > 1) {
                history.goBack()
              } else {
                history.push('/home')
              }
            }}
          />
        </section>
        <section className='container-fluid abroad-fs-exclude px-0 overflow-hidden'>
          <div className='py-4 bg-gray-100 row mt-32px'>
            <Container fluid className='custom-col-lg-8 custom-col-10 px-4'>
              <h2 className='font-montserrat font-semibold mb-0'>
                {user?.firstName}&nbsp;{user?.lastName}
              </h2>
              <p
                className={classNames('font-montserrat font-medium', {
                  'mb-0': location.pathname === '/profile/update-password',
                })}>
                {user?.email}
              </p>
              {match && (
                <Link
                  as='p'
                  to='/profile/update-password'
                  className='text-uppercase tracking-md-wide font-montserrat text-saffron-700 font-14'>
                  Update Password
                </Link>
              )}
              <div
                className={classNames('text-start', {
                  'pt-3': !isLGScreen,
                })}>
                <Button
                  variant='saffron'
                  size='md'
                  className='d-inline d-lg-none border-radius-normal mt-3'
                  onClick={onLogout}>
                  Sign Out
                </Button>
              </div>
            </Container>
          </div>
        </section>
        <section className='container-fluid abroad-fs-exclude px-lg-0'>
          <div
            className={classNames(
              'pt-3 custom-col-lg-8 custom-col-10 custom-offset-lg-1',
              { 'px-0': !isLGScreen },
            )}>
            {match && (
              <NavTabs
                pages={pages}
                pathname={location?.pathname}
                hash={location?.hash}
                NavLink={NavLink}
              />
            )}
            <Switch location={location}>
              <Route
                exact
                strict
                path={['/profile', '/profile/my-information']}>
                <ProfileForm />
              </Route>
              <Route exact strict path={'/profile/plans-payments'}>
                <UserPlansAndPayments />
              </Route>
              <Route exact strict path='/profile/update-password'>
                <UpdatePassword />
              </Route>
            </Switch>
          </div>
        </section>
      </main>
    </>
  )
}

export default Profile
