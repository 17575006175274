import React from 'react'
import TagManager from 'react-gtm-module'
import { useUserService, useErrorService } from '@abroad/components'
import ProgramEvents from '../../constants/events'
import API from '../../utils/API'

const ModuleFav = ({ isFavorite, moduleId, updateModuleInfo, title }) => {
  const { user } = useUserService()
  const Error = useErrorService()

  const gtmToggleModuleFavoriteAction = (isFavorite) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: ProgramEvents.event.toggleModuleFavorite,
        eventProps: {
          category: `${title} module like/dislike`,
          action: 'User like/dislike module',
          label: `${title} module like/dislike`,
          value: `isFavorite - ${!isFavorite} for ${title}`,
          userId: user?._id,
        },
      },
    })
  }

  const toggleFavorite = async () => {
    const isFavoriteLocal = isFavorite
    updateModuleInfo(!isFavorite)
    try {
      const { data } = await API.programs.toggleFavorite(
        {
          isFavorite: !isFavoriteLocal,
        },
        moduleId,
      )
      if (data) {
        gtmToggleModuleFavoriteAction()
      }
    } catch (e) {
      updateModuleInfo(isFavoriteLocal)
      Error.showError(e)
    }
  }
  return (
    <div className='d-flex icons my-4'>
      <span
        className={`icon icon-bookmark${
          isFavorite ? '-gold' : ' hover:text-saffron-700'
        } cursor-pointer`}
        onClick={toggleFavorite}
      />
    </div>
  )
}

export default ModuleFav
