import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {
  Button,
  surveyType,
  useErrorService,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import { openLinkInTab } from '../../utils/openLinkInTab'

const AssessmentTakenModal = ({
  isShowModal,
  onClose,
  isOpenSixMonthsButtons,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadingType, setLoadingType] = useState('')
  const { user } = useUserService()
  const Error = useErrorService()

  const getUrl = async (type) => {
    try {
      setIsLoading(true)
      const { data } = await API.survey.getLink(type || surveyType.WISDOM)
      setIsLoading(false)
      setLoadingType('')
      if (data?.code === 'upgrade_plan') {
        Error.showError(data)
      } else {
        openLinkInTab(data?.link, '_self')
      }
      onClose()
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
      setLoadingType('')
    }
  }

  return (
    <Modal show={isShowModal} size='lg' onHide={onClose} centered>
      <Modal.Body className='pb-3 pt-2 px-4'>
        <div className='s7'>
          {isOpenSixMonthsButtons
            ? `How long have you been working with ${user?.staticCompany}? Your response will help us tailor your experience.`
            : `The Awareness Assessment is designed specifically for people in operating roles. Since you're currently in a career transition, we recommend taking the Wisdom Assessment instead. This will ensure you get the most relevant and helpful analysis for your current situation.`}
        </div>
        <div className='d-flex justify-content-end mt-3 space-x-3'>
          {isOpenSixMonthsButtons ? (
            <>
              <Button
                variant='abroad-blue'
                size='sm'
                disabled={isLoading}
                isLoading={loadingType === surveyType.WISDOM}
                onClick={() => {
                  setLoadingType(surveyType.WISDOM)
                  getUrl()
                }}>
                Less Than 6 Months
              </Button>
              <Button
                variant='abroad-blue'
                size='sm'
                disabled={isLoading}
                isLoading={loadingType === surveyType.AWARENESS}
                onClick={() => {
                  setLoadingType(surveyType.AWARENESS)
                  getUrl(surveyType.AWARENESS)
                }}>
                More Than 6 Months
              </Button>
            </>
          ) : (
            <>
              <Button
                variant='abroad-blue'
                size='sm'
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => getUrl()}>
                Take Wisdom Assessment
              </Button>
              <Button
                variant='secondary-light'
                size='sm'
                disabled={isLoading}
                onClick={onClose}>
                Maybe Later
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AssessmentTakenModal
