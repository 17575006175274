import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import {
  ABSpinner,
  ModalProvider,
  useErrorService,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import {
  InsightModel,
  // ArchivedIntentions,
  InsightCard,
  ArchiveIntentionAction,
} from '../components'
import { PageHeader } from '../components/common'
import API from '../utils/API'
import classNames from 'classnames'
import CreateInsight from '../components/growthPlan/CreateInsight'
import audioService from '../utils/audioService'
import RecordAudio from '../components/common/RecordAudio'
import IntentionEvent from '../constants/events'

const Intention = () => {
  const [title, setTitle] = useState('')
  const [intention, setIntention] = useState()
  const history = useHistory()
  const Error = useErrorService()
  const { user } = useUserService()
  const notification = useNotificationService()
  const location = useLocation()
  const [isRecording, setIsRecording] = useState(false)
  const { intentionId } = useParams()
  const [isLoading, setIsLoading] = useState(
    location.state?.isLocal ? false : true,
  )
  const ref = useRef()
  const [mediaUrl, setMediaUrl] = useState()
  const [loadedAudioId, setLoadedAudioId] = useState()
  const insightAudioEleId = `insight-audio`

  useEffect(() => {
    const getIntentionById = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.intention.getIntentionById(intentionId)
        if (data?.archived || data === null) {
          history.replace('/coaching/intentions')
          return
        }
        setTitle(data?.title)
        setIntention(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (!location.state?.isLocal) {
      getIntentionById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const handleChange = useCallback((e) => {
    setTitle(e.target.value)
  }, [])

  const getUrl = useCallback(() => {
    if (location.state?.isLocal) {
      return API.intention.addIntention({ title })
    } else {
      return API.intention.updateIntention({ title }, intentionId)
    }
  }, [location, title, intentionId])

  const gtmAddIntentionAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: IntentionEvent.event.addIntention,
        eventProps: {
          category: IntentionEvent.category.addIntentionCategory,
          action: 'User added intention',
          label: 'added intention',
          value: `User added intention in growthPlan page`,
          userId: user?._id,
        },
      },
    })
  }

  const handleBlur = useCallback(async () => {
    if (title?.trim() !== '') {
      try {
        const { data } = await getUrl()
        if (location.state?.isLocal) gtmAddIntentionAction()
        history.replace(`/coaching/intentions/${data._id}`)
      } catch (e) {
        Error.showError(e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUrl, title, history])

  const onKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      ref.current.blur()
    }
  }, [])

  const updateInsight = (insight, isCreated) => {
    if (isCreated) {
      setIntention((prev) => {
        return {
          ...prev,
          insights: [insight, ...prev.insights],
        }
      })
    } else {
      const insights = intention.insights.map((i) => {
        if (insight._id === i._id) {
          return insight
        }
        return i
      })
      setIntention((prev) => {
        return {
          ...prev,
          insights: insights,
        }
      })
    }
  }

  const renderLoading = () => {
    if (isLoading) {
      return (
        <div className='mt-32px'>
          <ABSpinner />
        </div>
      )
    }
  }

  const recordAudio = async () => {
    try {
      await audioService.start()
      setIsRecording(true)
    } catch (e) {
      if (
        e.message.includes(
          'mediaDevices API or getUserMedia method is not supported in this browser.',
        )
      ) {
        notification.showNotification(
          'To record audio, use browsers like Chrome and Firefox.',
        )
      }
      switch (e.name) {
        case 'AbortError':
          notification.showNotification(
            'Recording halted: Your hardware is currently unavailable. Please ensure no other applications are using your microphone and try again.',
          )
          break
        case 'NotAllowedError':
          notification.showNotification(
            'Permission Denied: Please allow microphone access to record audio.',
          )
          break
        case 'NotFoundError':
          notification.showNotification(
            'Hardware Missing: We couldn’t find a connected microphone. Please check and try again.',
          )
          break
        case 'NotReadableError':
          notification.showNotification(
            'Hardware Error: An issue occurred at the system level preventing audio recording. Please restart your browser or computer and try again.',
          )
          break
        case 'SecurityError':
          notification.showNotification(
            'Security Restriction: Audio recording is not supported or enabled on this browser. Please use a different browser or check your settings.',
          )
          break
        // case 'TypeError':
        //   notification.showNotification('A Type Error has occured.')
        //   break
        case 'InvalidStateError':
          notification.showNotification(
            'Recording Error: Another recording is already in progress. Please finish or stop the current recording before starting a new one.',
          )
          break
        case 'UnknownError':
          notification.showNotification(
            'Unexpected Error: An unknown error occurred while recording. Please try again.',
          )
          break
        default:
          notification.showNotification(
            'Recording Error: An issue occurred while trying to record audio. Please refresh the page or try again later.',
          )
      }
    }
  }

  const updateList = useCallback((insight) => {
    setIsRecording(false)
    setIntention((prev) => {
      return {
        ...prev,
        insights: [insight, ...prev.insights],
      }
    })
  }, [])

  const textAreaSizeChangeHandler = () => {
    if (ref && ref.current) {
      ref.current.style.height = '0px'
      const textareaHeight = ref.current.scrollHeight
      ref.current.style.height = textareaHeight + 'px'
    }
  }

  useEffect(() => {
    textAreaSizeChangeHandler()
  }, [ref, title])

  return (
    <main className='intention'>
      <>
        <Helmet>
          <title>{'Intentions & Insights | Abroad'}</title>
          <meta name='title' content={'Intentions & Insights | Abroad'}></meta>
          <meta
            property='og:title'
            content={'Intentions & Insights | Abroad'}></meta>
          <meta
            property='twitter:title'
            content={'Intentions & Insights | Abroad'}></meta>
          <link
            rel='canonical'
            href={`${process.env.REACT_APP_DOMAIN}/coaching/intentions/${intentionId}`}></link>
          <meta
            property='og:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions/${intentionId}`}></meta>
          <meta
            property='twitter:url'
            content={`${process.env.REACT_APP_DOMAIN}/coaching/intentions/${intentionId}`}></meta>
        </Helmet>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Growth Plan'
          backArrowAction={() => history.push('/coaching/intentions')}
        />
        {renderLoading()}
        <audio id={insightAudioEleId} allow='autoplay'>
          <source src={mediaUrl} />
          Your browser does not support the <code>audio</code> element.
        </audio>
        {!isLoading && (
          <div>
            <div className='d-flex mb-32px intention-input'>
              <Form.Group className='d-flex w-100 intention-form-group'>
                <Form.Control
                  ref={ref}
                  className='border-0 s1 intention form-group-lg py-3 px-0'
                  as='textarea'
                  row={1}
                  value={title}
                  autoFocus={location.state?.isLocal}
                  placeholder='e.g., Create better decision making processes with my team'
                  onChange={handleChange}
                  onFocus={textAreaSizeChangeHandler}
                  onBlur={handleBlur}
                  onKeyPress={onKeyPress}
                />
              </Form.Group>
              {!location?.state?.isLocal && (
                <ArchiveIntentionAction intentionId={intention?._id} />
              )}
            </div>
            <ModalProvider>
              {location.state?.isLocal && (
                <div className='custom-gray-info-text space-y-2'>
                  <div className='s3'>Set Your Intention</div>
                  <div className='s5 fw-normal'>
                    Begin by inputting a clear intention in the title section,
                    focusing on your desired growth. Once set, you'll be able to
                    log insights, from moments of clarity to significant
                    breakthroughs. Your intention acts as your foundation, so
                    craft it with purpose, and let each insight illuminate your
                    path forward.
                  </div>
                </div>
              )}
              {!location.state?.isLocal && intention?.insights?.length === 0 && (
                <div className='custom-gray-info-text space-y-2'>
                  <div className='s3'>Log New Insight</div>
                  <div className='s5'>
                    With your intention set, you're on the path to growth. As
                    experiences and realizations emerge related to your
                    intention, capture your insights here, either as a voice
                    note or in writing. Each moment of clarity or observation
                    deepens your understanding and propels you towards your
                    goal. Embrace every insight, big or small, and witness your
                    growth unfold.
                  </div>
                </div>
              )}
              {!location.state?.isLocal && (
                <>
                  {intention?.insights?.length > 0 && (
                    <div className='s3'>Log New Insight</div>
                  )}
                  <Row
                    className={classNames({
                      'mt-32px': intention?.insights?.length === 0,
                      'mt-3': intention?.insights?.length > 0,
                    })}>
                    <CreateInsight type='text' intentionId={intentionId}>
                      <span className='icon icon-pencil font-saffron-700 action-icons' />
                    </CreateInsight>
                    <CreateInsight
                      type='audio'
                      isRecording={isRecording}
                      intentionId={intentionId}
                      recordAudio={recordAudio}>
                      {/* <span className='icon icon-microphone font-saffron-700 action-icons' /> */}
                      <div className='s6 text-white'>
                        Voice note recording is currently only available on our
                        app.{' '}
                        <span
                          className='text-saffron-300 cursor-pointer'
                          onClick={() => history.push('/download-app')}>
                          Click here to download
                        </span>
                      </div>
                    </CreateInsight>
                  </Row>
                </>
              )}
              {intention?.insights?.length > 0 && (
                <div className='mt-52px'>
                  {intention?.insights?.length > 0 && (
                    <div className='s3'>Your Insights</div>
                  )}
                  <Row>
                    {isRecording && (
                      <Col xs={12} sm={12} md={12} lg={6} className='my-3 px-3'>
                        <RecordAudio
                          intentionId={intentionId}
                          updateList={updateList}
                        />
                      </Col>
                    )}

                    {intention?.insights?.map((insight) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        className='my-3 px-3'
                        key={insight._id}>
                        <InsightCard
                          insight={insight}
                          intentionId={intentionId}
                          setMediaUrl={setMediaUrl}
                          insightAudioEleId={insightAudioEleId}
                          loadedAudioId={loadedAudioId}
                          setLoadedAudioId={setLoadedAudioId}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              <InsightModel updateInsight={updateInsight} />
            </ModalProvider>
          </div>
        )}
      </>
    </main>
  )
}

export default Intention
