import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { Form } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, plans, useErrorService } from '@abroad/components'
import { PageHeader } from '../components'
import API from '../utils/API'

const defaultEmailBodyTextForGrowth = `Dear Abroad Team,\n\nI'm interested in upgrading to the Growth Plan and would like to know more about the additional features and benefits. Please provide me with the necessary information to proceed with the upgrade.\n\nThank you for your help.`
const defaultEmailBodyTextForCoaching = `Dear Abroad Team,\n\nI'm interested in upgrading to the full plan and would like to know more about the additional features and benefits. Please provide me with the necessary information to proceed with the upgrade.\n\nThank you for your help.`

const inquiryStatusType = {
  UPGRADE_INQUIRY: 'upgradeInquiry',
  INQUIRY_SENT: 'inquirySent',
  SENDING_FAILED: 'sendingFailed',
}

const UpgradeInquiry = () => {
  const textAreaRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [inquiryStatus, setInquiryStatus] = useState(
    inquiryStatusType.UPGRADE_INQUIRY,
  )
  const Error = useErrorService()
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const queryPlanCode = query.get('plan')
  const title =
    inquiryStatus === inquiryStatusType.UPGRADE_INQUIRY
      ? `Press 'Send' to submit your upgrade request, or edit the message <br/> below to add more details.`
      : inquiryStatus === inquiryStatusType.INQUIRY_SENT
      ? 'Inquiry Sent'
      : 'Sending Failed'

  const description =
    inquiryStatus === inquiryStatusType.INQUIRY_SENT
      ? `Your upgrade inquiry has been sent successfully. Thank you for reaching out. Our support team will contact you shortly to provide you with detailed information and assist you through the upgrade process. Please check your inbox for our response.`
      : inquiryStatus === inquiryStatusType.SENDING_FAILED
      ? `Oops, it seems there was an error sending your request. Please try again or contact our support team directly at <b>service@abroad.io</b>`
      : ''

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const { status } = await API.user.upgradePlan({
        message: textAreaRef.current.value,
        planCode: queryPlanCode,
      })
      if (status) {
        setIsLoading(false)
        setInquiryStatus(inquiryStatusType.INQUIRY_SENT)
      } else {
        setIsLoading(false)
        setInquiryStatus(inquiryStatusType.SENDING_FAILED)
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
      setInquiryStatus(inquiryStatusType.SENDING_FAILED)
    }
  }
  return (
    <>
      <Helmet>
        <title>{'Upgrade Inquiry | Abroad'}</title>
        <meta name='title' content={'Upgrade Inquiry | Abroad'}></meta>
        <meta property='og:title' content={'Upgrade Inquiry | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Upgrade Inquiry | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/upgrade-inquiry`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/upgrade-inquiry`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/upgrade-inquiry`}></meta>
      </Helmet>
      <main>
        <PageHeader
          showBackArrow={true}
          title='Upgrade Inquiry'
          backArrowAction={() => history.goBack()}
        />
        <div className='upgrade-inquiry mt-32px'>
          <div
            className='inquiry-title'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {description && (
            <div
              className='s5 fw-normal mt-18px'
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          {inquiryStatus === inquiryStatusType.UPGRADE_INQUIRY && (
            <Form.Control
              as='textarea'
              ref={textAreaRef}
              className='mt-4 email-body-textarea s5 fw-normal'
              rows={6}
              defaultValue={
                queryPlanCode === plans.growth.code
                  ? defaultEmailBodyTextForGrowth
                  : defaultEmailBodyTextForCoaching
              }
              style={{ resize: 'none' }}
              disabled={isLoading}
            />
          )}
          <div
            className={classNames({
              'text-end mt-40px':
                inquiryStatus === inquiryStatusType.UPGRADE_INQUIRY,
              'text-start mt-52px':
                inquiryStatus !== inquiryStatusType.UPGRADE_INQUIRY,
            })}>
            <Button
              variant='saffron'
              className='border-radius-normal'
              disabled={isLoading}
              isLoading={isLoading}
              size='md'
              onClick={() => {
                if (isLoading) return
                if (inquiryStatus === inquiryStatusType.UPGRADE_INQUIRY) {
                  handleSubmit()
                } else {
                  history.length > 1 ? history.goBack() : history.push('/home')
                }
              }}>
              {inquiryStatus === inquiryStatusType.UPGRADE_INQUIRY
                ? 'Submit'
                : 'Return'}
            </Button>
          </div>
        </div>
      </main>
    </>
  )
}

export default UpgradeInquiry
