import React, { useCallback, useEffect, useState } from 'react'
import { PlayIcon } from '../assetsComponents'
import { isSafari } from '../../utils/utility'
import sysend from 'sysend'

const AssessmentCategoryAudio = ({
  categoryKeyName,
  mediaUrl,
  setMediaUrl,
  setLoadedAudioId,
  loadedAudioId,
  iconClasses = '',
  pauseOthers,
}) => {
  const [audio, setAudio] = useState(null)

  const [isPlaying, setIsPlaying] = useState(false)

  const categoryAudioEleId = 'category-audio'

  const pauseOtherPlayers = () => {
    const allPlayers = document.querySelectorAll('audio')
    allPlayers.forEach((player) => {
      if (player !== mediaUrl) {
        player.pause()
        setAudio(null)
        setLoadedAudioId(null)
        setIsPlaying(false)
      }
    })
  }
  const audioEndedHandler = useCallback(() => {
    audio.pause()
    setAudio(null)
    setLoadedAudioId(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio])

  const canPlayHandler = (event) => {
    if (!audio) return
    const promise = audio.play()
    if (promise !== undefined) {
      promise
        .then((_) => {
          if (audio && !isSafari) {
            audio.play()
          }
        })
        .catch((err) => {
          // Auto play disabled error
        })
    }
  }

  useEffect(() => {
    if (audio) {
      audio.volume = 0.8
      audio.loop = false
      try {
        audio.addEventListener('canplaythrough', canPlayHandler)
        audio.addEventListener('ended', audioEndedHandler)
        sysend.on('playIcon', (data) => {
          if (data?.message === 'playIcon') {
            setAudio(null)
            setIsPlaying(false)
            setLoadedAudioId(null)
            audio.pause()
          }
        })
      } catch (err) {
        // error
      }
    }
    return () => {
      if (audio) {
        audio.removeEventListener('canplaythrough', canPlayHandler)
        audio.removeEventListener('ended', audioEndedHandler)
        sysend.off('playIcon')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio])

  return (
    <>
      <PlayIcon
        isPlaying={loadedAudioId === categoryKeyName ? isPlaying : false}
        onClick={() => {
          if (audio && loadedAudioId === categoryKeyName) {
            audio.pause()
            setLoadedAudioId(null)
            setAudio(null)
            setIsPlaying(false)
          } else {
            if (pauseOthers) {
              pauseOtherPlayers()
              setLoadedAudioId(null)
              setAudio(null)
            }
            const categoryAudioEle = document.getElementById(categoryAudioEleId)
            setMediaUrl(mediaUrl)
            setAudio(categoryAudioEle)
            setLoadedAudioId(categoryKeyName)
            setIsPlaying(true)
            categoryAudioEle.load()
          }
        }}
        useSVGIcon={true}
        wrapperClasses='align-self-center'
        iconClasses={iconClasses}
        id={categoryKeyName}
      />
    </>
  )
}

export default AssessmentCategoryAudio
