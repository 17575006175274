import React from 'react'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import { plans, GrowthPlanBtn, useUserService } from '@abroad/components'
import CoachingPlanBtn from './CoachingPlanBtn'
import assessmentEvent from '../../constants/events'

const UpgradeNow = ({
  wrapperClasses = '',
  title,
  titleFn = () => {},
  titleClasses = '',
  subTitle,
  subTitleFn = () => {},
  subTitleClasses = '',
  buttonText = 'UPGRADE NOW',
  buttonClasses = '',
  plan = plans.growth.code,
}) => {
  const { user } = useUserService()

  const gtmUpgradeButtonClickedAction = (buttonText) => {
    // GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.upgradeButton,
        eventProps: {
          category: assessmentEvent.category.upgradeButtonCategory,
          action: 'User click on upgrade button',
          label: 'Click on upgrade button',
          value: `User Click on ${buttonText} button`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <div
      className={classNames(
        'upgrade-now bg-abroad-blue text-white',
        wrapperClasses,
      )}>
      {title ? (
        <h3
          className={classNames(
            's2 text-center text-white mb-0',
            titleClasses,
          )}>
          {title}
        </h3>
      ) : (
        titleFn()
      )}
      {subTitle ? (
        <h5 className={classNames('mt-3 s5 text-center mb-0', subTitleClasses)}>
          {subTitle}
        </h5>
      ) : (
        subTitleFn()
      )}
      <div className='text-center'>
        {plan === plans.coaching.code ? (
          <CoachingPlanBtn
            buttonText={buttonText}
            buttonClasses={classNames(
              'btn s10a text-uppercase leading-4 py-12px',
              {
                'onbording-action-btn': subTitle,
                'mt-32px': !subTitle,
              },
              buttonClasses,
            )}
          />
        ) : (
          <GrowthPlanBtn
            buttonText={buttonText}
            buttonClasses={classNames(
              'btn s10a text-uppercase leading-4 py-12px',
              {
                'onbording-action-btn': subTitle,
                'mt-32px': !subTitle,
              },
              buttonClasses,
            )}
            gtmUpgradeButtonClickedAction={gtmUpgradeButtonClickedAction}
          />
        )}
      </div>
    </div>
  )
}

export default UpgradeNow
