import React from 'react'
import classnames from 'classnames'

const UserProfileIcon = ({ className, isActive, ...rest }) => {
  return (
    <span
      className={classnames(
        'icon icon-user text-black cursor-pointer font-24',
        {
          'text-saffron-300 active': isActive
        },
        className
      )}
      {...rest}
    />
  )
}

export default UserProfileIcon
