import { useContext } from 'react'
import { IntentionContext } from '@abroad/components'

const useIntentionReducer = () => {
  const { state, dispatch } = useContext(IntentionContext)

  return { state, dispatch }
}

export default useIntentionReducer
