import React, { useContext, useEffect, useReducer } from 'react'
import { Col, Container } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import classNames from 'classnames'
import {
  FeatherLoader,
  useErrorService,
  useUserService,
} from '@abroad/components'
import NotificationList from './NotificationList'
import { NotificationCountContext } from '../../layout/contexts/NotificationProvider'
import API from '../../../utils/API'
import NotificationEvent from '../../../constants/events'

const initialNotificationState = {
  isLoading: true,
  notifications: { old: [], new: [] },
  error: null,
}

const notificationObj = {
  1: 'INDIVIDUAL_SESSION_NOTES',
  2: 'GROUP_SESSION_NOTES',
  3: 'DIRECT_MANAGER_FEEDBACK',
  4: 'LEADER_ASSESSMENT',
  5: 'LEADER_ASSESSMENT_READY',
}

const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_FETCH':
      return {
        ...state,
        isLoading: true,
      }
    case 'NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        notifications: action.payload.data,
      }
    case 'NOTIFICATIONS_FAIL':
      return {
        notifications: { old: [], new: [] },
        isLoading: false,
        error: action.payload,
      }
    case 'UPDATE_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload.data,
      }
    default:
      throw new Error()
  }
}

const NotificationSection = ({
  isDropdownOpen = false,
  clickNotificationhandler = () => {},
}) => {
  const source = process.env.REACT_APP_IMG_SOURCE
  const [notificationsObj, dispatch] = useReducer(
    notificationReducer,
    initialNotificationState,
  )
  const { notifications, isLoading } = notificationsObj
  const { old: oldNotifications, new: newNotifications } = notifications
  const Error = useErrorService()
  const { isReadHandler } = useContext(NotificationCountContext)
  const { user } = useUserService()
  const id = user?.id

  const gtmOpenNotificationAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: NotificationEvent.event.notifications,
        eventProps: {
          category: NotificationEvent.category.notificationsCategory,
          action: 'User click on notification icon',
          label: 'Click on notification icon',
          value: `User Click on notification icon.`,
          userId: id,
        },
      },
    })
  }

  const gtmReadNotificationAction = (data) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: NotificationEvent.event.unreadNotifications,
        eventProps: {
          category: NotificationEvent.category.unreadNotificationsCategory,
          action: 'User read particular notification',
          label: 'Read particular notification',
          value: `User read particular notification (${
            notificationObj[data?.type]
          }).`,
          userId: id,
        },
      },
    })
  }

  useEffect(() => {
    const getNotifications = async () => {
      dispatch({ type: 'NOTIFICATIONS_FETCH' })
      try {
        const { data: notificationData } = await API.user.getUserNotifications()

        if (notificationData) {
          const res = {
            old: [...notificationData?.read],
            new: [...notificationData?.notRead],
          }
          dispatch({
            type: 'NOTIFICATIONS_SUCCESS',
            payload: { data: res },
          })
        }
      } catch (e) {
        Error.showError(e)
        dispatch({ type: 'NOTIFICATIONS_FAIL', payload: e })
      }
    }
    if (isDropdownOpen) {
      getNotifications()
      gtmOpenNotificationAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen])

  const readNotificationhandler = async (id, data) => {
    try {
      const newNotification = newNotifications.filter(
        (data) => data.uniqueId !== id,
      )
      let isSkipedAPICall = false
      let notificationStatus = null
      if (data?.type === 1 || data?.type === 2 || data?.type === 5) {
        isSkipedAPICall = true
      }
      if (!isSkipedAPICall) {
        const { status } = await API.user.hasNotificationRead({
          type: data?.type,
          uniqueId: id,
        })
        if (status) notificationStatus = status
      }
      if (isSkipedAPICall || notificationStatus) {
        //set new notification variable
        dispatch({
          type: 'UPDATE_NOTIFICATIONS',
          payload: {
            data: {
              old: [...oldNotifications, { ...data }],
              new: [...newNotification],
            },
          },
        })
        isReadHandler()
      }
      gtmReadNotificationAction(data)
    } catch (e) {
      Error.showError(e)
    }
  }

  const renderFeatherIcon = () => {
    return <FeatherLoader width={60} />
  }

  return (
    <div className='notification-section p-2'>
      <>
        <Container className='px-0'>
          <div className='w-100'>
            {isLoading && (
              <Col className='d-flex align-items-center justify-content-center p-5'>
                {renderFeatherIcon()}
              </Col>
            )}
            {!isLoading &&
              oldNotifications?.length === 0 &&
              newNotifications?.length === 0 && (
                <Col className='d-flex align-items-center justify-content-center py-5'>
                  <p className='mb-0 notification-text'>
                    No Notification(s) yet!
                  </p>
                </Col>
              )}
            {!isLoading &&
              (oldNotifications?.length > 0 ||
                newNotifications?.length > 0) && (
                <div className='d-flex flex-column'>
                  <>
                    {newNotifications?.length > 0 && (
                      <>
                        <h3 className='mb-3 notification-title fw-bold'>NEW</h3>
                        {newNotifications?.map((data, index) => (
                          <NotificationList
                            data={data}
                            source={source}
                            wrapperClasses={
                              newNotifications?.length !== index + 1
                                ? 'mb-3'
                                : ''
                            }
                            key={index}
                            readNotificationhandler={readNotificationhandler}
                            clickNotificationhandler={clickNotificationhandler}
                            messageClasses='fw-bold'
                          />
                        ))}
                        {oldNotifications?.length > 0 && (
                          <h3
                            className={classNames('notification-title', {
                              'my-3': oldNotifications?.length > 0,
                              'mt-3 mb-0': oldNotifications?.length === 0,
                            })}>
                            EARLIER
                          </h3>
                        )}
                      </>
                    )}
                    <>
                      {oldNotifications?.map((data, index) => (
                        <NotificationList
                          key={index}
                          data={data}
                          source={source}
                          wrapperClasses={
                            oldNotifications?.length !== index + 1 ? 'mb-3' : ''
                          }
                          clickNotificationhandler={clickNotificationhandler}
                        />
                      ))}
                    </>
                  </>
                </div>
              )}
          </div>
        </Container>
      </>
    </div>
  )
}
export default NotificationSection
