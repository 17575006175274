import React from 'react'
import classNames from 'classnames'
import { Form } from 'react-bootstrap'

const CardSelectionCheckbox = ({
  checkboxId,
  selectedId,
  setSelectedId,
  label,
  enabledUncheckedAction = false,
  ...rest
}) => {
  return (
    <Form.Group className='mb-0 align-self-center me-3 custom-checkbox'>
      <Form.Check
        id={checkboxId}
        custom={true}
        onChange={() => {
          if (selectedId === checkboxId && enabledUncheckedAction) {
            setSelectedId(null)
            return
          }
          setSelectedId(checkboxId)
        }}
        checked={selectedId === checkboxId ? true : false}
        type='checkbox'
        className={classNames(
          {
            'cursor-pointer': !rest?.disabled,
            'cursor-disabled': rest?.disabled,
          },
          rest?.className,
        )}
        {...rest}>
        <Form.Check.Input
          id={checkboxId}
          onChange={() => {
            if (selectedId === checkboxId && enabledUncheckedAction) {
              setSelectedId(null)
              return
            }
            setSelectedId(checkboxId)
          }}
          checked={selectedId === checkboxId ? true : false}
          type='checkbox'
          className={classNames(
            {
              'cursor-pointer': !rest?.disabled,
              'cursor-disabled': rest?.disabled,
            },
            rest?.className,
          )}
          {...rest}
        />
        <Form.Check.Label
          className={classNames('checkbox-label text-uppercase s8', {
            'cursor-pointer': !rest?.disabled,
            'cursor-disabled': rest?.disabled,
          })}>
          {label}
        </Form.Check.Label>
      </Form.Check>
    </Form.Group>
  )
}

export default CardSelectionCheckbox
