import React from 'react'
import classnames from 'classnames'

const NextIcon = ({
  isNextDisabled,
  wrapperClassName,
  fontSize = '22px',
  ...rest
}) => {
  return (
    <span
      className={classnames(
        'icon icon-next text-dark-900 hover:text-saffron-700',
        {
          'text-white cursor-pointer': !isNextDisabled,
          'pointer-event-none': isNextDisabled,
        },
        wrapperClassName,
      )}
      style={{
        fontSize,
        ...(isNextDisabled && { color: 'rgba(255,255,255, 0.50)' }),
      }}
      {...rest}
    />
  )
}

export default NextIcon
