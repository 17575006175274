import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  useErrorService,
  ABSpinner,
  plans,
  useNotificationService,
  useUserService,
} from '@abroad/components'
import {
  CoachingPlanInformation,
  CoachingPlanPaymentForm,
  PageHeader,
} from '../components'
import API from '../utils/API'
import { sowRefId as sowRefIdConst } from '../utils/constants'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const CoachingPlanPayment = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [paymentsDetails, setPaymentsDetails] = useState([])
  const [isPaying, setIsPaying] = useState(false)
  const [sowRefLoader, setSOWRefLoader] = useState(true)
  const [staticSOWData, setStaticSOWData] = useState(null)
  const [selectedCardDetails, setSelectedCardDetails] = useState(null)
  const history = useHistory()
  const { coachingPlanId } = useParams()
  const Error = useErrorService()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const sowRefId = query.get(sowRefIdConst)
  const stripe = useStripe()
  const Notification = useNotificationService()
  const { user, setUser } = useUserService()

  const userPlanChangeHandle = () => {
    if ([plans.free.code, plans.growth.code].includes(user?.planCode)) {
      setUser({ ...user, planCode: plans.coaching.code })
      return
    }
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  useEffect(() => {
    const getSubscription = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.payment.getSubscription(coachingPlanId)
        if (data) {
          setPaymentsDetails(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        if (['resource_not_found', 'forbidden'].includes(e?.code)) {
          history.replace('/profile/plans-payments')
          return
        }
        Error.showError(e)
      }
    }

    const getStaticSOWByName = async () => {
      try {
        // just to pause popup for minimum 2 seconds (UX thing)
        const [res] = await Promise.all([
          API.coachingPlan.getStaticSOW(sowRefId),
          delay(2000),
        ])
        const { data } = res
        if (data) {
          setSOWRefLoader(false)
          setStaticSOWData(data)
          localStorage.removeItem(sowRefIdConst)
        }
      } catch (e) {
        setSOWRefLoader(false)
        Error.showError(e)
        history.replace('/profile/plans-payments')
      }
    }

    if (sowRefId) {
      setIsLoading(false)
      getStaticSOWByName()
    } else {
      setSOWRefLoader(false)
      getSubscription()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sowRefId])

  const paymentHandler = (values, isAddressObjChanged, cardId) => {
    if (!sowRefId) {
      paySOWHandler(values, isAddressObjChanged, cardId)
    } else {
      paySowWithoutCoachHandler(values, isAddressObjChanged, cardId)
    }
  }

  const paySowWithoutCoachHandler = async (
    values,
    isAddressObjChanged,
    cardId,
  ) => {
    setIsPaying(true)
    try {
      const { data } = await API.payment.paySowWithoutCoach({
        paymentMethodId: cardId,
        ...(isAddressObjChanged && { address: values }),
        sowName: sowRefId,
      })
      if (data?.sow?.actionRequired) {
        let options = {
          payment_method: data?.sow?.paymentMethodId,
        }
        // if (data?.sow?.status === 'requires_payment_method') {
        //   options =
        // }
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          data?.sow?.clientSecret,
          options,
        )
        if (paymentIntent?.status === 'succeeded') {
          await API.payment.confirmPayment({
            userId: user?._id,
            tempSowId: data?.sow?.id,
            paymentIntentId: paymentIntent?.id,
          })
          setIsPaying(false)
          userPlanChangeHandle()
          history.replace('/profile/plans-payments')
        } else {
          if (error?.code === 'card_declined') {
            Notification.showNotification(error?.message, 'danger')
          } else {
            Notification.showNotification(
              'Your payment is not completed. Please try again.',
              'danger',
            )
          }
          setIsPaying(false)
        }
      } else {
        setIsPaying(false)
        userPlanChangeHandle()
        history.replace('/profile/plans-payments')
      }
    } catch (e) {
      setIsPaying(false)
      Error.showError(
        e?.code === 'payment_fail'
          ? {
              code: e?.code,
              message: 'Your payment is not completed. Please try again.',
            }
          : e,
      )
    }
  }

  const paySOWHandler = async (values, isAddressObjChanged, cardId) => {
    setIsPaying(true)
    try {
      const { data } = await API.payment.paySOW({
        paymentMethodId: cardId,
        ...(isAddressObjChanged && { address: values }),
        sowId: coachingPlanId,
      })
      if (data?.sow?.actionRequired) {
        let options = {
          payment_method: data?.sow?.paymentMethodId,
        }
        // if (data?.sow?.status === 'requires_payment_method') {
        //   options =
        // }
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          data?.sow?.clientSecret,
          options,
        )
        if (paymentIntent?.status === 'succeeded') {
          await API.payment.confirmPayment({
            userId: user?._id,
            sowId: coachingPlanId,
            paymentIntentId: paymentIntent?.id,
          })
          setIsPaying(false)
          userPlanChangeHandle()
          history.replace('/profile/plans-payments')
        } else {
          if (error?.code === 'card_declined') {
            Notification.showNotification(error?.message, 'danger')
          } else {
            Notification.showNotification(
              'Your payment is not completed. Please try again.',
              'danger',
            )
          }
          setIsPaying(false)
        }
      } else {
        setIsPaying(false)
        userPlanChangeHandle()
        history.replace('/profile/plans-payments')
      }
    } catch (e) {
      setIsPaying(false)
      Error.showError(
        e?.code === 'payment_fail'
          ? {
              code: e?.code,
              message: 'Your payment is not completed. Please try again.',
            }
          : e,
      )
    }
  }

  const renderPageHelmet = () => {
    return (
      <Helmet>
        <title>{'Coaching Plan Payment | Abroad'}</title>
        <meta name='title' content={'Coaching Plan Payment | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Coaching Plan Payment | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Coaching Plan Payment | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}`}></meta>
      </Helmet>
    )
  }

  const renderPageHeader = () => {
    return (
      <section className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
        <PageHeader
          title='Plan Payment'
          showBackArrow={true}
          backArrowAction={() => {
            if (history.length > 1) {
              history.goBack()
            } else {
              history.push('/home')
            }
          }}
        />
      </section>
    )
  }

  if (sowRefLoader && sowRefId) {
    return (
      <>
        {renderPageHelmet()}
        <main>
          <StaticSOWLoaderModal show={sowRefLoader} />
          {renderPageHeader()}
        </main>
      </>
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      {renderPageHelmet()}
      <main>
        {renderPageHeader()}
        <section className='mt-52px'>
          <Container
            fluid
            className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
            <Row noGutters>
              <Col className='px-0' lg={5}>
                <CoachingPlanInformation
                  staticSOWData={staticSOWData}
                  subscription={paymentsDetails}
                  selectedCardDetails={selectedCardDetails}
                  setSelectedCardDetails={setSelectedCardDetails}
                />
              </Col>
              <Col className='px-0' lg={2}></Col>
              <Col className='px-0' lg={5}>
                <CoachingPlanPaymentForm
                  staticSOWData={staticSOWData}
                  subscription={paymentsDetails}
                  selectedCardDetails={selectedCardDetails}
                  onSuccess={paymentHandler}
                  isLoading={isPaying}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

const StaticSOWLoaderModal = ({ show }) => {
  return (
    <Modal show={show} onHide={() => {}} centered>
      <Modal.Body className='px-4 promo-accordion'>
        <Card className='border-0'>
          <Card.Body className='px-3 d-flex flex-column'>
            We are creating customised coaching plan for you.
            <br />
            Please wait for a moment...
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  )
}

const CoachingPlanPaymentWithStripe = () => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
          },
        ],
      }}>
      <CoachingPlanPayment />
    </Elements>
  )
}

export default CoachingPlanPaymentWithStripe
