import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import classNames from 'classnames'
import AssessmentScoreCard from './AssessmentScoreCard'
import { conditionMatched } from './OverviewSurveyResults'
import InfoIconOverlay from './InfoIconOverlay'
import { InformationIcon as InfoIcon } from '../../assetsComponents'
import { useResolutionService } from '../../../services'
import { ModelContext } from '../../../contexts'

const SurveySubcategoriesTable = ({
  title,
  survey,
  onLearnMoreClick,
  innerBackgroudColor,
  outerBackgroudColor,
  categoryKeyName,
  wrapperClasses,
  tableClasses,
  barHeight,
  parentId
}) => {
  const tableRef = useRef(null)
  const [height, setHeight] = useState(0)
  const [rowNumber, setRowNumber] = useState(null)
  const { isLGScreen } = useResolutionService()
  const { onShow, setModalData } = useContext(ModelContext)
  useLayoutEffect(() => {
    setHeight(tableRef.current.clientHeight)
  }, [])
  useEffect(() => {
    function handleWindowResize() {
      setHeight(tableRef.current.clientHeight)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  return (
    <div className={wrapperClasses}>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='assessment-chart text-center align-self-lg-center'>
          <AssessmentScoreCard
            title={title}
            percentage={survey?.results?.[categoryKeyName]?.percent}
            category={survey?.categories?.[categoryKeyName]}
            onLearnMoreClick={onLearnMoreClick}
            outerBackgroudColor={outerBackgroudColor}
            innerBackgroudColor={innerBackgroudColor}
            width='15.79rem'
            height={isLGScreen || conditionMatched ? `${height}px` : barHeight}
            linkClasses='s11 mb-0 text-white cursor-pointer'
            variant='table-attached-card'
          />
        </div>
        <div
          className={classNames('assessment-score mt-lg-0 mt-4', tableClasses)}
          ref={tableRef}
        >
          <div className='d-flex justify-content-between mb-18px pt-0 px-2'>
            <div className='d-flex'>
              <h3 className='s2 mb-0 '>{title}</h3>
            </div>
            <InfoIcon
              className='info-icon align-self-center cursor-pointer font-20 text-decoration-none'
              onClick={() => {
                onShow()
                setModalData(
                  survey?.categories?.find((e) => e._id === parentId)
                )
                onLearnMoreClick(
                  survey?.categories?.find((e) => e._id === parentId)?._id
                )
              }}
            />
          </div>
          <>
            {survey?.results?.[categoryKeyName]?.order.map(
              (subcategory, index) => (
                <div className='survey-subcat-link py-18px' key={index}>
                  <div
                    className={classNames('courses-title-row', {
                      'first-row': index === 0,
                      'border-bottom-transparent': index === rowNumber - 1
                    })}
                    data-id={
                      survey?.results?.[categoryKeyName]?.subcategories[
                        subcategory
                      ]?.title
                    }
                    onMouseEnter={() => {
                      setRowNumber(index)
                    }}
                    onMouseLeave={() => {
                      setRowNumber(null)
                    }}
                  >
                    <div className='courses-title'>
                      <span className='s6 text-black hover-text-bold'>
                        {
                          survey?.results?.[categoryKeyName]?.subcategories[
                            subcategory
                          ]?.title
                        }
                      </span>
                    </div>
                    <div className='courses-score'>
                      <span
                        style={{ color: innerBackgroudColor }}
                        className='hover-text-bold s6'
                      >
                        {Number.isInteger(
                          survey?.results?.[categoryKeyName]?.subcategories[
                            subcategory
                          ]?.percent
                        )
                          ? `${survey?.results?.[categoryKeyName]?.subcategories[subcategory]?.percent}%`
                          : 'N/A'}
                      </span>
                      <InfoIconOverlay
                        contentText={
                          survey?.categories?.find(
                            (e) => e?.key === subcategory
                          )?.description
                        }
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default SurveySubcategoriesTable
