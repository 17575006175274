import React from 'react'
import classNames from 'classnames'

const InformationIcon = ({
  active = false,
  className,
  stokeColor = 'white',
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      className={classNames('cursor-pointer', className, {
        active: active,
      })}
      {...rest}>
      <circle
        cx='10'
        cy='10'
        r='9.5'
        stroke={active ? '#bf9000' : stokeColor}
        fill={active ? '#bf9000' : 'none'}
      />
      <path
        d='M11.1726 13.9067C11 13.9227 10.8266 13.8838 10.6775 13.7955C10.5685 13.6838 10.5162 13.5287 10.5355 13.3739C10.5395 13.245 10.5549 13.1168 10.5815 12.9906C10.6073 12.8459 10.6406 12.7026 10.6813 12.5613L11.1342 11.0052C11.1809 10.8516 11.2118 10.6936 11.2263 10.5337C11.2263 10.3613 11.2494 10.2424 11.2494 10.1735C11.259 9.86606 11.1276 9.57106 10.8924 9.37241C10.6032 9.15067 10.243 9.04168 9.87909 9.06579C9.61834 9.06971 9.35961 9.11233 9.11143 9.19227C8.84019 9.2766 8.55486 9.37752 8.25549 9.49507L8.125 10.001C8.21327 9.97034 8.32077 9.93585 8.44357 9.89751C8.56074 9.86286 8.68219 9.84478 8.80437 9.84386C8.97576 9.82531 9.14827 9.86734 9.29182 9.96267C9.38933 10.0788 9.43508 10.2298 9.41849 10.3805C9.41806 10.5093 9.40391 10.6378 9.37626 10.7637C9.34938 10.8979 9.31485 11.0397 9.27262 11.1892L8.81588 12.7529C8.77907 12.8983 8.74961 13.0454 8.72761 13.1937C8.70966 13.3207 8.70069 13.4487 8.70073 13.577C8.69885 13.8865 8.84058 14.1794 9.08454 14.3704C9.37824 14.5956 9.74359 14.7072 10.1132 14.6847C10.3734 14.69 10.6328 14.6524 10.8809 14.5735C11.0984 14.4994 11.3888 14.3933 11.7522 14.2554L11.875 13.7724C11.7766 13.8132 11.6752 13.8465 11.5718 13.8721C11.4409 13.9019 11.3066 13.9135 11.1726 13.9067Z'
        fill={stokeColor}
      />
      <path
        d='M11.2437 5.63239C11.0331 5.41946 10.7556 5.30491 10.4698 5.31289C10.1841 5.30579 9.90691 5.42023 9.69589 5.63239C9.30909 5.99958 9.26589 6.64249 9.59945 7.06838C9.62918 7.10634 9.6614 7.14182 9.69589 7.17455C10.1366 7.60848 10.803 7.60848 11.2437 7.17455C11.6305 6.80372 11.671 6.15792 11.3342 5.73208C11.3062 5.6966 11.2759 5.66329 11.2437 5.63239Z'
        fill={stokeColor}
      />
    </svg>
  )
}

export default InformationIcon
