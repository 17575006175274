import { coachRecommends } from '@abroad/components'

export const sowRefId = 'coaching'

export const CONSTANTS = {
  LEADER_360_METADATA: {
    leader: {
      headerText: `Please send this Leader Assessment request to at least 3 direct reports.`,
      description: `You may view your results after 3 responses have been logged. Additional requests may be sent until your results have been made available.`,
      emailMessage: `Dear [Firstname],<br/><br/>As part of my Abroad coaching program, my coach and I would like to generate some feedback on my performance as a leader.<br/><br/>As one of my direct reports, I appreciate you taking 10 minutes to complete this leader assessment. It is important to note that your answers will remain anonymous. I will only be able to see the aggregate results of all my direct reports, so please answer the questions honestly.<br/><br/>Thank you for your time and feedback, username`,
    },
    simple360: {
      headerText: `Please send this feedback request to at least 3 people who you want to partake in your simple 360.`,
      description: `Only your coach will be able to see the responses. Once a request has been fulfilled, a green checkmark will appear next to their email.`,
      emailMessage: `Dear [Firstname],<br/><br/> As part of my Abroad coaching program, my coach and I would like to ask for feedback on my most important areas for personal and professional development. It is important to note that your answers will only be sent to my coach, and not me directly, so please answer the question with candor. My coach will then review the responses from my colleagues and integrate the feedback into my overall growth plan. Here is a question to guide your feedback.<br/><br/><b>If I experienced 2-3 breakthroughs in my personal and professional development over the next six months, what would you want those to be? In other words, what are my most important areas for personal and professional development?</b> <br/><br/>Your support of my development is important to me, so thanks for your time and feedback. <br/><br/>Thanks, [Firstname]`,
    },
  },
}
// export const participantLevelForManager = [
//   'manager',
//   'leader',
//   'executive',
//   'ceo',
// ]

export const messages = {
  noJourneysFound: `No ${coachRecommends.toLowerCase()} found.`,
  upgradeErrorMsg:
    'You need to upgrade your plan to take advantage of these features.',
}

export const mediaModuleTypeObject = {
  MODULE: 'module',
  MODULE_MEDIA: 'module-media',
  MEDIA_LIBRARY_AUDIO: 'media-library-audio',
}

export const leader360Type = {
  LEADER: 'leader',
  SIMPLE360: 'simple360',
}

export const surveyType = {
  AWARENESS: 'awareness',
  LEADER: 'leader',
  WISDOM: 'wisdom',
}
export const workStatus = {
  OPERATING: 'operating',
  TRANSITION: 'transition',
}

export const selfAwarenessMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/01+Self+Awareness.mp3'

export const teamCohesionMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/02+Team+Cohesion.mp3'

export const visionImpactMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/03+Vision+%26+Impact.mp3'

export const managerEfficacyMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/04+Manager+Efficacy.mp3'

export const selfAwarenessPerceptionMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/05+Self+Awareness+Perception.mp3'

export const inTransitionSelfAwarenessMediaUrl =
  'https://abroad-public.s3.us-east-2.amazonaws.com/06+Self+Awareness+(Leaders+in+Transition).mp3'

export const nameRegex = /^[A-Za-z\s-]+$/
