import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ABSpinner, useErrorService, useUserService } from '@abroad/components'
import API from '../utils/API'
import AuthService from '../utils/AuthService'
import sysend from 'sysend'

const ForceLogout = () => {
  let history = useHistory()
  const Error = useErrorService()
  const { user, setUser } = useUserService()

  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }

    const forceLogout = async () => {
      try {
        if (!inIframe() && (user?.isAdmin || user?.isCoach)) {
          const iframeRoot = document.getElementById('iframe-root')
          const i = document.createElement('iframe')
          i.style.display = 'none'
          i.src = `${process.env.REACT_APP_ADMIN_DOMAIN}/signout`
          iframeRoot.appendChild(i)
        }
        await API.onboarding.logout()
        setUser(null)
        AuthService.removeData()
        sysend.broadcast('client_logout', { message: 'client_logout' })
        setTimeout(() => {
          history.push('/', {})
        }, 300)
        if (inIframe()) {
          window.parent.postMessage(
            'redirect_to_client',
            process.env.REACT_APP_ADMIN_DOMAIN,
          )
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    forceLogout()
    return () => {
      window.removeEventListener('message', (event) => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <ABSpinner />
}

export default ForceLogout
