import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ABSpinner } from '@abroad/components'
import { OnboardSubTitle } from '../components/onboarding'
import API from '../utils/API'

const UnsubscribeEmail = ({ history, location: { search, pathname } }) => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search)
      const unsubscribeEmail = async () => {
        try {
          if (pathname === '/transformation/email/unsubscribe') {
            await API.transformation.unsubscribeEmail(search)
          } else {
            await API.selfReflection.unsubscribeEmail(search)
          }
          setIsLoading(false)
        } catch (e) {
          history.replace('/404')
        }
      }
      if (query.get('token')) {
        unsubscribeEmail()
      } else {
        history.replace('/')
      }
    } else {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname])
  return (
    <>
      <Helmet>
        <title>{'Unsubscribe Email | Abroad'}</title>
        <meta name='title' content={'Unsubscribe Email | Abroad'}></meta>
        <meta property='og:title' content={'Unsubscribe Email | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Unsubscribe Email | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}${pathname}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}${pathname}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}${pathname}`}></meta>
      </Helmet>
      <main>
        <section
          className={classNames({
            'bg-abroad-blue text-center vh-100': !isLoading,
          })}>
          {isLoading && <ABSpinner />}
          {!isLoading && (
            <div className='text-center p-4'>
              <OnboardSubTitle>
                You have successfully Unsubscribed Email from Abroad.
              </OnboardSubTitle>
              <div className='mt-4 w-100 d-flex s6 justify-content-center'>
                <Link to='/home' className='home-link'>
                  CLICK HERE TO RETURN TO HOME.
                </Link>
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  )
}

export default UnsubscribeEmail
