import React from 'react'
import TagManager from 'react-gtm-module'
import { useUserService } from '@abroad/components'
import TrackAudioPlayer from '../../constants/events'
import { MediaPlayPauseIcon } from '../assetsComponents'

const MobileAudioControl = ({ audio, mediaObject, isLoading, isPlaying }) => {
  const { user } = useUserService()
  const userId = user?.id

  const gtmPauseClickedAction = (mediaObject, userId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackAudioPlayer.event.audioPlayer,
        eventProps: {
          category: TrackAudioPlayer.category.audioPlayerCategory,
          action: 'User click on pause icon in media',
          label: 'pause icon in media',
          value: `pause icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
          userId,
        },
      },
    })
  }

  const gtmPlayClickedAction = (mediaObject, userId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackAudioPlayer.event.audioPlayer,
        eventProps: {
          category: TrackAudioPlayer.category.audioPlayerCategory,
          action: 'User click on play icon in media',
          label: 'play icon in media',
          value: `play icon media of category ID - ${mediaObject.category._id}, title is - ${mediaObject?.subCategory.title} audio name - ${mediaObject?.title} from Media Page`,
          userId,
        },
      },
    })
  }

  return (
    <MediaPlayPauseIcon
      showPlay={!isPlaying}
      isLoading={isLoading}
      onClick={(e) => {
        if (audio) {
          if (!isPlaying) {
            e.preventDefault()
            if (mediaObject) {
              gtmPlayClickedAction(mediaObject, userId)
            }
            audio.play()
          } else {
            e.preventDefault()
            if (mediaObject) {
              gtmPauseClickedAction(mediaObject, userId)
            }
            audio.pause()
          }
        }
      }}
    />
  )
}

export default MobileAudioControl
