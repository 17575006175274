import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CustomDropdownToggle } from '../common'
import { Logout } from '../onboarding'
import ProfileButton from './ProfileButton'

const ProfileDropdown = ({
  onLogout,
  i18n,
  profile,
  render,
  url1,
  url2,
  iconClasses
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    render(showDropdown)
  }, [showDropdown, render])

  return (
    <Dropdown
      show={showDropdown}
      className='z3'
      onToggle={() => setShowDropdown(!showDropdown)}
    >
      <Dropdown.Toggle as={CustomDropdownToggle} id='dropdown-profile'>
        <ProfileButton isActive={showDropdown} iconClasses={iconClasses} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        onClick={() => setShowDropdown(!showDropdown)}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 20]
              }
            }
          ]
        }}
        align='right'
        className='profile-dropdown header-drop-down text-center'
      >
        {profile()}
        <Logout
          className='text-left'
          onLogout={onLogout}
          i18n={i18n}
          url1={url1}
          url2={url2}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
