import React, { useState } from 'react'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { Form as RBForm } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import {
  useErrorService,
  useNotificationService,
  Button,
  useUserService,
} from '@abroad/components'
import HelpEvent from '../constants/events'
import { PageHeader } from '../components/common'
import { InputField } from '../components/form'
import API from '../utils/API'

const detailsText = `We're here to support your journey with Abroad. If you have questions, encounter challenges, or simply need guidance on any feature within the app, please don't hesitate to reach out. Describe your issue or query in the box below, and our dedicated team will get back to you promptly.`

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Ask a Question | Abroad'}</title>
      <meta name='title' content={'Ask a Question | Abroad'}></meta>
      <meta property='og:title' content={'Ask a Question | Abroad'}></meta>
      <meta property='twitter:title' content={'Ask a Question | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/ask-question`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/ask-question`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/ask-question`}></meta>
    </Helmet>
  )
}

const validationSchema = object().shape({
  message: string().required(),
})

const AskQuestion = () => {
  const [isLoading, setIsLoading] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const { user } = useUserService()

  const gtmSubmittedQuestionAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: HelpEvent.event.help,
        eventProps: {
          category: HelpEvent.category.helpCategory,
          action: 'User submit their question',
          label: 'submit question',
          value: `User submit their question in Help page`,
          userId: user?.id,
        },
      },
    })
  }

  const onSubmit = async (values, action) => {
    try {
      setIsLoading(true)
      await API.user.submitHelp({
        email: user?.email,
        name: user?.fullName,
        message: values.message,
        userAgent: window?.navigator?.userAgent,
      })
      setIsLoading(false)
      Notification.showNotification(
        'Your Question has been submitted!',
        'success',
      )
      gtmSubmittedQuestionAction()
      action.resetForm()
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      {renderHelmet()}
      <main>
        <PageHeader title='Ask a Question' />
        <section className='mt-32px mb-32px ask-question'>
          <h3 className='s5 mb-32px'>{detailsText}</h3>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{ message: '' }}>
            {({ handleSubmit }) => (
              <RBForm
                as={Form}
                onSubmit={handleSubmit}
                className='clearfix abroad-fs-exclude'>
                <InputField
                  as='textarea'
                  name='message'
                  rows='12'
                  style={{ resize: 'none', height: 'auto' }}
                  className='p-3'
                />
                <Button
                  type='submit'
                  isLoading={isLoading}
                  disabled={isLoading}
                  variant='saffron'
                  size='md'
                  className='float-end text-uppercase mt-32px border-radius-normal'>
                  Send message
                </Button>
              </RBForm>
            )}
          </Formik>
        </section>
      </main>
    </>
  )
}

export default AskQuestion
