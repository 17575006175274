import React, { useState } from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { leaderAssessmentsUrl, useUserService } from '@abroad/components'
import LAEvent from '../../constants/events'

const takeAssessmentBtnText = 'TAKE NEW ASSESSMENT'

const url = `/survey/requests/${leaderAssessmentsUrl}`

const PendingAssessments = ({ canCreateNewLAReq, pages }) => {
  const [showPendingLA, setShowPendingLA] = useState(false)
  const takeNewAssessmentTab = {
    path: `${url}/new`,
    name: takeAssessmentBtnText,
    id: 'new',
    disabled: !canCreateNewLAReq,
    takeAssessmentTab: true,
  }
  const history = useHistory()
  const { user } = useUserService()

  const gtmSelectPendingAssessmentAction = (currentDate) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LAEvent.event.selectPendingLA,
        eventProps: {
          category: LAEvent.category.selectPendingLACategory,
          action: 'User select pending leader assessment',
          label: 'Select pending leader assessment',
          value: `User select pending leader assessment, date is ${currentDate}.`,
          userId: user?.id,
        },
      },
    })
  }

  const renderTab = (page) => {
    return (
      <Col
        lg={5}
        md={5}
        sm={12}
        className={classNames('pending-assessment-tab text-center', {
          active: page?.takeAssessmentTab,
          disabled: page?.disabled,
          'cursor-pointer': !page?.disabled,
        })}
        onClick={() => {
          if (!page?.takeAssessmentTab) {
            gtmSelectPendingAssessmentAction(page?.name)
          }
          history.push(page?.path)
        }}
        key={page?.id}>
        {page?.name}
      </Col>
    )
  }

  return (
    <>
      {showPendingLA && (
        <p className='text-uppercase mb-12px s10c'>PENDING ASSESSMENTS: </p>
      )}
      <Container fluid>
        <Row>
          {showPendingLA ? (
            <>
              {pages?.map((page, index) => (
                <React.Fragment key={index}>{renderTab(page)}</React.Fragment>
              ))}
            </>
          ) : (
            <Col
              lg={5}
              md={5}
              sm={12}
              className='pending-assessment-tab text-center cursor-pointer'
              onClick={() => {
                setShowPendingLA(true)
              }}>
              VIEW PENDING ASSESSMENT
            </Col>
          )}
        </Row>
      </Container>
      {renderTab(takeNewAssessmentTab)}
    </>
  )
}

export default PendingAssessments
