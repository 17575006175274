import React, { useContext, useEffect, useState } from 'react'
import { Modal, Container, Row, Col, Spinner } from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'
import Imgix from 'react-imgix'
import TagManager from 'react-gtm-module'
import {
  Button,
  useUserService,
  useErrorService,
  ModelContext,
} from '@abroad/components'
import { AttachKeyResources } from '../common'
import { LayoutContext } from '../../utils/contexts'
import { isLGScreen } from '../../utils/utility'
import API from '../../utils/API'
import postSessionEvent from '../../constants/events'
import SessionEvent from '../../constants/events'

const SessionModel = () => {
  const { show, onHide, data } = useContext(ModelContext)
  const [sessionData, setSessionData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  let length = sessionData?.length
  const {
    setMediaObject,
    showPlayBar,
    setShowPlayBar,
    setIsMediaLoading,
    isMediaLoading,
    mediaObject,
  } = useContext(LayoutContext)
  const { user } = useUserService()
  const Error = useErrorService()
  const sessionId = data?._id

  const sessionDetailsOpenHandler = (sessionId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: SessionEvent.event.sessionDetails,
        eventProps: {
          category: SessionEvent.category.sessionDetailsCategory,
          action: 'User open session modal',
          label: 'Open session modal by user',
          value: `User open session modal with sessionId - ${sessionId}.`,
          userId: user?.id,
        },
      },
    })
  }

  useEffect(() => {
    const openSession = async () => {
      try {
        if (show === true) {
          //New Individual or Group Session Notes notification isRead API
          await API.user.hasNotificationRead({
            type: 1,
            uniqueId: data?._id,
          })
          await API.session.openSession({ isRead: true }, data?._id)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    const getSessionForClient = async () => {
      setIsLoading(true)
      try {
        if (show === true) {
          const responseData = await API.session.getSessionForClient(data?._id)
          setSessionData(responseData?.data)
          sessionDetailsOpenHandler(responseData?.data?._id)
          setIsLoading(false)
        }
      } catch (e) {
        onHide()
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getSessionForClient()
    openSession()
    return () => {
      setSessionData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const getMedia = async (mediaId, mediaSubCategoryId, isProgramMedia) => {
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      let mediaData
      if (isProgramMedia) {
        const { data } = await API.programs.getMediaById(
          mediaSubCategoryId,
          mediaId,
          null, // journeyId
          null, // sequenceId
          sessionId,
        )
        mediaData = data
      } else {
        mediaData = await API.mediaLibrary.getMediaById(
          mediaSubCategoryId,
          mediaId,
          null, // journeyId
          null, // sequenceId
          sessionId,
        )
        mediaData = mediaData?.data
      }
      if (mediaData) {
        await API.session.listenSessionAudio(
          { isMediaListened: true },
          data?._id,
        )

        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: postSessionEvent.event.ListenPSPAudio,
            eventProps: {
              category: postSessionEvent.category.ListenPSPAudioCategory,
              action:
                'User listen POST-SESSION PRACTICES audio from coaching page',
              label: 'POST-SESSION PRACTICES Audio',
              value: `User listen POST-SESSION PRACTICES audio with media SubcategoryId - ${mediaSubCategoryId} , mediaId - ${mediaId} in coaching page`,
              userId: user?.id,
            },
          },
        })
        let mediaObjectInstance = {
          ...mediaData,
          disabledControls: true,
        }
        setMediaObject(mediaObjectInstance)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  const getModuleFirstMedia = async (moduleId) => {
    if (isMediaLoading) return
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      const { data } = await API.programs.getModuleFirstMedia(
        moduleId,
        null, // journeyId
        null, // sequenceId
        sessionId,
      )
      if (data) {
        setMediaObject(data)
      }
      setIsMediaLoading(false)
    } catch (e) {
      if (e.code === 'upgrade_plan' && !mediaObject) {
        setShowPlayBar(false)
      }
      setIsMediaLoading(false)
      Error.showError(e)
    }
  }

  return (
    <Modal
      show={show}
      size='lg'
      onHide={() => {
        setSessionData(null)
        onHide()
      }}
      aria-labelledby='Session'
      className='session-modal'
      contentClassName={classnames({
        'mb-5': showPlayBar && isLGScreen,
      })}
      dialogClassName={classnames({
        'mb-5': showPlayBar && isLGScreen,
      })}
      centered>
      {isLoading ? (
        <Modal.Body className='pb-3 px-4 text-center'>
          <Spinner
            animation='border'
            className='text-saffron-700'
            role='status'
          />
        </Modal.Body>
      ) : (
        <>
          <Modal.Header className='border-0 pb-0 px-4'>
            <span className='text-uppercase tracking-md-wide font-montserrat text-saffron-700 font-12 mb-sm-2'>
              {sessionData?.date
                ? moment(sessionData?.date)
                    .utcOffset('+0000')
                    .format('Do MMMM YYYY')
                : null}
            </span>
            <span className='font-montserrat tracking-md-wide font-12 d-none d-md-block text-uppercase text-gray-501'>
              {`Session Duration: ${length} minutes`}
            </span>
          </Modal.Header>
          <Modal.Body className='pb-3 pt-0 px-4 bt-module'>
            <div className='session-modal-body'>
              <hr className='session-modal-divider mt-0' />
              <div className='font-montserrat tracking-md-wide font-12 d-md-none d-block text-uppercase text-gray-501 text-right mt-2'>
                {`Session Duration: ${length} minutes`}
              </div>
              {sessionData?.postSession ? (
                <div
                  className='font-open-sans pt-2 pt-lg-0 pb-3 pb-lg-0 coaching-modal-body abroad-fs-exclude'
                  dangerouslySetInnerHTML={{
                    __html: sessionData?.postSession,
                  }}
                />
              ) : (
                <div className='font-open-sans pt-2 pt-lg-0 pb-3 pb-lg-0 coaching-modal-body abroad-fs-exclude text-center'>
                  No notes added.
                </div>
              )}
              <div className='d-flex justify-content-between text-right align-items-center mt-3'>
                <div className='w-100'>
                  {sessionData &&
                    ([...sessionData?.medias, ...sessionData?.mediaModules]
                      .length > 0 ||
                      sessionData?.keySkills?.length > 0) && (
                      <>
                        <div className='text-uppercase tracking-md-wide font-montserrat text-saffron-700 font-12 text-left'>
                          Recommended Content
                        </div>
                        <hr className='session-modal-divider mt-0' />
                      </>
                    )}
                  <Container fluid>
                    <Row className='justify-content-start coaching-modal-audios-container'>
                      {sessionData &&
                        [...sessionData?.medias, ...sessionData?.mediaModules]
                          .length > 0 &&
                        [
                          ...sessionData?.medias,
                          ...sessionData?.mediaModules,
                        ].map((media) => (
                          <Col
                            key={media?._id}
                            onClick={() => {
                              if ('parentCategory' in media) {
                                getModuleFirstMedia(media?._id)
                              } else {
                                if (!isMediaLoading) {
                                  getMedia(
                                    media._id,
                                    media?.subCategoryId,
                                    media?.isProgram,
                                  )
                                }
                              }
                            }}
                            className='col-12 col-lg-7 mx-auto mx-lg-0 border-radius-10 pt-1 cursor-pointer d-flex align-items-center space-x-3 my-1 ps-0'>
                            <div className='media-image'>
                              <div className='main-image'>
                                <Imgix
                                  className='border-radius-5 border-black'
                                  imgixParams={{
                                    crop: 'edges',
                                    fit: 'crop',
                                    w: 144,
                                    h: 144,
                                  }}
                                  src={`${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`}
                                  height={42}
                                  width={42}
                                  alt='image'
                                />
                              </div>
                              {getPlayIconSvg()}
                            </div>
                            <div className='d-flex flex-column justify-content-start'>
                              <p className='font-open-sans font-bold font-14 mb-0 truncate leading-6 text-left font-montserrat'>
                                {'parentCategory' in media
                                  ? `${media?.subTitle}: ${media?.title}`
                                  : media?.title}
                              </p>
                              <span className='font-montserrat font-semibold font-10 text-left text-uppercase tracking-md-wide'>
                                {'parentCategory' in media
                                  ? media?.parentCategory?.title
                                  : media?.expert}
                              </span>
                            </div>
                          </Col>
                        ))}
                      {sessionData &&
                        Array.isArray(sessionData?.keySkills) &&
                        sessionData?.keySkills?.map((pdfData, index) => (
                          <AttachKeyResources
                            pdfData={pdfData}
                            key={index}
                            sessionId={data?._id}
                          />
                        ))}
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className='text-right mx-4 mb-3'>
            <Button
              variant='none'
              size='sm'
              onClick={onHide}
              className='px-3 text-uppercase font-montserrat secondary-popup-button'>
              Close
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

const getPlayIconSvg = () => {
  return (
    <div className='play-icon border-radius-5 bg-dark-600'>
      <span className='icon icon-play' style={{ fontSize: '42px' }} />
    </div>
  )
}

export default SessionModel
