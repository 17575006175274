import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '@abroad/components'

const DeleteIntentionModal = ({
  show,
  handleClose,
  deleteIntention,
  isLoading,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className='delete-intention-box'>
      <Modal.Header className='border-0 pb-0 px-4'>
        <Modal.Title className='s6'>
          Are you sure you want to permanently delete this intention?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pb-3 pt-2 px-4 border-0 s8'>
        You can't undo this action.
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end px-4 space-x-3'>
        <Button
          variant='none'
          onClick={handleClose}
          className='px-3 secondary-popup-button'
          size='sm'>
          Cancel
        </Button>
        <Button
          variant='saffron'
          size='sm'
          disabled={isLoading}
          isLoading={isLoading}
          onClick={deleteIntention}
          className='px-3 text-uppercase'>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteIntentionModal
