import React from 'react'
import classnames from 'classnames'

const BackwardIcon = ({
  isDisabled,
  className,
  fontSize = '1.375rem',
  ...rest
}) => {
  return (
    <span
      className={classnames(
        'icon icon-left-arrow text-black text-dark-900',
        {
          'cursor-pointer': !isDisabled,
          'pointer-event-none': isDisabled
        },
        className
      )}
      style={{
        fontSize,
        ...(isDisabled && {
          color: 'rgba(255,255,255, 0.50)'
        })
      }}
      {...rest}
    />
  )
}

export default BackwardIcon
