import React from 'react'
import classNames from 'classnames'
import UserProfileIcon from './UserProfileIcon'

const ProfileButton = ({ isActive, iconClasses = '' }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href='#'
      className={classNames(
        'text-dark-900 text-decoration-none d-flex justify-content-center align-items-center cursor-pointer profile-icon-wrapper',
        {
          active: isActive
        }
      )}
    >
      <UserProfileIcon isActive={isActive} className={iconClasses} />
    </a>
  )
}

export default ProfileButton
