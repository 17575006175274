import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { reportType } from './DirectReports'
import { InfoIcon } from '../assetsComponents'

const placement = 'top'
const professionalConnectionTitle = 'Professional Connection'
const personalConnectionTitle = 'Personal Connection'
const professionalConnectionDescription =
  'Your Professional Connection score gauges the strength of your work-related rapport with your direct reports. A strong professional bond fosters a collaborative environment and can directly impact team performance and outcomes'
const personalConnectionDescription =
  'Your Personal Connection score reflects the degree of closeness your direct reports feel with you on a human level. This emotional bond can significantly influence team engagement and overall morale.'

const ReportTooltip = ({ type }) => {
  const [show, setShow] = useState(false)
  const infoIconRef = useRef(null)
  return (
    <>
      <Overlay
        key={type}
        placement={placement}
        target={infoIconRef.current}
        show={show}
        rootClose
        onHide={() => setShow(false)}
        index={type}>
        <Tooltip
          id={`tooltip-${placement}`}
          className='text-start report-tooltip text-center'>
          <h3 className='s3 text-black mb-3'>
            {type === reportType.PROFESSIONAL_CONNECTION
              ? professionalConnectionTitle
              : personalConnectionTitle}
          </h3>
          <span className='s6a text-black fw-normal'>
            {type === reportType.PROFESSIONAL_CONNECTION
              ? professionalConnectionDescription
              : personalConnectionDescription}
          </span>
        </Tooltip>
      </Overlay>
      <div
        ref={infoIconRef}
        onClick={() => {
          setShow(true)
        }}
        className='d-flex'>
        <InfoIcon
          fillColor={show ? '#bf9000' : 'white'}
          className='align-self-center ms-3'
        />
      </div>
    </>
  )
}

export default ReportTooltip
