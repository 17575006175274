import React, { useState, useEffect } from 'react'
import { Button, useUserService, useErrorService } from '@abroad/components'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import API from '../../utils/API'

const UpcomingQuestsSignup = ({ questId }) => {
  const { user } = useUserService()
  const Error = useErrorService()
  const userId = user._id
  const [signupStatus, setSignedUpStatus] = useState()
  const [motivationText, setMotivationText] = useState('')
  const [impactText, setImpactText] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false)
  const requestData = {
    currentProfessionalStanding: impactText,
    motivationForJoinTrip: motivationText,
  }
  const getSignUpStatus = async () => {
    try {
      setSubmitBtnLoading(true)
      const { data } = await API.quests.signUpStatus(questId, userId)
      if (data) {
        setSignedUpStatus(data.status)
        setSubmitBtnLoading(false)
      }
    } catch (e) {
      Error.showError(e)
      setSubmitBtnLoading(false)
    }
  }
  useEffect(() => {
    getSignUpStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId, userId])

  const postpublicQuestsUpcoming = async () => {
    setIsLoading(true)
    try {
      await API.quests.signUpQuests(questId, requestData)
      getSignUpStatus()
      setIsLoading(false)
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  const handleMotivationChange = (event) => {
    const motivationText = event.target.value
    setMotivationText(motivationText)
    setIsSubmitDisabled(!motivationText.trim(' ') || !impactText.trim(' '))
    const textarea = event.target
    textarea.style.height = '145px'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  const handleImpactChange = (event) => {
    const impactText = event.target.value
    setImpactText(impactText)
    setIsSubmitDisabled(!motivationText.trim(' ') || !impactText.trim(' '))
    const textarea = event.target
    textarea.style.height = '145px'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  return (
    <>
      <div>
        {isLoading && submitBtnLoading ? (
          <ShimmerThumbnail
            className='shimmer-thumbnail-mediaplayer mt-32px'
            rounded
            width={'100%'}
            height={200}
          />
        ) : signupStatus ? (
          <div>
            <div className='mt-52px'>
              <div
                className='w-100 py-40px px-5'
                style={{ backgroundColor: '#F3F6F6' }}>
                <div className='s4 text-center '>
                  Thank you for your initial interest in this Bhutan Quest.
                  We'll
                  <br />
                  review your submission and get back to you soon.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className='mt-52px'>
              <div className='s2'>Sign Up Now</div>
              <div className='s6a mt-3'>
                We are diligent to make sure this is the right educational
                experience for you at this time in your life. Due to the small
                size of the group, we are looking for impact-minded leaders and
                people committed to their personal and professional growth to
                create the best possible experience.
              </div>

              <div className='upgrade-inquiry mt-32px'>
                <div className='inquiry-title'>
                  Current Professional Standing
                </div>
              </div>
              <div className='s7 mt-1'>
                Please tell us about your current role where you work. If you
                are in a career transition, please tell share a little about
                where you are in life right now.
              </div>
              <div className='unfocus'>
                <textarea
                  value={motivationText}
                  onChange={handleMotivationChange}
                  style={{
                    resize: 'none',
                    border: '2px solid #000',
                    overflow: 'hidden',
                  }}
                  className='w-100 border-radius-5 mt-3 text-dark bg-white px-2 focus-visible'
                  rows='5'></textarea>
              </div>
              <div className='upgrade-inquiry mt-32px'>
                <div className='inquiry-title'>
                  Motivation for Joining the Trip
                </div>
              </div>
              <div className='s7 mt-1'>
                What motivates you to join this trip to Bhutan, and how do you
                believe it will impact your personal and professional
                development?
              </div>
              <div className='unfocus'>
                <textarea
                  value={impactText}
                  onChange={handleImpactChange}
                  style={{
                    resize: 'none',
                    border: '2px solid #000',
                    overflow: 'hidden',
                  }}
                  className='w-100 border-radius-5 px-2 text-dark bg-white mt-40px focus-visible'
                  rows='5'></textarea>
              </div>
              <div className='row justify-content-end mt-32px'>
                <div className='col-lg-3 col-md-3 col-sm-4 col-5'>
                  <Button
                    className='border-0'
                    onClick={postpublicQuestsUpcoming}
                    disabled={isSubmitDisabled}
                    variant='saffron w-100 py-12px rounded'>
                    {submitBtnLoading ? 'Loading...' : 'SUBMIT'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UpcomingQuestsSignup
