import React from 'react'

const EyeIcon = ({ showPassword, setShowPassword, iconColor }) => {
  return (
    <>
      {showPassword ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='13'
          viewBox='0 0 26 13'
          fill='none'
          onClick={() => setShowPassword(!showPassword)}>
          <path
            d='M1.02734 11.5C1.02734 11.5 5.02734 1 13.0273 1C21.0273 1 25.0273 11.5 25.0273 11.5'
            stroke={iconColor}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M16 10C16 10 15 12 13 12C11 12 10 10 10 10'
            stroke={iconColor}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='24'
          viewBox='0 0 26 24'
          fill='none'
          onClick={() => setShowPassword(!showPassword)}>
          <circle cx='13' cy='15' r='2.5' fill={iconColor} stroke={iconColor} />
          <path
            d='M1 16.5C1 16.5 5 6 13 6C21 6 25 16.5 25 16.5'
            stroke={iconColor}
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      )}
    </>
  )
}

export default EyeIcon
