import React, { useContext } from 'react'
import classNames from 'classnames'
import { ModelContext } from '@abroad/components'

const AssessmentLearnMore = ({
  category,
  onLearnMoreClick,
  linkClasses = '',
}) => {
  const { onShow, setModalData } = useContext(ModelContext)
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={() => {
        onShow()
        setModalData(category)
        onLearnMoreClick(category?._id)
      }}
      data-toggle='modal'
      className={classNames('learn-more-link', linkClasses)}>
      Learn more
    </a>
  )
}

export default AssessmentLearnMore
