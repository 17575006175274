import React from 'react'

const FeatherIcon = ({ className, ...rest }) => {
  return (
    <span
      className={`icon icon-feather text-white font-26 ${className}`}
      {...rest}
    />
  )
}

export default FeatherIcon
