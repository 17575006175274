import React from 'react'
import Imgix from 'react-imgix'

const PlayBarImage = ({ imageUrl, width, height }) => {
  return (
    <Imgix
      imgixParams={{ fit: 'crop', w: width, h: height }}
      src={`${process.env.REACT_APP_IMG_SOURCE}/${imageUrl}`}
      height={height}
      width={width}
      alt='image'
    />
  )
}

PlayBarImage.defaultProps = {
  width: 64,
  height: 64,
}

export default PlayBarImage
