import React, { useContext } from 'react'
import classNames from 'classnames'
import OnboardSubTitle from './OnboardSubTitle'
import OnboardTitle from './OnboardTitle'
import { InviteDetailsContext } from '../../utils/contexts'
import { conditionMatched } from '../layout/Layout'

const signupTitle = 'Your Journey Begins with Awareness'
const signupSubTitle =
  'Join thousands of leaders and their teams who have taken our awareness assessment to uncover hidden opportunities for growth.'

const SignupLeftSection = () => {
  const { inviteLinkDetails } = useContext(InviteDetailsContext)

  return (
    <section
      className={classNames({
        'h-lg-65': !conditionMatched,
      })}>
      <div
        className={classNames('d-flex flex-column', {
          'signup-left-section': !inviteLinkDetails?.header,
          'signup-invite-link': inviteLinkDetails?.header,
        })}>
        <OnboardTitle className='word-break'>
          {inviteLinkDetails?.header ? inviteLinkDetails?.header : signupTitle}
        </OnboardTitle>
        {inviteLinkDetails?.body ? (
          <OnboardSubTitle
            className='word-break opacity-75 mt-4'
            commonClass='s5'>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div>${inviteLinkDetails?.body}</div>`,
              }}
            />
          </OnboardSubTitle>
        ) : (
          <OnboardSubTitle
            className='word-break opacity-75 mt-4'
            commonClass='s5'>
            {signupSubTitle}
          </OnboardSubTitle>
        )}
      </div>
    </section>
  )
}

export default SignupLeftSection
