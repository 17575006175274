import React from 'react'
import { Helmet } from 'react-helmet'
import { FavoriteAudios } from '../components'
import { PageHeader } from '../components/common'

const Favorites = () => {
  return (
    <>
      <Helmet>
        <title>{'Favorites | Abroad'}</title>
        <meta name='title' content={'Favorites | Abroad'}></meta>
        <meta property='og:title' content={'Favorites | Abroad'}></meta>
        <meta property='twitter:title' content={'Favorites | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/favorites`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/favorites`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/favorites`}></meta>
      </Helmet>
      <main>
        <PageHeader title='Favorites' />
        <FavoriteAudios />
      </main>
    </>
  )
}

export default Favorites
