import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Table } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects'
import { useLocation, useHistory, Link } from 'react-router-dom'
import PauseOrResumeSubscription from './PauseOrResumeSubscription'
import CancelSubscription from './CancelSubscription'
import Button from '../Button'
import { useErrorService } from '../../services'
import { paymentType, coachingPlanStatus, paymentStatus } from '../../helper'
import { formatDate } from '../../utils'

const SubscriptionTable = ({
  isRenderOnAdminSide = false,
  isTogglePayment,
  showHighlightedPart,
  API,
  userId
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [subscriptionPayments, setSubscriptionPayments] = useState([])
  const location = useLocation()
  const history = useHistory()
  const Error = useErrorService()

  useEffect(() => {
    const getUserSubscriptionPayment = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.payment.getUserSubscriptionPayment(userId)
        if (data) {
          setSubscriptionPayments(data)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserSubscriptionPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTogglePayment])

  const renderPageTitle = () => {
    return (
      <h3 className='s3 subscription-title'>
        {isRenderOnAdminSide ? 'Subscription' : 'My Subscription'}
      </h3>
    )
  }

  if (isLoading) {
    return (
      <>
        {renderPageTitle()}
        <ShimmerTable row={2} col={6} />
      </>
    )
  }

  return (
    <section>
      <>
        {renderPageTitle()}
        <Table hover responsive className='custom-table'>
          <thead>
            <tr>
              <th width='20%'>Name</th>
              <th width='20%'>Type</th>
              <th width='20%'>Upcoming Payment</th>
              <th width='20%'>Payment Date</th>
              {/* <th width='16%'>Status</th> */}
              <th width='20%' className='text-right text-end'>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptionPayments?.length > 0 ? (
              <>
                {subscriptionPayments?.map((subscription, index) => {
                  const noBillingReq =
                    subscription?.paymentStatus ===
                    paymentStatus.NO_BILLING_REQUIRED
                  const showActionBtns =
                    subscription?.paymentCycle ===
                      Object.values(paymentType)[0] &&
                    !noBillingReq &&
                    !subscription?.cancelledOn &&
                    !subscription.isTemp &&
                    ([
                      coachingPlanStatus[1].value,
                      coachingPlanStatus[4].value
                    ].includes(subscription?.status) ||
                      (subscription?.status === coachingPlanStatus[3].value &&
                        subscription?.payments?.paid === false))

                  return (
                    <tr
                      key={index}
                      id={subscription?._id}
                      className={classNames({
                        'highlight-row':
                          `#${subscription?._id}` === location?.hash &&
                          showHighlightedPart
                      })}
                    >
                      <td width='20%'>
                        {[paymentStatus.PENDING, paymentStatus.FAILED].includes(
                          subscription?.paymentStatus
                        ) ? (
                          <>
                            {isRenderOnAdminSide ? (
                              <Link
                                to={`/coach/clients/${userId}/plans/${subscription?._id}`}
                              >
                                Coaching Plan with{' '}
                                {subscription?.coachId?.fullName}
                              </Link>
                            ) : (
                              <>
                                {subscription?.coachId
                                  ? `Coaching Plan with ${subscription?.coachId?.fullName}`
                                  : 'Coaching Plan'}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {isRenderOnAdminSide && subscription?.coachId ? (
                              <Link
                                to={`/coach/clients/${userId}/plans/${subscription?._id}`}
                              >
                                Coaching Plan with{' '}
                                {subscription?.coachId?.fullName}
                              </Link>
                            ) : (
                              <>
                                {subscription?.coachId
                                  ? `Coaching Plan with ${subscription?.coachId?.fullName}`
                                  : 'Coaching Plan'}
                              </>
                            )}
                          </>
                        )}
                      </td>
                      <td width='20%' className='text-capitalize'>
                        {[paymentStatus.PENDING, paymentStatus.FAILED].includes(
                          subscription?.paymentStatus
                        ) ? (
                          <>{!subscription?.payments ? 'New' : 'Failed'}</>
                        ) : (
                          <>{subscription?.paymentCycle}</>
                        )}
                      </td>
                      <td width='20%'>
                        {[paymentStatus.PENDING, paymentStatus.FAILED].includes(
                          subscription?.paymentStatus
                        ) ? (
                          <>
                            {/* added initialFee && amount */}
                            {subscription?.initialFee &&
                            subscription?.amount &&
                            subscription?.paymentCycle ===
                              Object.values(paymentType)[0]
                              ? `$${
                                  subscription?.initialFee +
                                  subscription?.amount
                                }`
                              : null}
                            {/* added amount */}
                            {subscription?.initialFee === 0 &&
                            subscription?.amount &&
                            subscription?.paymentCycle ===
                              Object.values(paymentType)[0]
                              ? `$${subscription?.amount}`
                              : null}
                            {/* added initialFee & payment type is onetime */}
                            {subscription?.paymentCycle ===
                              Object.values(paymentType)[1] &&
                            (subscription?.initialFee ||
                              subscription?.initialFee === 0)
                              ? `$${subscription?.initialFee}`
                              : null}
                          </>
                        ) : (
                          <>
                            {noBillingReq
                              ? 'N/A'
                              : subscription?.nextChargeAmount
                              ? `$${subscription?.nextChargeAmount}`
                              : '-'}
                          </>
                        )}
                      </td>
                      <td width='20%'>
                        {[paymentStatus.PENDING, paymentStatus.FAILED].includes(
                          subscription?.paymentStatus
                        ) ? (
                          '-'
                        ) : (
                          <>
                            {noBillingReq ||
                            subscription?.cancelledOn ||
                            subscription.status === 'STOPPED'
                              ? 'N/A'
                              : subscription?.nextChargeDate
                              ? formatDate(subscription?.nextChargeDate)
                              : '-'}
                          </>
                        )}
                      </td>
                      {/* <td width='14%'>
                        {
                          coachingPlanStatus.find(
                            (e) => e.value === subscription?.status
                          ).label
                        }
                        {[paymentStatus.PAID, paymentStatus.PAUSED].includes(
                          subscription.paymentStatus
                        ) &&
                          subscription?.cancelledOn && (
                            <>
                              <br />
                              <span className='text-red-800 text-error'>
                                (*Set to be terminated on{' '}
                                {moment(subscription?.cancelledOn).format(
                                  'MM/DD/YYYY'
                                )}
                                )
                              </span>
                            </>
                          )}
                      </td> */}
                      <td width='20%' className='text-right text-end pe-0 pl-0'>
                        {[paymentStatus.PENDING, paymentStatus.FAILED].includes(
                          subscription?.paymentStatus
                        ) ? (
                          <>
                            {isRenderOnAdminSide ? (
                              '-'
                            ) : (
                              <>
                                {subscription?.paymentCycle ===
                                  Object.values(paymentType)[0] &&
                                subscription?.paymentStatus ===
                                  paymentStatus.FAILED ? (
                                  <Link
                                    to={`/profile/plans-payments/coaching-plan/${subscription?._id}/card/${subscription?.paymentMethodId}/change-card?isPaid=false`}
                                  >
                                    Retry
                                  </Link>
                                ) : (
                                  <Button
                                    variant='saffron'
                                    size='md'
                                    onClick={() => {
                                      history.push(
                                        subscription?.isTemp
                                          ? `/profile/plans-payments/coaching-plan/coaching-plan-${subscription?.amount}?coaching=coaching-plan-${subscription?.amount}`
                                          : `/profile/plans-payments/coaching-plan/${subscription?._id}`
                                      )
                                    }}
                                    className='text-uppercase border-radius-normal text-white hover:text-white'
                                  >
                                    Pay
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {showActionBtns ? (
                              <div className='d-flex flex-column'>
                                {!isRenderOnAdminSide && (
                                  <span>
                                    {/* Option to change card - only if active (client only) */}
                                    <Link
                                      to={`/profile/plans-payments/coaching-plan/${subscription?._id}/card/${subscription?.paymentMethodId}/change-card`}
                                    >
                                      Change card
                                    </Link>
                                  </span>
                                )}
                                {isRenderOnAdminSide && (
                                  <span>
                                    {/* Option to pause/resume subscription - only if active
  (admin only) */}
                                    <PauseOrResumeSubscription
                                      sowId={subscription?._id}
                                      newValue={
                                        subscription?.status ===
                                        coachingPlanStatus[4].value
                                          ? coachingPlanStatus[1].value
                                          : coachingPlanStatus[4].value
                                      }
                                      modalText={`Are you sure you want to ${
                                        subscription?.status ===
                                        coachingPlanStatus[4].value
                                          ? 'resume'
                                          : 'pause'
                                      } this subscription?`}
                                      linkText={`${
                                        subscription?.status ===
                                        coachingPlanStatus[4].value
                                          ? 'Resume'
                                          : 'Pause'
                                      } Subscription`}
                                      onSuccess={() => {
                                        const newPaymentObj =
                                          subscriptionPayments.map(
                                            (subPayment) =>
                                              subPayment?._id ===
                                              subscription?._id
                                                ? {
                                                    ...subPayment,
                                                    status:
                                                      subscription?.status ===
                                                      coachingPlanStatus[4]
                                                        .value
                                                        ? coachingPlanStatus[1]
                                                            .value
                                                        : coachingPlanStatus[4]
                                                            .value
                                                  }
                                                : subPayment
                                          )
                                        setSubscriptionPayments(newPaymentObj)
                                      }}
                                      API={API}
                                      userId={userId}
                                    />
                                  </span>
                                )}
                                <span>
                                  {/* Option to cancel a subscription - only if active */}
                                  {!subscription?.cancelledOn && (
                                    <CancelSubscription
                                      sowId={subscription?._id}
                                      onSuccess={(data) => {
                                        const newPaymentObj =
                                          subscriptionPayments.map(
                                            (subPayment) =>
                                              subPayment?._id ===
                                              subscription?._id
                                                ? {
                                                    ...data
                                                  }
                                                : subPayment
                                          )
                                        setSubscriptionPayments(newPaymentObj)
                                      }}
                                      API={API}
                                      nextChargeDate={
                                        subscription?.nextChargeDate
                                      }
                                    />
                                  )}
                                </span>
                              </div>
                            ) : (
                              '-'
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  You don’t have any subscriptions
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    </section>
  )
}

export default SubscriptionTable
