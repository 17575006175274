import React from 'react'
import { Nav } from 'react-bootstrap'

const ABNav = ({ children }) => {
  return (
    <Nav
      variant='tabs'
      defaultActiveKey='/home'
      className='custom-tabs border-0 justify-content-center'
    >
      {children}
    </Nav>
  )
}

export default { ...Nav, ...ABNav }
