import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import { ShimmerButton, ShimmerTitle } from 'react-shimmer-effects'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import AddCreditCard from './AddCreditCard'
import Button from '../Button'
import { useErrorService, useUserService } from '../../services'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const AddCardModal = ({ API, onSuccess, isDisabled = false }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const Error = useErrorService()
  const { user } = useUserService()

  useEffect(() => {
    if (stripe) {
      setStripeLoading(false)
    }
  }, [stripe])

  const addCard = async () => {
    try {
      setIsLoading(true)
      const data = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
        billing_details: {
          name: user?.fullName,
          email: user?.email,
          address: {}
        }
      })
      if (data && !data?.error) {
        const { data: cardData } = await API.payment.attachPayMethod({
          paymentMethodId: data?.paymentMethod?.id
        })
        if (cardData) {
          setIsLoading(false)
          setShowModal(false)
          onSuccess(data?.paymentMethod?.id)
        }
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'
      >
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>ADD CARD</h3>
        </Modal.Header>
        {stripeLoading ? (
          <div className='text-center border-0 mt-32 mb-32px add-card-shimmer'>
            <ShimmerTitle
              line={1}
              variant='primary'
              className='input-shimmer mb-4'
            />
            <ShimmerTitle
              line={1}
              variant='primary'
              className='input-shimmer mt-4'
            />
            <div className='d-flex justify-content-between justify-content-lg-end mt-40px'>
              <ShimmerButton side='lg' />
              <ShimmerButton side='lg' />
            </div>
          </div>
        ) : (
          <>
            <Modal.Body className='payment-modal-body add-card'>
              <AddCreditCard
                baseStyle={{
                  iconColor: '#666EE8',
                  color: '#000',
                  lineHeight: '32px',
                  fontWeight: 600,
                  fontFamily: 'Open Sans',
                  fontSize: '0.875rem',
                  letterSpacing: '-0.28px',
                  '::placeholder': {
                    fontSize: '0.75rem',
                    color: '#00000040'
                  }
                }}
                labelClasses='mb-4'
              />
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
              <Button
                variant='secondary-light'
                onClick={handleClose}
                disabled={isLoading}
                className='px-3 secondary-popup-button'
                size='sm'
              >
                Cancel
              </Button>
              <Button
                variant='saffron'
                size='sm'
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => addCard()}
                className='text-uppercase border-radius-normal text-white hover:text-white'
              >
                ADD CARD
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <span
        onClick={() => setShowModal(true)}
        className={classNames('s11 text-saffron-700', {
          'cursor-pointer': !isLoading && !isDisabled,
          'pointer-events-none pe-none': isLoading || isDisabled
        })}
      >
        Add New Card
      </span>
    </>
  )
}

const AddCardWrapperWithStripe = ({ onSuccess, API, isDisabled }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans'
          }
        ]
      }}
    >
      <AddCardModal API={API} onSuccess={onSuccess} isDisabled={isDisabled} />
    </Elements>
  )
}

export default AddCardWrapperWithStripe
