import React from 'react'
import { Helmet } from 'react-helmet'
import { ProgramsList } from '../components'
import { PageHeader } from '../components/common'

const Program = () => {
  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{'Programs | Abroad'}</title>
        <meta name='title' content={'Programs | Abroad'}></meta>
        <meta property='og:title' content={'Programs | Abroad'}></meta>
        <meta property='twitter:title' content={'Programs | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/programs`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/programs`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/programs`}></meta>
      </Helmet>
    )
  }

  return (
    <>
      {renderHelmet()}
      <PageHeader title='Programs' />
      <ProgramsList />
    </>
  )
}

export default Program
