import React from 'react'
import { ModalProvider } from '@abroad/components'
import { IntentionProvider, SessionModel } from '../components'
import SessionPractice from './SessionPractice'

const CoachingProvider = () => {
  return (
    <IntentionProvider>
      <ModalProvider>
        <SessionPractice />
        <SessionModel />
      </ModalProvider>
    </IntentionProvider>
  )
}

export default CoachingProvider
