import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, useUserService, useErrorService } from '@abroad/components'
import { PageHeader, GrayInfoCard, SelectCoachAndTime } from '../components'
import API from '../utils/API'

const CoachAndCircles = () => {
  const { user } = useUserService()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const Error = useErrorService()
  const [selectedCoachDetail, setSelectedCoachDetail] = useState(null)
  const location = useLocation()

  useEffect(() => {
    if (user?.isInGroup && user?.isGrowthPlanCreated) {
      history.push('/home')
    }
  }, [user, history])

  const handleSessionTime = async () => {
    if (!user?.isGrowthPlanCreated) {
      history.push({
        pathname: '/schedule-session/individual',
        state: {
          selectedCoachDetail: {
            ...selectedCoachDetail,
            dateAndTime: [
              selectedCoachDetail?.dateAndTime.find(
                (a) => a?.slotId === selectedCoachDetail?.slotId,
              ),
            ],
          },
          surveyType: location.state?.surveyType,
        },
      })
    } else {
      try {
        setIsLoading(true)
        const groupId = selectedCoachDetail?.slotId
        const coachId = selectedCoachDetail?.coachId
        const { data } = await API.company.getIndividualSessionScheduling(
          groupId,
          coachId,
        )
        setIsLoading(false)
        if (data) {
          history.push('/schedule-detail', {
            dateAndTime: [
              selectedCoachDetail?.dateAndTime.find(
                (a) => a?.slotId === selectedCoachDetail?.slotId,
              ),
            ],
            surveyType: location.state?.surveyType,
            onlyInGroup: true,
          })
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <Container className='px-0' fluid>
      <PageHeader title='Vanderbilt Coaching Circles' />
      <h3 className='s3 mt-3'>
        Select your Coach and Coaching Circle times that works best for you:
      </h3>
      <React.Fragment>
        <GrayInfoCard
          className='mb-4rem mt-4 p-4 assessment-info-box circle-detail-box'
          titleFn={() => {
            return (
              <>
                <p className='mb-3 s5 font-weight-500'>
                  Start your journey of growth by booking your 75-minute
                  Coaching Circle.
                </p>
                <p className='mb-3 s5 font-weight-500'>
                  Each month you’ll get to meet with a small group of your peers
                  to explore important topics to support your growth
                  development.
                </p>
                <p className='mb-3 s5 font-weight-500'>
                  The Coaching Circles are facilitated by skilled Abroad coaches
                  and you’ll participate in engaging discussions while also
                  receiving and giving peer support. Each session includes
                  important insights and content tailored to ignite your
                  personal and professional development.
                </p>
                <p className='mb-3 s5 font-weight-500'>
                  Please choose a time for your Coaching Circle that you are
                  able to commit to over the next 5 months.
                </p>
                <p className='m-0 s5 font-weight-500'>
                  Once you have chosen your Coaching Circle times, schedule your
                  1:1 session with your Abroad Coach, and you will be all set!
                </p>
              </>
            )
          }}
        />
        <SelectCoachAndTime setSelectedCoachDetail={setSelectedCoachDetail} />
        <div className='row justify-content-between mt-32px'>
          <div className='col-lg-2 col-md-3 col-sm-4 my-2'>
            <Button
              className='text-uppercase border-radius-5 py-12px px-50px line-height-normal btn-abroad-blue w-100 '
              onClick={() => history.goBack()}>
              BACK
            </Button>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12 my-2 order-first order-sm-2'>
            <Button
              variant='saffron'
              isLoading={isLoading}
              disabled={!selectedCoachDetail}
              className='border-radius-normal text-uppercase text-white py-12px w-100 '
              onClick={() => handleSessionTime()}>
              {user?.isGrowthPlanCreated
                ? 'SCHEDULE'
                : 'STEP 2: SCHEDULE 1:1 GROWTH PLAN SESSION'}
            </Button>
          </div>
        </div>
      </React.Fragment>
    </Container>
  )
}

export default CoachAndCircles
