import React from 'react'

const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}>
      {children}
    </span>
  )
})

export default CustomDropdownToggle
