import { useContext } from 'react'
import { NotificationContext } from '../Components'

const useNotificationService = () => {
  const Notification = useContext(NotificationContext)

  return Notification
}

export default useNotificationService
