import React from 'react'
import { Dropdown } from 'react-bootstrap'
import classnames from 'classnames'

const Logout = ({ className, onLogout }) => {
  return (
    <Dropdown.Item
      as='button'
      className={classnames('cursor-pointer logout-button', className)}
      onClick={() => onLogout()}>
      Sign Out
    </Dropdown.Item>
  )
}

export default Logout
