import React, { useState } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { InputField, PasswordInValidError } from '../form'
import { CustomDropdownToggle } from '../common'

const SignupPasswordField = ({
  placeholder,
  isSubmitting = false,
  isCustomValidating = false,
  ...rest
}) => {
  const { handleBlur, setFieldTouched } = useFormikContext()
  const [showPasswordSuggestion, setShowPasswordSuggestion] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  return (
    <Dropdown show={showPasswordSuggestion} style={{ position: 'top' }}>
      <Dropdown.Toggle
        as={CustomDropdownToggle}
        id='dropdown-custom-components'>
        <InputField
          showTooltip
          onFocus={() => {
            setShowPasswordSuggestion(true)
            setFieldTouched('password', true, true)
          }}
          onBlur={(event) => {
            setShowPasswordSuggestion(false)
            handleBlur(event)
          }}
          name='password'
          type='password'
          placeholder={placeholder}
          isSubmitting={isSubmitting}
          isCustomValidating={isCustomValidating}
          {...rest}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                mainAxis: true,
                altAxis: true, // false by default
                boundary: document.querySelector('#password-container'),
              },
            },
          ],
        }}
        className={classNames(
          'w-100 shadow p-2 password-suggestion-menu border-radius-5',
          {
            'is-invalid-password': !isValidPassword,
            'is-valid-password invisible': isValidPassword,
          },
        )}>
        <PasswordInValidError setIsValidPassword={setIsValidPassword} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SignupPasswordField
