import React from 'react'

const SubscriptionTableLink = ({ onClick, disabled, linkText }) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        // eslint-disable-next-line no-script-url
        href='javascript:void(0)'
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        disabled={disabled}
        className={disabled ? 'pointer-event-none' : ''}
      >
        {linkText}
      </a>
    </>
  )
}

export default SubscriptionTableLink
