import React from 'react'
import { paymentType } from '@abroad/components'
import CardsListing from './CardsListing'

export const renderPlanPayment = (subscription) => {
  return (
    <>
      {/* added initialFee && amount */}
      {subscription?.initialFee &&
      subscription?.amount &&
      subscription?.paymentCycle === Object.values(paymentType)[0]
        ? `$${subscription?.initialFee + subscription?.amount}`
        : null}
      {/* added amount */}
      {subscription?.initialFee === 0 &&
      subscription?.amount &&
      subscription?.paymentCycle === Object.values(paymentType)[0]
        ? `$${subscription?.amount}`
        : null}
      {/* added initialFee & payment type is onetime */}
      {subscription?.paymentCycle === Object.values(paymentType)[1] &&
      (subscription?.initialFee || subscription?.initialFee === 0)
        ? `$${subscription?.initialFee}`
        : null}
    </>
  )
}

const CoachingPlanInformation = ({
  staticSOWData,
  subscription,
  selectedCardDetails,
  setSelectedCardDetails,
}) => {
  return (
    <>
      {staticSOWData ? (
        <h3 className='s3 mb-12px'>
          {staticSOWData?.planName} - {renderPlanPayment(staticSOWData)}
        </h3>
      ) : (
        <h3 className='s3 mb-12px'>
          Your Coaching Plan - {renderPlanPayment(subscription)}
        </h3>
      )}
      <span className='s7'>
        Take your next steps on the journey of personal growth and
        transformative leadership.
      </span>
      <CardsListing
        selectedCardDetails={selectedCardDetails}
        setSelectedCardDetails={setSelectedCardDetails}
        enabledUncheckedAction={true}
      />
    </>
  )
}

export default CoachingPlanInformation
