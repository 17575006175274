import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import AssessmentLearnMore from './AssessmentLearnMore'

const AssessmentScoreCard = ({
  title,
  percentage,
  wrapperClasses,
  variant,
  linkClasses,
  category,
  onLearnMoreClick,
  outerBackgroudColor,
  innerBackgroudColor,
  width,
  height,
  titleClasses = '',
  titlePosition = 'bottom',
}) => {
  const outerCardStyle = {
    width: width,
    height: height,
    borderRadius: '0.313rem',
    background: outerBackgroudColor,
    position: 'relative',
  }
  const innerCardStyle = {
    content: '',
    position: 'absolute',
    background: innerBackgroudColor,
    borderRadius: '0.313rem',
    bottom: 0,
    left: 0,
    height: Number.isInteger(percentage) ? `${percentage}%` : '0%',
    width: width,
    animation: 'card-score-filler 2s ease-in-out',
  }
  const pageBreakStyle = { pageBreakInside: 'avoid' }

  useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = `
      @media print {
        
        .print-inner-card {
          height: ${
            Number.isInteger(percentage) ? `${percentage}%` : '0%'
          } !important;
        }
        .
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [percentage])

  return (
    <div className={classNames(wrapperClasses)}>
      {variant === 'normal-card' ? (
        <div style={pageBreakStyle}>
          {titlePosition === 'top' && (
            <h3 className={classNames('s2 mb-4', titleClasses)}>{title}</h3>
          )}
          <div className='position-relative d-flex justify-content-center'>
            <div style={outerCardStyle}>
              <div
                className='print-inner-card score-card'
                style={innerCardStyle}></div>
              <div className='category-percentage position-absolute w-100'>
                <span>
                  {Number.isInteger(percentage) ? `${percentage}%` : 'N/A'}
                </span>
              </div>
            </div>
          </div>
          {titlePosition === 'bottom' && (
            <h3 className={classNames('s2 my-2', titleClasses)}>{title}</h3>
          )}
          <div className={classNames({ 'mt-4': titlePosition === 'top' })}>
            <AssessmentLearnMore
              linkClasses={linkClasses}
              category={category}
              onLearnMoreClick={onLearnMoreClick}
            />
          </div>
        </div>
      ) : (
        <div style={pageBreakStyle}>
          <div className='position-relative d-flex justify-content-lg-start justify-content-center'>
            <div className='score-height' style={outerCardStyle}>
              <div
                className='score-card print-inner-card'
                style={innerCardStyle}>
                <div className='score-text w-100 d-flex justify-content-center align-items-center h-100'>
                  {Number.isInteger(percentage) ? `${percentage}%` : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

AssessmentScoreCard.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['table-attached-card', 'normal-card']),
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
  wrapperClasses: PropTypes.string,
}

AssessmentScoreCard.defaultProps = {
  variant: 'normal-card',
  wrapperClasses: '',
  titlePosition: 'bottom',
}

export default AssessmentScoreCard
