import React from 'react'
import loadable from '@loadable/component'
import { isLGScreen } from './utils/utility'

const LoadableImgix = loadable(() => {
  // eslint-disable-next-line no-unused-expressions
  import('lazysizes')
  // eslint-disable-next-line no-unused-expressions
  import('lazysizes/plugins/parent-fit/ls.parent-fit')
  // eslint-disable-next-line no-unused-expressions
  import('lazysizes/plugins/attrchange/ls.attrchange')
  return import(/* webpackChunkName: "imgix" */ 'react-imgix')
})

const Imgix = React.memo(({ children, ...rest }) => {
  return (
    <LoadableImgix
      fallback={
        <img
          className='lazyload w-100 h-lg-100 img-cover'
          src={`${rest.src}?${
            !isLGScreen ? '' : 'crop=top&fit=crop&ar=2.21:1&'
          }fill=blur&blur=300&px=16&auto=format`}
          alt='page-banner'
        />
      }
      {...rest}
    >
      {children}
    </LoadableImgix>
  )
})

export default Imgix
