import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import CKEditor from '../editor'
import {
  Button,
  useUserService,
  ModelContext,
  useErrorService,
} from '@abroad/components'
import API from '../../utils/API'
import AddNewInsightEvent from '../../constants/events'

const InsightModel = ({ updateInsight }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { show, onHide, data } = useContext(ModelContext)
  // const { state, dispatch } = useIntentionReducer()
  const Error = useErrorService()
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [description, setDescription] = useState('')
  const { user } = useUserService()

  const gtmAddNewInsightAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: AddNewInsightEvent.event.AddNewInsight,
        eventProps: {
          category: AddNewInsightEvent.category.AddNewInsightCategory,
          action: 'user Add new insight in growthPlan page',
          label: 'Add new insights',
          value: `user Add new insight in growthPlan page`,
          userId: user?.id,
        },
      },
    })
  }

  useEffect(() => {
    if (isEditorReady) {
      setTimeout(() => {
        setDescription(data?.description ? data.description : '')
      }, 50)
    }
  }, [data, isEditorReady])

  const saveChanges = async () => {
    if (data?._id) {
      try {
        setIsLoading(true)
        await API.intention.updateInsight(
          {
            description: description,
          },
          data.intentionId,
          data._id,
        )
        setIsLoading(false)
        onHide()
        updateInsight({ ...data, description }, false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    } else {
      try {
        setIsLoading(true)
        const { data: response } = await API.intention.addInsight(
          { description: description },
          data?.intentionId,
        )
        setIsLoading(false)
        onHide()
        updateInsight(response, true)
        gtmAddNewInsightAction()
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
  }

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='Insight'
      centered>
      <Modal.Body>
        <div className='mb-3'>
          <span className='s3'>Insight</span>
        </div>
        <CKEditor
          data={description}
          onReady={(editor) => {
            setIsEditorReady(true)
          }}
          onChange={(event, editor) => {
            setDescription(editor.getData())
          }}
        />
        <div className='d-flex justify-content-end mt-3'>
          <Button
            variant='saffron-square'
            disabled={isLoading || !description?.trim()}
            isLoading={isLoading}
            className={'text-white s10a border-radius-3'}
            onClick={saveChanges}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default InsightModel
