import React from 'react'

const SurveyComparisonTabelRow = ({ title, survey0, survey1 }) => {
  return (
    <tr>
      <td>{title}</td>
      <td className='text-center'>
        {Number.isInteger(survey0) ? `${survey0}%` : 'N/A'}
      </td>
      <td className='text-center'>
        {Number.isInteger(survey1) ? `${survey1}%` : 'N/A'}
      </td>
      <td className='text-center'>
        {Number.isInteger(survey0 && survey1)
          ? survey1 - survey0 > 0
            ? `+${parseInt(survey1) - parseInt(survey0)}%`
            : `${parseInt(survey1) - parseInt(survey0)}%`
          : 'N/A'}
      </td>
    </tr>
  )
}

export default SurveyComparisonTabelRow
