import React from 'react'
import { Helmet } from 'react-helmet'
import Imgix from 'react-imgix'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '../components'

const DownloadApp = () => {
  const history = useHistory()

  const imageFilename = 'abroad-app-download.png'

  return (
    <>
      <Helmet>
        <title>{'Download App | Abroad'}</title>
        <meta name='title' content={'Download App | Abroad'}></meta>
        <meta property='og:title' content={'Download App | Abroad'}></meta>
        <meta property='twitter:title' content={'Download App | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/download-app`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/download-app`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/download-app`}></meta>
      </Helmet>
      <main>
        <PageHeader
          showBackArrow={true}
          title='Download the Abroad App'
          backArrowAction={() => history.goBack()}
        />
        <div className='s3 mt-52px mb-3'>
          Unlock Full Access with Our Mobile App
        </div>
        <div className='s5 mb-52px'>
          For the best experience and enhanced functionalities, download our app
          to your smartphone. It's designed to make your journey seamless,
          intuitive, and enjoyable.
        </div>
        <div className='d-flex align-items-center flex-lg-row flex-column'>
          <div className='download-app-image-wrp'>
            <Imgix
              className='lazyload w-100 img-cover'
              src={`${process.env.REACT_APP_IMG_SOURCE}/${imageFilename}`}
              alt='app download link'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${imageFilename}?fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
          </div>
          <div className='s5 download-app-steps'>
            <b>Quick Steps to Get Started:</b>
            <br />
            <span>
              1. Scan the QR code with your phone’s camera.
              <br />
              2. Tap the link that appears to download the app.
              <br />
              3. Open the app and navigate to the desired feature.
            </span>
          </div>
        </div>
      </main>
    </>
  )
}

export default DownloadApp
