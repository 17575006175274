import React, { useState, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { faqs } from './dummy-data'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Button, useUserService } from '@abroad/components'
import { PageHeader, GrayInfoCard } from '../common'
import { useLocation } from 'react-router-dom'

const FAQsListing = () => {
  const history = useHistory()
  const { user } = useUserService()
  const [showMore, setShowMore] = useState(false)
  const displayedFaqs = showMore ? faqs : faqs.slice(0, 3)
  const location = useLocation()

  useEffect(() => {
    if (user?.isInGroup && user?.isGrowthPlanCreated) {
      history.push('/home')
    }
  }, [user, history])

  return (
    <>
      <Container className='px-0' fluid>
        <PageHeader title='Vanderbilt Coaching Circles' />
        <div>
          <GrayInfoCard
            className='mb-4 mt-4 p-4 assessment-info-box circle-detail-box'
            titleFn={() => {
              return (
                <>
                  <p className='mb-3 s5 font-weight-500'>
                    Thank you for completing your assessment.
                  </p>
                  <p className='mb-3 s5 font-weight-500'>
                    Please choose your Coaching Circle dates and times and
                    schedule your 1:1 Growth Plan Session to view your results.
                  </p>
                </>
              )
            }}
          />
        </div>
        <section className='mb-52px'>
          <div className='faqs-accordion circles-faqs'>
            <h2 className='s14 mb-0'>
              Learn More About the Coaching Circles program. Check out the FAQs
              Below
            </h2>
            <div className='mt-30px'>
              {displayedFaqs.map((faq, index) => (
                <Accordion className='mt-6px' key={index}>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      <span className='s6'>{faq.title}</span>
                    </Accordion.Header>
                    <Accordion.Body className='p-0 pt-0 pb-0'>
                      <div
                        dangerouslySetInnerHTML={{ __html: faq.description }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
            <div className='d-flex justify-content-end mt-3'>
              <Button
                className='view-more-toggle p-0 bg-transparent'
                onClick={() => setShowMore(!showMore)}>
                {showMore ? 'View Less' : 'View More'}
                <span
                  className={`accordion-icon ms-2 ${
                    showMore ? 'rotated' : ''
                  }`}></span>
              </Button>
            </div>
          </div>
        </section>
        <div className='d-flex flex-column-4 justify-content-end mt-32px'>
          <Button
            className='border-radius-5 text-uppercase py-12px btn-session'
            onClick={() =>
              history.push('/schedule-session', {
                surveyType: location.state?.surveyType,
              })
            }>
            STEP 1: SELECT YOUR COACH & CIRCLE TIMES
          </Button>
        </div>
      </Container>
    </>
  )
}

export default FAQsListing
