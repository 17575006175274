import React, { useState } from 'react'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import { Button, useErrorService } from '@abroad/components'
import API from '../../utils/API'

const DeleteCard = ({ onSuccess, cardId, isLoading = false }) => {
  const [showModal, setShowModal] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const Error = useErrorService()
  const handleClose = () => {
    if (isRemoving) return
    setShowModal(false)
  }

  const deleteCardHandler = async () => {
    try {
      setIsRemoving(true)
      const { status } = await API.payment.detachCard({
        paymentMethodId: cardId,
      })
      if (status) {
        setIsRemoving(false)
        onSuccess(cardId)
        handleClose()
      }
    } catch (e) {
      handleClose()
      setIsRemoving(false)
      Error.showError(e)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'>
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>Remove Card</h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body'>
          <h3 className='confirmation-title'>
            Are you sure, you want to permanently remove this card?
          </h3>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='none'
            onClick={handleClose}
            disabled={isRemoving}
            className='px-3 secondary-popup-button'
            size='sm'>
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isRemoving}
            isLoading={isRemoving}
            onClick={deleteCardHandler}
            className='text-uppercase border-radius-normal text-white hover:text-white'>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      <span
        className={classNames('s11 delete-link align-self-center', {
          'cursor-pointer': !isRemoving || !isLoading,
          'pe-none': isRemoving || isLoading,
        })}
        onClick={() => setShowModal(true)}>
        Delete Card
      </span>
    </>
  )
}

export default DeleteCard
