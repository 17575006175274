import React from 'react'
import { Button } from '@abroad/components'
import { Container } from 'react-bootstrap'
import classnames from 'classnames'
import { isLGScreen } from '../../utils/utility'

const UpgradePlan = () => {
  return (
    <section>
      <Container className='my-4 px-5'>
        <p className='font-24 font-lg-44 font-montserrat font-semibold'>
          You need to upgrade your plan to take advantage of this feature
        </p>
        <div className='mt-5 py-4 archived-intention'>
          <Button
            as='a'
            href='https://www.abroad.io/plans'
            target='_blank'
            rel='noopener noreferrer'
            variant='saffron'
            size='lg'
            className={classnames('font-semibold', { 'w-8/12': !isLGScreen })}>
            Upgrade Now
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default UpgradePlan
