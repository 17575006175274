import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import packageJson from '../../package.json'
import AuthService from './AuthService'
global.appVersion = packageJson.version

const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request'],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGES,
  enabledReleaseStages: ['development', 'staging', 'production'],
  appVersion: global?.appVersion,
  appType: 'client',
  onError: function (event) {
    event.setUser(AuthService.getUserData()?._id)
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default ErrorBoundary
export { bugsnagClient }
