import React from 'react'

const VolumeIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      className='icon-volume cursor-pointer'
      {...props}
    >
      <path
        d='M23.0399 5.8339C24.6934 8.1325 25.6673 10.9528 25.6673 14.0005C25.6673 17.0483 24.6934 19.8686 23.0399 22.1672M18.3702 9.3339C19.2929 10.6567 19.834 12.2655 19.834 14.0005C19.834 15.7357 19.2929 17.3444 18.3702 18.6672M11.2407 6.26052L7.54739 9.95383C7.34561 10.1556 7.24472 10.2565 7.12698 10.3286C7.0226 10.3926 6.9088 10.4397 6.78975 10.4683C6.65548 10.5006 6.51281 10.5006 6.22745 10.5006H4.20065C3.54726 10.5006 3.22056 10.5006 2.971 10.6277C2.75148 10.7396 2.573 10.918 2.46114 11.1376C2.33398 11.3871 2.33398 11.7139 2.33398 12.3672V15.6339C2.33398 16.2873 2.33398 16.614 2.46114 16.8635C2.573 17.0831 2.75148 17.2616 2.971 17.3734C3.22056 17.5005 3.54726 17.5005 4.20065 17.5005H6.22745C6.51281 17.5005 6.65548 17.5005 6.78975 17.5328C6.9088 17.5614 7.0226 17.6086 7.12698 17.6725C7.24472 17.7446 7.34561 17.8455 7.54739 18.0473L11.2407 21.7405C11.7405 22.2403 11.9904 22.4902 12.2049 22.5072C12.391 22.5217 12.573 22.4464 12.6942 22.3044C12.834 22.1408 12.834 21.7874 12.834 21.0807V6.9205C12.834 6.21371 12.834 5.86031 12.6942 5.69667C12.573 5.55469 12.391 5.47934 12.2049 5.49399C11.9904 5.51087 11.7405 5.76076 11.2407 6.26052Z'
        // stroke='#BF9000'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default VolumeIcon
