import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Card as RBCard } from 'react-bootstrap'

const ABCard = React.forwardRef(
  (
    {
      children,
      className,
      onHoverShadow,
      shadowClass,
      border,
      shadow,
      ...reset
    },
    ref
  ) => {
    return (
      <RBCard
        ref={ref}
        className={classnames(
          'rounded-lg border-0 border-radius-10',
          {
            shadow: shadow,
            'hover:shadow transition': onHoverShadow
          },
          shadowClass,
          className
        )}
        {...reset}
      >
        {children}
      </RBCard>
    )
  }
)

ABCard.propTypes = {
  shadow: PropTypes.bool,
  onHoverShadow: PropTypes.bool
}

ABCard.defaultProps = {
  shadow: true,
  onHoverShadow: false
}

export default { ...RBCard, ...ABCard }
