import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { isLGScreen } from '../utils/utility'

const ErrorView = ({ redirectToHomepage = () => {} }) => {
  return (
    <React.Fragment>
      <div className='position-relative h-100 w-100 vh-100 vw-100 not-found'>
        {isLGScreen ? (
          <Imgix
            className='lazyload w-100 h-lg-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found.png`}
          />
        ) : (
          <Imgix
            className='lazyload vh-100 h-md-100 vw-100'
            imgixParams={{ ar: '16:9', fil: 'crop', auto: 'format' }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/not-found-sm.png`}
          />
        )}

        <Container
          fluid
          className='position-absolute inset-0 h-100 p-lg-5 p-md-5 p-2 text-lg-left text-md-left text-center error-view'>
          <Row className='w-100 h-100 m-0'>
            <Col>
              <div className='w-lg-35 w-sm-100'>
                <p className='font-lg-24 font-md-24 font-sm-14 font-weight-medium text-break'>
                  Something went wrong! Please try again later.
                </p>
              </div>
              <span className='font-lg-24 font-md-24 tracking-lg-wide home-link'>
                <p
                  onClick={() => {
                    redirectToHomepage()
                    setTimeout(() => {
                      window.location.reload()
                    }, 50)
                  }}
                  className='home-link return-link cursor-pointer text-lg-start'>
                  CLICK HERE TO RETURN TO HOME.
                </p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ErrorView
