import React, { useEffect, useState } from 'react'
import { Col, Row, Form as RBForm } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { ShimmerButton, ShimmerText, ShimmerTitle } from 'react-shimmer-effects'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { number, object, string } from 'yup'
import {
  AddCreditCard,
  Button,
  useUserService,
  useErrorService,
} from '@abroad/components'
import { renderPlanPayment } from './CoachingPlanInformation'
import { InputField } from '../form'
import API from '../../utils/API'

const defaultAddress = {
  line1: '',
  line2: '',
  state: '',
  country: '',
  postal_code: '',
}

const validationSchema = object().shape({
  line1: string().notRequired(),
  line2: string().notRequired(),
  state: string().notRequired(),
  country: string().notRequired(),
  postal_code: number().integer().notRequired(),
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const CoachingPlanPaymentForm = ({
  selectedCardDetails,
  staticSOWData,
  subscription,
  onSuccess,
  isLoading: isPaying,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [billingAddress, setBillingAddress] = useState(defaultAddress)
  const [intialStateAddressObj, setIntialStateAddressObj] =
    useState(defaultAddress)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const elements = useElements()
  const stripe = useStripe()
  const { user } = useUserService()
  const Error = useErrorService()
  const userId = user?._id

  useEffect(() => {
    const getUserAddress = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.user.getUserAddress(userId)
        if (data?.address) {
          // remove "city" key from response
          Object.keys(data?.address).forEach(
            (key) => data?.address[key] === null && delete data?.address[key],
          )
          setBillingAddress((prev) => ({
            ...prev,
            ...data?.address,
          }))
          setIntialStateAddressObj((prev) => ({
            ...prev,
            ...data?.address,
          }))
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    getUserAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div className='payment-form-shimmer'>
        {[1, 2, 3, 4, 5, 6].map((number, index) => (
          <React.Fragment key={index}>
            <ShimmerText line={1} />
            <ShimmerTitle
              line={1}
              variant='primary'
              className='input-shimmer mb-4'
              key={index}
            />
          </React.Fragment>
        ))}
        <ShimmerButton size='md' />
      </div>
    )
  }

  const checkAddressChangeHandler = (values) => {
    const initialObjectKeys = Object.keys(intialStateAddressObj).sort()
    const addressObjectKeys = Object.keys(values).sort()
    const areEqual = initialObjectKeys.every((key, index) => {
      const objValue1 = intialStateAddressObj[key]
      const objValue2 = values[addressObjectKeys[index]]
      return objValue1 === objValue2
    })
    return areEqual ? false : true
  }

  const onSubmit = async (values) => {
    const isAddressObjChanged = checkAddressChangeHandler(values)
    if (selectedCardDetails?.id) {
      setIsSubmitting(false)
      onSuccess(
        isAddressObjChanged ? values : null,
        isAddressObjChanged,
        selectedCardDetails?.id,
      ) // addressObj = null
    } else {
      try {
        setIsSubmitting(true)
        const data = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement('cardNumber'),
          billing_details: {
            name: user?.fullName,
            email: user?.email,
            address: {},
          },
        })
        if (data && !data?.error) {
          const { data: cardData } = await API.payment.attachPayMethod({
            paymentMethodId: data?.paymentMethod?.id,
          })
          if (cardData) {
            setIsSubmitting(false)
            onSuccess(
              isAddressObjChanged ? values : null,
              isAddressObjChanged,
              data?.paymentMethod?.id,
            ) // addressObj = null
          }
        } else {
          setIsSubmitting(false)
        }
      } catch (e) {
        setIsSubmitting(false)
        Error.showError(e)
      }
    }
  }

  return (
    <Formik
      initialValues={billingAddress}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, handleBlur }) => (
        <>
          <RBForm
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(e)
            }}
            as={Form}
            className='coaching-payment user-payment mw-100 m-0'>
            <div className='add-card'>
              {selectedCardDetails?.id ? null : (
                <AddCreditCard
                  baseStyle={{
                    iconColor: '#666EE8',
                    color: '#000',
                    lineHeight: '32px',
                    fontWeight: 600,
                    fontFamily: 'Open Sans',
                    fontSize: '0.875rem',
                    letterSpacing: '-0.28px',
                    '::placeholder': {
                      fontSize: '0.75rem',
                      color: '#00000040',
                    },
                  }}
                  labelClasses='mb-4'
                />
              )}
              <div className='form-group credit-card-wrp mb-4'>
                <label>Address Line 1</label>
                <InputField
                  formGroupProps={{
                    className: 'mb-4',
                  }}
                  name='line1'
                  type='text'
                  size='md'
                  className='border-radius-3 address-input'
                />
              </div>
              <div className='form-group credit-card-wrp mb-4'>
                <label>Address Line 2</label>
                <InputField
                  formGroupProps={{
                    className: 'mb-4',
                  }}
                  name='line2'
                  type='text'
                  size='md'
                  className='border-radius-3 address-input'
                />
              </div>
              <div className='form-group credit-card-wrp mb-4'>
                <label>Country</label>
                <InputField
                  formGroupProps={{
                    className: 'mb-4',
                  }}
                  name='country'
                  type='text'
                  size='md'
                  className='border-radius-3 address-input'
                />
              </div>
              <Row className='justify-content-between'>
                <Col className='col-6'>
                  <>
                    <label>State</label>
                    <InputField
                      formGroupProps={{
                        className: 'mb-0',
                      }}
                      name='state'
                      type='text'
                      size='md'
                      className='border-radius-3 address-input'
                    />
                  </>
                </Col>
                <Col className='col-5'>
                  <>
                    <label>ZIPCODE</label>
                    <InputField
                      formGroupProps={{
                        className: 'mb-0',
                      }}
                      name='postal_code'
                      type='number'
                      size='md'
                      className='border-radius-3 address-input'
                    />
                  </>
                </Col>
              </Row>
              <div className='d-flex justify-content-between payment-info mt-30px'>
                <span className='total align-self-center'>TOTAL</span>
                <span className='amount'>
                  {renderPlanPayment(
                    staticSOWData ? staticSOWData : subscription,
                  )}{' '}
                  USD
                </span>
              </div>
              <Button
                type='submit'
                variant='saffron'
                className='text-uppercase line-height-normal w-100 s9 border-radius-normal mt-30px text-white hover:text-white'
                disabled={isSubmitting || isPaying}
                isLoading={isSubmitting || isPaying}
                size='md'>
                MAKE PAYMENT
              </Button>
            </div>
          </RBForm>
        </>
      )}
    </Formik>
  )
}

const CoachingPlanPaymentFormWithStripe = ({
  selectedCardDetails,
  staticSOWData,
  subscription,
  onSuccess,
  isLoading,
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
          },
        ],
      }}>
      <CoachingPlanPaymentForm
        selectedCardDetails={selectedCardDetails}
        staticSOWData={staticSOWData}
        subscription={subscription}
        onSuccess={onSuccess}
        isLoading={isLoading}
      />
    </Elements>
  )
}

export default CoachingPlanPaymentFormWithStripe
