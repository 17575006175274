import React from 'react'
import { useHistory } from 'react-router-dom'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import Imgix from 'react-imgix'
import { Row, Col } from 'react-bootstrap'
import { useResolutionService } from '../../hooks'

const UpcomingQuestsGallery = ({ upcomingpublic, questId }) => {
  const history = useHistory()
  const { isMDScreen } = useResolutionService()
  return (
    <>
      {upcomingpublic.galleryImageFileNames &&
      upcomingpublic.galleryImageFileNames.length > 0 ? (
        <div className='mt-52px'>
          <div className='d-flex justify-content-between align-item-center'>
            <h1 className='s3 mb-0'>Image Gallery</h1>
            <p
              className='s11 text-saffron-700 cursor-pointer pe-auto my-auto'
              onClick={() => {
                const galleryUrl = `/quests/${questId}/gallery`
                history.push({
                  pathname: galleryUrl,
                  state: {
                    galleryImageFileNames: upcomingpublic.galleryImageFileNames,
                  },
                })
              }}>
              View Full
            </p>
          </div>
          <div>
            <PhotoProvider>
              <Row>
                {upcomingpublic.galleryImageFileNames
                  .slice(0, 4)
                  .map((galleryImageFileName, index) => (
                    <PhotoView
                      key={index}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${galleryImageFileName}`}>
                      <Col
                        className='mt-24px'
                        xl={index === 0 || index === 3 ? 8 : 4}
                        lg={index === 0 || index === 3 ? 6 : 6}
                        md={index === 0 || index === 3 ? 8 : 4}
                        sm={12}>
                        <Imgix
                          className='border-radius-10 lazyload imgix-object-cover cursor-pointer'
                          imgixParams={{
                            fit: 'crop',
                            crop: 'center',
                            ar: isMDScreen ? '2:1' : '5:2',
                          }}
                          src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${galleryImageFileName}`}
                          width={'100%'}
                          height={'100%'}
                          alt='image'
                          attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            sizes: 'data-sizes',
                          }}
                          htmlAttributes={{
                            src: `${
                              process.env.REACT_APP_IMG_SOURCE
                            }/quest/${questId}/${galleryImageFileName}?ar=${
                              isMDScreen ? '2:1' : '5:2'
                            }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                          }}
                        />
                      </Col>
                    </PhotoView>
                  ))}
              </Row>
            </PhotoProvider>
          </div>
        </div>
      ) : null}
    </>
  )
}
export default UpcomingQuestsGallery
