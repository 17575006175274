import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover, Image } from 'react-bootstrap'
import classnames from 'classnames'
import Button from '../Button'

let ABtooltips = null

const ABTooltip = ({
  children,
  controllId,
  contentText,
  className,
  overlayProps,
  buttonLabel,
  customPlacement,
  placement,
  showOnlyOnce,
  showFeather,
  contentTextClass,
  wrapperClasses = ''
}) => {
  const tooltipRef = useRef(null)
  const [renderTooltip, setRenderTooltip] = useState(false)
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)

  const getTooltipData = () => {
    try {
      if (!window.localStorage) {
        return ABtooltips
      } else {
        return JSON.parse(localStorage.getItem('ABtooltips'))
      }
    } catch (e) {
      // Error
    }
  }

  useEffect(() => {
    try {
      const tooltipData = getTooltipData()
      if (tooltipData) {
        if (!tooltipData.includes(controllId)) {
          setRenderTooltip(true)
        }
      } else {
        if (!window.localStorage) {
          ABtooltips = []
        } else {
          localStorage.setItem('ABtooltips', JSON.stringify([]))
        }
        setRenderTooltip(true)
      }
    } catch (err) {
      // error
    }
  }, [controllId])

  const showTooltip = (event) => {
    setShow(true)
    setTarget(event.target)
  }

  const hideTooltip = () => {
    if (showOnlyOnce) {
      const tooltipData = getTooltipData()
      if (!window.localStorage) {
        ABtooltips = [...tooltipData, controllId]
      } else {
        localStorage.setItem(
          'ABtooltips',
          JSON.stringify([...tooltipData, controllId])
        )
      }
      setRenderTooltip(false)
    }
    setShow(false)
  }

  if (!renderTooltip) return null

  return (
    <span ref={tooltipRef} className={wrapperClasses}>
      <div
        className={classnames(
          'cursor-pointer',
          {
            invisible: show,
            'tooltip-icon-container': !children
          },
          className
        )}
        onClick={showTooltip}
      >
        {!children && (
          <Image
            className='tooltip-icon'
            src={`${process.env.PUBLIC_URL}/assets/images/abroad-feather-gold@2x.png`}
          />
        )}
        {children}
      </div>
      <Overlay
        rootClose
        arrowProps={{
          style: {
            display: 'none'
          }
        }}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: ({ placement, reference, popper }) => {
                  if (placement === 'auto') {
                    return [-10, 0]
                  }
                  if (placement === 'auto-end') {
                    return [-popper.width / 2.3, 0]
                  }
                  if (customPlacement && placement === 'bottom-end') {
                    return [-20, -40]
                  }
                  return []
                }
              }
            }
          ]
        }}
        show={show}
        target={target}
        placement={placement || 'auto'}
        container={tooltipRef.current}
        onHide={hideTooltip}
        {...overlayProps}
      >
        <Popover className='tooltip-box'>
          <Popover.Content className='px-3 pt-2 pb-3 clearfix'>
            {showFeather && (
              <Image
                className='popover-icon'
                src={`${process.env.PUBLIC_URL}/assets/images/abroad-feather-gold@2x.png`}
              />
            )}
            <p
              className={classnames(
                'font-open-sans font-weight-light font-italic',
                contentTextClass
              )}
            >
              {contentText}
            </p>
            <Button
              onClick={hideTooltip}
              variant='black'
              size='xs'
              className='d-none d-lg-block float-right text-capitalize'
            >
              {buttonLabel}
            </Button>
            <Button
              onClick={hideTooltip}
              variant='black'
              className='d-block d-lg-none float-right text-capitalize'
            >
              {buttonLabel}
            </Button>
          </Popover.Content>
        </Popover>
      </Overlay>
    </span>
  )
}

ABTooltip.propTypes = {
  customPlacement: PropTypes.bool,
  buttonLabel: PropTypes.string,
  contentText: PropTypes.string.isRequired,
  controllId: PropTypes.string.isRequired, // uniq controll id for each tooltip
  overlayProps: PropTypes.instanceOf(Overlay),
  showOnlyOnce: PropTypes.bool, // tooltip only visible once
  showFeather: PropTypes.bool,
  wrapperClasses: PropTypes.string
}

ABTooltip.defaultProps = {
  buttonLabel: 'Close',
  customPlacement: false,
  showOnlyOnce: true,
  showFeather: true
}

export default ABTooltip
