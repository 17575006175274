import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { NavTabs, ABSpinner, useErrorService } from '@abroad/components'
import { PageHeader } from '../common'
import QuestsLearningResourcesAudio from './QuestsLearningResourcesAudio'
import API from '../../utils/API'
import QuestsLearningResourcesVideo from './QuestsLearningResourcesVideo'
import QuestsLearningResourcesBlog from './QuestsLearningResourcesBlog'
const QuestsLearningResources = () => {
  const { questId, category } = useParams()
  const Error = useErrorService()
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [blogs, setBlogs] = useState([])
  const [videos, setVideos] = useState([])
  const resourceCategories = ['Listen', 'Watch', 'Read']
  useEffect(() => {
    const getAllBlogCategories = async () => {
      try {
        const { data } = await API.quests.getQuestMedias(questId)
        if (data) {
          setVideos(data.videos || [])
          setBlogs(data.blogs || [])
          setIsLoading(false)
        } else {
          setBlogs([])
          setVideos([])
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getAllBlogCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])
  return (
    <>
      <Helmet>
        <title>{'Learning Resources | Abroad'}</title>
        <meta name='title' content={'Learning Resources | Abroad'}></meta>
        <meta
          property='og:title'
          content={'Learning Resources | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Learning Resources | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      </Helmet>
      <PageHeader
        showBackArrow={true}
        version='small'
        title='Your Quest'
        backArrowAction={() => history.push('/your-quest')}
      />
      <h1 className='s1 py-16px'>Learning Resources</h1>

      <NavTabs
        pages={resourceCategories.map((cat) => ({
          path: `/your-quest/${questId}/learning-resources/${cat}`,
          name: cat,
        }))}
        pathname={location?.pathname}
        hash={location?.hash}
        NavLink={NavLink}
      />

      {isLoading ? (
        <ABSpinner />
      ) : (
        <>
          {category === 'Watch' && videos.length === 0 && (
            <h3 className='mt-32px text-center s5'>
              There are no videos available at the moment. Please check back
              later!
            </h3>
          )}
          {category === 'Read' && blogs.length === 0 && (
            <h3 className='mt-32px text-center s5'>
              There are no blogs available at the moment. Please check back
              later!
            </h3>
          )}
          {category === 'Listen' && (
            <QuestsLearningResourcesAudio questId={questId} />
          )}
          {category === 'Watch' && videos.length > 0 && (
            <QuestsLearningResourcesVideo category={videos} questId={questId} />
          )}
          {category === 'Read' && blogs.length > 0 && (
            <QuestsLearningResourcesBlog category={blogs} questId={questId} />
          )}
        </>
      )}
    </>
  )
}

export default QuestsLearningResources
