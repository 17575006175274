import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { PaymentSuccessIcon } from '@abroad/components'

const FeedbackSubmittedModal = ({ showModal, setShowModal }) => {
  const onHide = () => setShowModal(false)
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      centered
      className='delete-intention-box'>
      <Modal.Body className='pb-0 pt-3 px-4 border-0 text-center'>
        <PaymentSuccessIcon />
        <h4 className='pay-sucess-txt pt-2'>
          Thank you, your feedback has been submitted.
        </h4>
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-center px-4 space-x-3'>
        <Button
          onClick={onHide}
          size='md'
          className='px-2 px-lg-4 s9 border-radius-normal'
          variant='saffron'>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FeedbackSubmittedModal
