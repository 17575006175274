import React, { useReducer } from 'react'
import { IntentionContext } from '@abroad/components'
import intentionReducer, { initialState } from './Reducer/intentionReducer'

const IntentionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(intentionReducer, initialState)
  return (
    <IntentionContext.Provider value={{ state, dispatch }}>
      {children}
    </IntentionContext.Provider>
  )
}

export default IntentionProvider
