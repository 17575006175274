export const faqs = [
  {
    id: '1',
    title: 'Why is this program part of my onboarding at Vanderbilt?',
    description: `
    <p class='s6a'>This program was designed to give each Vanderbilt employee their best possible start in their new role and to enhance their engagement and onboarding journey.</p>
    <p class='s6a'>Abroad alongside Vanderbilt’s People, Culture, and Belonging team has designed this 6-month Coaching Circle program that merges science, wisdom, and technology to unlock the latent potential within all Vanderbilt new hires and staff.</p>
    <p class='s6a'>A key intention of this program is to offer equitable opportunities for coaching and development while aiming to strengthen psychological safety, connection, diversity, and culture throughout Vanderbilt. The program fosters Vanderbilt’s challenge of "Dare to Grow.”</p>`,
  },
  {
    id: '2',
    title: 'Is there a fee to participate in the Coaching Circles Program?',
    description: `<p class='s6a'>No, there is no fee to participate. This is provided to all new hires as a benefit of joining Vanderbilt and is included as part of your onboarding.</p>`,
  },
  {
    id: '3',
    title: 'What are the components of the program?',
    description: `<p class='s6a'>The program comprises one 45-minute 1:1 Growth Plan Session with an Abroad Leadership Coach to support you in clarifying your personal and professional goals and set you up for success as you start this new phase of your career by joining Vanderbilt.</p>
    <p class='s6a'>It also includes 5 live 75-minute group sessions (Coaching Circles) with 9 of your peers to explore relevant topics to support you in your professional growth. In these dynamic discussions, you’ll get to give and receive peer support while learning key skills to support you in your growth and development.</p>`,
  },
  {
    id: '4',
    title: 'What is a Growth Plan Session?',
    description: `<p class='s6a'>Your 1:1 Growth Plan Session is a confidential coaching session that will support you in identifying and clarifying important personal and professional goals as you embark on your journey at Vanderbilt. It is tailored to your needs and will be based on your assessment.</p>
    <p class='s6a'>Most people report feeling inspired, empowered and motivated towards their chosen goals after meeting with their coach for their 1:1 Growth Plan Session. It’s a valuable time set aside just for you.</p>
    <p class='s6a'>Each team member receives 1 session at the start of the program with the same coach that will facilitate the coaching circle that they select. </p>`,
  },
  {
    id: '5',
    title: 'How do I sign up?',
    description: `
    <p class='s6a'>There are three quick steps to sign up:</p>
      <ol class='s6a'>
        <li>First, click “Select Your Coach & Circle” on this page</li>
        <li>Then choose the circle times that work best for you.</li>
        <li>Once you choose your circle time, book your 1:1 Growth Plan Session with your coach before your the first Coaching Circle session.</li>
      </ol>`,
  },
  {
    id: '6',
    title: 'What is a coach?',
    description: `<p class='s6a'> A coach is a trained professional who works with people to help them achieve specific personal or professional goals. They provide guidance, support, and strategies to enhance performance, develop skills, and overcome challenges.</p>`,
  },
  {
    id: '7',
    title: 'What is coaching?',
    description: `<p class='s6a'>The International Coaching Federation defines coaching as partnering with clients in a thought-provoking and creative process that inspires them to maximize their personal and professional potential. The process of coaching often unlocks previously untapped sources of imagination, productivity and leadership.</p>`,
  },
  {
    id: '8',
    title: 'What are the differences between coaching and therapy?',
    description: `<p class='s6a'>Coaching is action-oriented, focusing on achieving specific personal or professional goals. It’s forward-looking and designed to help individuals optimize their strengths and navigate transitions. Coaches are not licensed therapists and work with clients who are mentally healthy.</p>
    <p class='s6a'> Therapy is focused on emotional healing, often addressing past trauma, mental health issues, or emotional distress. Therapists are licensed professionals who diagnose and treat mental health conditions and provide deeper emotional support.</p>
    <p class='s6a'>While someone might be receiving coaching and therapy at the same time, the main distinction is that, coaching is about achieving goals, while therapy is about healing and mental health.</p> `,
  },
  {
    id: '9',
    title: 'What are the benefits of Coaching?',
    description: `
    <ul class='s6a'> 
      <li><b>Self-Awareness:</b> Gain insight into your strengths and areas for improvement, essential for personal growth.</li>
      <li><b>Goal Achievement:</b> Set realistic goals and receive support to create a clear path toward success.</li>
      <li><b>Enhanced Performance:</b> Improve skills in academics, career, or sports with strategies to excel.</li>
      <li><b>Confidence Boost:</b> Build self-confidence by overcoming challenges and reaching milestones.</li>
      <li><b>Better Decisions:</b> Get guidance to enhance decision-making and problem-solving skills.</li>
      <li><b>Stress Management:</b> Learn to handle stress and maintain a healthy work-life balance.</li>
      <li><b>Career Growth:</b> Improve leadership and communication skills for professional advancement.</li>
      <li><b>Motivation:</b> Stay inspired to push beyond your comfort zone and keep improving.</li>
      <li><b>Personalized Support:</b> Receive tailored coaching to meet your specific goals and needs.</li>
    </ul>`,
  },
  {
    id: '10',
    title: `Is my 1:1 session with my Coach confidential?`,
    description: `<p class='s6a'>Yes. Your Abroad Coach may take notes that are available to the Abroad Coaching Team, but the conversation with your coach will never be shared with Vanderbilt University.</p>`,
  },
  {
    id: '11',
    title: 'How will my information be protected?',
    description: `<p class='s6a'>Your information will never be shared with Vanderbilt University. However, if there is a quality concern someone from the Abroad team may review your session notes. From time to time Abroad may share aggregate assessment results. If this ever happens, the information will be included with all the other data and they would be in aggregate with no identifying information, so we can assure 100% confidentiality.</p>`,
  },
  {
    id: '12',
    title: 'What is a Coaching Circle?',
    description: `<p class='s6a'>Coaching Circles are 75-minute, coach-facilitated group sessions (10 people) designed to unlock latent potential and create cultures of leadership, well-being and belonging. Each Circle starts with a brief mindfulness exercise followed by 10 minutes of enriching content designed to foster your growth and development.</p>`,
  },
  {
    id: '13',
    title: 'What topics will be covered in the Coaching Circles?',
    description: `<p class='s6a'>The topics covered in the first circles will include:</p>
    <ul class='s6a'>
      <li>Growth Mindset & Self Compassion</li>
      <li>Making Friends with Fear & Anxiety</li>
      <li>Focus - How to Manage Distractions for Maximum Productivity</li>
      <li>System 1 Mind</li>
      <li>Burnout</li>
    </ul>`,
  },
  {
    id: '14',
    title: 'What if I have technical difficulties?',
    description: `<p class='s6a'>If you have any technical difficulties, please send an email to <a href='mailto:service@abroad.io'>service@abroad.io</a></p>`,
  },
  {
    id: '15',
    title: 'Who is the best person to go to if I have a question?',
    description: `<p class='s6a'>For general questions, reach out to <a href='mailto:voyage@vanderbilt.edu'>voyage@vanderbilt.edu</a>. For questions about the assessment reach out to service@abroad.io. If you need to reschedule a coaching session, contact your coach directly.</p>`,
  },
  {
    id: '16',
    title:
      'What if I can’t find a date for my coaching circle, or my Growth Plan Session?',
    description: `<p class='s6a'>Reach out to <a href='mailto:voyage@vanderbilt.edu'>voyage@vanderbilt.edu</a> and include <a href='mailto:service@abroad.io' >service@abroad.io</a> in CC. Together we’ll work to ensure you are able to find a time that works for you.</p>`,
  },
  {
    id: '17',
    title: 'What if I need to reschedule my 1:1 Growth Plan Session?',
    description: `<p class='s6a'>Contact your Abroad Coach directly to reschedule your 1:1 Growth Plan Session.</p>`,
  },
  {
    id: '18',
    title: 'What is the program’s cancellation policy?',
    description: `<p class='s6a'>Any 1:1 Growth Plan Sessions missed or cancelled with less than 24 hours notice will be counted as a session. If you miss your Coaching Circle, we will share the relevant content so that you can stay up to speed with the group.</p>`,
  },
  {
    id: '19',
    title: 'Which Abroad assessment should I take?',
    description: `<p class='s6a'>If you've worked at Vanderbilt University for less than 6 months, please take the Wisdom Assessment. If you've worked at Vanderbilt University for more than 6 months, please take the Awareness Assessment.</p>`,
  },
]
