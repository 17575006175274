import React, { useEffect, useState } from 'react'
import { SliderIconForNumberTabs } from '../assetsComponents'

const ArrowWrapper = ({
  children,
  parentRef,
  classProps,
  parentId,
  childId,
  shiftValueForPrevBtn = -50,
  shiftValueForNextBtn = +50
}) => {
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)
  const [showArrows, setShowArrows] = useState(false)
  const isLGScreen = window.innerWidth > 1080

  const tabsScoll = (shift) => {
    parentRef.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(
        parentRef.current.scrollWidth - parentRef.current.scrollLeft
      ) <= parentRef.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  useEffect(() => {
    const tabWidth = document?.getElementById(parentId)?.offsetWidth
    const navItemsWidth = document?.getElementById(childId)?.scrollWidth
    if (tabWidth > 0 && navItemsWidth > 0) {
      if (tabWidth < navItemsWidth) {
        setShowArrows(true)
      } else {
        setShowArrows(false)
      }
    }
  }, [childId, parentId, classProps])

  return (
    <React.Fragment>
      {showArrows && scrollX !== 0 && isLGScreen && (
        <SliderIconForNumberTabs
          scollHandler={() => tabsScoll(shiftValueForPrevBtn)}
          wrapperClasses='tab-button-prev cursor-pointer'
        />
      )}
      {children}
      {showArrows && !scrolEnd && isLGScreen && (
        <SliderIconForNumberTabs
          scollHandler={() => tabsScoll(shiftValueForNextBtn)}
          wrapperClasses='tab-button-next cursor-pointer'
        />
      )}
    </React.Fragment>
  )
}
export default ArrowWrapper
