import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Imgix from 'react-imgix'
import { ModuleMiniDetails } from '../breakthrough'
import { useResolutionService } from '../../hooks'

const QuestsCard = ({ item }) => {
  const { isMDScreen } = useResolutionService()
  const history = useHistory()
  const [expanded, setExpanded] = useState(false)

  const getDay = (dateString) => {
    const date = new Date(dateString)
    return date.getUTCDate()
  }

  const startDay = getDay(item.startDate)
  const endDay = getDay(item.endDate)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }
  const getDescription = () => {
    if (expanded) {
      return item.description
    } else {
      return item.description.length > 550
        ? item.description.slice(0, 550) + '...'
        : item.description
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          const questUrl = `/quests/${item._id}/public`
          history.push({
            pathname: questUrl,
            state: { month: item.month, year: item.year },
          })
        }}>
        <div className='row g-0 program-wrp cursor-pointer border-radius-5 p-32px custom-gray-bg-100 mt-24px'>
          <div className='col col-lg-4 col-12'>
            <div className='position-relative overflow-hidden border-radius-5'>
              <Imgix
                className='border-radius-5 img-cover w-100 program-img-zoom lazyload'
                imgixParams={{
                  fit: 'crop',
                  crop: 'edges',
                  ar: isMDScreen ? '3:1' : '4:5',
                }}
                src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${item._id}/${item.imageFileName}`}
                alt='image'
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
                htmlAttributes={{
                  src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${item._id}/${
                    item.imageFileName
                  }
                  ?ar=${
                    isMDScreen ? '3:1' : '4:5'
                  }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`,
                }}
              />

              <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
            </div>
          </div>
          <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
            <ModuleMiniDetails
              showDots={false}
              details={{
                label1: `${item.month}`,
                label2: `${startDay}-${endDay},`,
                label3: `${item.year}`,
              }}
            />
            <div className='s2 my-12px'>
              <span className='fst-italic'>{item.title}</span>
            </div>
            <pre
              onClick={(e) => {
                e.stopPropagation()
                toggleExpand()
              }}
              className={`s7 mb-0 pre`}
              dangerouslySetInnerHTML={{ __html: getDescription() }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestsCard
