import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Col, Container, Row } from 'react-bootstrap'
import {
  ABSpinner,
  ModalProvider,
  leaderAssessments,
  leaderAssessmentsUrl,
  useUserService,
  useErrorService,
  PrintableArea,
} from '@abroad/components'
import {
  TakeNewAssessmentBtn,
  AssessmentScoreCard,
  CategoryModal,
  DirectReports,
  EfficacySubCategoryTable,
  AwarenessGapAnalysis,
  CompareSubCategoryTable,
  // SortedSubCategoriesForLA,
  PreviousResults,
} from '../components'
import { GrayInfoCard, PageHeader } from '../components/common'
import { conditionMatched } from '../components/layout'
import LAEvent from '../constants/events'
import { getAssessmentCurrentData } from '../utils/date'
import API from '../utils/API'
import { ProgramContext } from '../utils/contexts'
import { useResolutionService } from '../hooks'
import {
  managerEfficacyMediaUrl,
  selfAwarenessPerceptionMediaUrl,
} from '../utils/constants'
const perceivedAwarenessKeyTitle = 'Perceived Awareness'
const managerEfficacyBGColor = '#84A07C'
const perceivedAwarenessBGColor = '#4978AB'
const categoryAudioEleId = 'category-audio'

const LAResults = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [leaderSurvey, setLeaderSurvey] = useState({})
  const [awarenessSurvey, setAwarenessSurvey] = useState()
  const [selfAwarenessKeyTitle, setSelfAwarenessKeyTitle] = useState('')
  const [managerEfficacyKeyTitle, setManagerEfficacyKeyTitle] = useState('')
  const [awarenessSurveyTitlePercent, setAwarenessSurveyTitlePercent] =
    useState({})
  const [comparisonChartTitleArray, setComparisonChartTitleArrray] = useState(
    [],
  )
  const [perceivedArray, setPerceivedArray] = useState([])
  const [selfAwarenessArray, setSelfAwarenessArray] = useState([])
  const [comparisonArray, setComparisonArray] = useState([])
  const [canCreateNewLAReq, setCanCreateNewLAReq] = useState(false)
  const [mediaUrl, setMediaUrl] = useState(null)
  const [loadedAudioId, setLoadedAudioId] = useState(null)
  const { showEnrollBtn } = useContext(ProgramContext)
  const { id } = useParams()
  const { isLGScreen, isXSScreen } = useResolutionService()
  const { categories } = leaderSurvey
  const { user } = useUserService()
  const history = useHistory()
  const Error = useErrorService()
  const userId = user?._id

  const getValueByIdFromLeaderSurvey = (id, queryKey) => {
    return leaderSurvey?.results[id][queryKey]
  }

  const getSubCategoryValueByKey = (subcategories, key, queryKey) => {
    return subcategories[key][queryKey]
  }

  const getCategoryName = (id) => {
    return categories?.find((e) => e._id === id)?.title
  }

  const getCategoryDescription = (id) => {
    return categories?.find((e) => e._id === id)?.description
  }

  const getComparisonChartTitleArray = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order',
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })

    const mainArr = []
    const selfAwarenessSubcategories =
      awarenessSurvey?.results?.self_awareness?.subcategories

    keyArray.forEach((keyProp) => {
      mainArr.push(selfAwarenessSubcategories[keyProp]?.title)
    })
    setComparisonChartTitleArrray(mainArr)
  }

  const getPerceivedArrayForComparison = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order',
    )
    const perceivedSubcategories = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'subcategories',
    )

    const resultArr = idsArray.map(
      (idProp) => perceivedSubcategories[idProp]?.percent,
    )

    setPerceivedArray(resultArr)
  }

  const getSelfAwarenessArrayForComparison = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order',
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })
    const { subcategories } = awarenessSurvey?.results?.self_awareness
    const resultArray = keyArray.map((key) => subcategories[key]?.percent)
    setSelfAwarenessArray(resultArray)
  }

  const getComparisonArray = () => {
    const idsArray = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'order',
    )
    const keyArray = []
    idsArray.forEach((id) => {
      keyArray.push(categories?.find((e) => e?._id === id)?.key)
    })

    const mainArr = []
    const selfAwarenessSubcategories =
      awarenessSurvey?.results?.self_awareness?.subcategories
    const perceivedSubcategories = getValueByIdFromLeaderSurvey(
      '5b46b176ce416b175ff98e17',
      'subcategories',
    )

    keyArray.forEach((keyProp) => {
      mainArr.push({
        id: categories?.find((e) => e?.key === keyProp)?._id,
        title: selfAwarenessSubcategories[keyProp]?.title,
        selfAwarenessValue: selfAwarenessSubcategories[keyProp]?.percent,
        perceivedValue:
          perceivedSubcategories[
            categories?.find((c) => c?.key === keyProp)?._id
          ]?.percent,
      })
    })
    setComparisonArray(mainArr)
  }

  useEffect(() => {
    const getLeaderResult = async () => {
      setIsLoading(true)
      try {
        // Leader Assessment is ready notification isRead API
        await API.user.hasNotificationRead({
          type: 5,
          uniqueId: id,
        })
        const { data } = await API.survey.getLeaderAssessmentResult(
          user?.id,
          id,
        )
        if (data) {
          setLeaderSurvey(data)
          setAwarenessSurvey(data?.latestAwarenessSurveyResult)
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        if (e?.code === 'not_taken_survey' || e?.code === 'forbidden') {
          history.replace('/home')
        }
        setIsLoading(false)
      }
    }
    if (id) getLeaderResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const gtmUserSeeLAResultAction = () => {
      //GTM code
      TagManager.dataLayer({
        dataLayer: {
          event: LAEvent.event.laResult,
          eventProps: {
            category: LAEvent.category.laResultCategory,
            action: 'User see leader assessment result',
            label: 'See leader assessment result',
            value: `User see their leader assessment result with surveyId - ${id}.`,
            userId,
          },
        },
      })
    }
    gtmUserSeeLAResultAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (awarenessSurvey) {
      getComparisonChartTitleArray()
      getPerceivedArrayForComparison()
      getSelfAwarenessArrayForComparison()
      getComparisonArray()
      setSelfAwarenessKeyTitle(
        categories?.find((e) => e._id === '5b46b176ce416b175ff98e17')?.title,
      )
      setManagerEfficacyKeyTitle(
        categories?.find((e) => e._id === '62963961a279e16eeb3aa867')?.title,
      )
      setAwarenessSurveyTitlePercent({
        title: awarenessSurvey?.results?.self_awareness?.title,
        percent: awarenessSurvey?.results?.self_awareness?.percent,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awarenessSurvey])

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${leaderAssessments} Result | Abroad`}</title>
        <meta
          name='title'
          content={`${leaderAssessments} Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${leaderAssessments} Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${leaderAssessments} Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/${id}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/${id}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/requests/${leaderAssessmentsUrl}/${id}`}></meta>
      </Helmet>
    )
  }

  const gtmLearnMoreBtnClickedAction = (categoryInfo) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LAEvent.event.laLearnMoreBtn,
        eventProps: {
          category: LAEvent.category.laLearnMoreBtnCategory,
          action: 'User open learn more modal',
          label: 'Open learn more modal on result page',
          value: `User open learn more modal, category is ${categoryInfo?.title}.`,
          userId,
        },
      },
    })
  }

  const renderPageHeader = () => (
    <PageHeader
      titleFn={() => {
        return (
          <h3 className='mb-0 s1'>
            {leaderAssessments} -{' '}
            <br
              className={showEnrollBtn && isLGScreen ? 'd-block' : 'd-none'}
            />
            {/* getAssessmentCurrentData(dateValue, format) */}
            {getAssessmentCurrentData(
              leaderSurvey?.results?.createdAt,
              'MMMM Do, YYYY',
            )}
          </h3>
        )
      }}
    />
  )

  const gtmTakeLeaderAssessment = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LAEvent.event.takeLeaderAssessment,
        eventProps: {
          category: LAEvent.category.takeLeaderAssessmentCategory,
          action: `User click on 'New Leader Assessment' button`,
          label: `'Click on 'New Leader Assessment' button on leader result page`,
          value: `User click on 'New Leader Assessment' button on leader result page`,
          userId,
        },
      },
    })
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      {renderHelmet()}
      <main>
        {renderPageHeader()}
        <section className='mt-32px'>
          <TakeNewAssessmentBtn
            text='New Leader Assessment'
            onClick={() => {
              gtmTakeLeaderAssessment()
              history.push(`/survey/requests/${leaderAssessmentsUrl}/new`)
            }}
            btnClasses='mb-32px'
            isBtnDisabled={!canCreateNewLAReq}
          />
          <Container fluid className='px-0'>
            <ModalProvider>
              <Row className='mb-52px d-flex justify-content-center align-items-center'>
                <div className='d-flex flex-lg-row flex-column'>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className='text-center d-lg-flex justify-content-lg-center'>
                    <AssessmentScoreCard
                      title={managerEfficacyKeyTitle}
                      linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
                      category={categories?.find(
                        (e) => e._id === '62963961a279e16eeb3aa867',
                      )}
                      onLearnMoreClick={() => {
                        gtmLearnMoreBtnClickedAction(
                          categories?.find(
                            (e) => e._id === '62963961a279e16eeb3aa867',
                          ),
                        )
                      }}
                      percentage={getValueByIdFromLeaderSurvey(
                        '62963961a279e16eeb3aa867',
                        'percent',
                      )}
                      backgroudColor={managerEfficacyBGColor}
                      wrapperClasses='mr-lg-36px'
                      outerBackgroudColor='#E0E7DE'
                      innerBackgroudColor='#84A07C'
                      width={
                        conditionMatched || isXSScreen ? '14rem' : '19.5rem'
                      }
                      height='14.745rem'
                      titlePosition='top'
                    />
                    <AssessmentScoreCard
                      title={perceivedAwarenessKeyTitle}
                      linkClasses='s11 mt-4 mb-0 text-dark-900-5 cursor-pointer'
                      category={categories?.find(
                        (e) => e._id === '5b46b176ce416b175ff98e17',
                      )}
                      onLearnMoreClick={() => {
                        gtmLearnMoreBtnClickedAction(
                          categories?.find(
                            (e) => e._id === '5b46b176ce416b175ff98e17',
                          ),
                        )
                      }}
                      percentage={getValueByIdFromLeaderSurvey(
                        '5b46b176ce416b175ff98e17',
                        'percent',
                      )}
                      backgroudColor={perceivedAwarenessBGColor}
                      wrapperClasses='ml-lg-36px mt-lg-0 mt-4'
                      outerBackgroudColor='rgba(73, 120, 171, 0.25)'
                      innerBackgroudColor='#4978AB'
                      width={
                        conditionMatched || isXSScreen ? '14rem' : '19.5rem'
                      }
                      height='14.745rem'
                      titlePosition='top'
                    />
                  </Col>
                </div>
              </Row>
              <DirectReports
                totalRequestsCompletedCount={
                  leaderSurvey?.totalRequestsCompletedCount
                }
                totalRequestsCount={leaderSurvey?.totalRequestsCount}
                workingRelationshipPercentage={
                  leaderSurvey?.results?.workingRelationshipPercentage
                }
                personalRelationshipPercentage={
                  leaderSurvey?.results?.personalRelationshipPercentage
                }
                requestedUsers={leaderSurvey?.requestedUsers}
              />
              <GrayInfoCard
                className='mb-52px py-4 assessment-info-box'
                titleFn={() => {
                  return (
                    <p className='text-center mb-0 s6a font-weight-500'>
                      To better understand your results, we recommend listening
                      to the audios that describes the context of each category.
                      For further information on each subcategory, simply click
                      on the corresponding info icons.
                    </p>
                  )
                }}
              />
              <audio id={categoryAudioEleId} allow='autoplay'>
                <source src={mediaUrl} />
                Your browser does not support the <code>audio</code> element.
              </audio>
              <EfficacySubCategoryTable
                mediaUrl={managerEfficacyMediaUrl}
                managerEfficacyKeyTitle={managerEfficacyKeyTitle}
                categories={categories}
                clickOnLearnMoreBtn={gtmLearnMoreBtnClickedAction}
                getValueByIdFromLeaderSurvey={getValueByIdFromLeaderSurvey}
                managerEfficacyBGColor={managerEfficacyBGColor}
                getCategoryName={getCategoryName}
                getSubCategoryValueByKey={getSubCategoryValueByKey}
                getCategoryDescription={getCategoryDescription}
                categoryKeyName='manager_efficacy'
                setMediaUrl={setMediaUrl}
                loadedAudioId={loadedAudioId}
                setLoadedAudioId={setLoadedAudioId}
                pauseOthers
              />
              <AwarenessGapAnalysis
                mediaUrl={selfAwarenessPerceptionMediaUrl}
                selfAwarenessKeyTitle={selfAwarenessKeyTitle}
                getValueByIdFromLeaderSurvey={getValueByIdFromLeaderSurvey}
                awarenessSurveyTitlePercent={awarenessSurveyTitlePercent}
                comparisonChartTitleArray={comparisonChartTitleArray}
                perceivedArray={perceivedArray}
                selfAwarenessArray={selfAwarenessArray}
                setAwarenessSurvey={setAwarenessSurvey}
                categoryKeyName='perceived_self_awareness'
                setMediaUrl={setMediaUrl}
                loadedAudioId={loadedAudioId}
                setLoadedAudioId={setLoadedAudioId}
                pauseOthers
              />
              <CompareSubCategoryTable
                comparisonArray={comparisonArray}
                getCategoryDescription={getCategoryDescription}
              />
              {/* <SortedSubCategoriesForLA
                results={leaderSurvey?.results}
                getCategoryName={getCategoryName}
                getCategoryDescription={getCategoryDescription}
              /> */}
              <PreviousResults setCanCreateNewLAReq={setCanCreateNewLAReq} />
              <CategoryModal />
            </ModalProvider>
          </Container>
        </section>
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default LAResults
