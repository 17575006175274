import React from 'react'
import { FeatherLoader } from '@abroad/components'
import classNames from 'classnames'

const ModuleActions = ({
  className = 'font-24 mb-4',
  isFavorite = false,
  toggleIsFavorite = () => {},
  rightMostIcon = '',
  handleRightMostIconClick = () => {},
  isFavoriteLoading = false,
  playIconLoading = false,
  // showInfoIcon = false,
  // showCheckmarkIcon = false,
}) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-lg-end mt-lg-0 mt-2',
        className,
      )}>
      <span
        onClick={toggleIsFavorite}
        className={`icon icon-bookmark${
          isFavorite ? '-gold' : ' hover:text-saffron-700'
        } text-black cursor-pointer align-self-center`}
      />
      {isFavoriteLoading && (
        <span className='ms-3'>
          <FeatherLoader />
        </span>
      )}
      {/* todo */}
      {/* {playIconLoading && (
        <span className='ms-auto'>
          <FeatherLoader />
        </span>
      )} */}
      {rightMostIcon && (
        <span
          className={`icon ${rightMostIcon} font-saffron-700 cursor-pointer align-self-center ml-20px`}
          onClick={handleRightMostIconClick}
        />
      )}
    </div>
  )
}

export default ModuleActions
