import React, { useContext, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { programIds, coachRecommendsUrl } from '@abroad/components'
import Media from './Media'
import { BreakThroughBanner } from '../components'
import { LayoutContext } from '../utils/contexts'
import { getProgramUrlByParentId } from '../utils/utility'
import mediaLibraryConstants from '../constants/mediaLibrary'

const MediaInfo = () => {
  const [bannerImage, setBannerImage] = useState(null)
  const [category, setCategory] = useState(null)
  let location = useRouteMatch('/media/:mediaCategory/:categoryId/:mediaId')
  const mediaCategory = location?.params?.mediaCategory
  const categoryId = location?.params?.categoryId
  const { isJourneyModuleAudio, journeyAudioType } = useContext(LayoutContext)
  let history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const urlJourneyId = query.get('journeyId')
  const urlJourneyType = query.get('journeyType')
  const urlJourneyGroupId = query.get('journeyGroupId')
  const programUrl = getProgramUrlByParentId(category)

  const handleClosePage = (journeyId, sequenceId) => {
    if (
      mediaCategory === 'program' ||
      (mediaCategory === 'category' &&
        Object.values(programIds).includes(category))
    ) {
      if (journeyId && isJourneyModuleAudio === false) {
        history.push(`/${coachRecommendsUrl}#${getJourneyTypePath()}`)
        return
      }
      let url = `/media/${categoryId}`
      if (journeyId) {
        url += `?journeyId=${journeyId}&sequenceId=${sequenceId}&journeyType=${
          journeyAudioType || urlJourneyType
        }`
        if (urlJourneyGroupId) {
          url += `&journeyGroupId=${urlJourneyGroupId}`
        }
      }
      history.push(url)
    } else if (mediaCategory === 'category') {
      if (journeyId) {
        history.push(`/${coachRecommendsUrl}#${getJourneyTypePath()}`)
        return
      }
      const mainCategory = mediaLibraryConstants.mediaCategories.find(
        (cat) => cat.id === category,
      )
      if (mainCategory?.to) {
        history.push(`${mainCategory.to}`)
      }
    } else if (urlJourneyId) {
      history.push(`/${coachRecommendsUrl}#${getJourneyTypePath()}`)
    } else {
      history.push(programUrl)
    }
  }

  const getJourneyTypePath = () => {
    const type = journeyAudioType || urlJourneyType
    let path
    switch (type) {
      case 1:
      case '1':
        path = 'individual'
        break
      case 2:
      case '2':
        path = 'organization'
        break
      case 3:
      case '3':
        path = 'group'
        break
      default:
        break
    }
    return path
  }

  return (
    <main>
      <section className='breakthrough-container vw-100 overflow-hidden'>
        <div className='position-relative h-100 w-100'>
          <BreakThroughBanner bannerImage={bannerImage} />
          <Container
            fluid
            className='position-absolute p-0 inset-0 text-white image-overlay'>
            <div className='position-relative w-100 h-100 d-flex flex-column'>
              <Media
                setBannerImage={(img) => setBannerImage(img)}
                setCategory={(category) => setCategory(category)}
                handleClosePage={handleClosePage}
              />
            </div>
          </Container>
        </div>
      </section>
    </main>
  )
}

export default MediaInfo
