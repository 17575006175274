import React from 'react'
import classNames from 'classnames'

const JournalIcon = ({
  active = false,
  className,
  stokeColor = 'black',
  ...rest
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      className={classNames(
        'align-self-center cursor-pointer',
        {
          active: active,
        },
        className,
      )}
      {...rest}>
      <path
        d='M2.4 0.5H13.6C14.668 0.5 15.5 1.32763 15.5 2.30769V17.6923C15.5 18.6724 14.668 19.5 13.6 19.5H2.4C1.33199 19.5 0.5 18.6724 0.5 17.6923V2.30769C0.5 1.32763 1.33199 0.5 2.4 0.5Z'
        fill={active ? '#BF9000' : 'none'}
        stroke={active ? '#BF9000' : stokeColor}
        strokeLinejoin='round'
      />
      <path
        d='M11.2031 0V20'
        strokeLinejoin='round'
        className='journel-line'
        stroke={active ? 'white' : stokeColor}
      />
    </svg>
  )
}

export default JournalIcon
