import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const RightArrowIcon = React.forwardRef(
  (
    { className, bgColor, style, bgOpacity, removeSwiperClass, ...rest },
    ref
  ) => {
    return (
      <svg
        ref={ref}
        className={classnames(
          {
            'swiper-button-next': !removeSwiperClass
          },
          className
        )}
        width='16'
        height='23'
        viewBox='0 0 16 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={style}
        {...rest}
      >
        <path
          d='M2 21L14 11.2937'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          d='M2 1.5867L14 11.293'
          stroke='black'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </svg>
    )
  }
)

RightArrowIcon.propTypes = {
  removeSwiperClass: PropTypes.bool
}

RightArrowIcon.defaultProps = {
  removeSwiperClass: false
}

export default RightArrowIcon
