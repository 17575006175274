const gtm = {
  event: {
    resilienceModule: 'resilience_like_dislike',
    media: 'media_like_dislike',
    login: 'login',
    signUp: 'signup',
    audioPlayer: 'audio_controls',
    TakeAssessment: 'take_assessment',
    AddNewInsight: 'add_new_insight',
    AddNewIntention: 'add_new_intention',
    singleMediaOfMudule: 'module_audio',
    journalDataSave: 'save_journal',
    help: 'submit_question',
    profile: 'profile_update',
    resetPassword: 'reset_password',
    resendEmail: 'resend_email',
    updatePassword: 'update_password',
    compareAssessment: 'compare_assessment',
    nextModule: 'next_module',
    learnMore: 'learn_more',
    cookie: 'cookie_accept',
    payment: 'plan_upgrade',
    applyPromoCode: 'apply_promocode',
    ListenPSPAudio: 'listen_session_practices_audio',
    deleteIntention: 'delete_intention',
    notifications: 'notifications',
    unreadNotifications: 'read_particular_notification',
    laResult: 'la_result',
    selectPendingLA: 'select_pending_la',
    submitLARequest: 'submit_la_request',
    submitManagerRequest: 'submit_direct_manager_feedback_request',
    sessionDetails: 'session_details',
    sessionKeySkills: 'session_key_skills',
    userJourneysAudio: 'coach_recommends_audio',
    //orgJourneysAudio: 'org_journeys_audio',
    groupJourneysAudio: 'group_coach_recommends_audio',
    practicalOrPoeticAudio: 'practical_or_poetic_audio',
    keySkills: 'key_skills',
    moduleAudio: 'module_audio_like_dislike',
    favoritedAudioDislike: 'favorited_audio_dislike',
    surveyCategoryDetails: 'survey_category_details',
    laLearnMoreBtn: 'la_learn_more',
    toggleModuleFavorite: 'module_like_dislike',
    openAssessmentTypeform: 'open_assessment_typeform',
    upgradeButton: 'upgrade_button_clicked',
    openContinueProgram: 'open_continue_program',
    openProgram: 'open_program',
    playMediaLibraryAudio: 'play_medialibrary_audio',
    playFavoritedAudio: 'play_favorited_audio',
    toggleSessionMediaBookmark: 'toggle_session_media_bookmark',
    toggleCoachRecommendsMediaBookmark: 'toggle_journeys_media_bookmark',
    restoreIntention: 'restore_intention',
    addIntention: 'add_intention',
    archiveIntention: 'archive_intention',
    takeLeaderAssessment: 'take_leader_assessment',
    openLARequestsSentUserModal: 'open_la_request_sent_user_modal',
    playJournalMedia: 'play_journal_media',
    programEnrollBtnClicked: 'program_enroll_btn_clicked',
    togglePlayBarSize: 'toggle_playbar_size',
    openMediaDescription: 'open_media_description',
  },
  category: {
    breakthroughModuleCategory: 'Breakthrough Module',
    resilienceModuleCategory: 'Resilience Module',
    mediaCategory: 'User like/dislike media',
    loginCategory: 'User Sign In successfully',
    signUpCategory: 'User Sign Up successfully',
    audioPlayerCategory: 'audioPlayer tracking',
    UserTakeAssessmentCategory: 'Take assessment by user',
    AddNewInsightCategory: 'Add new insight',
    AddNewIntentionCategory: 'Add new intention',
    singleMediaOfMuduleCategory: 'Module audio listen',
    journalDataSaveCategory: 'User save journal',
    helpCategory: 'submit User their Question',
    profileCategory: 'User update profile',
    resetPasswordCategory: 'User reset password successfully',
    resendEmailCategory: 'User click on resend email button',
    updatePasswordCategory: 'User update password successfully',
    compareAssessmentCategory: 'User compare two assessment',
    nextModuleCategory: 'User go to next module',
    learnMoreCategory: 'User click on learnMore botton',
    cookieCategory: 'User Accept Cookie',
    paymentCategory: 'User update plan',
    applyPromoCodeCategory: 'User apply promocode',
    ListenPSPAudioCategory: 'User listen attached audio',
    deleteIntentionCategory: 'User delete Intention',
    notificationsCategory: 'Click on notification icon',
    unreadNotificationsCategory: 'Read particular notification',
    laResultCategory: 'See leader assessment result',
    selectPendingLACategory: 'Select pending leader assessment',
    submitLARequestCategory: 'Send leader assessment request',
    submitManagerRequestCategory: 'Send direct manager feedback request',
    sessionDetailsCategory: 'Open Session Details',
    sessionKeySkillsCategory: 'Download recommended key skills',
    userJourneysAudioCategory: 'Play individual coach recommends audio',
    // orgJourneysAudioCategory: 'Play organization journeys audio',
    groupJourneysAudioCategory: 'Play group coach recommends audio',
    practicalOrPoeticAudioCategory: 'Play practical or poetic audio',
    keySkillsCategory: 'Download key skills',
    moduleAudioCategory: 'Module audio like/dislike',
    favoritedAudioDislikeCategory: 'Favorited audio dislike',
    surveyCategoryDetailsCategory: 'Open survey category details',
    laLearnMoreBtnCategory: 'Open learn more modal',
    openAssessmentTypeformCategory: 'User open assessment typeform',
    upgradeButtonCategory: 'Click on upgrade button',
    openContinueProgramCategory: 'User open continue program from home page',
    openProgramCategory: 'Open program from programs page',
    playMediaLibraryAudioCategory: 'Play medialibrary audio',
    playFavoritedAudioCategory: 'Play favorited audio from favorite page',
    toggleSessionMediaBookmarkCategory:
      'Toggle session media bookmark on sessions page',
    toggleCoachRecommendsMediaBookmarkCategory:
      'Toggle journeys media bookmark on coach recommends page',
    restoreIntentionCategory: 'Restore intention in growthPlan page',
    addIntentionCategory: 'Add intention in growthPlan page',
    archiveIntentionCategory: 'Archive Intention in growthPlan page',
    takeLeaderAssessmentCategory: `Click on 'New Leader Assessment' button`,
    openLARequestsSentUserModalCategory:
      'Open leader assessment requests sent user modal',
    playJournalMediaCategory: 'Play journal media',
    programEnrollBtnClickedCategory: `User clicked 'ENROLL IN YOUR PROGRAM' button`,
    togglePlayBarSizeCategory: 'User toggle playbar size',
    openMediaDescriptionCategory: 'Open media description from media player',
  },
}
export default gtm
