import React, { useEffect, useState } from 'react'
import { Button, useUserService } from '@abroad/components'
import { useParams, useHistory } from 'react-router-dom'
import API from '../../utils/API'
import TagManager from 'react-gtm-module'
import NextModuleEvent from '../../constants/events'

const NextModuleBtn = ({ activeAudioFromSamePage }) => {
  const [audio, setAudio] = useState(null)
  let { categoryId } = useParams()
  const [nextModuleURL, setNextModuleURL] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const [isDisabled, setIsDisabled] = useState(true)
  const [hideNextButton, setHideNextButton] = useState(false)
  const { user } = useUserService()
  const userId = user?.id

  useEffect(() => {
    const getNextModule = async () => {
      try {
        const { data } = await API.programs.getNextModule(categoryId)
        setIsLoading(false)
        setNextModuleURL(`/media/${data.id}`)
      } catch (e) {
        if (e?.code === 'last_module') {
          setHideNextButton(true)
        }
        setIsLoading(false)
      }
    }
    getNextModule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (activeAudioFromSamePage) {
      const audioEl = document.getElementById('audio')
      setAudio(audioEl)
    }
  }, [activeAudioFromSamePage])

  useEffect(() => {
    const setAudioTime = (event) => {
      if (event.target.currentTime > (event.target.duration * 90) / 100) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
    if (audio) {
      audio.addEventListener('timeupdate', setAudioTime)
    }
    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', setAudioTime)
      }
    }
  }, [audio])

  const nextModule = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: NextModuleEvent.event.nextModule,
        eventProps: {
          category: NextModuleEvent.category.nextModuleCategory,
          action: 'User go to next module',
          label: 'go to next module',
          value: `User go to next module with categoryId - ${categoryId} in Breakthrough page`,
          userId,
        },
      },
    })
    history.push(nextModuleURL)
  }

  if (hideNextButton) return null

  return (
    <Button
      onClick={nextModule}
      isLoading={isLoading}
      disabled={isDisabled}
      size='lg'
      variant='module'
      className='font-18 font-medium'>
      Next Module
    </Button>
  )
}

export default NextModuleBtn
