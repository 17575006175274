import React from 'react'
import { Image } from 'react-bootstrap'

const FeatherLoader = (props) => {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Image
        src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
        {...props}
      />
    </div>
  )
}

FeatherLoader.defaultProps = {
  width: 20
}

export default FeatherLoader
