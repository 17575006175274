export const isLGScreen = window.innerWidth > 1080
export const isSMScreen = window.innerWidth > 576 && window.innerWidth < 768
export const isMDScreen = window.innerWidth > 768 && window.innerWidth < 1080

export const readMore = (string, maxWords, forceDots = false) => {
  if (!string) {
    return string
  }
  const strippedString = string.trim()
  const array = strippedString.split(' ')
  const wordCount = array.length
  let resultString = array.splice(0, maxWords).join(' ')
  if (resultString.length > 22) {
    resultString = resultString.substr(0, 21)
  }
  if (wordCount > maxWords || forceDots) {
    resultString += '...'
  }

  return resultString
}

export const nameRegex = /^\s*[a-zA-Z]+( [A-Za-z]+)?\s*$/
