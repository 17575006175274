import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUserService } from '@abroad/components'

const PublicRoute = ({ children, component: Component, render, ...rest }) => {
  const { user } = useUserService()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (render) {
          render(props)
          return
        }
        if (user) {
          return <Redirect to={'/home'} />
        } else {
          return children || <Component {...props} />
        }
      }}
    />
  )
}

export default PublicRoute
