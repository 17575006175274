import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { SignupHeaderIcon } from '@abroad/components'
import { InviteDetailsContext } from '../../utils/contexts'

const AbroadGoldenIcon = () => {
  const { inviteLinkDetails } = useContext(InviteDetailsContext)
  const { pathname } = useLocation()
  const isNotProduction = process.env.REACT_APP_ENV !== 'prod'
  const isMatchPage = [
    '/account',
    '/verify_email',
    '/verify-email-sent',
  ].includes(pathname)

  return (
    <div className='d-flex align-items-center justify-content-center abroad-logo'>
      <Link to={'/'}>
        {inviteLinkDetails?.logoFileName && isMatchPage ? (
          <Image
            src={`https://${
              isNotProduction ? 'abroad-public-dev' : 'abroad-public'
            }.s3.us-east-2.amazonaws.com/${inviteLinkDetails?.type}/${
              inviteLinkDetails?._id
            }/${inviteLinkDetails?.logoFileName}`}
            style={{
              height: 87 / window.devicePixelRatio,
              width: 'auto',
            }}
            alt='header logo'
            className='cursor-pointer signup-logo'
          />
        ) : (
          <SignupHeaderIcon alt='header logo' />
        )}
      </Link>
    </div>
  )
}

export default AbroadGoldenIcon
