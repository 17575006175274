import React, { useEffect, useState } from 'react'
import { useErrorService } from '@abroad/components'
import API from '../../../utils/API'

export const NotificationCountContext = React.createContext({
  count: null,
  isReadHandler: () => {},
})

const NotificationProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(null)
  const Error = useErrorService()

  const isReadHandler = () => {
    if (notificationCount === null || notificationCount === 0) return
    setNotificationCount((prev) => (prev === 1 ? null : prev - 1))
  }

  const getConvertedCount = (count) => {
    if (count === 0) return null
    return count
  }

  useEffect(() => {
    const getNotificationCount = async () => {
      try {
        const { data } = await API.user.getUserNotificationCount()
        if (data) {
          const count = data?.count || 0
          const finalCount = getConvertedCount(count)
          setNotificationCount(finalCount)
        }
      } catch (e) {
        Error.showError(e)
        setNotificationCount(null)
      }
    }
    getNotificationCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NotificationCountContext.Provider
      value={{ count: notificationCount, isReadHandler }}>
      {children}
    </NotificationCountContext.Provider>
  )
}

export default NotificationProvider
