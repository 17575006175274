const plans = {
  free: {
    name: 'Awareness',
    code: 'free',
    sequence: 0,
    license: 1,
    price: 0.0,
    duration: 7300 // in days
  },
  growth: {
    name: 'Growth',
    code: 'growth',
    sequence: 1,
    license: 1,
    price: 500.0,
    duration: 365 + 28
  },
  coaching: {
    name: 'Coaching',
    code: 'coaching',
    sequence: 2,
    license: 1,
    price: 2500.0,
    duration: 365 + 28
  }
}

export default plans
