import React from 'react'
import { FeatherLoader, useUserService } from '@abroad/components'
import TableBodyCell from './TableBodyCell'
import { tableConst } from './table-constant'
import { useStartMediaPlayer } from '../media'
import { gtmPlayFavoriteAudioAction } from '../favorite'

/**
 * Table body component
 *
 * @param {*} getTableBodyProps               // react table body props
 * @param {*} rows                            // react table body props
 * @param {*} prepareRow                      // react table body props
 * @param {Function} renderRow                // React renderer prop to show row data
 */
const TableBody = React.memo(
  ({
    getTableBodyProps,
    columns,
    isLoading,
    rows,
    emptyTableMessage,
    prepareRow,
    renderRow,
    totalNumberOfColumns,
    otherOption,
    bookmarkHover,
    trClasses,
  }) => {
    const { setMediaParams } = useStartMediaPlayer()
    const { user } = useUserService()
    return (
      <tbody {...getTableBodyProps()}>
        {rows.length === 0 && !isLoading && (
          <tr key={-2}>
            <td
              colSpan={
                totalNumberOfColumns ? totalNumberOfColumns : columns.length
              }
              className='text-center'>
              {emptyTableMessage}
            </td>
          </tr>
        )}
        {
          // Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr
                key={index}
                {...row.getRowProps()}
                {...(otherOption && {
                  onClick: () => {
                    if (otherOption === tableConst.FAVORITE_PAGE) {
                      if (bookmarkHover) return

                      // play media or module
                      setMediaParams({
                        mediaId: row?.values?.title?._id,
                        mediaSubCategoryId:
                          row?.values?.title?.subCategoryId?._id,
                        isProgramMedia: row?.values?.title?.isProgram,
                      })

                      // GTM function
                      gtmPlayFavoriteAudioAction(
                        row?.values?.title?._id,
                        row?.values?.title?.subCategoryId?._id,
                        row?.values?.title?.isProgram,
                        user?._id,
                      ) // (mediaId, subCategoryId, isProgramMedia,userId)
                    }
                  },
                })}
                className={trClasses}>
                {
                  // Loop over the rows cells
                  renderRow ? (
                    renderRow(row.cells)
                  ) : (
                    <>
                      {row.cells.map((cell, i) => (
                        <TableBodyCell key={i} cell={cell} index={i} />
                      ))}
                    </>
                  )
                }
              </tr>
            )
          })
        }
        {isLoading && (
          <tr key={-1}>
            <td
              colSpan={
                totalNumberOfColumns ? totalNumberOfColumns : columns.length
              }
              className='text-center'>
              <FeatherLoader />
            </td>
          </tr>
        )}
      </tbody>
    )
  },
)

export default TableBody
