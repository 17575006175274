import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { FeatherLoader } from '@abroad/components'
import { LayoutContext } from '../../utils/contexts'

const MediaPlayPauseIcon = ({ className, isLoading, showPlay, ...rest }) => {
  const [goldColorVisible, setGoldColorVisible] = useState(false)
  const { minimizePlayBar } = useContext(LayoutContext)
  const fillColor =
    minimizePlayBar && goldColorVisible
      ? '#efc030'
      : minimizePlayBar && !goldColorVisible
      ? '#fff'
      : goldColorVisible
      ? '#efc030'
      : '#000'
  if (isLoading) {
    return (
      <div className='position-relative'>
        <div className='media-player-loader-animation' />
        <div className='d-flex align-items-center justify-content-center media-player-loader'>
          <FeatherLoader />
        </div>
      </div>
    )
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      className={classnames('cursor-pointer', className)}
      onMouseOver={(e) => {
        setGoldColorVisible(true)
      }}
      onMouseEnter={(e) => {
        setGoldColorVisible(true)
      }}
      onMouseLeave={(e) => {
        setGoldColorVisible(false)
      }}
      {...rest}>
      {!minimizePlayBar && <circle cx='24' cy='24' r='24' fill='white' />}
      {showPlay ? (
        <path
          d='M33.625 23.7835C33.7917 23.8797 33.7917 24.1203 33.625 24.2165L19.375 32.4437C19.2083 32.54 19 32.4197 19 32.2272L19 15.7728C19 15.5803 19.2083 15.46 19.375 15.5563L33.625 23.7835Z'
          fill={fillColor}
        />
      ) : (
        <>
          <rect x='18' y='16' width='3' height='16' rx='0.5' fill={fillColor} />
          <rect x='27' y='16' width='3' height='16' rx='0.5' fill={fillColor} />
        </>
      )}
    </svg>
  )
}

export default MediaPlayPauseIcon
