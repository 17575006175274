import React, { useState } from 'react'
import classnames from 'classnames'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import { EyeIcon } from '../assetsComponents'
import { isLGScreen } from '../../utils/utility'
import { labels, placeholders } from '../../constants/form'

const InputField = React.forwardRef(
  (
    {
      formGroupProps: {
        className: formGroupClassName,
        ...otherFormGroupProps
      } = {},
      children,
      id,
      label,
      labelProps,
      isHorizontalFormControl = false,
      renderChildren,
      size,
      className,
      showTooltip,
      customErrorMessage,
      showErrorMessage,
      type,
      placeholder,
      removeFormikField,
      onFocus,
      isSubmitting = false,
      isCustomValidating = false,
      ...rest
    },
    ref,
  ) => {
    const formText = placeholders
    const formLabel = labels
    const [field, meta, helpers] = useField({ ...rest, ...{ type: type } })
    const [showPassword, setShowPassword] = useState(false)
    const [renderPasswordShowIcon, setRenderPasswordShowIcon] = useState(false)

    const renderErrorMessages = (textColorClass = 'text-error') => {
      if (!showErrorMessage) {
        return null
      }
      if (customErrorMessage) {
        return customErrorMessage
      }
      return (
        <>
          {customErrorMessage ? (
            <Form.Text className='text-muted font-montserrat font-semibold font-12'>
              {customErrorMessage}
            </Form.Text>
          ) : (
            <>
              {meta.error && (
                <Form.Text className='text-muted font-montserrat font-semibold font-12'>
                  <ErrorMessage
                    component='span'
                    name={rest?.name}
                    className={classnames(
                      'font-montserrat font-semibold transition',
                      textColorClass,
                      className,
                    )}
                  />
                </Form.Text>
              )}
            </>
          )}
        </>
      )
    }

    const onFocusField = (e) => {
      if (type === 'password') {
        setRenderPasswordShowIcon(true)
      }
      if (onFocus) {
        onFocus()
      }
      helpers.setTouched(true, true)
    }

    const renderPasswordIcon = () => {
      if (type === 'password' && renderPasswordShowIcon) {
        return (
          <div className='position-relative'>
            <div className='position-absolute password-visibility-icon'>
              <EyeIcon
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                iconColor={rest?.iconColor || 'white'}
              />
            </div>
          </div>
        )
      }
      return null
    }

    const renderLabel = () => {
      if (label) {
        return (
          <Form.Label
            className={classnames('s10a order-0', {
              'mb-lg-0 is-horizontal-label': isHorizontalFormControl,
              'col-sm-2': isHorizontalFormControl && isLGScreen,
              'mb-12px': !isHorizontalFormControl,
            })}
            {...(isHorizontalFormControl && !isLGScreen && { sm: 2 })}
            {...labelProps}>
            {formLabel[label]}
          </Form.Label>
        )
      }
      return null
    }

    const renderFormControl = () => {
      return (
        <Form.Control
          ref={ref}
          className={classnames(
            'border-radius-5 transition abroad-fs-exclude s8 order-1 form-input',
            {
              'password-visibility-input pe-5':
                type === 'password' && renderPasswordShowIcon,
            },
            {
              'bg-image-none': type === 'password',
            },
            className,
          )}
          isValid={meta.touched && !meta.error}
          isInvalid={
            field?.value === '' && !isSubmitting && isCustomValidating
              ? false
              : meta.touched && meta.error
          }
          onFocus={onFocusField}
          size={size || isLGScreen ? size : 'lg'}
          type={type === 'password' && showPassword ? 'text' : type}
          placeholder={formText[placeholder]}
          {...(!removeFormikField ? field : {})}
          {...rest}>
          {children || renderChildren}
        </Form.Control>
      )
    }

    return (
      <Form.Group
        controlId={id || `control-${rest?.name}-${Math.random()}`}
        className={classnames(
          'input-field-group d-flex flex-column position-relative',
          formGroupClassName,
        )}
        {...otherFormGroupProps}>
        {renderErrorMessages()}
        {isHorizontalFormControl ? (
          <span class='d-flex position-relative align-items-center'>
            <div class='d-lg-flex align-items-center w-100'>
              {renderLabel()}
              {renderFormControl()}
              {renderPasswordIcon()}
            </div>
          </span>
        ) : (
          <>
            {renderFormControl()}
            {renderLabel()}
            {renderPasswordIcon()}
          </>
        )}
      </Form.Group>
    )
  },
)

InputField.defaultProps = {
  showTooltip: false,
  removeFormikField: false,
  type: 'text',
  showErrorMessage: false,
}

InputField.propTypes = {
  removeFormikField: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
}

export default InputField
