import React from 'react'

export const LayoutContext = React.createContext({
  showSidebar: window.innerWidth >= 992 ? true : false,
  setShowSidebar: () => {},
  showPlayBar: false,
  setShowPlayBar: () => {},
  minimizePlayBar: false,
  setMinimizePlayBar: () => {},
  mediaObject: null,
  setMediaObject: () => {},
  audio: null,
  setAudio: () => {},
  volumeLevel: 0.3,
  setVolumeLevel: () => {},
  duration: 0,
  setDuration: () => {},
  curTime: 0,
  setCurTime: () => {},
  tempTime: null,
  setTempTime: () => {},
  contextJourneyId: null,
  setContextJourneyId: () => {},
  contextJourneyAudioId: null,
  setContextJourneyAudioId: () => {},
  isJourneyModuleAudio: null,
  setIsJourneyModuleAudio: () => {},
  journeyAudioType: null,
  setJourneyAudioType: () => {},
  journeyGroupId: null,
  setJourneyGroupId: () => {},
})

export const InviteDetailsContext = React.createContext({
  inviteLinkDetails: {},
  setInviteLinkDetails: () => {},
})

export const ProgramContext = React.createContext({
  programDetails: {},
  setProgramDetails: () => {},
  showEnrollBtn: false,
  setShowEnrollBtn: () => {},
})

export const UpgradePlanModalContext = React.createContext({
  show: false,
  onHide: () => {},
  onShow: () => {},
  setModalData: () => {},
  data: null,
})
