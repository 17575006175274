import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import Button from '../../Button'
import { plans } from '../../../helper'

const GrowthPlanBtn = ({
  buttonClasses = '',
  buttonText = 'Inquire about Growth Plan',
  buttonEvent,
  gtmUpgradeButtonClickedAction
}) => {
  const history = useHistory()
  return (
    <Button
      variant='saffron'
      className={classNames('btn s10a text-uppercase', buttonClasses)}
      onClick={() => {
        gtmUpgradeButtonClickedAction(buttonText)
        history.push(`/upgrade-inquiry?plan=${plans.growth.code}`)
      }}
    >
      {buttonText}
    </Button>
  )
}

export default GrowthPlanBtn
