import React, { useState } from 'react'
import TagManager from 'react-gtm-module'
import classNames from 'classnames'
import { useErrorService, useUserService } from '@abroad/components'
import DeleteIntentionModal from './DeleteIntentionModal'
import DeleteIntentionEvent from '../../constants/events'
import API from '../../utils/API'

const DeleteIntentionLink = ({
  onSuccess,
  setIsDeleting,
  isRestoring,
  intentionId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const { user } = useUserService()
  const Error = useErrorService()
  const userId = user?.id
  const handleClose = () => setShow(false)
  const gtmDeleteIntentionAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: DeleteIntentionEvent.event.deleteIntention,
        eventProps: {
          category: DeleteIntentionEvent.category.deleteIntentionCategory,
          action: 'User delete intention',
          label: 'Delete intention',
          value: `User delete intention in growthPlan page`,
          userId,
        },
      },
    })
  }

  const deleteIntention = async () => {
    try {
      setIsLoading(true)
      setIsDeleting(true)
      await API.intention.deleteIntention(intentionId)
      gtmDeleteIntentionAction()
      onSuccess(intentionId)
      setIsDeleting(false)
      setIsLoading(false)
    } catch (e) {
      setIsDeleting(false)
      setIsLoading(false)
      Error.showError(e)
    }
    setShow(false)
  }

  return (
    <>
      <DeleteIntentionModal
        show={show}
        handleClose={handleClose}
        deleteIntention={deleteIntention}
        isLoading={isLoading}
      />
      <span
        className={classNames('delete-link s10a', {
          'cursor-pointer': !isLoading,
          'pe-none': isLoading || isRestoring,
        })}
        onClick={() => setShow(true)}>
        {isLoading ? 'deleting...' : 'delete'}
      </span>
    </>
  )
}

export default DeleteIntentionLink
