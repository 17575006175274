import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ABSpinner, ModalProvider, useErrorService } from '@abroad/components'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { GlobalJournalModal, GlobalJournalCard } from '../components'
import { PageHeader } from '../components/common'
import API from '../utils/API'
import classNames from 'classnames'
import CreateInsight from '../components/growthPlan/CreateInsight'
import RecordAudio from '../components/common/RecordAudio'

const GlobalJournal = () => {
  const [globalJournal, setGlobalJournal] = useState()
  const [mediaMetadata, setMediaMetadata] = useState()
  const history = useHistory()
  const Error = useErrorService()
  const { categoryType, subCategoryId, mediaId } = useParams()
  const [isRecording, setIsRecording] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [mediaUrl, setMediaUrl] = useState()
  const [loadedAudioId, setLoadedAudioId] = useState()
  const journalAudioEleId = `global-journal-audio`

  useEffect(() => {
    const getGlobalJournals = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.journal.getGlobalJournals()
        setGlobalJournal(data)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getProgramMediaJournals = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.journal.getProgramMediaJournals(
          subCategoryId,
          mediaId,
        )
        setGlobalJournal(data)
        setMediaMetadata({
          title: data?.media?.title,
          question: '',
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    const getLibraryMediaJournals = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.journal.getLibraryMediaJournals(
          subCategoryId,
          mediaId,
        )
        setGlobalJournal(data)
        setMediaMetadata({
          title: data?.media?.title,
          question: data?.media?.question,
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (categoryType === 'program') {
      getProgramMediaJournals()
    } else if (categoryType === 'library') {
      getLibraryMediaJournals()
    } else {
      getGlobalJournals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryType, subCategoryId, mediaId])

  const updateLocalJournals = (journal, isCreated) => {
    if (isCreated) {
      setGlobalJournal((prev) => {
        return {
          ...prev,
          journals: [journal, ...prev.journals],
        }
      })
    } else {
      const journals = globalJournal.journals.map((i) => {
        if (journal._id === i._id) {
          return journal
        }
        return i
      })
      setGlobalJournal((prev) => {
        return {
          ...prev,
          journals: journals,
        }
      })
    }
  }

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{'Journal | Abroad'}</title>
        <meta name='title' content={'Journal | Abroad'}></meta>
        <meta property='og:title' content={'Journal | Abroad'}></meta>
        <meta property='twitter:title' content={'Journal | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/journal`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/journal`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/journal`}></meta>
      </Helmet>
    )
  }

  const updateList = useCallback((journal) => {
    setIsRecording(false)
    setGlobalJournal((prev) => {
      return {
        ...prev,
        journals: [journal, ...prev.journals],
      }
    })
  }, [])

  if (isLoading) {
    return (
      <>
        {renderHelmet()}
        <PageHeader
          showBackArrow={true}
          title='Journal'
          backArrowAction={() => history.goBack()}
        />
        <div className='mt-32px'>
          <ABSpinner />
        </div>
      </>
    )
  }

  return (
    <main className='intention'>
      <>
        {renderHelmet()}
        <PageHeader
          showBackArrow={true}
          title='Journal'
          backArrowAction={() => history.goBack()}
        />
        <audio id={journalAudioEleId} allow='autoplay'>
          <source src={mediaUrl} />
          Your browser does not support the <code>audio</code> element.
        </audio>
        {!isLoading && (
          <div>
            <ModalProvider>
              <div className='custom-gray-info-text mt-4 mb-32px'>
                <div className='s5'>
                  Your Journal is a space for reflection and documentation, a
                  cornerstone of your personal journey on our platform. Whether
                  you're capturing a fleeting thought or a deep reflection, you
                  can easily pen it down or use voice notes, making it versatile
                  for on-the-go insights. Accessible from anywhere within our
                  platform, this tool ensures you never miss an opportunity to
                  note down crucial moments, fostering continuous growth and
                  self-awareness.
                </div>
              </div>
              {globalJournal?.journals?.length > 0 && (
                <div className='s3'>Log New Journal Entry</div>
              )}
              <Row
                className={classNames({
                  'mt-32px': globalJournal?.journals?.length === 0,
                  'mt-3': globalJournal?.journals?.length > 0,
                })}>
                <CreateInsight type='text' journalId={'journalId'}>
                  <span className='icon icon-pencil font-saffron-700 action-icons' />
                </CreateInsight>
                <CreateInsight
                  type='audio'
                  isRecording={isRecording}
                  journalId={'journalId'}
                  recordAudio={() => {}}>
                  {/* <span className='icon icon-microphone font-saffron-700 action-icons' /> */}
                  <div className='s6 text-white'>
                    Voice note recording is currently only available on our app.{' '}
                    <span
                      className='text-saffron-300 cursor-pointer'
                      onClick={() => history.push('/download-app')}>
                      Click here to download
                    </span>
                  </div>
                </CreateInsight>
              </Row>
              {globalJournal?.journals?.length > 0 && (
                <div className='mt-52px'>
                  {globalJournal?.journals?.length > 0 && (
                    <div className='s3'>Your Journal Entries</div>
                  )}
                  <Row>
                    {isRecording && (
                      <Col xs={12} sm={12} md={12} lg={6} className='my-3 px-3'>
                        <RecordAudio
                          journalId={'journalId'}
                          updateList={updateList}
                        />
                      </Col>
                    )}
                    {globalJournal?.journals?.map((journal) => (
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        className='my-3 px-3'
                        key={journal._id}>
                        <GlobalJournalCard
                          mediaMetadata={mediaMetadata}
                          journalObj={journal}
                          setMediaUrl={setMediaUrl}
                          journalAudioEleId={journalAudioEleId}
                          loadedAudioId={loadedAudioId}
                          setLoadedAudioId={setLoadedAudioId}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              <GlobalJournalModal
                mediaId={mediaId}
                mediaMetadata={mediaMetadata}
                updateLocalJournals={updateLocalJournals}
              />
            </ModalProvider>
          </div>
        )}
      </>
    </main>
  )
}

export default GlobalJournal
