import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import InputField from './InputField'

const UserSendRequestInput = ({
  className = '',
  index,
  canAddMembers = true,
  canViewResult = false,
  type,
  inputType = 'text',
  ...rest
}) => {
  const { values } = useFormikContext()
  const [showUserTaskSurveyIcon, setShowUserTaskSurveyIcon] = useState(false)
  const [isDisabledField, setIsDisabledField] = useState(false)

  useEffect(() => {
    setIsDisabledField(
      values?.[type][index]?.hasOwnProperty('_id') ||
        !canAddMembers ||
        canViewResult,
    )
    setShowUserTaskSurveyIcon(
      values?.[type][index]?.isSurveyCompleted && inputType === 'email',
    )
  }, [values, type, index, inputType, canAddMembers, canViewResult])

  return (
    <InputField
      label={inputType === 'email' ? 'EMAIL' : 'FIRST NAME'}
      formGroupProps={{ className: 'w-100' }}
      type={inputType}
      disabled={isDisabledField}
      {...rest}
      className={classNames('w-100', {
        'green-checkmark': showUserTaskSurveyIcon,
      })}
    />
  )
}

export default UserSendRequestInput
