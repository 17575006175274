import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import {
  ABSpinner,
  plans,
  ModalProvider,
  useErrorService,
  useUserService,
  PrintableArea,
} from '@abroad/components'
import moment from 'moment'
import API from '../utils/API'
import LearnMoreBtnEvent from '../constants/events'
import {
  CategoryModal,
  OverviewSurveyResults,
  PageHeader,
  SortedSubCategories,
  SurveySubcategoriesTable,
  TakeNewAssessment,
  SurveyPreviousResults,
} from '../components'
import { getAssessmentCurrentData } from '../utils/date'
import { GrayInfoCard, UpgradeNow } from '../components/common'
import { ProgramContext } from '../utils/contexts'
import { useResolutionService } from '../hooks'
import {
  inTransitionSelfAwarenessMediaUrl,
  selfAwarenessMediaUrl,
  teamCohesionMediaUrl,
  visionImpactMediaUrl,
} from '../utils/constants'

const categoryAudioEleId = 'category-audio'

const SurveyResult = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const { showEnrollBtn } = useContext(ProgramContext)
  const [survey, setSurvey] = useState(null)
  const history = useHistory()
  const Error = useErrorService()
  const { user } = useUserService()
  const { isLGScreen } = useResolutionService()
  const userId = user?.id
  const [mediaUrl, setMediaUrl] = useState(null)
  const [loadedAudioId, setLoadedAudioId] = useState()

  useEffect(() => {
    const getSurveys = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.survey.getSurveyById(id)
        if (data) {
          setSurvey(data)
        }
        setIsLoading(false)
      } catch (e) {
        if (e?.code === 'forbidden' || e?.code === 'not_taken_survey') {
          Error.showError(e)
          history.replace('/home')
        } else if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (id) {
      getSurveys()
    } else {
      history.replace('/survey')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const gtmLearnMoreBtnClickedAction = (categoryId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LearnMoreBtnEvent.event.learnMore,
        eventProps: {
          category: LearnMoreBtnEvent.category.learnMoreCategory,
          action: 'User click on learnMore button',
          label: 'click on learnMore button',
          value: categoryId,
          userId,
        },
      },
    })
  }

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <Helmet>
        <title>{'Survey Result | Abroad'}</title>
        <meta name='title' content={'Survey Result | Abroad'}></meta>
        <meta property='og:title' content={'Survey Result | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Survey Result | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${id}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${id}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${id}`}></meta>
      </Helmet>
      <main>
        <section className='abroad-fs-exclude'>
          <ModalProvider>
            <PageHeader
              titleFn={() => {
                return (
                  <h3 className='mb-0 s1'>
                    Awareness Assessment -{' '}
                    <br
                      className={
                        showEnrollBtn && isLGScreen ? 'd-block' : 'd-none'
                      }
                    />
                    {getAssessmentCurrentData(
                      survey?.createdAt,
                      'MMMM Do, YYYY',
                    )}
                  </h3>
                )
              }}
            />
            <div
              className={classNames({
                'mt-32px mb-32px': user?.workStatus === 'operating',
                'mt-32px': user?.workStatus === 'transition',
              })}>
              {user?.workStatus === 'operating' &&
                moment().diff(moment(survey?.createdAt), 'days') >= 1 && (
                  <TakeNewAssessment
                    variant='abroad-blue'
                    title='TAKE NEW ASSESSMENT'
                  />
                )}
            </div>
            <OverviewSurveyResults
              wrapperClasses='mb-52px'
              survey={survey}
              onLearnMoreClick={gtmLearnMoreBtnClickedAction}
              titlePosition='top'
            />
            <GrayInfoCard
              className='mb-52px py-4 assessment-info-box'
              titleFn={() => {
                return (
                  <p className='text-center mb-0 s6a font-weight-500'>
                    To better understand your results, we recommend listening to
                    the audios that describes the context of each category. For
                    further information on each subcategory, simply click on the
                    corresponding info icons.
                  </p>
                )
              }}
            />
            <audio id={categoryAudioEleId} allow='autoplay'>
              <source src={mediaUrl} />
              Your browser does not support the <code>audio</code> element.
            </audio>
            <SurveySubcategoriesTable
              mediaUrl={
                survey?.inTransition
                  ? inTransitionSelfAwarenessMediaUrl
                  : selfAwarenessMediaUrl
              }
              wrapperClasses='mb-52px'
              title='Self Awareness'
              categoryKeyName='self_awareness'
              survey={survey}
              onLearnMoreClick={gtmLearnMoreBtnClickedAction}
              innerBackgroudColor='#0A223C'
              outerBackgroudColor='rgba(10, 34, 60, 0.25)'
              barHeight='22.437rem'
              key='self_awareness'
              setMediaUrl={setMediaUrl}
              loadedAudioId={loadedAudioId}
              setLoadedAudioId={setLoadedAudioId}
              pauseOthers
            />
            {!survey?.inTransition && (
              <>
                <SurveySubcategoriesTable
                  mediaUrl={teamCohesionMediaUrl}
                  wrapperClasses='mb-52px'
                  title='Team Cohesion'
                  categoryKeyName='team_cohesion'
                  survey={survey}
                  onLearnMoreClick={gtmLearnMoreBtnClickedAction}
                  innerBackgroudColor='#CE8147'
                  outerBackgroudColor='#F2DFD1'
                  barHeight='22.437rem'
                  key='team_cohesion'
                  setMediaUrl={setMediaUrl}
                  loadedAudioId={loadedAudioId}
                  setLoadedAudioId={setLoadedAudioId}
                  pauseOthers
                />
                <SurveySubcategoriesTable
                  mediaUrl={visionImpactMediaUrl}
                  wrapperClasses='mb-52px'
                  title='Vision & Impact'
                  categoryKeyName='vision__impact'
                  survey={survey}
                  onLearnMoreClick={gtmLearnMoreBtnClickedAction}
                  innerBackgroudColor='#EFC030'
                  outerBackgroudColor='#FBEFCB'
                  tableClasses='align-self-lg-center'
                  barHeight='22.437rem'
                  key='vision__impact'
                  setMediaUrl={setMediaUrl}
                  loadedAudioId={loadedAudioId}
                  setLoadedAudioId={setLoadedAudioId}
                  pauseOthers
                />
                <SortedSubCategories
                  results={survey?.results}
                  inTransition={survey?.inTransition}
                />
              </>
            )}
            {user?.planCode === plans.free.code ? (
              <UpgradeNow
                wrapperClasses='mt-52px mb-52px border-radius-full'
                title='Unleash Unlimited Insights'
                subTitleFn={() => {
                  return (
                    <h5 className='mt-3 s5 text-center mb-0'>
                      Upgrade to the Growth Plan for unlimited access to your
                      Awareness Assessment <br className='d-lg-block d-none' />{' '}
                      and chart your progress in real-time.
                    </h5>
                  )
                }}
                buttonClasses='leading-4'
                buttonText='Inquire about Growth Plan'
                plan={plans.growth.code}
              />
            ) : (
              <SurveyPreviousResults />
            )}
            <CategoryModal />
          </ModalProvider>
        </section>
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default SurveyResult
