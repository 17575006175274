import React, { useState } from 'react'
import Notification from './Notification'

export const NotificationContainerContext = React.createContext({
  notification: null,
  setNotification: () => {}
})

const NotificationContainerProvider = ({ children }) => {
  const [notification, setNotification] = useState(null)

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setNotification })
    }
    return child
  })

  const clearMessage = () => {
    setNotification({ message: null, type: null })
  }

  return (
    <React.Fragment>
      {childrenWithProps}
      <NotificationContainerContext.Provider
        value={{ notification: notification, clearMessage }}
      >
        <Notification />
      </NotificationContainerContext.Provider>
    </React.Fragment>
  )
}

export default NotificationContainerProvider
