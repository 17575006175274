import React, { useState } from 'react'
import { Form as RBFrom, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import TagManager from 'react-gtm-module'
import {
  Button,
  useUserService,
  useNotificationService,
  useErrorService,
} from '@abroad/components'
import { InputField } from '../form'
import { SignupPasswordField } from '../onboarding'
import API from '../../utils/API'
import UpdatePasswordEvent from '../../constants/events'

const UpdatePassword = () => {
  let history = useHistory()
  const { user } = useUserService()
  const Notification = useNotificationService()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = object().shape({
    currentPassword: string().required('\0'),
    password: string()
      .matches(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()-=_+\\[\\]{};\':",./<>?\\\\|]).{8,}$',
        '',
      )
      .required('Required'),
    confirmPassword: string()
      .test(
        'password-match',
        'Please Make Sure The Passwords Match',
        function (value) {
          const { password } = this.parent
          return password === value
        },
      )
      .required('\0'),
  })

  const gtmUserUpdatedPasswordAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: UpdatePasswordEvent.event.updatePassword,
        eventProps: {
          category: UpdatePasswordEvent.category.updatePasswordCategory,
          action: 'User update password successfully',
          label: 'Update password',
          value: `User update password successfully in UpdatePassword page`,
          userId: user?.id,
        },
      },
    })
  }

  const onSubmit = async (values, action) => {
    try {
      setIsLoading(true)
      await API.user.updatePassword(
        {
          currentPassword: values.currentPassword,
          password: values.password,
        },
        user.id,
      )
      setIsLoading(false)
      gtmUserUpdatedPasswordAction()
      Notification.showNotification('Password has been updated!', 'success')
      history.push('/profile')
    } catch (e) {
      if (e.code === 'invalid_password') {
        Notification.showNotification(e.message)
      } else {
        Error.showError(e)
      }
      setIsLoading(false)
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ password: '', confirmPassword: '', currentPassword: '' }}
      onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <RBFrom as={Form} className='my-info mt-32px'>
            <Row>
              <Col lg={6} md={12} sm={12}>
                <InputField
                  label='Current Password'
                  name='currentPassword'
                  type='password'
                  placeholder='Current Password'
                  size='lg'
                  sm='10'
                  iconColor='black'
                />
              </Col>
              <Col lg={6} md={0} sm={0}></Col>
              <Col lg={6} md={12} sm={12}>
                <SignupPasswordField
                  label='Password'
                  placeholder='Password'
                  size='lg'
                  iconColor='black'
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <InputField
                  label='Confirm password'
                  name='confirmPassword'
                  type='password'
                  placeholder='Confirm password'
                  size='lg'
                  sm='10'
                  iconColor='black'
                />
              </Col>
            </Row>
            <div className='text-end'>
              <Button
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={isLoading}
                type='submit'
                size='md'
                className='border-radius-normal'
                variant='saffron'>
                Update
              </Button>
            </div>
          </RBFrom>
        )
      }}
    </Formik>
  )
}

export default UpdatePassword
