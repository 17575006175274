import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { InfoIcon } from '../assetsComponents'

const InfoIconOverlay = ({ contentText, title, placement = 'bottom' }) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip
          id={`tooltip-${placement}-${title}`}
          className='s11 text-center info-tooltip'>
          {contentText}
        </Tooltip>
      }>
      <span className='d-inline-block ms-3'>
        <InfoIcon className='info-icon align-self-center cursor-pointer font-20 text-decoration-none' />
      </span>
    </OverlayTrigger>
  )
}

export default InfoIconOverlay
