import React from 'react'
import { FreePlanNoSurvey } from './FreePlanHome'

const CustomHomeLASurvey = () => {
  return (
    <div className='mt-32px'>
      <div className='s3 mb-12px'>
        Thank You, Your Feedback Has Been Submitted
      </div>
      <div className='s5 mb-52px'>
        If you would like to receive data-driven insights on your personal
        well-being, team cohesion, and motivation, we encourage you to take
        Abroad’s free <strong>15-minute self Assessment.</strong> As an added
        bonus, we will provide you with audio content related to your key areas
        of growth.
      </div>
      <FreePlanNoSurvey isRenderOnCustomHomePage={true} />
    </div>
  )
}

export default CustomHomeLASurvey
