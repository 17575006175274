import React, { useState } from 'react'

const PauseIcon = ({ isLoading, ...rest }) => {
  const [goldColorVisible, setGoldColorVisible] = useState(null)
  return (
    <svg
      {...rest}
      className='cursor-pointer'
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 68 68'
      onMouseOver={(e) => {
        setGoldColorVisible(true)
      }}
      onMouseEnter={(e) => {
        setGoldColorVisible(true)
      }}
      onMouseLeave={(e) => {
        setGoldColorVisible(null)
      }}
    >
      <defs>
        <linearGradient id='gradAnimation'>
          <animate
            attributeName='y2'
            values='0;0;1;1;0'
            keyTimes='0;.25;.5;.75;1'
            dur='1s'
            repeatCount='indefinite'
          />
          <animate
            attributeName='x2'
            values='0;1;1;0;0'
            keyTimes='0;.25;.5;.75;1'
            dur='1s'
            repeatCount='indefinite'
          />
          <animate
            attributeName='y1'
            values='1;1;0;0;1'
            keyTimes='0;.25;.5;.75;1'
            dur='1s'
            repeatCount='indefinite'
          />
          <animate
            attributeName='x1'
            values='1;0;0;1;1'
            keyTimes='0;.25;.5;.75;1'
            dur='1s'
            repeatCount='indefinite'
          />
          <stop
            offset='0.2'
            style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 1 }}
          />
          <stop
            offset='0.3'
            style={{ stopColor: 'rgb(191,144,0)', stopOpacity: 1 }}
          />
          <stop
            offset='0.5'
            style={{ stopColor: 'rgb(191,144,0)', stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <g
        id='Group_329'
        data-name='Group 329'
        transform='translate(-926.452 -631)'
      >
        <g
          id='Ellipse_14'
          data-name='Ellipse 14'
          transform='translate(926.452 631)'
          fill='none'
          stroke={
            isLoading
              ? "url('#gradAnimation')"
              : goldColorVisible
              ? '#efc030'
              : '#fff'
          }
          strokeWidth='2'
        >
          <circle cx='34' cy='34' r='34' stroke='none' />
          <circle cx='34' cy='34' r='33' fill='none' />
        </g>
        <line
          id='Line_15'
          data-name='Line 15'
          y2='26.054'
          transform='translate(952.532 652)'
          fill='none'
          stroke={goldColorVisible ? '#efc030' : '#fff'}
          strokeLinecap='round'
          strokeWidth='3'
        />
        <path
          id='Path_64'
          data-name='Path 64'
          d='M0,0V26.054'
          transform='translate(969.148 652)'
          fill='none'
          stroke={goldColorVisible ? '#efc030' : '#fff'}
          strokeLinecap='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}

export default PauseIcon
