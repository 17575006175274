import React, { useRef, useEffect } from 'react'

const TextArea = (props) => {
  const textareaRef = useRef(null)
  useEffect(() => {
    textareaRef.current.style.height = '0px'
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = scrollHeight + 'px'
  }, [props])
  return (
    <textarea
      ref={textareaRef}
      rows={'1'}
      placeholder='Type your answer here...'
      className='bg-transparent s13 focus-visible resize-none mb-3 border-0 w-100'
      style={{ color: '#fff' }}
      {...props}
    />
  )
}

export default TextArea
