import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { ShimmerTable, ShimmerTitle } from 'react-shimmer-effects'
import { useErrorService, useUserService } from '@abroad/components'
import CompareAssessmentEvent from '../../constants/events'
import { getAssessmentCurrentData } from '../../utils/date'
import API from '../../utils/API'
import { surveyType } from '../../utils/constants'

const type = 'personal'

const SurveyPreviousResults = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [previousResults, setPreviousResults] = useState([])
  const [rowNumber, setRowNumber] = useState(null)
  const { id } = useParams()
  const Error = useErrorService()
  const { user } = useUserService()

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await API.survey.getSurveys(type, surveyType.AWARENESS) // (surveyType, filterBy))
        if (data && data?.surveys?.length > 0) {
          const filteredData = data?.surveys?.filter(
            (survey) => survey?._id !== id,
          )
          const sortedSurveys = filteredData.sort((a, b) => {
            return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1
          })
          setPreviousResults(sortedSurveys)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (id) getSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const gtmCompareAssessmentAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: CompareAssessmentEvent.event.compareAssessment,
        eventProps: {
          category: CompareAssessmentEvent.category.compareAssessmentCategory,
          action: 'User compare two assessment',
          label: 'compare two assessment',
          value: `User compare two assessment in survey page (Personal Assessment)`,
          userId: user?.id,
        },
      },
    })
  }

  if (isLoading) {
    return (
      <div className='previous-result'>
        <div className='w-25 mb-18px'>
          <ShimmerTitle line={1} />
        </div>
        <ShimmerTable row={2} col={3} />
      </div>
    )
  }

  if (previousResults?.length === 0) {
    return null
  }

  return (
    <div className='previous-result'>
      <div className='d-flex mb-18px'>
        <h3 className='previous-result-title mb-0 ms-2'>Previous Results</h3>
      </div>
      {previousResults?.map((survey, index) => (
        <React.Fragment key={index}>
          {survey?._id !== id && (
            <div
              key={index}
              className={classNames(
                'result-link d-flex justify-content-between assessment-result-link',
                {
                  'first-result-link': index === 0,
                  'border-bottom-transparent': index === rowNumber - 1,
                },
              )}
              onMouseEnter={() => {
                setRowNumber(index)
              }}
              onMouseLeave={() => {
                setRowNumber(null)
              }}>
              <Link
                to={`/survey/${survey?._id}`}
                className='result-active-link hover-text-bold ms-2 assessment-result-link'>
                {getAssessmentCurrentData(survey?.createdAt, 'MMMM Do YYYY')}
              </Link>
              <Link
                to={`/survey/comparison-report?survey=${id}&survey=${survey?._id}&surveyType=${surveyType.AWARENESS}`}
                onClick={() => gtmCompareAssessmentAction()}
                className='s11 align-self-center cursor-pointer text-saffron-700 text-decoration-none hover-text-bold me-2'>
                Compare
              </Link>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default SurveyPreviousResults
