import React from 'react'
import {
  BackwardIcon,
  ForwardIcon,
  NextIcon,
  PreviousIcon,
} from '../assetsComponents'
import { ProgressBar } from '../media'
import MobileAudioControl from '../media/MobileAudioControl'

// todo - on mobile screen -> player click UI
const Audio = (props) => {
  return (
    <div className='controls z3 d-black d-lg-none bg-dark-700 py-4 text-white'>
      <ProgressBar
        identifier='-mobile'
        curTime={props.curTime}
        duration={props.duration}
        setClickedTime={(time) => props.setClickedTime(time)}
        bufferSize={props.bufferSize}
      />
      <div className='bar-time d-flex justify-content-between align-items-center'>
        <BackwardIcon
          fontSize='0.875rem'
          className='me-1'
          wrapperClassName='mr-32px'
          onClick={() => {
            props.audio.currentTime = props.audio.currentTime - 15
          }}
          isDisabled={props.isMediaLoading}
        />
        <PreviousIcon
          wrapperClassName='mr-32px'
          isPreviousDisabled={props.isPreviousDisabled}
          fontSize='1.5rem'
          onClick={() => {
            if (!props.isPreviousDisabled) {
              props.playPrev()
            }
          }}
        />
        <MobileAudioControl
          audio={props.audio}
          isLoading={props.isLoading}
          isPlaying={props.isPlaying}
        />
        <NextIcon
          wrapperClassName='ml-32px'
          isNextDisabled={props.isNextDisabled}
          fontSize='1.5rem'
          onClick={() => {
            if (!props.isNextDisabled) {
              props.playNext()
            }
          }}
        />
        <ForwardIcon
          wrapperClassName='ml-32px'
          className='ms-1'
          fontSize='0.875rem'
          onClick={() => {
            props.audio.currentTime = props.audio.currentTime + 15
          }}
          isDisabled={props.isMediaLoading}
        />
      </div>
    </div>
  )
}

export default Audio
