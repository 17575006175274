import React from 'react'
import classnames from 'classnames'

const GoBackIcon = ({
  className = '',
  showSmallIcon = false,
  onClick = () => {},
  ...rest
}) => {
  return (
    <>
      {showSmallIcon ? (
        <div className='go-back-icon'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            onClick={onClick}
            className={classnames('cursor-pointer', className)}
          >
            <path
              d='M11 1.41406L4 8.20845'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path
              d='M11 14.9975L4 8.20312'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>

          <svg
            width='16'
            height='16'
            viewBox='0 0 24 43'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={onClick}
            className={classnames('cursor-pointer', className)}
            {...rest}
          >
            <path
              d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
              fill='black'
            />
          </svg>
        </div>
      ) : (
        <svg
          className={classnames('cursor-pointer', className)}
          width='17.15'
          height='33.29'
          viewBox='0 0 21 37'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={onClick}
          {...rest}
        >
          <path
            d='M19.15 2L2 18.6462'
            stroke='black'
            strokeWidth='2.5'
            strokeLinecap='round'
          />
          <path
            d='M19.15 35.2927L2 18.6465'
            stroke='black'
            strokeWidth='2.5'
            strokeLinecap='round'
          />
        </svg>
      )}
    </>
  )
}

export default GoBackIcon
