import React from 'react'

const RightClickIcon = ({ className = '', ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      className={className}
      {...rest}
    >
      <path
        d='M6 0C2.68203 0 0 2.68203 0 6C0 9.31797 2.68203 12 6 12C9.31797 12 12 9.31797 12 6C12 2.68203 9.31797 0 6 0ZM8.90323 4.75576L5.35023 8.29493C5.23963 8.40553 5.10138 8.46083 4.96313 8.46083C4.82488 8.46083 4.67281 8.40553 4.57604 8.29493L2.82028 6.553C2.59908 6.3318 2.59908 5.98618 2.82028 5.76498C3.04147 5.54378 3.3871 5.54378 3.60829 5.76498L4.96313 7.11982L8.11521 3.96774C8.33641 3.74654 8.68203 3.74654 8.90323 3.96774C9.1106 4.18894 9.1106 4.54839 8.90323 4.75576Z'
        fill='#509F92'
      />
    </svg>
  )
}

export default RightClickIcon
