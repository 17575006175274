import { useContext } from 'react'
import { AuthContext } from '../contexts'

const useUserService = () => {
  const { user, setUser } = useContext(AuthContext)

  return { user, setUser }
}

export default useUserService
