import React, { useState } from 'react'
import TagManager from 'react-gtm-module'
import classNames from 'classnames'
import { Col, Modal } from 'react-bootstrap'
import { useUserService } from '@abroad/components'
import { InfoIcon } from '../assetsComponents'
import LAEvent from '../../constants/events'

const RequestedUserListingModal = ({ requestedUsers, className, index }) => {
  const [showModal, setShowModal] = useState(false)
  const onHide = () => setShowModal(false)
  const { user } = useUserService()
  const userId = user?._id
  const gtmOpenRequestsSentUserAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LAEvent.event.openLARequestsSentUserModal,
        eventProps: {
          category: LAEvent.category.openLARequestsSentUserModalCategory,
          action: 'User open leader assessment request sent modal',
          label:
            'Open leader assessment request sent modal on leader result page',
          value:
            'User open leader assessment request sent modal on leader result page',
          userId,
        },
      },
    })
  }
  return (
    <>
      <span className={classNames('d-flex', className)} index={index}>
        <InfoIcon
          fillColor={showModal ? '#bf9000' : 'white'}
          className='mr-12px'
          onClick={() => {
            gtmOpenRequestsSentUserAction()
            setShowModal(true)
          }}
        />
      </span>
      <Modal
        show={showModal}
        size='lg'
        onHide={onHide}
        centered
        index={index}
        className='requests-user-modal'>
        <Modal.Header closeButton className='border-bottom-none'>
          <h3 className='s3 text-black text-start px-0 mb-0'>Requests Sent</h3>
        </Modal.Header>
        <Modal.Body className='requests-sent-body'>
          <div className='d-flex flex-column px-0'>
            <div className='d-flex'>
              <Col className='col-6 mb-3 s10c text-saffron-700 text-start ps-0 pe-2'>
                FIRST NAME
              </Col>
              <Col className='col-6 mb-3 s10c text-saffron-700 text-start pe-0 ps-2'>
                EMAIL
              </Col>
            </div>
            {requestedUsers.map((user, index) => (
              <div key={index} className='d-flex'>
                <Col
                  className={classNames(
                    'col-6 text-black text-start align-self-center ps-0 pe-2 requested-user-details',
                    {
                      'mt-0': index === 0,
                      'mt-4': index !== 0,
                    },
                  )}>
                  {user?.firstName}
                </Col>
                <Col
                  className={classNames(
                    'col-6 text-black text-start align-self-center ps-2 pe-0 requested-user-details',
                    {
                      'mt-0': index === 0,
                      'mt-4': index !== 0,
                    },
                  )}>
                  {user?.email}
                </Col>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RequestedUserListingModal
