import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const controlBarRoot = document.getElementById('media-control-bar')

const MediaControlBarRoot = ({ children }) => {
  const el = useRef(document.createElement('div')).current

  useEffect(() => {
    controlBarRoot.appendChild(el)
    return () => {
      controlBarRoot.removeChild(el)
    }
  }, [el])

  return ReactDOM.createPortal(children, el)
}

export default MediaControlBarRoot
