import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  ABSpinner,
  useErrorService,
  coachRecommends,
  coachRecommendsUrl,
  plans,
  useUserService,
} from '@abroad/components'
import Journey from './Journey'
import { LearnAboutCoaching, PageHeader } from '../components'
import API from '../utils/API'

const freePlanUserDetailsText = `Ever wondered which audios align best with your growth trajectory? With a dedicated coach, you'll have access to a curated learning journey. Tailored to your unique needs and updated regularly based on your coaching sessions, 'Coach Recommends' ensures you're always aligned with content that resonates and propels you forward.`
const growthPlanUserDetailsText = `You've taken the first step with your growth plan call. Imagine having continuous access to a tailored learning journey, handpicked by your coach based on ongoing insights from your sessions. The 'Coach Recommends' tab is where your growth truly amplifies, ensuring you’re consistently aligned with content that fuels your progress.`

const individualTabLink = {
  name: 'individual',
  hashURL: '#individual',
  path: `/${coachRecommendsUrl}`,
  //defaultUrl: `/${coachRecommendsUrl}`,
}

const groupTabLink = {
  name: 'group',
  hashURL: '#group',
  path: `/${coachRecommendsUrl}`,
  optionalName: 'group',
}

const JourneysWrapper = () => {
  let location = useLocation()
  const [journeyMetadata, setJourneyMetadata] = useState()
  const history = useHistory()
  const [pages, setPages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const Error = useErrorService()
  const { user } = useUserService()

  useEffect(() => {
    const getJourneyMetadata = async () => {
      try {
        const { data } = await API.journey.getJourneyMetadata()
        if (data) {
          setJourneyMetadata(data)
        }
      } catch (e) {
        Error.showError(e)
      }
    }
    getJourneyMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      journeyMetadata?.teamJourneyExist &&
      journeyMetadata?.clientJourneyExist
    ) {
      setPages([individualTabLink, groupTabLink])
    } else if (journeyMetadata?.teamJourneyExist) {
      setPages([groupTabLink])
    } else {
      // no tabs
    }
    if (journeyMetadata) setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyMetadata])

  useEffect(() => {
    if (
      journeyMetadata &&
      Object.keys(journeyMetadata).length > 0 &&
      location.pathname === `/${coachRecommendsUrl}`
    ) {
      if (location.hash === '#group' && !journeyMetadata?.teamJourneyExist) {
        // if user has not team journey and user come from "/journeys#group" sidebar link, redirect to "/journeys#individual"
        history.push(`/${coachRecommendsUrl}#individual`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyMetadata, location])

  if (isLoading) {
    return <ABSpinner />
  }

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{`${coachRecommends} | Abroad`}</title>
        <meta name='title' content={`${coachRecommends} | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${coachRecommends} | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${coachRecommends} | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/${coachRecommendsUrl}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/${coachRecommendsUrl}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/${coachRecommendsUrl}`}></meta>
      </Helmet>
    )
  }

  if ([plans.free.code, plans.growth.code].includes(user?.planCode)) {
    return (
      <>
        {renderHelmet()}
        <main>
          <section>
            <PageHeader title={coachRecommends} />
            <div className='mt-32px'>
              <LearnAboutCoaching
                titleFn={() => {
                  return (
                    <>
                      {user?.planCode === plans.free.code ? (
                        <h3 className='s2 text-black mb-0'>
                          Your Personalized
                          <br />
                          Learning Journey Awaits
                        </h3>
                      ) : (
                        <h3 className='s2 text-black mb-0'>
                          Deepen Your Growth with a
                          <br />
                          Personalized Learning Experience
                        </h3>
                      )}
                    </>
                  )
                }}
                detailsText={
                  user?.planCode === plans.free.code
                    ? freePlanUserDetailsText
                    : growthPlanUserDetailsText
                }
                plan={plans.coaching.code}
                imageFilename='learn-more-2.jpg'
                imgCropFP={{
                  ar: '4:5',
                  'fp-x': 0.51,
                  'fp-y': 0,
                  auto: 'format',
                }}
                detailsTextClasses='fw-normal'
                buttonText='Inquire about Coaching'
              />
            </div>
          </section>
        </main>
      </>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main>
        <section className='journeys-wrapper'>
          <PageHeader title={coachRecommends} />
          <Journey
            pageUrl={location?.hash === '#group' ? 'group' : 'individual'}
            pages={pages}
            setPages={setPages}
          />
        </section>
      </main>
    </>
  )
}

export default JourneysWrapper
