import React, { useContext, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import {
  useErrorService,
  useUserService,
  mediaContent,
  internalMediaTypes,
  // programs as allPrograms,
} from '@abroad/components'
// import { getAudioProgress, isLGScreen } from '../../utils/utility'
import API from '../../utils/API'
import { LayoutContext } from '../../utils/contexts'
import breakthroughModuleEvent from '../../constants/events'
import FavBTMedia from './FavBTMedia'

const ParticularAudio = ({ media, programName, source }) => {
  const { setMediaObject, isMediaLoading, setIsMediaLoading, setShowPlayBar } =
    useContext(LayoutContext)
  const Error = useErrorService()
  const history = useHistory()
  let { moduleId } = useParams()
  const { search } = useLocation()
  const [isLoading, setIsDownloading] = useState(false)
  const query = new URLSearchParams(search)
  const urlJourneyId = query.get('journeyId')
  const urlJourneyAudioId = query.get('sequenceId')
  const { user } = useUserService()
  const userId = user?.id

  const handleDownloadPDF = async () => {
    if (isLoading) return
    try {
      setIsDownloading(true)
      const { data } = await API.programs.getURLForSkillPDF(moduleId, media._id)
      if (data) {
        gtmDownloadPDFAction()
        window.open(data.url.url)
        setIsDownloading(false)
      }
    } catch (e) {
      setIsDownloading(false)
      Error.showError(e)
    }
  }

  const gtmDownloadPDFAction = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: breakthroughModuleEvent.event.keySkills,
        eventProps: {
          category: breakthroughModuleEvent.category.keySkillsCategory,
          action: 'User download key skills',
          label: 'Download key skills',
          value: `User download key skills, title is ${media?.title}`,
          userId,
        },
      },
    })
  }

  const gtmMediaClickedAction = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: source
          ? breakthroughModuleEvent.event.practicalOrPoeticAudio
          : breakthroughModuleEvent.event.singleMediaOfMudule,
        eventProps: {
          category: source
            ? breakthroughModuleEvent.category.practicalOrPoeticAudioCategory
            : breakthroughModuleEvent.category.singleMediaOfMuduleCategory,
          action: `User play ${
            source ? 'practical or poetic audio' : 'module audio'
          }`,
          label: 'Play audio in module',
          value: `User play ${
            source ? 'practical or poetic audio' : 'module audio'
          } by audioId - ${source ? media?._id : media.id} mediaTitle - ${
            media?.title
          } from ${programName} Page`,
          userId,
        },
      },
    })
  }

  const onMediaClickedHandler = async () => {
    if (media.isUpgradeRequired) {
      return
    }
    if (media.type !== mediaContent.AUDIO) {
      handleDownloadPDF()
      return
    }
    if (isMediaLoading) {
      return
    }
    gtmMediaClickedAction()
    try {
      setIsMediaLoading(true)
      setShowPlayBar(true)
      const { data } = await API.programs.getMediaById(
        moduleId,
        media.id,
        urlJourneyId,
        urlJourneyAudioId,
      )
      setMediaObject(data)
      setIsMediaLoading(false)
    } catch (e) {
      const customErrors = [
        'ValidationError',
        'resource_not_found',
        'not_found',
        'media_not_found',
      ]
      if (customErrors.includes(e?.code)) {
        history.replace('/404')
      } else {
        Error.showError(e)
      }
    }
  }

  return (
    <React.Fragment key={media?.id}>
      <div className='cursor-pointer' onClick={onMediaClickedHandler}>
        {media.internalMediaType === internalMediaTypes.POETIC && (
          <div className='s11 text-saffron-700 mb-2'>In a Few Words</div>
        )}
        <div className='s6 mb-2'>{media?.title}</div>
        <div className='s7 mb-3 opacity-50 module-media-description'>
          {media?.description}
        </div>
      </div>
      {/* todo - requirement not cleared */}
      {/* <div className='audio-list-divider'>
        <div
          className='bar-progress'
          style={{
            background: `linear-gradient(to right, rgba(255,255,255,0) 100%, rgba(80,80,80,0.5) 0), linear-gradient(to right, black ${getAudioProgress(
              media?.lastPlayedDuration,
              media?.duration,
            )}%, rgba(80,80,80,0.5) 0)`,
          }}></div>
      </div> */}
      <div className={'d-flex justify-content-between'}>
        <div>
          {/* <span className='icon icon-extra-settings text-black cursor-pointer align-self-center me-3' /> */}
          {!media.isUpgradeRequired && (
            <FavBTMedia
              categoryId={moduleId}
              mediaId={media?._id}
              isFavoriteAudio={media?.isFavorite}
              programName={programName}
            />
          )}
          {/* <span className='icon icon-info text-black cursor-pointer align-self-center me-3' /> */}
          {media?.isCompleted && (
            <span className='icon icon-check-gold align-self-center me-3' />
          )}
        </div>
        {mediaContent.PDF !== media?.type ? (
          <div className='d-flex align-items-center'>
            <span className='s11 me-2'>
              {Math.round(media?.duration / 60)} Mins
            </span>
            <span
              className='icon icon-play font-saffron-700 cursor-pointer align-self-center'
              onClick={onMediaClickedHandler}
            />
          </div>
        ) : (
          <div>
            <span
              className='icon icon-download-gold cursor-pointer align-self-center'
              onClick={onMediaClickedHandler}>
              <span className='path1' />
              <span className='path2' />
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default ParticularAudio
