import { programIds } from '../helper/common'
const showJourneyMediaDescription = (media) => {
  let description = ''
  if (media?.type === 'module') {
    const title = media?.parentId?.title?.replace(' Program', ', ') || ''
    return description.concat(`${title} Module ${media?.sequence + 1}`)
  } else if (
    media?.type === 'media' &&
    Object.values(programIds).includes(media?.categoryId?._id)
  ) {
    const title = media?.categoryId?.title?.replace(' Program', ', ') || ''
    return description.concat(
      `${title} Module ${
        media?.subCategoryId?.sequence ? media?.subCategoryId?.sequence + 1 : ''
      }`
    )
  } else {
    description = description.concat(
      `${media?.categoryId?.title}, ${media?.subCategoryId?.title}`
    )

    return description
  }
}

export default showJourneyMediaDescription
