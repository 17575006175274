import React from 'react'
import classNames from 'classnames'

const GrayInfoCard = ({
  className = 'mb-32px',
  text = '',
  titleFn = () => {},
}) => {
  return (
    <div className={classNames('custom-gray-info-text s5', className)}>
      {text ? text : titleFn()}
    </div>
  )
}

export default GrayInfoCard
