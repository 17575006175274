import React from 'react'

// todo - deprecated - remove it
function UpgradePlanText({ className }) {
  return (
    <div className={className}>
      <a
        onClick={() => {
          window.open('https://www.abroad.io/plans')
        }}
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.abroad.io/plans'
        className='text-white text-center upgrade-text z4 font-lg-14 font-12'>
        To access the Abroad media library, please upgrade your account.
      </a>
    </div>
  )
}

export default UpgradePlanText
