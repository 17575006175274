import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, ModelContext } from '@abroad/components'

const CategoryModal = () => {
  const { show, onHide, data } = useContext(ModelContext)

  return (
    <Modal show={show} size='lg' onHide={onHide} centered>
      <Modal.Header className='border-0 pb-0 px-4'>
        <span className='text-uppercase s4'>{data?.title}</span>
      </Modal.Header>
      <Modal.Body className='pb-3 pt-2 px-4'>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
          className='s7'
        />
        <div className='text-right mt-3 space-x-3'>
          <Button
            variant='secondary-light'
            size='sm'
            onClick={onHide}
            className='px-3'>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryModal
