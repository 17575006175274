import React from 'react'
import FeatherLoader from './Spinner/FeatherLoader'

const Loader = ({ featherProps, height }) => {
  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ height: `${height}vh` }}
    >
      <FeatherLoader width={featherProps.width} {...featherProps} />
    </div>
  )
}

Loader.defaultProps = {
  featherProps: {
    width: 60
  },
  height: 100
}

export default Loader
