import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
import { history } from '@abroad/components'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Routes from './routes'
import CacheBuster from './CacheBuster'
import './styles/index.css'
import './styles/index.scss'
// import ErrorBoundary from './utils/BugsnagClient'

const CookieConsent = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "CookieConsent" */ './CookieConsent'),
    1000,
  ),
)

if (process.env.NODE_ENV === 'production') {
  import(/* webpackChunkName: "gtm" */ 'react-gtm-module').then(
    (TagManager) => {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
          appVersion: global?.appVersion,
        },
      }
      TagManager.initialize(tagManagerArgs)
    },
  )
}

function App() {
  return (
    <Suspense fallback=''>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload()
          }
          return null
        }}
      </CacheBuster>
      <Router history={history}>
        <Routes />
        <CookieConsent />
      </Router>
    </Suspense>
  )
}

export default App
