import React, { useContext } from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import Imgix from 'react-imgix'
import { useParams } from 'react-router-dom'
import {
  PlayIcon,
  plans,
  useUserService,
  mediaCategoriesIds,
} from '@abroad/components'
import useStartMediaPlayer from './useStartMediaPlayer'
import { ABSwiperSlider } from '../slider'
import { LayoutContext, UpgradePlanModalContext } from '../../utils/contexts'
import MediaLibraryEvent from '../../constants/events'
import { useResolutionService } from '../../hooks'

const MediaList = ({ category, rowMarginBottom = 'mb-40px' }) => {
  const { setContextJourneyId } = useContext(LayoutContext)
  const { setMediaParams } = useStartMediaPlayer({ disabledControls: false })
  const { user } = useUserService()
  const { onShow, setModalData } = useContext(UpgradePlanModalContext)
  const { id } = useParams()
  const { isXSScreen } = useResolutionService()

  const openUpgradePlanModal = () => {
    onShow()
    setModalData({
      header: `Unlock the Full \nAbroad Media Library`,
      subHeader: `You're exploring the tip of an insightful iceberg! Our media library is packed with bite-sized audios designed to enhance your understanding and cultivate growth. Enjoy unrestricted access to the entire library, diving into all 108 transformative audios by upgrading to a Growth Plan`,
      btnText: 'Inquire about Growth Plan',
      plan: plans.growth.code,
    })
  }

  const gtmPlayMediaLibraryAudioAction = (mediaId, subCategoryId) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: MediaLibraryEvent.event.playMediaLibraryAudio,
        eventProps: {
          category: MediaLibraryEvent.category.playMediaLibraryAudioCategory,
          action: 'User play media library audio',
          label: 'Play media library audio',
          value: `User play media library audio (mediaId - ${mediaId}, subCategoryId - ${subCategoryId})`,
          userId: user?.id,
        },
      },
    })
  }

  const renderSubcategoryMedia = (media, style = {}) => {
    return (
      <div key={media.id}>
        <div
          className='cursor-pointer'
          style={style}
          onClick={(e) => {
            if (media.isUpgradeRequired) {
              openUpgradePlanModal()
              return
            }
            setContextJourneyId(null)
            if (!media.isUpgradeRequired) {
              gtmPlayMediaLibraryAudioAction(
                media.id,
                media?.subCategory?._id || media?.subCategoryId,
              )
              setMediaParams({
                mediaId: media.id,
                mediaSubCategoryId:
                  media?.subCategory?._id || media?.subCategoryId,
                isProgramMedia: media?.isProgram,
              })
            }
          }}>
          <div className='position-relative border-radius-10 overflow-hidden'>
            <Imgix
              className='lazyload border-radius-10 img-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'edges',
                w: 400,
                h: 400,
                ar: '1:1',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${process.env.REACT_APP_IMG_SOURCE}/${media.imageFilename}?fit=crop&fill=blur&h=400&w=400&ar=1:1&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10 hover:bg-black-100 hover:bg-opacity-75 breakthrough-media-card'>
              <PlayIcon className='play-icon' />
            </div>
          </div>
        </div>
        <div className='d-block mt-15px mb-3px'>
          <div
            className={classNames('s7 text-truncate', {
              'cursor-pointer': media.isUpgradeRequired,
            })}
            onClick={() => {
              if (media.isUpgradeRequired) {
                openUpgradePlanModal()
              }
            }}>
            {media.isUpgradeRequired && (
              <span className='icon icon-padlock me-1 font-16 font-saffron-700' />
            )}
            {media.title}
          </div>
        </div>
        <div className='s11 font-saffron-700'>
          {Math.round(media.duration / 60)} minutes
        </div>
      </div>
    )
  }

  return (
    <Container
      className={classNames(
        `p-0 ${
          id !== mediaCategoriesIds.managerEfficacyId ? rowMarginBottom : ''
        }`,
      )}
      fluid>
      {id === mediaCategoriesIds.managerEfficacyId ? (
        <>
          <div className='s3 mb-3'>{category.title}</div>
          <div className='d-flex flex-wrap'>
            {category.medias.map((media) => (
              <div
                style={{
                  width: isXSScreen ? '100%' : '12.438rem',
                }}
                className={classNames('subcategory-audio-card', {
                  'mr-25px': !isXSScreen,
                  'me-0': isXSScreen,
                })}>
                {/* renderSubcategoryMedia(mediaObject,style) */}
                {renderSubcategoryMedia(
                  media,
                  isXSScreen
                    ? {}
                    : {
                        width: '12.438rem',
                        height: '12.438rem',
                      },
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <Row className='g-0'>
          <Col className='g-0'>
            <div className='s3 mb-3'>{category.title}</div>
            <ABSwiperSlider containerClassName='card-swiper'>
              {category.medias.map((media) => renderSubcategoryMedia(media))}
            </ABSwiperSlider>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default MediaList
