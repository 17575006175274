import React, { useState } from 'react'
import { Form, Alert, Row, Col } from 'react-bootstrap'
import { string, object } from 'yup'
import { Formik } from 'formik'
import TagManager from 'react-gtm-module'
import { Link, useHistory, useLocation } from 'react-router-dom'
import sysend from 'sysend'
import Cookies from 'js-cookie'
import ScrollToError from './ScrollToError'
import EmailField from './EmailField'
import { Button, useUserService } from '@abroad/components'
import { InputField, Checkbox } from '../form'
import API from '../../utils/API'
import AuthService from '../../utils/AuthService'
import { isLGScreen } from '../../utils/utility'
import { bugsnagClient } from '../../utils/BugsnagClient'
import LoginEvent from '../../constants/events'
import FullStoryService from '../../utils/FullStoryService'
import { sowRefId as sowRefIdConst } from '../../utils/constants'

const LoginFormInitialValues = {
  email: '',
  password: '',
  remember: false,
}

const Login = () => {
  const location = useLocation()
  const User = useUserService()
  let history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)

  /* eslint-disable */
  const validationSchema = object().shape({
    email: string()
      .email('Please enter a valid Email address')
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      .max(255)
      .required('Required'),
    password: string().required('Required'),
  })
  /* eslint-enable */

  const gtmUserCookieAcceptAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LoginEvent.event.cookie,
        eventProps: {
          category: LoginEvent.category.cookieCategory,
          action: 'User accept cookie',
          label: 'User accept cookie',
          value: `User accept cookie analyticsCookies :
          ${Cookies.get('rcl_statistics_consent') === 'true' ? true : false},
        thirdPartyCookies:
          ${
            Cookies.get('rcl_marketing_consent') === 'true' ? true : false
          } ,necessaryCookies : true`,
        },
      },
    })
  }

  const gtmUserSigninAction = (data) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: LoginEvent.event.login,
        userId: data.id,
        eventProps: {
          category: LoginEvent.category.loginCategory,
          action: 'User Sign In successfully',
          label: 'User Sign In',
          value: `User Sign In with plan is ${data.planCode}`,
          userId: data?.id,
        },
      },
    })
  }

  const onSubmit = async (values) => {
    try {
      setIsLoading(true)
      setError(null)
      const { data } = await API.onboarding.login({
        email: values.email,
        password: values.password,
        rememberUser: values.remember,
      })
      if (data) {
        const userData = {
          _id: data.id,
          id: data.id,
          email: data.email,
          fullName: data.fullName,
          firstName: data.fname,
          lastName: data.lname,
          emailVerified: data.emailVerified,
          showAffiliation: data.showAffiliation,
          surveyTaken: data.surveyTaken,
          workStatus: data.workStatus,
          wisdomTaken: data.wisdomTaken,
          planCode: data.planCode,
          paid: data.paid,
          isCoach: data.isCoach,
          isAdmin: data.isAdmin || data.admin,
          companyAdmin: data.companyAdmin,
          company: data.company,
          referralCode: data?.referralCode,
          twoFactorMode: data?.twoFactorMode,
          secret: data?.secret,
          expiresOn: data?.expiresOn,
          affiliation: data?.affiliation || [],
          // qr: data?.qr,
          ...(data?.companyTeamName && {
            companyTeamName: data?.companyTeamName,
          }),
          isTwoFactorVerified: User?.user?.isTwoFactorVerified,
          staticCompany: data?.staticCompany || '',
        }
        User.setUser(userData)
        const isAdminOrCoach = userData?.isAdmin || userData?.isCoach
        const twoFactorMode = userData?.twoFactorMode
        AuthService.setLoginState(values.remember)
        const query = new URLSearchParams(location?.search)
        let nextUrl = query.get('next')
        const tempFrom = location?.state?.from
        if (!nextUrl && tempFrom) {
          nextUrl = `${process.env.REACT_APP_CLIENT_DOMAIN}${tempFrom}`
        }
        if (isAdminOrCoach && twoFactorMode && !userData?.isTwoFactorVerified) {
          if (twoFactorMode === 'APP') {
            history.push('/2fa-login', {
              cameFrom: !tempFrom ? 'admin' : 'client',
              next: nextUrl,
            })
          } else {
            history.push('/2fa-email', {
              cameFrom: !tempFrom ? 'admin' : 'client',
              next: nextUrl,
            })
          }
        } else if (
          isAdminOrCoach &&
          !twoFactorMode &&
          !userData?.isTwoFactorVerified
        ) {
          history.push(`/2fa-setup${nextUrl ? `?next=${nextUrl}` : ''}`)
        } else {
          // Static SOW Redirection - starts
          const sowRefId = localStorage.getItem(sowRefIdConst)
          if (sowRefId && data.emailVerified) {
            history.replace(
              `/profile/plans-payments/coaching-plan/${sowRefId}?${sowRefIdConst}=${sowRefId}`,
            )
            return
          }
          // Static SOW Redirection - ends

          const from = location?.state?.from
          setTimeout(() => {
            const query = new URLSearchParams(location?.search)
            const nextUrl = query.get('next')
            sysend.broadcast('client_signin', { message: 'client_signin' })
            if (!nextUrl && query && query.toString().includes('c')) {
              history.push(`/user-payment${location?.search}`)
            } else if (nextUrl && (userData?.isAdmin || userData?.isCoach)) {
              window.location.replace(nextUrl)
            } else if (from) {
              history.push(from)
            } else {
              if (!userData.surveyTaken && !userData.wisdomTaken) {
                history.push('/survey')
              } else {
                history.push('/home')
              }
            }
          })
        }
        //Cookie Accept code
        if (
          Cookies.get('rcl_consent_given') === 'true' &&
          Cookies.get('is_cookie_accept') === undefined
        ) {
          await API.user.acceptCookie(
            {
              cookiePreference: {
                analyticsCookies:
                  Cookies.get('rcl_statistics_consent') === 'true'
                    ? true
                    : false,
                thirdPartyCookies:
                  Cookies.get('rcl_marketing_consent') === 'true'
                    ? true
                    : false,
                cookiesAccepted: true,
              },
            },
            data.id,
          )
          gtmUserCookieAcceptAction()
          const date = new Date()
          date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
          document.cookie = `is_cookie_accept=true;expires=${date.toUTCString()};path=/`
        }
        gtmUserSigninAction(data)

        bugsnagClient.setUser({ id: data.id })
        FullStoryService.createFullStoryScript(data.id)
      }
    } catch (e) {
      setIsLoading(false)
      setError(e)
      bugsnagClient.leaveBreadcrumb('Error State', e, 'error')
      bugsnagClient.notify('Sign In Error')
    }
  }
  return (
    <Formik
      initialValues={LoginFormInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ handleSubmit, values: { email } }) => (
        <div className='d-flex flex-column mt-32px overflow-x-hidden'>
          {error ? (
            <div className='login-error-wrapper mb-2'>
              <Alert className='login-error text-black mb-0 p-0'>
                <div className='text-center s8'>{error?.message}</div>
                {error?.code === 'invalid_email_or_password' && (
                  <div className='text-center s8 reset-password-link'>
                    <Link
                      to={{
                        pathname: '/forgot-password',
                        state: { email },
                      }}
                      title='Forgot Password'
                      className='text-error'>
                      Reset your password?
                    </Link>
                  </div>
                )}
              </Alert>
            </div>
          ) : null}
          <div>
            <Row>
              <Col className='d-flex flex-column'>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    setIsSubmitting(true)
                    handleSubmit(e)
                  }}>
                  <ScrollToError />
                  <EmailField
                    isCustomValidating={true}
                    isSubmitting={isSubmitting}
                  />
                  <InputField
                    showTooltip
                    name='password'
                    type='password'
                    label='PASSWORD'
                    formGroupProps={{
                      className: 'mt-40px d-flex flex-column position-relative',
                    }}
                    size='lg'
                    isSubmitting={isSubmitting}
                    isCustomValidating={true}
                  />
                  <div className='d-flex justify-content-between mt-13px'>
                    <Checkbox
                      name='remember'
                      type='checkbox'
                      label='Remember Me'
                      labelProps={{
                        className: 'cursor-pointer s8 text-white ms-1',
                      }}
                      custom={true}
                    />
                    <div className='s8 align-self-center'>
                      <Link
                        to={{
                          pathname: '/forgot-password',
                          state: { email },
                        }}
                        title='Forgot Password'
                        className='text-red-500'>
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                  <Button
                    variant='saffron'
                    className='w-100 btn s8 onbording-action-btn'
                    disabled={isLoading}
                    size={isLGScreen ? '' : 'lg'}
                    isLoading={isLoading}
                    type='submit'>
                    SIGN IN
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default Login
