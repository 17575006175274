import React from 'react'

const AudioElement = React.memo(({ mediaId, mediaUrl }) => {
  return (
    <audio id='audio' data-id={mediaId} allow='autoplay'>
      <source src={mediaUrl} />
      Your browser does not support the <code>audio</code> element.
    </audio>
  )
})

export default AudioElement
