import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ABSpinner, PolygonChart } from '@abroad/components'
import SurveyComparisonTabelRow from './SurveyComparisonTabelRow'
import Feedback from './Feedback'
import { PageHeader } from '../common'

const SurveyComparisonReport = ({ isLoading, comparisonData }) => {
  const history = useHistory()
  const preDate = moment(comparisonData[0]?.submittedAt).format('MMM DD, YYYY')
  const postDate = moment(comparisonData[1]?.submittedAt).format('MMM DD, YYYY')
  const formatedPreDate = `${moment(preDate).format('MM-DD-YYYY')}`
  const formatedPostDate = `${moment(postDate).format('MM-DD-YYYY')}`

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <div className='abroad-fs-exclude'>
      <section>
        <PageHeader
          title='Awareness Assessment'
          version='small'
          backArrowAction={() => {
            if (history.length > 1) {
              history.goBack()
            } else {
              history.push('/survey')
            }
          }}
          showBackArrow={true}
        />
        <h3 className='s1 mt-minus-04 mb-32px'>Compare Results</h3>
      </section>
      <section>
        <Container className='pb-4' fluid>
          <Row className='mb-3'>
            <Col className='text-center'>
              <div className='d-flex flex-column text-gray-800 align-items-center font-open-sans'>
                <p className={classNames('w-100 font-normal')}>
                  The following charts and quantitative data help you see how
                  your Self Awareness, Team Cohesion, and Vision & Impact scores
                  have changed between the two assessment periods you selected.
                </p>
                <p className={classNames('w-100 font-normal')}>
                  We invite you not to be overly concerned with whether your
                  scores have increased or decreased. Sometimes life and
                  business present challenges that require us to change our
                  working habits for a period of time. If an area decreases,
                  that is good awareness around opportunities to course correct.
                  Similarly, if an area increases, we invite you to celebrate,
                  but also not attach to it always being that way.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Self Awareness */}
      <section>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-blue-100'>
              Self Awareness
            </span>
          </Container>
        </div>
        <Container fluid>
          <Row noGutters>
            <Col className='col-12 bg-gray-101 col-lg-3'>
              {/* survey length < 3 */}
              <Container
                fluid='lg'
                className='d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'>
                <div className='assessment-date space-y-2'>
                  <div className='bg-blue-100 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPreDate}
                    <br />
                  </div>
                </div>
                <div className='assessment-date space-y-2'>
                  <div className='bg-blue-200 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPostDate}
                    <br />
                  </div>
                </div>
              </Container>
            </Col>
            <Col className='col-12 col-lg-9 py-4'>
              <div className='d-flex flex-column align-items-center'>
                <PolygonChart
                  categories={[
                    'Burnout Buffer',
                    'Fear & Anxiety',
                    'Healthy Responsibility',
                    'Psychological Adaptability',
                    'Self Compassion',
                    'Time Management & Mindset',
                  ]}
                  colors={['#69B1d8', '#0076BD']}
                  surveyOne={[
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .burnout_buffer?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .fear__decision_making?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .healthy_responsibility?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .psychological_adaptability?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .self_compassion?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.self_awareness.subcategories
                        .time_management__mindset?.percent,
                    ),
                  ]}
                  surveyTwo={[
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .burnout_buffer?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .fear__decision_making?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .healthy_responsibility?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .psychological_adaptability?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .self_compassion?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.self_awareness.subcategories
                        .time_management__mindset?.percent,
                    ),
                  ]}
                />
                <Container fluid>
                  <Table
                    responsive
                    hover
                    size='sm'
                    className='comparison-table m-auto'>
                    <thead>
                      <tr>
                        <th>Subcategory</th>
                        <th className='text-center text-blue-100'>
                          Score #1
                          <br />
                          {formatedPreDate}
                        </th>
                        <th className='text-center text-blue-200'>
                          Score #2
                          <br />
                          {formatedPostDate}
                        </th>
                        <th className='text-center'>
                          Change <br />
                          (+/-)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <SurveyComparisonTabelRow
                        title='Burnout Buffer'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.burnout_buffer?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.burnout_buffer?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Fear & Anxiety'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.fear__decision_making?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.fear__decision_making?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Healthy Responsibility'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.healthy_responsibility?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.healthy_responsibility?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Psychological Adaptability'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.psychological_adaptability?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.psychological_adaptability?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Self Compassion'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.self_compassion?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.self_compassion?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Time Management & Mindset'
                        survey0={
                          comparisonData[0]?.results.self_awareness
                            .subcategories.time_management__mindset?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.self_awareness
                            .subcategories.time_management__mindset?.percent
                        }
                      />
                    </tbody>
                  </Table>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Team Cohesion */}
      <section>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-green-100'>
              Team Cohesion
            </span>
          </Container>
        </div>
        <Container fluid>
          <Row noGutters>
            <Col className='col-12 bg-gray-101 col-lg-3'>
              {/* survey length < 3 */}
              <Container
                fluid='lg'
                className='d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'>
                <div className='assessment-date space-y-2'>
                  <div className='bg-green-100 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPreDate}
                    <br />
                  </div>
                </div>
                <div className='assessment-date space-y-2'>
                  <div className='bg-green-200 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPostDate}
                    <br />
                  </div>
                </div>
              </Container>
            </Col>
            <Col className='col-12 col-lg-9 py-4'>
              <div className='d-flex flex-column align-items-center'>
                <PolygonChart
                  categories={[
                    'Agreements & Accountability',
                    'Collaboration',
                    'Communication',
                    'Culture',
                    'Decision Making',
                    'Emotional Intelligence',
                    'Team Quality',
                    'Trust',
                  ]}
                  colors={['#509f92', '#37763B']}
                  surveyOne={[
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .agreements?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .collaboration?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .communication?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .culture?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .decision_making?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .emotional_intelligence?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .team_quality?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.team_cohesion.subcategories
                        .trust?.percent,
                    ),
                  ]}
                  surveyTwo={[
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .agreements?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .collaboration?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .communication?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .culture?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .decision_making?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .emotional_intelligence?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .team_quality?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.team_cohesion.subcategories
                        .trust?.percent,
                    ),
                  ]}
                />
                <Container fluid>
                  <Table
                    responsive
                    hover
                    size='sm'
                    className='comparison-table m-auto'>
                    <thead>
                      <tr>
                        <th>Subcategory</th>
                        <th className='text-center text-green-100'>
                          Score #1
                          <br />
                          {formatedPreDate}
                        </th>
                        <th className='text-center text-green-200'>
                          Score #2
                          <br />
                          {formatedPostDate}
                        </th>
                        <th className='text-center'>
                          Change <br />
                          (+/-)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <SurveyComparisonTabelRow
                        title='Agreements & Accountability'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .agreements?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .agreements?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Collaboration'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .collaboration?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .collaboration?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Communication'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .communication?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .communication?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Culture'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .culture?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .culture?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Decision Making'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .decision_making?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .decision_making?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Emotional Intelligence'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .emotional_intelligence?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .emotional_intelligence?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Team Quality'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .team_quality?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .team_quality?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Trust'
                        survey0={
                          comparisonData[0]?.results.team_cohesion.subcategories
                            .trust?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.team_cohesion.subcategories
                            .trust?.percent
                        }
                      />
                    </tbody>
                  </Table>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Vision & Impact */}
      <section>
        <div className='result-category-compare-panel py-2'>
          <Container fluid>
            <span className='result-category-compare-label text-red-500'>
              Vision & Impact
            </span>
          </Container>
        </div>
        <Container fluid>
          <Row noGutters>
            <Col className='col-12 bg-gray-101 col-lg-3'>
              {/* survey length < 3 */}
              <Container
                fluid='lg'
                className='d-flex flex-row flex-lg-column h-100 justify-content-center align-items-center space-x-2 space-lg-y-3 py-2'>
                <div className='assessment-date space-y-2'>
                  <div className='bg-red-500 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPreDate}
                    <br />
                  </div>
                </div>
                <div className='assessment-date space-y-2'>
                  <div className='bg-red-700 result-compare-circle' />
                  <div className='text-center results-date'>
                    RESULTS DATED&#58;&nbsp;
                    {formatedPostDate}
                    <br />
                  </div>
                </div>
              </Container>
            </Col>
            <Col className='col-12 col-lg-9 py-4'>
              <div className='d-flex flex-column align-items-center'>
                <PolygonChart
                  categories={[
                    'Compassion for Others',
                    'Intrinsic Motivation',
                    'Leadership Strength',
                    'Superordinate Goals',
                  ]}
                  colors={['#BC4C6A', '#7E2D55']}
                  surveyOne={[
                    parseInt(
                      comparisonData[0]?.results.vision__impact.subcategories
                        .compassion_for_others?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.vision__impact.subcategories
                        .intrinsic_motivation?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.vision__impact.subcategories
                        .leadership_strength?.percent,
                    ),
                    parseInt(
                      comparisonData[0]?.results.vision__impact.subcategories
                        .global_impact_goals?.percent,
                    ),
                  ]}
                  surveyTwo={[
                    parseInt(
                      comparisonData[1]?.results.vision__impact.subcategories
                        .compassion_for_others?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.vision__impact.subcategories
                        .intrinsic_motivation?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.vision__impact.subcategories
                        .leadership_strength?.percent,
                    ),
                    parseInt(
                      comparisonData[1]?.results.vision__impact.subcategories
                        .global_impact_goals?.percent,
                    ),
                  ]}
                />
                <Container fluid>
                  <Table
                    responsive
                    hover
                    size='sm'
                    className='comparison-table m-auto'>
                    <thead>
                      <tr>
                        <th>Subcategory</th>
                        <th className='text-center text-red-500'>
                          Score #1
                          <br />
                          {formatedPreDate}
                        </th>
                        <th className='text-center text-red-700'>
                          Score #2
                          <br />
                          {formatedPostDate}
                        </th>
                        <th className='text-center'>
                          Change <br />
                          (+/-)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <SurveyComparisonTabelRow
                        title='Compassion for Others'
                        survey0={
                          comparisonData[0]?.results.vision__impact
                            .subcategories.compassion_for_others?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.vision__impact
                            .subcategories.compassion_for_others?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Intrinsic Motivation'
                        survey0={
                          comparisonData[0]?.results.vision__impact
                            .subcategories.intrinsic_motivation?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.vision__impact
                            .subcategories.intrinsic_motivation?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Leadership Strength'
                        survey0={
                          comparisonData[0]?.results.vision__impact
                            .subcategories.leadership_strength?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.vision__impact
                            .subcategories.leadership_strength?.percent
                        }
                      />
                      <SurveyComparisonTabelRow
                        title='Superordinate Goals'
                        survey0={
                          comparisonData[0]?.results.vision__impact
                            .subcategories.global_impact_goals?.percent
                        }
                        survey1={
                          comparisonData[1]?.results.vision__impact
                            .subcategories.global_impact_goals?.percent
                        }
                      />
                    </tbody>
                  </Table>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Feedback />
    </div>
  )
}

export default SurveyComparisonReport
