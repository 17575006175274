import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useErrorService, useUserService, ABSpinner } from '@abroad/components'
import ChooseCircleDate from './ChooseCircleDate'
import CoachDetail from './CoachDetail'
import API from '../../utils/API'

const SelectCoachAndTime = ({ setSelectedCoachDetail }) => {
  const Error = useErrorService()
  const { user } = useUserService()

  const companyName = user?.company
  const [checkedCircleDetail, setCheckedCircleDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [coachesAndTiming, setCoachesAndTiming] = useState(null)

  const handleCoachSelection = (coachDetail) => {
    setCheckedCircleDetail(coachDetail)
    setSelectedCoachDetail(coachDetail)
  }

  useEffect(() => {
    const getCoachesAndTimings = async () => {
      try {
        const { data } = await API.company.getCoachAndCircles(companyName)
        if (data) {
          setCoachesAndTiming(data)
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getCoachesAndTimings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderCoachDetail = (coachDetail) => {
    return (
      <Container fluid className='mt-4 p-4 select-coach-circles'>
        <div className='row'>
          <div className='col col-lg-4 col-md-6 col-12 mb-sm-25px'>
            <img
              alt={coachDetail?.coach?.fullName}
              className='border-radius-5 img-cover w-100 lazyload h-auto coach-image'
              src={`${process.env.REACT_APP_PROFILE_IMAGE}/profile/${coachDetail?.coach?.coachProfile?.profileImage}`}
            />
          </div>

          <div className='col col-lg-8 col-md-6 col-12 pl-lg-32px pt-lg-0 d-flex flex-column justify-content-start'>
            <h3 className='s1 mb-4'>{coachDetail?.coach?.fullName}</h3>
            <CoachDetail detail={coachDetail?.coach?.coachProfile} />
          </div>
        </div>
        <div className='mt-3'>
          <ChooseCircleDate
            dateAndTime={coachDetail?.circles?.map((circle) => ({
              from: circle.startTime,
              to: circle.endTime,
              dates: circle.dates,
              slotId: circle._id,
            }))}
            coachId={coachDetail?.coachId}
            growthPlanLink={coachDetail?.coach?.coachProfile?.growthPlanLink}
            checkedCircleDetail={checkedCircleDetail}
            setCheckedCircleDetail={handleCoachSelection}
          />
        </div>
      </Container>
    )
  }

  return (
    <section>
      {isLoading ? (
        <ABSpinner />
      ) : (
        <>
          {coachesAndTiming
            .filter((coachDetail) =>
              coachDetail?.circles?.some((circle) => circle.dates?.length > 0),
            )
            .map((coachDetail, index) => {
              const selectedCoachDetail =
                coachDetail?.coachId === checkedCircleDetail?.coachId
              return (
                <React.Fragment key={index}>
                  {!checkedCircleDetail || selectedCoachDetail ? (
                    <React.Fragment>
                      {renderCoachDetail(coachDetail)}
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )
            })}
        </>
      )}
    </section>
  )
}

export default SelectCoachAndTime
