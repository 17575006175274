import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Imgix from 'react-imgix'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import { useErrorService } from '@abroad/components'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/components/pagination/pagination.scss'
import '../../styles/customSwiper.scss'
import { PageHeader } from '../common'
import API from '../../utils/API'
import { useResolutionService } from '../../hooks'
import UpcomingQuestsFaculty from './UpcomingQuestsFaculty'
import UpcomingQuestsGallery from './UpcomingQuestsGallery'
import UpcomingQuestsInclusionsInvestments from './UpcomingQuestsInclusionsInvestments'
import UpcomingQuestsSignup from './UpcomingQuestsSignup'

const UpcomingQuests = () => {
  const history = useHistory()
  const { isMDScreen } = useResolutionService()
  const { questId } = useParams()
  const Error = useErrorService()
  const [startDay, setStartDay] = useState()
  const [endDay, setEndDay] = useState()
  const [upcomingpublic, setUpcomingPublic] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    const getpublicQuestsUpcoming = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.publicQuestsUpcomingGet(questId)
        setUpcomingPublic(data)
        setIsLoading(false)
        const getDay = (dateString) => {
          const date = new Date(dateString) 
          return date.getUTCDate()
        }
        setStartDay(getDay(data.startDate))
        setEndDay(getDay(data.endDate))
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getpublicQuestsUpcoming()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])
  
  return (
    <div>
      <PageHeader
        showBackArrow={true}
        version='small'
        title={`Bhutan`}
        backArrowAction={() =>
          history.push(`/quests`)
        }
      />
      <div>
        {isLoading ? (
          <ShimmerThumbnail
            className='shimmer-thumbnail-mediaplayer border-radius-10 mt-52px'
            rounded
            width={'100%'}
            height={373}
          />
        ) : (
          <div>
            <div className='s1 mb-32px mt-lg-0 mt-2'>{upcomingpublic.month}&nbsp;{startDay}-{endDay},&nbsp;{upcomingpublic.year}</div>
            <Imgix
              className='border-radius-10 lazyload imgix-object-cover'
              imgixParams={{
                fit: 'crop',
                crop: 'center',
                ar: isMDScreen ? '2:1' : '5:2',
              }}
              src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${upcomingpublic.imageFileName}`}
              width={'100%'}
              alt='image'
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                src: `${
                  process.env.REACT_APP_IMG_SOURCE
                }/quest/${questId}/${
                  upcomingpublic.imageFileName
                }?ar=${
                  isMDScreen ? '2:1' : '5:2'
                }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
              }}
            />
            <div className='s2 mt-4'>{upcomingpublic.title}</div>

            <div>
              <UpcomingQuestsFaculty 
                upcomingpublic={upcomingpublic} 
                questId={questId} 
              />
              
              <UpcomingQuestsGallery 
                upcomingpublic={upcomingpublic} 
                questId={questId} 
              />

              <UpcomingQuestsInclusionsInvestments 
                upcomingpublic={upcomingpublic}  
                questId={questId} 
              />

              <UpcomingQuestsSignup questId={questId} />
            </div>
          </div> 
        )}
        
      </div>
    </div>
  )
}

export default UpcomingQuests