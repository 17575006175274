import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import {
  WisdomComparisonReport,
  useErrorService,
  surveyType,
  PrintableArea,
} from '@abroad/components'
import { SurveyComparisonReport } from '../components'
import { HeaderWrp } from '../components/layout'
import { UpgradePlan } from '../components/common'
import API from '../utils/API'

const SurveyComparison = () => {
  let { search } = useLocation()
  let history = useHistory()
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [planUpgradeRequire, setPlanUpgradeRequire] = useState(false)
  const [comparisonData, setComparisonData] = useState([])
  const query = new URLSearchParams(search)
  const params = query.getAll('survey')
  const querySurveyType = query.get('surveyType')
  useEffect(() => {
    const getComparion = async () => {
      try {
        const { data } = await API.survey.getComparisonReport(
          search,
          querySurveyType,
        )
        setComparisonData(data)
        setPlanUpgradeRequire(false)
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else if (e?.code === 'upgrade_plan') {
          setIsLoading(false)
          setPlanUpgradeRequire(true)
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (params.length === 2) {
      getComparion()
    } else {
      history.replace('/survey')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{'Survey Category | Abroad'}</title>
        <meta name='title' content={'Survey Category | Abroad'}></meta>
        <meta property='og:title' content={'Survey Category | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Survey Category | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/comparison-report`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/comparison-report`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/comparison-report`}></meta>
      </Helmet>
    )
  }

  if (planUpgradeRequire) {
    return (
      <div className='text-dark-900'>
        {renderHelmet()}
        <UpgradePlan />
      </div>
    )
  }

  return (
    <>
      {renderHelmet()}
      <main className='survey-comparison'>
        {querySurveyType === surveyType.WISDOM ? (
          <WisdomComparisonReport
            preDate={moment(comparisonData[0]?.submittedAt).format(
              'MMM DD, YYYY',
            )}
            postDate={moment(comparisonData[1]?.submittedAt).format(
              'MMM DD, YYYY',
            )}
            isLoading={isLoading}
            comparisonData={comparisonData}
            header={() => {
              return <HeaderWrp />
            }}
            onGoBack={() => {
              if (history.length > 1) {
                history.goBack()
              } else {
                history.push('/wisdom')
              }
            }}
            surveyType={surveyType.WISDOM}
            isRequiredToFormatDate={false}
          />
        ) : (
          <SurveyComparisonReport
            isLoading={isLoading}
            comparisonData={comparisonData}
          />
        )}
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}

export default SurveyComparison
