import React from 'react'
import classnames from 'classnames'

const PreviousIcon = ({
  wrapperClassName,
  isPreviousDisabled,
  fontSize = '22px',
  ...rest
}) => {
  return (
    <span
      className={classnames(
        'icon icon-previous hover:text-saffron-700',
        {
          'text-white cursor-pointer': !isPreviousDisabled,
          'pointer-event-none': isPreviousDisabled,
        },
        wrapperClassName,
      )}
      style={{
        fontSize,
        ...(isPreviousDisabled && {
          color: 'rgba(255,255,255, 0.50)',
        }),
      }}
      {...rest}
    />
  )
}

export default PreviousIcon
