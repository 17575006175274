import React, { useState } from 'react'

const VolumeProgressBar = ({
  volumeCurTime,
  indentifier = '',
  setVolumeClickedTime,
}) => {
  const [hover, setHover] = useState(false)
  const volumeDuration = 1
  const curPercentage = (volumeCurTime / volumeDuration) * 100

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX
    let volumeBar = document.querySelector(
      `#media-control-volume-bar${indentifier}`,
    )
    const barStart = volumeBar.getBoundingClientRect().left + window.scrollX
    const barWidth = volumeBar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = volumeDuration / barWidth
    if (timePerPixel * clickPositionInBar > 1) {
      return 1
    } else if (timePerPixel * clickPositionInBar < 0) {
      return 0
    }
    return timePerPixel * clickPositionInBar
  }

  const handleTimeDrag = (e) => {
    setVolumeClickedTime(calcClickedTime(e))

    const updateTimeOnMove = (eMove) => {
      setVolumeClickedTime(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  const toggleHover = () => {
    setHover(!hover)
  }

  return (
    <div className='bar-vol'>
      <div
        className='bar-volume'
        id={`media-control-volume-bar${indentifier}`}
        style={{
          background: `linear-gradient(to right, ${
            hover ? '#EFC030' : 'white'
          } ${curPercentage}%, #6F6F6F 0)`,
        }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onMouseDown={handleTimeDrag}>
        <span
          className='bar-volume-knob'
          style={{ left: `calc(${curPercentage}% - 7px)` }}
        />
      </div>
    </div>
  )
}

export default VolumeProgressBar
