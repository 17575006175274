import React from 'react'
import classNames from 'classnames'

const SliderIconForNumberTabs = ({
  wrapperClasses = '',
  scollHandler = () => {}
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='12'
      viewBox='0 0 8 12'
      fill='none'
      className={classNames(wrapperClasses)}
      onClick={scollHandler}
    >
      <path
        d='M1 10.707L7 5.8539'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M1 1.00038L7 5.85352'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default SliderIconForNumberTabs
