import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useErrorService } from '../../services'
import Button from '../Button'
import SubscriptionTableLink from './SubscriptionTableLink'
import { coachingPlanStatus, paymentStatus } from '../../helper'

const PauseOrResumeSubscription = ({
  sowId,
  modalText,
  linkText,
  newValue,
  onSuccess,
  API,
  userId
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const handleClose = () => {
    if (isLoading) return
    setShowModal(false)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.user.updatePlanStatus(userId, sowId, {
        status: newValue,
        paymentStatus:
          newValue === coachingPlanStatus[4].value
            ? paymentStatus.PAUSED
            : paymentStatus.RESUMED
      })
      if (data) {
        setIsLoading(false)
        onSuccess()
        handleClose()
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'
      >
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>
            Pause/Resume a Subscription
          </h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body'>
          <h3 className='confirmation-title'>{modalText}</h3>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='none'
            onClick={handleClose}
            disabled={isLoading}
            className='px-3 secondary-popup-button'
            size='sm'
          >
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmit}
            className='text-uppercase border-radius-normal text-white hover:text-white'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <SubscriptionTableLink
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        linkText={linkText}
      />
    </>
  )
}

export default PauseOrResumeSubscription
