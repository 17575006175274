import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import QuestsBlogCard from './QuestsBlogCard'

const QuestsLearningResourcesBlog = ({ category, questId }) => {
  return (
    <>
      <Container fluid>
        <Row className='g-0'>
          <Col className='g-0'>
            {category.map((blog) => (
              <QuestsBlogCard blog={blog} questId={questId} />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default QuestsLearningResourcesBlog
