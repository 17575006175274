import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useResolutionService from '../../services/useResolutionService'

const PolygonChart = ({
  categories,
  surveyOne,
  surveyTwo,
  colors,
  backgroundColor = '#fff',
  className = 'mb-4',
  height = 400
}) => {
  const { isLGScreen, isXSScreen } = useResolutionService()
  return (
    <HighchartsReact
      containerProps={{
        className: className
      }}
      highcharts={Highcharts}
      options={{
        chart: {
          polar: true,
          type: 'line',
          width: isLGScreen ? null : isXSScreen ? 320 : 360,
          backgroundColor: backgroundColor,
          height: height
        },
        title: {
          text: ``,
          x: -80
        },
        pane: {
          size: isLGScreen ? '60%' : '40%'
        },
        xAxis: {
          categories: categories,
          tickmarkPlacement: 'on',
          lineWidth: 0
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0
        },
        tooltip: {
          shared: true,
          pointFormat:
            '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}%</b><br/>'
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          y: 70,
          layout: 'vertical'
        },
        series: [
          {
            name: '',
            showInLegend: false,
            data: surveyOne,
            pointPlacement: 'on'
          },
          {
            name: '',
            showInLegend: false,
            data: surveyTwo,
            pointPlacement: 'on'
          }
        ],
        colors: colors,
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  align: 'center',
                  // verticalAlign: 'bottom',
                  layout: 'horizontal'
                },
                yAxis: {
                  labels: {
                    align: 'left',
                    x: 0,
                    y: -5
                  },
                  title: {
                    text: null
                  }
                },
                subtitle: {
                  text: null
                },
                credits: {
                  enabled: false
                }
              }
            }
          ]
        }
      }}
    />
  )
}

PolygonChart.propTypes = {
  categories: PropTypes.array.isRequired,
  surveyOne: PropTypes.array.isRequired,
  surveyTwo: PropTypes.array.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default PolygonChart
