import React from 'react'
import classnames from 'classnames'

const PreviousIcon = ({ className, isPreviousDisabled, ...rest }) => {
  return (
    <span
      className={classnames(
        'icon icon-previous text-white hover:text-saffron-700',
        {
          'cursor-pointer': !isPreviousDisabled,
          'pointer-event-none': isPreviousDisabled
        }
      )}
      style={{
        fontSize: '22px',
        ...(isPreviousDisabled && {
          color: 'rgba(255,255,255, 0.50)'
        })
      }}
      {...rest}
    />
  )
}

export default PreviousIcon
