import React from 'react'
import OverviewSurveyResults from './OverviewSurveyResults'
import SurveySubcategoriesTable from './SurveySubcategoriesTable'
import SortedSubCategories from './SortedSubCategories'

const WisdomSummaryResult = ({
  gtmLearnMoreBtnClickedAction = () => {},
  survey
}) => {
  return (
    <>
      <OverviewSurveyResults
        survey={survey}
        onLearnMoreClick={gtmLearnMoreBtnClickedAction}
        titlePosition='top'
      />
      <SurveySubcategoriesTable
        wrapperClasses='mb-52px'
        title='Self Understanding'
        categoryKeyName='self_understanding'
        survey={survey}
        onLearnMoreClick={gtmLearnMoreBtnClickedAction}
        innerBackgroudColor='#123C69'
        outerBackgroudColor='rgba(18,60,105, 0.25)'
        barHeight='22.437rem'
        key='self_understanding'
        parentId='6639261151fc141698164cb6'
      />
      <SurveySubcategoriesTable
        wrapperClasses='mb-52px'
        title='Wisdom & Connection'
        categoryKeyName='wisdom__connection'
        survey={survey}
        onLearnMoreClick={gtmLearnMoreBtnClickedAction}
        innerBackgroudColor='#285238'
        outerBackgroudColor='rgba(40,82,56, 0.25)'
        barHeight='22.437rem'
        key='wisdom__connection'
        parentId='6639261151fc141698164cb7'
      />
      <SortedSubCategories
        results={survey?.results}
        categories={survey?.categories}
      />
    </>
  )
}

export default WisdomSummaryResult
