// guide: this file is HOC, contains all actions like play prev, next etc. , no UI here
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { useErrorService, useUserService } from '@abroad/components'
import TagManager from 'react-gtm-module'
import { LayoutContext } from '../../utils/contexts'
import API from '../../utils/API'
import TrackAudioPlayer from '../../constants/events'
import { checkIsProgramAudio } from '../../utils/utility'

const MediaControler = (Component) => {
  return function Comp(props) {
    const {
      volumeLevel,
      setVolumeLevel,
      mediaObject,
      setMediaObject,
      audio,
      setDuration,
      curTime,
      setCurTime,
      tempTime,
      setTempTime,
      contextJourneyId,
      contextJourneyAudioId,
      setContextJourneyId,
      setContextJourneyAudioId,
      setIsJourneyModuleAudio,
      setJourneyAudioType,
      journeyGroupId,
    } = useContext(LayoutContext)
    const [isLoading, setIsLoading] = useState(false)
    let match = useRouteMatch('/media')
    const Error = useErrorService()
    const [clickedTime, setClickedTime] = useState()
    const [bufferSize, setBufferSize] = useState(0)
    const history = useHistory()

    let { search } = useLocation()
    const query = new URLSearchParams(search)
    let urlJourneyId = query.get('journeyId')
    let urlJourneyAudioId = query.get('sequenceId')
    const { user } = useUserService()
    const userId = user?.id

    useEffect(() => {
      if (urlJourneyId) {
        setContextJourneyId(urlJourneyId)
      }
      if (urlJourneyAudioId) {
        setContextJourneyAudioId(urlJourneyAudioId)
      }
    }, [
      urlJourneyId,
      urlJourneyAudioId,
      setContextJourneyId,
      setContextJourneyAudioId,
    ])

    useEffect(() => {
      if (audio) {
        audio.volume = volumeLevel
      }
    }, [audio, volumeLevel])

    const getMedia = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.mediaLibrary.getSignedUrlByMediaId(
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
        if (data) {
          setMediaObject({ ...mediaObject, mediaUrl: data.mediaUrl })
        }
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    const playPrevCall = () => {
      if (
        checkIsProgramAudio(mediaObject?.category?.key) ||
        checkIsProgramAudio(mediaObject?.category)
      ) {
        return API.programs.getPreviousMedia(
          mediaObject?.subCategory._id,
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
      } else {
        return API.mediaLibrary.getPreviousMedia(
          mediaObject?.subCategory._id,
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
      }
    }

    const playPrev = async () => {
      try {
        setIsLoading(true)
        const { data } = await playPrevCall()
        setMediaObject(data)
        setContextJourneyAudioId(data?.journey?.sequenceId)
        setIsJourneyModuleAudio(data?.journey?.type === 'module')
        setJourneyAudioType(data?.journey?.journeyType)
        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: TrackAudioPlayer.event.audioPlayer,
            eventProps: {
              category: TrackAudioPlayer.category.audioPlayerCategory,
              action: 'User play prev. media',
              label: 'Play previous',
              value: `Play Previous media of category ID - ${mediaObject.category._id}, Title is - ${mediaObject.subCategory.title} from Media Page`,
              userId,
            },
          },
        })
        setIsLoading(false)
        if (
          typeof props.showJournalModal === 'boolean' &&
          !props.showJournalModal &&
          match?.path === '/media'
        ) {
          if (
            checkIsProgramAudio(data?.category?.key) ||
            checkIsProgramAudio(data?.category)
          ) {
            let url = `/module/${data.category._id}/${data?.subCategory?._id}`
            if (contextJourneyId) {
              url += `?journeyId=${contextJourneyId}&sequenceId=${data?.journey?.sequenceId}&journeyType=${data?.journey?.journeyType}`
              if (journeyGroupId) {
                url += `&journeyGroupId=${journeyGroupId}`
              }
            }
            history.replace(url)
          } else {
            let url = `/media/category/${data?.subCategory?._id}/${data.id}`
            if (contextJourneyId) {
              url += `?journeyId=${contextJourneyId}&sequenceId=${data?.journey?.sequenceId}&journeyType=${data?.journey?.journeyType}`
              if (journeyGroupId) {
                url += `&journeyGroupId=${journeyGroupId}`
              }
            }
            history.replace(url)
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }

    const playNextAPICall = useCallback(() => {
      if (
        checkIsProgramAudio(mediaObject?.category?.key) ||
        checkIsProgramAudio(mediaObject?.category)
      ) {
        return API.programs.getNextMedia(
          mediaObject?.subCategory._id,
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
      } else {
        return API.mediaLibrary.getNextMedia(
          mediaObject?.subCategory._id,
          mediaObject?.id,
          contextJourneyId,
          contextJourneyAudioId,
        )
      }
    }, [mediaObject, contextJourneyId, contextJourneyAudioId])

    const playNext = useCallback(async () => {
      try {
        setIsLoading(true)
        const { data } = await playNextAPICall()
        //GTM code
        TagManager.dataLayer({
          dataLayer: {
            event: TrackAudioPlayer.event.audioPlayer,
            eventProps: {
              category: TrackAudioPlayer.category.audioPlayerCategory,
              action: 'User play next media',
              label: 'Play next media',
              value: `Play next media of category ID - ${mediaObject.category._id}, Title is - ${mediaObject?.subCategory.title} Audio Name - ${mediaObject?.title} from Media Page`,
              userId,
            },
          },
        })
        setMediaObject(data)
        setContextJourneyAudioId(data?.journey?.sequenceId)
        setIsJourneyModuleAudio(data?.journey?.type === 'module')
        setJourneyAudioType(data?.journey?.journeyType)
        setIsLoading(false)
        if (
          typeof props.showJournalModal === 'boolean' &&
          !props.showJournalModal &&
          match?.path === '/media'
        ) {
          if (
            checkIsProgramAudio(data?.category?.key) ||
            checkIsProgramAudio(data?.category)
          ) {
            let url = `/module/${data.category._id}/${data?.subCategory?._id}`
            if (contextJourneyId) {
              url += `?journeyId=${contextJourneyId}&sequenceId=${data?.journey?.sequenceId}&journeyType=${data?.journey?.journeyType}`
              if (journeyGroupId) {
                url += `&journeyGroupId=${journeyGroupId}`
              }
            }
            history.replace(url)
          } else {
            let url = `/media/category/${data?.subCategory?._id}/${data.id}`
            if (contextJourneyId) {
              url += `?journeyId=${contextJourneyId}&sequenceId=${data?.journey?.sequenceId}&journeyType=${data?.journey?.journeyType}`
              if (journeyGroupId) {
                url += `&journeyGroupId=${journeyGroupId}`
              }
            }
            history.replace(url)
          }
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playNextAPICall])

    const progressHandler = (event) => {
      if (audio.buffered.length > 0) {
        setBufferSize(
          (100 * audio.buffered.end(audio.buffered.length - 1)) /
            audio.duration,
        )
      }
    }

    useEffect(() => {
      if (audio) {
        setDuration(audio.duration)
        setVolumeLevel(audio.volume)
        audio.addEventListener('progress', progressHandler)
      }
      return () => {
        if (audio) {
          audio.removeEventListener('progress', progressHandler)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio])

    const setAudioTime = useCallback(
      (e) => {
        if (tempTime) {
          setCurTime('tempTime', tempTime)
        } else if (e.target.currentTime !== 0) {
          setCurTime(e.target.currentTime)
        }
        // setTempTime(null)
      },
      [tempTime, setCurTime],
    )

    useEffect(() => {
      let setAudioData
      if (audio) {
        setAudioData = (e) => {
          setDuration(e.target.duration)
          if (tempTime && mediaObject.id === e.target.dataset.id) {
            audio.currentTime = tempTime
          }
          setTempTime(null)
        }

        audio.addEventListener('timeupdate', setAudioTime)
        audio.addEventListener('loadeddata', setAudioData)
      }
      return () => {
        if (audio) {
          audio.removeEventListener('loadeddata', setAudioData)
          audio.removeEventListener('timeupdate', setAudioTime)
        }
      }
    }, [audio, tempTime, setAudioTime, setTempTime, setDuration, mediaObject])

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // const errorHandler = (e) => {
    //   if (!isLoading) {
    //     setTempTime(audio.currentTime)
    //     getMedia()
    //   }
    // }

    // useEffect(() => {
    //   if (audio) {
    //     audio.addEventListener('error', errorHandler)
    //   }
    //   return () => {
    //     if (audio) {
    //       audio.removeEventListener('error', errorHandler)
    //     }
    //   }
    // }, [audio, errorHandler, mediaObject])

    useEffect(() => {
      if (clickedTime && clickedTime !== curTime) {
        audio.currentTime = clickedTime
        setClickedTime(0)
      }
    }, [audio, clickedTime, curTime])

    const formatDuration = (duration) => {
      return moment
        .duration(duration, 'seconds')
        .format('mm:ss', { trim: false })
    }

    const favoritesAPI = () => {
      if (checkIsProgramAudio(mediaObject?.category?.key)) {
        return API.programs.favoriteModuleAudio(
          mediaObject.subCategory._id,
          mediaObject.id,
          {
            isFavorite: !mediaObject.isFavorite,
          },
        )
      } else {
        return API.mediaLibrary.toggleFavorite(
          {
            isFavorite: !mediaObject.isFavorite,
          },
          mediaObject.id,
        )
      }
    }

    const toggleIsFavorite = async () => {
      try {
        const { data } = await favoritesAPI()
        if (data) {
          setMediaObject({
            ...mediaObject,
            isFavorite: !mediaObject.isFavorite,
          })

          //GTM code
          TagManager.dataLayer({
            dataLayer: {
              event: TrackAudioPlayer.event.media,
              eventProps: {
                category: TrackAudioPlayer.category.mediaCategory,
                action: 'User like/dislike media',
                label: 'Audio like/dislike',
                value: `isFavorite - ${!mediaObject.isFavorite} of  mediaID - ${
                  mediaObject.id
                } from Audio player`,
                userId,
              },
            },
          })
        }
      } catch (e) {
        Error.showError(e)
      }
    }

    return (
      <Component
        {...props}
        playPrev={playPrev}
        playNext={playNext}
        formatDuration={formatDuration}
        bufferSize={bufferSize}
        isLoading={isLoading}
        setClickedTime={setClickedTime}
        getMedia={getMedia}
        setBufferSize={setBufferSize}
        volumeLevel={volumeLevel}
        setVolumeLevel={setVolumeLevel}
        toggleIsFavorite={toggleIsFavorite}
        clickedTime={clickedTime}
      />
    )
  }
}

export default MediaControler
