import React from 'react'
import { plans } from '@abroad/components'
import { LearnAboutCoaching } from '../common'

const freePlanUserLADetailsText = `Discover how your team perceives you as a leader. With the Growth Plan, send out our exclusive 'Leader Assessment' to gain invaluable feedback on your managerial efficacy and perceived awareness. Upgrade today and uncover the feedback that can transform your leadership journey.`

const FreeUserLA = () => {
  return (
    <section className='mt-32px'>
      <LearnAboutCoaching
        titleFn={() => {
          return (
            <h3 className='s2 text-black mb-0'>
              Elevate Your
              <br />
              Leadership Vision
            </h3>
          )
        }}
        detailsText={freePlanUserLADetailsText}
        buttonText='Inquire about Growth Plan'
        plan={plans.growth.code}
        imageFilename='learn-more-4.jpg'
        imgCropFP={{
          ar: '4:5',
          'fp-x': 0.51,
          'fp-y': 0,
          auto: 'format',
        }}
        detailsTextClasses='fw-normal'
      />
    </section>
  )
}

export default FreeUserLA
