import React from 'react'
import { useParams, Link } from 'react-router-dom'
import classNames from 'classnames'
import Card from 'react-bootstrap/Card'
import { numberToWords } from 'number-to-words'

const IntentionCard = ({ intention, index }) => {
  const { questId } = useParams()
  return (
    <Link
      to={{
        pathname: `/your-quest/${questId}/intentions/${intention?._id}`,
        state: { isLocal: intention?.isLocal },
      }}
      className='text-decoration-none intention-card'>
      <Card className='custom-gray-bg-100 border-0 border-radius-5'>
        <Card.Body className='px-4 py-3 space-y-3'>
          <Card.Title
            className={classNames(
              'intention-text s10a d-flex justify-content-between',
              {
                'font-saffron-700': !intention?.isLocal,
                'font-black': intention?.isLocal,
              },
            )}>
            {`INTENTION ${numberToWords.toWords(index)}`}
            <span
              className={classNames(
                'icon icon-right-arrow s10a align-self-center',
                {
                  'font-saffron-700': !intention?.isLocal,
                  'font-black': intention?.isLocal,
                },
              )}></span>
          </Card.Title>
          <Card.Text className='s6 font-black intention-title'>
            {intention?.title}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}

export default IntentionCard
