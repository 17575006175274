import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorService, FeatherLoader } from '@abroad/components'
import API from '../../utils/API'
import MediaLibSwiperUI from './MediaLibSwiperUI'

const RecentFavorites = () => {
  const swiperRef = useRef(null)
  const Error = useErrorService()
  const [recentFavData, setRecentFavData] = useState([])
  const [recentFavLoading, setRecentFavLoading] = useState(false)
  const history = useHistory()
  const [page, setPage] = useState(1)
  const limit = 6

  useEffect(() => {
    const getRecentlyPlayedData = async () => {
      try {
        if (page === 1) {
          setRecentFavLoading(true)
        }
        const rpResp = await API.user.getRecentFavorites({
          page,
          limit,
        })
        if (rpResp.data && Array.isArray(rpResp.data)) {
          setRecentFavData([...recentFavData, ...rpResp.data])
        }
        setRecentFavLoading(false)
      } catch (e) {
        if (e?.code === 'not_taken_survey') {
          setRecentFavLoading(false)
          return
        } else if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setRecentFavLoading(false)
          Error.showError(e)
        }
      }
    }
    getRecentlyPlayedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (!recentFavLoading && recentFavData.length > 0) {
      setTimeout(() => {
        if (swiperRef && swiperRef.current) {
          swiperRef.current.update()
        }
      })
    }
  }, [recentFavLoading, recentFavData])

  if (recentFavLoading) {
    return (
      <div className='text-center my-2'>
        <FeatherLoader />
      </div>
    )
  }

  if (recentFavData.length === 0) {
    return null
  }

  return (
    <MediaLibSwiperUI
      isLoading={recentFavLoading}
      header='Recent Favorites'
      rightHeader='Go to Media Library'
      limit={limit}
      setPage={setPage}
      data={recentFavData}
      rightHeaderRedirectFn={() => history.push('/media-library')}
    />
  )
}

export default RecentFavorites
