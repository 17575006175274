import React, { useState } from 'react'
import classNames from 'classnames'
import { Table } from 'react-bootstrap'
import Imgix from 'react-imgix'
import TagManager from 'react-gtm-module'
import { programIds, useUserService } from '@abroad/components'
import {
  getAllProgramsKeysArray,
  isCutomSMScreen,
  titleCase,
} from '../../utils/utility'
import { mediaModuleTypeObject } from '../../utils/constants'
import CoachRecommendsEvent from '../../constants/events'

const JourneyAudiosListingTable = ({
  mediasList,
  getModuleFirstMedia,
  playJourneysMediaHandler,
  getMedia,
  toggleIsFavorite,
  isToggleFavoriteLoading,
}) => {
  const allProgramsKeyArray = getAllProgramsKeysArray()
  const { user } = useUserService()
  const [bookmarkHover, setBookmarkHover] = useState(false)

  const getMediaOrModuleSubDetails = (media) => {
    return (
      <div className='s11 d-flex text-dark-900-5 custom-ellipsis-text'>
        <div className='d-flex'>
          <span>
            {titleCase(
              media?.type === mediaModuleTypeObject?.MODULE
                ? media?.mediaOrModuleDetail?.parentId?.title
                : media?.mediaOrModuleDetail?.categoryId?.title,
            )}
          </span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='4'
            height='4'
            viewBox='0 0 4 4'
            fill='none'
            className='mx-1 align-self-center'>
            <circle cx='2' cy='2' r='2' fill='black' fill-opacity='0.5' />
          </svg>
        </div>
        <span className='custom-ellipsis-text'>
          {titleCase(
            media?.type === mediaModuleTypeObject?.MODULE
              ? media?.mediaOrModuleDetail?.subTitle
              : media?.mediaOrModuleDetail?.subCategoryId?.title,
          )}
        </span>
      </div>
    )
  }

  const getMediaOrModuleType = (media) => {
    if (media?.type === mediaModuleTypeObject?.MODULE) {
      return mediaModuleTypeObject?.MODULE
    } else if (
      media?.type !== mediaModuleTypeObject?.MODULE &&
      Object.values(programIds).includes(
        media?.mediaOrModuleDetail?.categoryId?._id,
      )
    ) {
      return mediaModuleTypeObject?.MODULE_MEDIA
    } else {
      return mediaModuleTypeObject?.MEDIA_LIBRARY_AUDIO
    }
  }

  const gtmToggleMediaBookmarkAction = (
    mediaOrModuleId,
    subCategoryId,
    isFavorite,
  ) => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: CoachRecommendsEvent.event.toggleCoachRecommendsMediaBookmark,
        eventProps: {
          category:
            CoachRecommendsEvent.category
              .toggleCoachRecommendsMediaBookmarkCategory,
          action: 'User toggle coach recommends media bookmark',
          label: 'toggle coach recommends media bookmark',
          value: `User toggle coach recommends media bookmark, (mediaOrModuleId - ${mediaOrModuleId}, subCategoryId - ${subCategoryId}, isFavorite - ${!isFavorite})`,
          userId: user?._id,
        },
      },
    })
  }

  return (
    <Table responsive className='custom-table align-middle table-hover'>
      <thead>
        <tr className='s10a border-dark'>
          {!isCutomSMScreen && <th width='5%'>#</th>}
          <th width={isCutomSMScreen ? '50%' : '40%'}>TITLE</th>
          <th width='25%'>EXPERT</th>
          <th width='23%'>LENGTH</th>
          <th width='7%'></th>
        </tr>
      </thead>
      <tbody>
        {mediasList.map((media, index) => (
          <tr
            key={index}
            className={classNames({
              'pe-none': isToggleFavoriteLoading,
              'cursor-pointer': !isToggleFavoriteLoading,
            })}
            onClick={() => {
              if (bookmarkHover) return
              playJourneysMediaHandler()
              if (media?.type === mediaModuleTypeObject?.MODULE) {
                getModuleFirstMedia(media?.mediaOrModuleId, media?._id) // moduleId, sequenceId
              } else {
                getMedia(
                  media?.mediaOrModuleId, // mediaId
                  media?.mediaOrModuleDetail?.subCategoryId?._id, // mediaSubCategoryId
                  allProgramsKeyArray.includes(
                    media?.mediaOrModuleDetail?.categoryId?.key,
                  ), // isProgramMedia
                  media?._id, // sequenceId
                )
              }
            }}>
            {!isCutomSMScreen && (
              <td width='5%' className='hover:td-play-icon'>
                <span>{index + 1}</span>
                <span className='icon icon-play font-16 icon-outline-gold text-start'></span>
              </td>
            )}
            <td width={isCutomSMScreen ? '50%' : '40%'}>
              <div className='d-flex'>
                <Imgix
                  className='border-radius-5'
                  imgixParams={{ crop: 'edges', fit: 'crop', w: 144, h: 144 }}
                  src={`${process.env.REACT_APP_IMG_SOURCE}/${media?.mediaOrModuleDetail?.imageFilename}`}
                  height={window.innerWidth > 2000 ? 60 : 48}
                  width={window.innerWidth > 2000 ? 60 : 48}
                  alt='image'
                />
                <div className='ms-2 media-details d-flex flex-column w-100 align-self-center'>
                  <div className='d-flex'>
                    {media?.mediaOrModuleDetail?.isCompleted && (
                      <span className='icon icon-check-gold font-16 text-start me-2 mb-1 align-self-center'></span>
                    )}
                    <span className='mb-1 custom-ellipsis-text'>
                      {titleCase(media?.mediaOrModuleDetail?.title)}
                    </span>
                  </div>
                  {getMediaOrModuleSubDetails(media)}
                </div>
              </div>
            </td>
            <td width='25%'>
              {media?.mediaOrModuleDetail?.subCategoryId?.expert ||
                media?.mediaOrModuleDetail?.parentId?.expert ||
                media?.mediaOrModuleDetail?.expert}
            </td>
            <td width='23%' className='text-center'>
              {media?.mediaOrModuleDetail?.duration
                ? `${Math.round(
                    media?.mediaOrModuleDetail?.duration / 60,
                  )} Mins`
                : '-'}
            </td>
            <td width='7%'>
              <span
                onMouseEnter={(e) => {
                  setBookmarkHover(true)
                }}
                onMouseLeave={(e) => {
                  setBookmarkHover(false)
                }}
                className={classNames('icon font-16', {
                  'icon-bookmark hover:text-saffron-700':
                    !media?.mediaOrModuleDetail?.isFavorite,
                  'icon-bookmark-gold': media?.mediaOrModuleDetail?.isFavorite,
                  'pe-none': isToggleFavoriteLoading,
                  'cursor-pointer': !isToggleFavoriteLoading,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  gtmToggleMediaBookmarkAction(
                    media?.mediaOrModuleId,
                    media?.mediaOrModuleDetail.subCategoryId?._id,
                    media?.mediaOrModuleDetail?.isFavorite,
                  ) // mediaOrModuleId, subCategoryId, isFavorite
                  toggleIsFavorite(
                    getMediaOrModuleType(media),
                    media?.mediaOrModuleId,
                    media?.mediaOrModuleDetail.subCategoryId?._id,
                    media?.mediaOrModuleDetail?.isFavorite || false,
                  ) // type, mediaOrModuleId, subCategoryId, isFavorite
                }}></span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default JourneyAudiosListingTable
