import React from 'react'
import classNames from 'classnames'
import Imgix from 'react-imgix'
import TagManager from 'react-gtm-module'
import { Col, Container, Row } from 'react-bootstrap'
import { plans, GrowthPlanBtn, useUserService } from '@abroad/components'
import CoachingPlanBtn from './CoachingPlanBtn'
import assessmentEvent from '../../constants/events'

const LearnAboutCoaching = ({
  wrapperClasses = '',
  title,
  titleFn = () => {},
  titleClasses = '',
  detailsText,
  detailsTextFn = () => {},
  detailsTextClasses = '',
  buttonText = 'LEARN MORE ABOUT COACHING',
  buttonClasses = '',
  plan,
  imageFilename,
  imgCropFP,
}) => {
  const { user } = useUserService()

  const gtmUpgradeButtonClickedAction = (buttonText) => {
    // GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.upgradeButton,
        eventProps: {
          category: assessmentEvent.category.upgradeButtonCategory,
          action: 'User click on upgrade button',
          label: 'Click on upgrade button',
          value: `User Click on ${buttonText} button`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <Container fluid>
      <Row className='d-flex bg-gray-100 border-radius-5'>
        <Col className='px-0'>
          <div
            className={classNames(
              'd-flex flex-column learning-coaching h-100',
              wrapperClasses,
            )}>
            <div className='d-flex flex-column learn-more-details'>
              {title ? (
                <h3 className={classNames('s2 text-black mb-0', titleClasses)}>
                  {title}
                </h3>
              ) : (
                titleFn()
              )}
              {detailsText ? (
                <h5
                  className={classNames(
                    'mt-12px s6a mb-0 text-black',
                    detailsTextClasses,
                  )}>
                  {detailsText}
                </h5>
              ) : (
                detailsTextFn()
              )}
            </div>
            <div>
              {plan === plans.coaching.code ? (
                <CoachingPlanBtn
                  buttonText={buttonText}
                  buttonClasses={classNames(
                    'btn s8 text-uppercase onbording-action-btn py-12px',
                    buttonClasses,
                  )}
                />
              ) : (
                <GrowthPlanBtn
                  buttonText={buttonText}
                  buttonClasses={classNames(
                    'btn s8 text-uppercase onbording-action-btn py-12px',
                    buttonClasses,
                  )}
                  gtmUpgradeButtonClickedAction={gtmUpgradeButtonClickedAction}
                />
              )}
            </div>
          </div>
        </Col>
        <Col
          lg={5}
          className={classNames('px-0', {
            'd-none': window.innerWidth < 1200,
            'd-block': window.innerWidth >= 1200,
          })}>
          <Imgix
            className='lazyload w-100 h-auto img-cover learn-more-image overflow-hidden'
            imgixParams={{
              fit: 'crop',
              crop: 'focalpoint',
              ...imgCropFP,
            }}
            src={`${process.env.REACT_APP_IMG_SOURCE}/${imageFilename}`}
            alt='image'
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
            htmlAttributes={{
              src: `${
                process.env.REACT_APP_IMG_SOURCE
              }/${imageFilename}?fit=crop&crop=focalpoint&fill=blur&blur=300&px=16&auto=format}${
                imgCropFP &&
                `&fp-x=${imgCropFP['fp-x']}&fp-y=${imgCropFP['fp-y']}&ar=${imgCropFP['ar']}`
              }`, // low quality image here
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default LearnAboutCoaching
