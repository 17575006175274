import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorService, FeatherLoader } from '@abroad/components'
import API from '../../utils/API'
import MediaLibSwiperUI from './MediaLibSwiperUI'

const RecentlyPlayedAudio = () => {
  const recentlyPlayedSwiperRef = useRef(null)
  const Error = useErrorService()
  const [recentlyPlayedData, setRecentlyPlayedData] = useState([])
  const [isRecentlyPlayedLoading, setIsRecentlyPlayedLoading] = useState(false)
  const history = useHistory()
  const [recentlyPlayedPage, setRecentlyPlayedPage] = useState(1)
  const limit = 6

  useEffect(() => {
    const getRecentlyPlayedData = async () => {
      try {
        if (recentlyPlayedPage === 1) {
          setIsRecentlyPlayedLoading(true)
        }
        const rpResp = await API.mediaLibrary.getRecentlyPlayedMedias({
          page: recentlyPlayedPage,
          limit,
        })
        if (rpResp.data && Array.isArray(rpResp.data)) {
          setRecentlyPlayedData([...recentlyPlayedData, ...rpResp.data])
        }
        setIsRecentlyPlayedLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsRecentlyPlayedLoading(false)
          Error.showError(e)
        }
      }
    }
    getRecentlyPlayedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyPlayedPage])

  useEffect(() => {
    if (!isRecentlyPlayedLoading && recentlyPlayedData.length > 0) {
      setTimeout(() => {
        if (recentlyPlayedSwiperRef && recentlyPlayedSwiperRef.current) {
          recentlyPlayedSwiperRef.current.update()
        }
      })
    }
  }, [isRecentlyPlayedLoading, recentlyPlayedData])

  if (isRecentlyPlayedLoading) {
    return (
      <div className='text-center my-2'>
        <FeatherLoader />
      </div>
    )
  }

  if (recentlyPlayedData.length === 0) {
    return null
  }

  return (
    <MediaLibSwiperUI
      isLoading={isRecentlyPlayedLoading}
      header='Listen Again'
      rightHeader='Go to Media Library'
      limit={limit}
      setPage={setRecentlyPlayedPage}
      data={recentlyPlayedData}
      rightHeaderRedirectFn={() => history.push('/media-library')}
    />
  )
}

export default RecentlyPlayedAudio
