import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Audio from './Audio'

const ModuleAudio = (props) => {
  return (
    <Row className='justify-content-center mx-0 g-0'>
      <Col className='col-12 col-lg-4'>
        <Audio {...props} />
      </Col>
    </Row>
  )
}

export default ModuleAudio
