import React from 'react'
import classnames from 'classnames'

const NextIcon = ({ isNextDisabled, className, ...rest }) => {
  return (
    <span
      className={classnames(
        'icon icon-next text-black text-dark-900 hover:text-saffron-700',
        {
          'cursor-pointer': !isNextDisabled,
          'pointer-event-none': isNextDisabled
        }
      )}
      style={{
        fontSize: '22px',
        ...(isNextDisabled && { color: 'rgba(255,255,255, 0.50)' })
      }}
      {...rest}
    />
  )
}

export default NextIcon
