import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ScrollToError = () => {
  const { submitCount, isValid, errors, isSubmitting } = useFormikContext()

  const isObject = (value) => {
    const type = typeof value
    return !!value && (type === 'object' || type === 'function')
  }

  const getFirstErrorKey = (object, keys = []) => {
    const firstErrorKey = Object.keys(object)[0]
    if (isObject(object[firstErrorKey])) {
      return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey])
    }
    return [...keys, firstErrorKey].join('.')
  }

  useEffect(() => {
    if (!isValid && submitCount !== 0 && isSubmitting) {
      const firstErrorKey = getFirstErrorKey(errors)
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, isValid, errors, isSubmitting])

  return null
}

export default ScrollToError
