import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useErrorService } from '../../services'
import Button from '../Button'
import SubscriptionTableLink from './SubscriptionTableLink'

const CancelSubscription = ({ sowId, onSuccess, API, nextChargeDate }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const handleClose = () => {
    if (isLoading) return
    setShowModal(false)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.payment.cancelSubscription({
        coachingPlanId: sowId
      })
      if (data) {
        handleClose()
        setIsLoading(false)
        onSuccess(data)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className='payment-modal'
      >
        <Modal.Header className='payment-modal-header d-flex justify-content-center'>
          <h3 className='payment-modal-title mb-0'>CANCEL SUBSCRIPTION</h3>
        </Modal.Header>
        <Modal.Body className='payment-modal-body'>
          <h3 className='confirmation-title'>
            Are you sure that you want to cancel
            <br className='d-lg-block d-none' />
            this subcription?
          </h3>
          <span className='confirmation-subtitle'>
            This action cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between justify-content-lg-end pt-0'>
          <Button
            variant='secondary-light'
            onClick={handleClose}
            disabled={isLoading}
            className='px-3 secondary-popup-button'
            size='sm'
          >
            Cancel
          </Button>
          <Button
            variant='saffron'
            size='sm'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmit}
            className='text-uppercase border-radius-normal text-white hover:text-white'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <SubscriptionTableLink
        onClick={() => setShowModal(true)}
        disabled={isLoading}
        linkText='Cancel Subscription'
      />
    </>
  )
}

export default CancelSubscription
