import React, { useState } from 'react'
import DeleteIntentionLink from './DeleteIntentionLink'
import RestoreIntentionLink from './RestoreIntentionLink'

const RestoreDeleteIntention = ({ onSuccess, intention }) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <RestoreIntentionLink
        onSuccess={onSuccess}
        intentionId={intention?._id}
        setIsRestoring={setIsLoading}
        isDeleting={isLoading}
        intention={intention}
      />
      <DeleteIntentionLink
        onSuccess={onSuccess}
        intentionId={intention?._id}
        setIsDeleting={setIsLoading}
        isRestoring={isLoading}
      />
    </>
  )
}

export default RestoreDeleteIntention
