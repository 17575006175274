import React from 'react'
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EmailExistError = ({ email }) => {
  return (
    <div className='login-error-wrapper mt-2 mb-3'>
      <Alert className='login-error text-black mb-0 p-0'>
        <div className='d-block text-center'>
          <>This email already exists.</>
          <Link
            to={{
              pathname: '/forgot-password',
              state: { email },
            }}
            title='Forgot Password'
            className='text-error'>
            &nbsp;<>Reset Your Password</>
          </Link>
          &nbsp;or&nbsp;
          <Link className='text-error' to={{ pathname: '/', state: { email } }}>
            Sign In.
          </Link>
        </div>
      </Alert>
    </div>
  )
}

export default EmailExistError
