import { useEffect } from 'react'

const PrintableArea = ({ printSelector }) => {
  const handlePrint = () => {
    const printContent = document.querySelector(printSelector)
    if (!printContent) {
      return
    }

    const iframe = document.createElement('iframe')

    document.body.appendChild(iframe)
    document.body.style.overflow = 'hidden'

    const iframeDoc = iframe.contentWindow.document
    const styles = Array.from(document.styleSheets).reduce(
      (styleString, sheet) => {
        try {
          return (styleString += sheet.ownerNode.outerHTML)
        } catch (e) {
          return styleString
        }
      },
      ''
    )

    iframeDoc.open()
    iframeDoc.write(`
      <html>
        <head>
          <title>Print</title>
          ${styles}
          <style>
            @media print {
              @page {
                size: auto; 
              }
            }
          </style>
        </head>
        <body>
          ${printContent.outerHTML}
        </body>
      </html>
    `)
    iframeDoc.close()

    iframe.contentWindow.focus()
    iframe.contentWindow.print()

    setTimeout(() => {
      document.body.removeChild(iframe)
    }, 1000)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault()
        handlePrint()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return null
}

export default PrintableArea
