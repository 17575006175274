import React from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import {
  ResetPasswordForm,
  OnboardTitle,
  AbroadGoldenIcon,
  ReturnBtn,
} from '../components/onboarding'
import { conditionMatched } from '../components/layout'

const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>{'Reset Password | Abroad'}</title>
        <meta name='title' content={'Reset Password | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/password-reset`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/password-reset`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/password-reset`}></meta>
      </Helmet>
      <main>
        <Container fluid className='px-0'>
          <Row className='vh-100 row g-0'>
            <Col className='onboard bg-abroad-blue'>
              <div className='h-100 d-flex flex-column align-items-center'>
                <AbroadGoldenIcon />
                <section
                  className={classNames('onboard-wrapper mt-32px', {
                    'h-lg-65': !conditionMatched,
                  })}>
                  <OnboardTitle className='mb-32px'>
                    Reset Password
                  </OnboardTitle>
                  <ResetPasswordForm />
                </section>
                <ReturnBtn to='/' />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default ResetPassword
