import React from 'react'
import classNames from 'classnames'

const OrderSummaryText = ({
  label,
  text,
  labelClasses = '',
  textClasses = '',
  separatorClasses = '',
  isShowSeparator = true,
}) => {
  return (
    <React.Fragment>
      <div className='d-flex justify-content-between'>
        <span
          className={classNames(
            'summary-label align-self-center',
            labelClasses,
          )}>
          {label}
        </span>
        <span className={classNames('summary-text', textClasses)}>{text}</span>
      </div>
      {isShowSeparator && (
        <hr className={classNames('summary-separator', separatorClasses)} />
      )}
    </React.Fragment>
  )
}

export default OrderSummaryText
