import React from 'react'
import classNames from 'classnames'

const InfoIcon = ({ title = '', className = '' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    className={classNames('info-icon', className)}
  >
    <circle cx='8' cy='8' r='7.5' stroke='black' />
    <circle className='info-circle' cx='8' cy='4.33594' r='1' fill='black' />
    <rect x='7' y='6.66943' width='2' height='6' rx='1' fill='black' />
  </svg>
)

export default InfoIcon
