import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { InfoIcon } from '../assetsComponents'
import { conditionMatched } from '../layout'

const SortedSubCategories = ({ results, inTransition }) => {
  const [sortedSubCategories, setSortedSubCategories] = useState([])
  const selfAwarenessId = '5b46b176ce416b175ff98e17'
  const teamCohesionId = '5b46b176ce416b175ff98e2f'
  const visionImpactId = '5b46b176ce416b175ff98de6'

  useEffect(() => {
    if (results) {
      let subCategories = results.self_awareness.order.map((cat) => ({
        ...results.self_awareness.subcategories[cat],
        key: cat,
        parentId: selfAwarenessId,
      }))
      if (!inTransition) {
        subCategories = [
          ...subCategories,
          ...results?.team_cohesion?.order.map((cat) => ({
            ...results.team_cohesion.subcategories[cat],
            key: cat,
            parentId: teamCohesionId,
          })),
          ...results?.vision__impact?.order.map((cat) => ({
            ...results.vision__impact.subcategories[cat],
            key: cat,
            parentId: visionImpactId,
          })),
        ]
      }
      subCategories = subCategories.sort((a, b) => b.percent - a.percent)
      setSortedSubCategories(subCategories)
    }
  }, [results, inTransition])

  const renderSubCategoriesBox = (category, index) => {
    return (
      <div className='d-flex category-details'>
        <Col className='col-9'>
          <h3 className='mb-0 s7 hover-text-bold ms-2'>{category?.title}</h3>
        </Col>
        <Col className='col-3 d-flex justify-content-end'>
          <p
            className={classNames('mb-0 align-self-center hover-text-bold s7', {
              'text-abroad-blue': category?.parentId === selfAwarenessId,
              'text-orange-100': category?.parentId === teamCohesionId,
              'text-saffron-300': category?.parentId === visionImpactId,
            })}>{`${category.percent}%`}</p>
          <Link
            to={`/survey/category/${category?.key}`}
            className='text-decoration-none me-2'>
            <InfoIcon className='info-icon align-self-center cursor-pointer font-20 ms-3 text-decoration-none' />
          </Link>
        </Col>
      </div>
    )
  }

  return (
    <Row className='d-flex flex-lg-row flex-column mb-52px'>
      <Col
        lg={12}
        md={12}
        sm={12}
        className='mb-lg-0 mb-3 d-flex flex-lg-row flex-column'>
        <div className='mr-lg-12px w-100 mb-lg-0 mb-4'>
          <h3
            className={classNames('text-black mb-3', {
              s3: conditionMatched,
              s2: !conditionMatched,
            })}>
            Celebrations
          </h3>
          <div className='box-content'>
            {sortedSubCategories.slice(0, 3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className='ml-lg-12px w-100'>
          <h3
            className={classNames('text-black mb-3', {
              s3: conditionMatched,
              s2: !conditionMatched,
            })}>
            Key Areas for Growth
          </h3>
          <div className='box-content'>
            {sortedSubCategories.slice(-3).map((category, index) => (
              <React.Fragment key={index}>
                {renderSubCategoriesBox(category, index)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default SortedSubCategories
