import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'

const promoCodeStatusModal = ({ show, proceed }) => {
  return (
    <Modal
      show={show}
      onHide={() => {}}
      centered
      className='delete-intention-box'>
      <Modal.Body className='pb-0 pt-3 px-4 border-0 text-center'>
        <p className='font-18'>
          The promo code has not yet been applied. If you would like to proceed
          without the code, select yes
        </p>
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-center px-4 space-x-3'>
        <Button
          type='submit'
          className='px-2 px-lg-4 border-radius-5'
          variant='secondary-light'
          onClick={() => {
            proceed(false)
          }}>
          No
        </Button>
        <Button
          type='submit'
          size='sm'
          className='px-2 px-lg-4 border-radius-5'
          variant='black'
          onClick={() => {
            proceed(true)
          }}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const PromoCodeStatusModalComponent = promoCodeStatusModal

const comfirm = createConfirmation(confirmable(PromoCodeStatusModalComponent))

const confirmation = (message, options = {}) => {
  return comfirm(Object.assign({ confirmation: message }, options))
}

export default confirmation
