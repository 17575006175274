export const manageGroup = 'manage-group'
export const programIds = {
  breakthroughProgramId: '5fddc237a483be3860dea6f2',
  evolutionProgramId: '60e885f48ea7c31048bc553d',
  resilienceProgramId: '639970530a71c612b0026943',
  essentialsProgramId: '656dc22e079eab484d6e3288'
}

export const internalMediaTypes = {
  POETIC: 'POETIC',
  PRACTICAL: 'PRACTICAL',
  KEY_SKILL: 'KEY_SKILL'
}

export const mediaContent = {
  AUDIO: 'AUDIO',
  PDF: 'PDF'
}

export const programs = {
  breakthrough: {
    id: '5fddc237a483be3860dea6f2',
    name: 'Breakthrough',
    title: 'Breakthrough Program',
    hasFirstModuleIsIntro: false,
    key: 'breakthrough_program',
    pageUrl: '/program/5fddc237a483be3860dea6f2',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  evolution: {
    id: '60e885f48ea7c31048bc553d',
    name: 'Evolution',
    title: 'Evolution Program',
    hasFirstModuleIsIntro: false,
    key: 'evolution_program',
    pageUrl: '/program/60e885f48ea7c31048bc553d',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  resilience: {
    id: '639970530a71c612b0026943',
    name: 'Trauma Informed Leadership',
    title: 'Trauma Informed Leadership Program',
    hasFirstModuleIsIntro: false,
    key: 'resilience_program',
    pageUrl: '/program/639970530a71c612b0026943',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  essentials: {
    id: '656dc22e079eab484d6e3288',
    name: 'Leadership Essentials',
    title: 'Leadership Essentials Program',
    hasFirstModuleIsIntro: false,
    key: 'leadership_essentials',
    pageUrl: '/program/656dc22e079eab484d6e3288',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  '5fddc237a483be3860dea6f2': {
    id: '5fddc237a483be3860dea6f2',
    name: 'Breakthrough',
    title: 'Breakthrough Program',
    hasFirstModuleIsIntro: false,
    key: 'breakthrough_program',
    pageUrl: '/program/5fddc237a483be3860dea6f2',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  '60e885f48ea7c31048bc553d': {
    id: '60e885f48ea7c31048bc553d',
    name: 'Evolution',
    title: 'Evolution Program',
    hasFirstModuleIsIntro: false,
    key: 'evolution_program',
    pageUrl: '/program/60e885f48ea7c31048bc553d',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  '639970530a71c612b0026943': {
    id: '639970530a71c612b0026943',
    name: 'Trauma Informed Leadership',
    title: 'Trauma Informed Leadership Program',
    hasFirstModuleIsIntro: false,
    key: 'resilience_program',
    pageUrl: '/program/639970530a71c612b0026943',
    description:
      'The content below has been personalized based on the results of your assessments.'
  },
  '656dc22e079eab484d6e3288': {
    id: '656dc22e079eab484d6e3288',
    name: 'Leadership Essentials',
    title: 'Leadership Essentials Program',
    hasFirstModuleIsIntro: false,
    key: 'leadership_essentials',
    pageUrl: '/program/656dc22e079eab484d6e3288',
    description:
      'The content below has been personalized based on the results of your assessments.'
  }
}

export const showInUrls = [
  'http://localhost:3001',
  'http://localhost:3002',
  'https://rc.abroad.io',
  'https://rc-admin.abroad.io'
]

export const mediaCategoriesConstants = [
  {
    id: '5b46b176ce416b175ff98e17',
    name: 'Self Awareness',
    key: 'self_awareness',
    description:
      'The content below has been personalized based on the results of your assessment(s).',
    to: '/self-awareness/5b46b176ce416b175ff98e17',
    hidden: [],
    hiddenInPlans: [],
    urlWords: ['self-awareness']
  },
  {
    id: '5b46b176ce416b175ff98e2f',
    name: 'Team Cohesion',
    key: 'team_cohesion',
    description:
      'The content below has been personalized based on the results of your assessment(s).',
    to: '/team-cohesion/5b46b176ce416b175ff98e2f',
    hidden: [],
    hiddenInPlans: [],
    urlWords: ['team-cohesion']
  },
  {
    id: '5b46b176ce416b175ff98de6',
    name: 'Vision & Impact',
    key: 'Vision_&_Impact',
    description:
      'The content below has been personalized based on the results of your assessment(s).',
    to: '/vision-and-impact/5b46b176ce416b175ff98de6',
    hidden: [],
    hiddenInPlans: [],
    urlWords: ['vision-and-impact']
  },
  {
    id: '639c067da4d9940fd87f216d',
    name: 'Manager Efficacy',
    key: 'manager_efficacy',
    description: '',
    to: '/manager-efficacy/639c067da4d9940fd87f216d',
    hidden: [],
    hiddenInPlans: [],
    urlWords: ['manager-efficacy']
  },
  {
    id: '60eef62e0c2ca67244cbdb9a',
    name: 'Mindfulness & Somatics',
    key: 'mindfulness_&_somatics',
    description:
      'This library includes mindfulness and somatic practices that bring you into greater presence and relaxation',
    to: '/mindfulness-and-somatics/60eef62e0c2ca67244cbdb9a',
    hidden: ['prod'],
    hiddenInPlans: [],
    urlWords: ['mindfulness-and-somatics']
  },
  {
    id: '6144525714454a1a5609d5c1',
    name: 'Leadership Stories',
    key: 'leadership_stories',
    description:
      'The content below has been personalized based on the results of your assessment(s).',
    to: '/leadership-stories/6144525714454a1a5609d5c1',
    hidden: ['prod'],
    hiddenInPlans: [],
    urlWords: ['leadership-stories']
  }
  // {
  //   id: '5fddc237a483be3860dea6d2',
  //   name: 'Breath and Meditation',
  //   key: 'breath_and_meditation',
  //   description:
  //     'The content below has been personalized based on the results of your assessment(s).',
  //   to: '/breath-and-meditation/5fddc237a483be3860dea6d2',
  //   urlWords: ['breath-and-meditation'],
  // },
  // {
  //   id: '5fddc237a483be3860dea6d3',
  //   name: 'Ancient Wisdom',
  //   key: 'ancient_wisdom',
  //   description:
  //     'The content below has been personalized based on the results of your assessment(s).',
  //   to: '/ancient-wisdom/5fddc237a483be3860dea6d3',
  //   urlWords: ['ancient-wisdom'],
  // },
]

export const mediaCategoriesIds = {
  selfAwarenessId: '5b46b176ce416b175ff98e17',
  teamCohesionId: '5b46b176ce416b175ff98e2f',
  visionAndImpactId: '5b46b176ce416b175ff98de6',
  managerEfficacyId: '639c067da4d9940fd87f216d'
}

export const simple360 = 'Simple 360'
export const simple360Url = 'simple-360'

export const sows = {
  prefix: {
    individual: 'B2C: ',
    b2b: 'B2B: ',
    b2bGroup: 'B2B: Groups',
    normalGroup: 'B2C: Groups',
    growth: 'Growth:'
  },
  label: {
    individual: 'B2C',
    b2b: 'B2B',
    b2bGroup: 'B2B Groups',
    normalGroup: 'B2C Groups',
    clientSideIndividual: 'Individual',
    clientSideB2B: 'B2B',
    clientSideGroup: 'Group',
    growth: 'Growth'
  },
  planType: {
    individual: 'individual',
    b2b: 'b2b',
    growth: 'growth'
  }
}
// do not change order of paymentStatus it's affect payment table code
export const paymentStatus = {
  PAID: 'PAID',
  FAILED: 'FAILED',
  PAUSED: 'PAUSED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  NOT_INITIATED: 'NOT_INITIATED',
  NO_BILLING_REQUIRED: 'NO_BILLING_REQUIRED',
  RESUMED: 'RESUMED'
  // old --->
  // SUCCEEDED: 'succeeded',
  // FAILED: 'failed',
  // ALL: 'all',
  // PAUSED: 'paused',
  // STOPPED: 'stopped',
  // PENDING: 'pending',
  // 'NOT-INITIATED': 'not_initiated'
}
export const paymentStatusLabel = [
  { value: 'PAID', label: 'Paid' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PAUSED', label: 'Paused' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'PENDING', label: 'Pending' },
  {
    value: 'NOT_INITIATED',
    label: 'Billing Required(To be sent to the client)'
  },
  { value: 'NO_BILLING_REQUIRED', label: 'No Billing Required' },
  { value: 'RESUMED', label: 'Resumed' }
  // old --->
  // { value: 'succeeded', label: 'Succeeded' },
  // { value: 'failed', label: 'Failed' },
  // { value: 'all', label: 'All' },
  // { value: 'paused', label: 'Paused' },
  // { value: 'stopped', label: 'Stopped' },
  // { value: 'pending', label: 'Pending' },
  // { value: 'not_initiated', label: 'Not Initiated' }
]
// do not change order of paymentType it's affect payment table code
export const paymentType = {
  RECURRING: 'recurring',
  ONETIME: 'onetime',
  BOTH: 'both'
}
// do not change order of coachingPlanStatus it's affect coaching-plan code
export const coachingPlanStatus = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Terminate', value: 'STOPPED' },
  // { label: 'Pending', value: 'PENDING' },
  { label: 'Resumed', value: 'RESUMED' },
  { label: 'Paused', value: 'PAUSED' }
]

export const coachRecommends = 'Coach Recommends'
export const coachRecommendsUrl = 'coach-recommends'

export const leaderAssessments = 'Leader Assessment'
export const leaderAssessmentsUrl = 'leader-assessments'

export const surveyType = {
  AWARENESS: 'awareness',
  LEADER: 'leader',
  WISDOM: 'wisdom'
}
