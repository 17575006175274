import React, { useContext } from 'react'
import { Col, Card } from 'react-bootstrap'
import { ModelContext } from '@abroad/components'
import classNames from 'classnames'
const CreateInsight = ({
  children,
  intentionId,
  type,
  isRecording = false,
  recordAudio = () => {},
}) => {
  const { onShow, setModalData } = useContext(ModelContext)
  return (
    <Col xs={12} sm={12} md={12} lg={6} className='my-3 my-lg-0 px-3'>
      <Card
        className={classNames('border-0 border-radius-10 h-100', {
          'custom-gray-bg-100 cursor-pointer': type === 'text',
          'bg-abroad-blue': type === 'audio',
          'opacity-50': type === 'audio' && isRecording,
        })}
        onClick={() => {
          if (type === 'audio') {
            // if (!isRecording) {
            //   recordAudio()
            // }
          } else {
            onShow()
            setModalData({ intentionId: intentionId })
          }
        }}>
        <Card.Body className='d-flex justify-content-center align-items-center'>
          {children}
        </Card.Body>
      </Card>
    </Col>
  )
}

export default CreateInsight
