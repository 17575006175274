import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Row, Col } from 'react-bootstrap'
import TakeNewAssessment from './TakeNewAssessment'
import WisdomSummaryResult from './WisdomSummaryResult'
import GrowthPlanBtn from './GrowthPlanBtn'
import SurveyPreviousResults from './SurveyPreviousResults'
import { getAssessmentCurrentData } from '../../../utils/date'
import ABSpinner from '../../Spinner/index'
import { ModalProvider } from '../../common'
import { plans, surveyType } from '../../../helper'
import { useErrorService, useUserService } from '../../../services'
import CategoryModal from '../CategoryModal'
import moment from 'moment'

const WisdomAssessmentResultSection = ({
  showEnrollBtn = false,
  isLGScreen,
  API,
  isRenderOnAdmin = false,
  gtmLearnMoreBtnClickedAction = () => {},
  gtmAssessmentBtnClickedAction = () => {},
  gtmCompareAssessmentAction = () => {},
  gtmUpgradeButtonClickedAction = () => {},
  renderHeader,
  AssessmentEvent,
  apiFunction
}) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [survey, setSurvey] = useState(null)
  const { user } = useUserService()
  const history = useHistory()
  const Error = useErrorService()

  useEffect(() => {
    const getWisdomSurveyById = async () => {
      setIsLoading(true)
      try {
        const { data } = await apiFunction()
        if (data) {
          setSurvey(data)
        }
        setIsLoading(false)
      } catch (e) {
        if (e?.code === 'forbidden' || e?.code === 'not_taken_survey') {
          Error.showError(e)
          history.replace('/home')
        } else if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (id) {
      getWisdomSurveyById()
    } else {
      history.replace(isRenderOnAdmin ? '/home' : '/survey')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <main>
        <section
          className={classNames('abroad-fs-exclude wisdom-assessment', {
            'custom-offset-lg-1 custom-col-lg-12 px-lg-0': isRenderOnAdmin
          })}
        >
          <ModalProvider>
            <Row
              className={classNames('page-header', {
                'mb-32px':
                  moment().diff(moment(survey?.createdAt), 'days') === 0
              })}
            >
              <Col className='text-start d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <h3 className=' mb-0 s1 mt-3'>
                    Wisdom Assessment -{' '}
                    <br
                      className={
                        showEnrollBtn && isLGScreen ? 'd-block' : 'd-none'
                      }
                    />
                    {getAssessmentCurrentData(
                      survey?.createdAt,
                      'MMMM Do, YYYY'
                    )}
                  </h3>
                </div>
                {renderHeader()}
              </Col>
            </Row>
            {!isRenderOnAdmin &&
              moment().diff(moment(survey?.createdAt), 'days') >= 1 && (
                <TakeNewAssessment
                  variant='abroad-blue'
                  title='TAKE NEW ASSESSMENT'
                  className='mt-32px mb-32px'
                  API={API}
                  gtmAssessmentBtnClickedAction={gtmAssessmentBtnClickedAction}
                />
              )}
            <WisdomSummaryResult
              gtmLearnMoreBtnClickedAction={gtmLearnMoreBtnClickedAction}
              survey={survey}
            />
            {!isRenderOnAdmin && (
              <>
                {user?.planCode === plans.free.code ? (
                  <div className='upgrade-now bg-abroad-blue text-white mt-52px mb-52px border-radius-full'>
                    <h3 className='s2 text-center text-white mb-0'>
                      Unleash Unlimited Insights
                    </h3>
                    <h5 className='mt-3 s5 text-center mb-0'>
                      Upgrade to the Growth Plan for unlimited access to your
                      Awareness Assessment <br className='d-lg-block d-none' />{' '}
                      and chart your progress in real-time.
                    </h5>
                    <div className='text-center'>
                      <GrowthPlanBtn
                        buttonText='Inquire about Growth Plan'
                        buttonClasses='btn s10a text-uppercase leading-4 py-12px leading-4 mt-32px'
                        buttonEvent={AssessmentEvent}
                        gtmUpgradeButtonClickedAction={
                          gtmUpgradeButtonClickedAction
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <SurveyPreviousResults
                    compareAssessmentEvent={AssessmentEvent}
                    surveyType={surveyType.WISDOM}
                    gtmCompareAssessmentAction={gtmCompareAssessmentAction}
                    API={API}
                    isRenderOnAdmin={isRenderOnAdmin}
                  />
                )}
              </>
            )}
            <CategoryModal />
          </ModalProvider>
        </section>
      </main>
    </>
  )
}

export default WisdomAssessmentResultSection
