import React from 'react'

const UserProfileIcon = ({ isActive, ...rest }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='20'
      viewBox='0 0 17 20'
      className='align-self-center user-profile-icon ms-3'
      fill={isActive ? '#bf9000' : 'none'}
      {...rest}>
      <path
        d='M3.35557 11.8445L3.3622 11.8238L3.367 11.8025C3.59261 10.8055 4.71944 10.3256 5.58745 10.8487L7.68184 12.1109C8.47119 12.5865 9.45762 12.5865 10.247 12.1109L12.3414 10.8487C13.2094 10.3256 14.3362 10.8055 14.5618 11.8025L14.5667 11.8241L14.5735 11.8451L16.4505 17.6863C16.5277 18.0672 16.3511 18.4986 15.9337 18.8775C15.5117 19.2606 14.9323 19.5 14.4348 19.5H3.49403C2.99682 19.5 2.41999 19.2609 2.00043 18.8783C1.58558 18.4999 1.40963 18.0683 1.48736 17.686L3.35557 11.8445ZM13.4425 5C13.4425 7.48735 11.4355 9.5 8.9644 9.5C6.49329 9.5 4.48632 7.48735 4.48632 5C4.48632 2.51265 6.49329 0.5 8.9644 0.5C11.4355 0.5 13.4425 2.51265 13.4425 5Z'
        stroke={isActive ? '#bf9000' : 'black'}
      />
    </svg>
  )
}

export default UserProfileIcon
