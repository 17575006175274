import React from 'react'

/**
 * Cell for table data
 *
 * @param {*} cell                    // React table cell(table data) props
 */
const TableBodyCell = ({ cell, index, children, className }) => {
  return (
    <td
      {...cell.getCellProps()}
      width={cell.column.width}
      {...cell.column?.dataProps}
      className={`${className} ${cell.column?.dataProps?.className}`}>
      {children || cell.render('Cell') || cell.value}
    </td>
  )
}

export default TableBodyCell
