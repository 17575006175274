import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { CardsListing, PageHeader } from '../components'

const ChangeCard = () => {
  const { coachingPlanId, cardId } = useParams()
  const history = useHistory()

  const renderPageHelmet = () => {
    return (
      <Helmet>
        <title>{'Change Card | Abroad'}</title>
        <meta name='title' content={'Change Card | Abroad'}></meta>
        <meta property='og:title' content={'Change Card | Abroad'}></meta>
        <meta property='twitter:title' content={'Change Card | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}/card/${cardId}/change-card`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}/card/${cardId}/change-card`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/profile/plans-payments/coaching-plan/${coachingPlanId}/card/${cardId}/change-card`}></meta>
      </Helmet>
    )
  }

  return (
    <>
      {renderPageHelmet()}
      <main>
        <section className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
          <PageHeader
            title='Change Card'
            showBackArrow={true}
            backArrowAction={() => {
              if (history.length > 1) {
                history.goBack()
              } else {
                history.push('/home')
              }
            }}
          />
        </section>
        <section>
          <Container
            fluid
            className='custom-col-lg-8 custom-col-10 custom-offset-lg-1'>
            <Row noGutters>
              <Col className='px-0' lg={5}>
                <CardsListing
                  selectedCardDetails={{
                    id: cardId,
                  }}
                  isDiscardOnsubmit={false}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default ChangeCard
