import React from 'react'
import classNames from 'classnames'
import { leader360Type } from '../../utils/constants'

const AddNewRequestInput = ({ type, addNewInputs, isDisabled }) => {
  const contentId = document.getElementById('content')
  const inputId = document.getElementById('leader-360-inputs')

  const addNewRequestInputHandler = () => {
    if (isDisabled) return
    addNewInputs()

    // scroll at bottom logic
    if (type === leader360Type.SIMPLE360) {
      setTimeout(
        () =>
          inputId.scrollTo({
            top: inputId.scrollHeight,
            behavior: 'smooth',
          }),
        100,
      )
      return
    }
    setTimeout(() => {
      contentId.scrollTo(0, contentId.scrollHeight)
    }, 100)
  }

  return (
    <span
      className={classNames(
        'icon icon-plus font-40 text-abroad-blue align-self-center',
        {
          'cursor-disabled disable opacity-50': isDisabled,
        },
        {
          'cursor-pointer': !isDisabled,
        },
      )}
      onClick={addNewRequestInputHandler}></span>
  )
}
export default AddNewRequestInput
