import React from 'react'

/**
 * Table header
 *
 * @param {*} headerGroups                      // react-table header propa
 * @param {Function} renderCell                 // React renderer props to display header data
 */
const TableHeader = ({ headerGroups, renderCell }) => {
  return (
    <thead>
      {
        // Loop over the header rows
        headerGroups.map((headerGroup) => (
          // Apply the header row props
          <tr {...headerGroup.getHeaderGroupProps()}>
            {
              // Loop over the headers in each row
              renderCell(headerGroup.headers)
            }
          </tr>
        ))
      }
    </thead>
  )
}

export default TableHeader
