import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { plans, GrowthPlanBtn, useUserService } from '@abroad/components'
import TagManager from 'react-gtm-module'
import { UpgradePlanModalContext } from '../../utils/contexts'
import CoachingPlanBtn from './CoachingPlanBtn'
import assessmentEvent from '../../constants/events'

const UpgradePlanModal = () => {
  const { show, onHide, data } = useContext(UpgradePlanModalContext)
  const buttonClasses = 'btn s8 text-uppercase py-12px'
  const { user } = useUserService()

  const gtmUpgradeButtonClickedAction = (buttonText) => {
    // GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: assessmentEvent.event.upgradeButton,
        eventProps: {
          category: assessmentEvent.category.upgradeButtonCategory,
          action: 'User click on upgrade button',
          label: 'Click on upgrade button',
          value: `User Click on ${buttonText} button`,
          userId: user?.id,
        },
      },
    })
  }

  return (
    <Modal
      show={show}
      size='lg'
      onHide={onHide}
      aria-labelledby='upgrade-plan-modal'
      centered>
      <Modal.Body className='p-32px bg-abroad-blue text-white'>
        <div className='mb-12px'>
          <pre className='s2'>{data?.header}</pre>
        </div>
        <div className='s6a font-weight-500'>{data?.subHeader}</div>
        <div className='d-flex justify-content-between mt-52px align-items-center'>
          {data?.plan === plans.coaching.code ? (
            <CoachingPlanBtn
              buttonText={data?.btnText}
              buttonClasses={buttonClasses}
            />
          ) : (
            <GrowthPlanBtn
              buttonText={data?.btnText}
              buttonClasses={buttonClasses}
              gtmUpgradeButtonClickedAction={gtmUpgradeButtonClickedAction}
            />
          )}
          <div>
            <span
              className='icon icon-cross cursor-pointer'
              onClick={() => onHide()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UpgradePlanModal
