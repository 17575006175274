import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { ShimmerTable, ShimmerTitle } from 'react-shimmer-effects'
import { leaderAssessmentsUrl, useErrorService } from '@abroad/components'
import { getAssessmentCurrentData } from '../../utils/date'
import API from '../../utils/API'

const PreviousResults = ({ setCanCreateNewLAReq }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [previousResults, setPreviousResults] = useState([])
  const [rowNumber, setRowNumber] = useState(null)
  const Error = useErrorService()
  const { id } = useParams()

  useEffect(() => {
    const getLAdata = async () => {
      try {
        const { data } = await API.survey.getLeaderRequests()
        if (data) {
          setCanCreateNewLAReq(data?.canCreateNewLAReq)
          const modifiedCompletedRequests = data?.completedRequests?.map(
            (request) => ({ ...request, isCompleted: true }),
          )
          const modifiedPendingRequestsData = data?.pendingRequests?.map(
            (request) => ({ ...request, isPended: true }),
          )
          const filteredData = [
            ...modifiedCompletedRequests,
            ...modifiedPendingRequestsData,
          ]?.filter((assessment) => assessment?._id !== id)
          const sortedAssessments = filteredData?.sort((a, b) => {
            return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1
          })
          setPreviousResults(sortedAssessments)
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (id) getLAdata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isLoading) {
    return (
      <div className='previous-result'>
        <div className='w-25 mb-18px'>
          <ShimmerTitle line={1} />
        </div>
        <ShimmerTable row={2} col={3} />
      </div>
    )
  }

  if (previousResults?.length === 0) {
    return null
  }

  return (
    <div className='previous-result'>
      <h3 className='previous-result-title mb-18px mt-52px ms-2'>
        Previous Results
      </h3>
      {previousResults?.map((previousResult, index) => (
        <React.Fragment key={index}>
          {previousResult?._id !== id && (
            <div
              key={index}
              className={classNames('result-link assessment-result-link', {
                'first-result-link': index === 0,
                'border-bottom-transparent': index === rowNumber - 1,
              })}
              onMouseEnter={() => {
                setRowNumber(index)
              }}
              onMouseLeave={() => {
                setRowNumber(null)
              }}>
              <Link
                to={
                  previousResult?.isPended
                    ? `/survey/requests/${leaderAssessmentsUrl}/${previousResult?._id}`
                    : `/survey/leader/${previousResult?._id}`
                }
                className='result-active-link hover-text-bold ms-2 assessment-result-link'>
                {getAssessmentCurrentData(
                  previousResult?.createdAt,
                  'MMMM Do YYYY',
                )}{' '}
                {previousResult?.isPended ? '(Pending)' : ''}
              </Link>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default PreviousResults
