import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { ShimmerTable } from 'react-shimmer-effects'
import Imgix from 'react-imgix'
import API from '../../utils/API'
import { commonConst } from '../../constants/common'
import { getAssessmentCurrentData, sortedByDate } from '../../utils/date'

const QuestSession = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const handleRowClick = (sessionId) => {
    history.push(`/coaching/session/${sessionId}`)
  }

  const [individualAndGroupSessions, setIndividualAndGroupSessions] = useState(
    [],
  )

  useEffect(() => {
    const getSessions = async () => {
      try {
        const { data } = await API.session.getSession()
        const sessionDataFilteredByDate = [...data?.clients, ...data?.teams]
        sortedByDate(sessionDataFilteredByDate, 'date')
        setIndividualAndGroupSessions(sessionDataFilteredByDate)
        setIsLoading(false)
        // dispatch({ type: 'POST_SESSION_SUCCESS', payload: null })
      } catch (e) {
        setIsLoading(false)
        if (e?.code === 'upgrade_plan') {
          //   dispatch({ type: 'POST_SESSION_FAIL', payload: e })
        } else {
          //   dispatch({ type: 'POST_SESSION_FAIL', payload: null })
          Error.showError(e)
        }
      }
    }
    getSessions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImageName = (session) => {
    const { mediaModules = [], medias = [] } = session
    const r1 = mediaModules.find((e) => e.imageFilename)
    if (r1) {
      return r1.imageFilename
    }
    const r2 = medias.find((e) => e.imageFilename)
    return r2?.imageFilename || commonConst.abroadPlaceholderImage
  }

  const getTitleBySessionType = (session) => {
    if (session?.isGrowthSession) {
      return 'Growth Plan'
    }
    return !!session?.clientId ? 'Individual' : 'Group'
  }
  if (isLoading) {
    return (
      <>
        <ShimmerTable row={1} col={5} />
      </>
    )
  }

  return (
    <>
      {individualAndGroupSessions.filter(
        (session) => session.type === 'individual' && session.isGrowthSession,
      ).length > 0 && (
        <div className='table-responsive mt-32px'>
          <Table className='custom-table align-middle table-hover'>
            <thead>
              <tr className='s10a border-dark'>
                <th width='7%'></th>
                <th width='27%'>DATE</th>
                <th width='27%'>SESSION TYPE</th>
                <th width='27%'>COACH</th>
                <th width='12%' className='text-end'>
                  NOTES
                </th>
              </tr>
            </thead>
            <tbody>
              {individualAndGroupSessions
                .filter(
                  (session) =>
                    session.type === 'individual' && session.isGrowthSession,
                )
                .map((session, index) => (
                  <tr
                    key={index === 0}
                    className='cursor-pointer'
                    onClick={() => handleRowClick(session._id)}>
                    <td width='7%' className='ps-0'>
                      <div>
                        <Imgix
                          className='border-radius-5'
                          imgixParams={{
                            crop: 'edges',
                            fit: 'crop',
                            w: 144,
                            h: 144,
                          }}
                          src={`${
                            process.env.REACT_APP_IMG_SOURCE
                          }/${getImageName(session)}`}
                          height={48}
                          width={48}
                          alt='image'
                        />
                      </div>
                    </td>
                    <td width='27%'>
                      {getAssessmentCurrentData(session?.date, 'MMMM Do YYYY')}
                    </td>
                    <td width='27%'>{getTitleBySessionType(session)}</td>
                    <td width='27%'>{session?.coachId?.fullName}</td>
                    <td width='12%' className='text-end'>
                      <div className='pe-2 text-saffron-700 hover'>View</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  )
}

export default QuestSession
