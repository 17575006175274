import React from 'react'
import classNames from 'classnames'

const CloseIcon = ({
  className = '',
  discardMargin = false,
  color,
  fontSize,
  ...rest
}) => {
  return (
    <span
      className={classNames(
        'icon icon-close icon-cross font-16 cursor-pointer',
        { 'ml-2': !discardMargin },
        className
      )}
      style={{
        ...(color && { color: color }),
        ...(fontSize && { fontSize: fontSize })
      }}
      {...rest}
    />
  )
}

export default CloseIcon
